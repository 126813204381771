import React, { useCallback, useEffect, useState } from 'react';
import {
	CourseTypeEnum,
	LessonType,
	LessonTypeEnum,
	LessonTypeToText,
} from 'components/_v2/Course/type';
import { Image } from 'react-bootstrap';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import {
	QuizReattemptDetails,
	QuizResultStatusEnum,
} from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';
import { CERTIFICATE_LESSON } from 'types/globalTypes';
import './Accordion.css';
import { PRE_RECORDED_COURSE_KEY } from 'pages/auth/core/AuthHelpers';
import { useCourseContext } from 'context/CourseContext';
import { toast } from 'react-hot-toast';
import Loading from 'components/_v2/CommonComponents/Loading';
import { quizLocalStorageKey } from 'context/QuizContext';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { UPSERT_COURSE_PROGRESS } from 'components/Courses-Page/core/requests';

const quizDurationFormatter = (duration: number) => {
	const minutes = Math.floor(duration / 60);
	const remainingSeconds = duration % 60;
	return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const AccordionBody = ({
	lessons,
	showLockIcon,
	courseType,
	currentSelectedLessonId,
	quizReattemptList,
	handleLessonChange,
	fullScale,
	hasCertificate = false,
	courseId,
}: {
	lessons: LessonType[];
	courseType: CourseTypeEnum;
	showLockIcon: boolean;
	currentSelectedLessonId?: string;
	quizReattemptList?: QuizReattemptDetails[];
	handleLessonChange?: (lessonId: string) => void;
	fullScale?: boolean;
	hasCertificate?: boolean;
	courseId: string;
}) => {
	const { userCourses, setUserCourses } = useCourseContext();

	const [localStoragePreRecordedCourseData, setLocalStoragePreRecordedCourseData] = useState(() => {
		return JSON.parse(localStorage.getItem(PRE_RECORDED_COURSE_KEY));
	});

	const [upsertCourse, { loading: loadingUpsert, error: errorUpsert }] =
		useMutation(UPSERT_COURSE_PROGRESS);

	const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

	const handleDownloadFile = async (downloadContentUrl: string, lessonData: LessonType) => {
		setIsDownloadingPdf(true);
		try {
			const response = await fetch(downloadContentUrl);
			const blob = await response.blob();
			const courseName = userCourses.find((course) => course.id === courseId)?.name;
			const fileName = `${courseName}:${lessonData.name}`;
			const blobUrl = window.URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(blobUrl); // Cleanup
			upsertCourse({
				variables: {
					upsertCourseProgressInput: {
						courseId,
						lessonId: lessonData.id,
						sectionId: lessonData.sectionId,
						duration: 100,
						isLessonCompleted: true,
					},
				},
				onCompleted: () => {
					const localStorageForPreRecorded = JSON.parse(
						localStorage.getItem(PRE_RECORDED_COURSE_KEY),
					);
					const recordedCoursePdfInLocalStorage =
						localStorageForPreRecorded?.[courseId]?.[lessonData.sectionId]?.[lessonData.id];
					if (recordedCoursePdfInLocalStorage) {
						recordedCoursePdfInLocalStorage.completed = true;
						recordedCoursePdfInLocalStorage.completedDuration = 100;
					}
					localStorage.setItem(PRE_RECORDED_COURSE_KEY, JSON.stringify(localStorageForPreRecorded));
				},
				onError: () => {
					toast.error('Error updating progress!');
				},
			});
			setIsDownloadingPdf(false);
		} catch (e) {
			toast.error('Could not download the PDF.');
			setIsDownloadingPdf(false);
		}
	};

	useEffect(() => {
		const areAllLessonsCompletedInTheCourse = Object.values(
			localStoragePreRecordedCourseData?.[courseId] || {},
		).every((sections) =>
			Object.values(sections).every((lesson: { completed: boolean }) => lesson.completed),
		);
		if (areAllLessonsCompletedInTheCourse) {
			setUserCourses((prevState) => {
				return prevState.map((item) =>
					item.id === courseId && item.courseType.slug === CourseTypeEnum.RECORDED_COURSE
						? {
								...item, // Spread the original item to create a new object
								// Update the property immutably
								sections: item.sections.map((section, idx) => ({
									...section,
									lessons: section.lessons.map((lesson, lessonIdx) =>
										idx === item.sections.length - 1 && lessonIdx === section.lessons.length - 1
											? {
													...lesson,
													isLock: false,
													disabledSelection: false,
											  }
											: lesson,
									),
								})),
						  }
						: item,
				);
			});
		}
	}, [localStoragePreRecordedCourseData]);

	useEffect(() => {
		const handleStorageChange = () => {
			const updatedValue = JSON.parse(localStorage.getItem(PRE_RECORDED_COURSE_KEY));
			setLocalStoragePreRecordedCourseData(updatedValue);
		};

		// Add event listener for changes made in different tabs
		window.addEventListener('storage', handleStorageChange);

		// Override `localStorage.setItem` to detect same-tab updates
		const originalSetItem = localStorage.setItem;
		localStorage.setItem = function (key, value) {
			originalSetItem.apply(this, arguments); // Call the original method
			if (key === PRE_RECORDED_COURSE_KEY) {
				// Dispatch custom event for same-tab updates
				window.dispatchEvent(new Event('localStorageUpdated'));
			}
		};

		// Add event listener for custom event
		window.addEventListener('localStorageUpdated', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
			window.removeEventListener('localStorageUpdated', handleStorageChange);
			localStorage.setItem = originalSetItem; // Restore original method
		};
	}, []);

	const listItemRenderer = useCallback(
		(lesson: LessonType) => {
			const quizDuration = lesson.quiz && quizDurationFormatter(lesson.quiz.duration);
			const showYellowBackground = lesson.id === currentSelectedLessonId;
			const showPendingOrFailedQuizStatus = quizReattemptList?.find(
				(quizDetails) => quizDetails.lessonId === lesson.id && quizDetails.quizId === lesson.quizId,
			);
			const isCertificateDisabledAdditionalCondition =
				lesson.id === CERTIFICATE_LESSON && !!quizReattemptList?.length;

			const allowReattemptQuiz =
				quizReattemptList?.find(
					(listItem) => listItem.lessonId === lesson.id && listItem.quizId === lesson.quizId,
				)?.allowReAttempt || false;

			const renderQuizStatus = (quizReattemptDetails: QuizReattemptDetails) => {
				const localStorageDetails = JSON.parse(sessionStorage.getItem(quizLocalStorageKey));
				if (localStorageDetails) {
					const allExistingLessonIds = Object.keys(localStorageDetails);
					const lessonIdWithActiveQuiz = allExistingLessonIds.find((lessonId: string) =>
						moment(localStorageDetails[lessonId]?.endTime).isSameOrAfter(moment()),
					);
					if (quizReattemptDetails.lessonId === lessonIdWithActiveQuiz) {
						return;
					}
				}
				switch (quizReattemptDetails?.status) {
					case QuizResultStatusEnum.PENDING:
						return (
							<span
								style={{ color: '#D66600', fontSize: '12px' }}
								className="d-flex align-items-center p_reg gap-2">
								<Image
									src={toAbsoluteUrl('/media/course-detail-images/information-circle.svg')}
									height="14px"
								/>
								Please attempt the quiz to continue
							</span>
						);
					case QuizResultStatusEnum.FAILED:
						return (
							<span
								style={{
									color: quizReattemptDetails?.allowReAttempt ? '#d66600' : '#EE0000',
									fontSize: '12px',
								}}
								className="d-flex align-items-center gap-2">
								<Image
									src={toAbsoluteUrl('/media/course-detail-images/information-circle.svg')}
									height="14px"
								/>
								{quizReattemptDetails?.allowReAttempt
									? 'Please reattempt the quiz to continue'
									: 'You have failed all attempts on quiz'}
							</span>
						);
					default:
						return null;
				}
			};

			const addExtraFunctions = handleLessonChange
				? {
						onClick: () =>
							lesson?.disabledSelection || isCertificateDisabledAdditionalCondition
								? null
								: handleLessonChange(lesson.id),
				  }
				: {};

			return (
				<div
					className={`d-flex flex-md-row rounded-2 flex-row gap-2 p-2 ${
						showYellowBackground ? 'selectedLesson' : ''
					} ${
						lesson.disabledSelection || isCertificateDisabledAdditionalCondition
							? 'disabledSelection'
							: ''
					}`}
					{...addExtraFunctions}>
					<div className="align-item-center">
						{fullScale ? (
							<Image src={toAbsoluteUrl('/media/icons/list-item.svg')} height={20} width={20} />
						) : (
							<Image src={toAbsoluteUrl('/media/icons/circle-bullet.svg')} height={20} width={20} />
						)}
					</div>
					<div className="flex-grow-1 flex-column text-xl-start text-lg-start text-start">
						<p className="p_reg mb-1">{lesson.name}</p>
						{lesson.quizId &&
							[QuizResultStatusEnum.PENDING, QuizResultStatusEnum.FAILED].includes(
								showPendingOrFailedQuizStatus?.status,
							) &&
							renderQuizStatus(showPendingOrFailedQuizStatus)}
						{isCertificateDisabledAdditionalCondition && (
							<div className="d-flex align-items-center gap-2">
								<Image
									src={toAbsoluteUrl('/media/course-detail-images/information-circle.svg')}
									height="14px"
								/>
								<span className="fs-12 text-danger p_reg">
									Pass all quizzes{' '}
									{hasCertificate ? 'to enable download certificate' : 'for course completion'}
								</span>
							</div>
						)}
						{lesson.type === LessonTypeEnum.DOCS &&
							lesson.id != CERTIFICATE_LESSON &&
							!lesson.isLock &&
							fullScale && (
								<div
									className={`border-grey-color fs-12 z-2 d-flex gap-2 px-2 py-2 ${
										isDownloadingPdf ? 'disabled' : ''
									}`}
									style={{ width: 'fit-content', borderRadius: '4px' }}
									role="button"
									onClick={async () => await handleDownloadFile(lesson.contentUrl, lesson)}>
									{isDownloadingPdf ? (
										<Loading width="20px" />
									) : (
										<Image
											src={toAbsoluteUrl('/media/MyCourses/download-pdf.svg')}
											alt="Download"
										/>
									)}
									Download PDF
								</div>
							)}
					</div>

					{lesson.quizId && fullScale && lesson.quiz.duration > 0 && lesson.quiz.hasTimeLimit ? (
						<p>{quizDuration} min</p>
					) : null}
					{showLockIcon ? (
						<>
							{courseType === CourseTypeEnum.RECORDED_COURSE && lesson.id != CERTIFICATE_LESSON ? (
								//User Course Progress table checkbox
								!localStoragePreRecordedCourseData?.[courseId]?.[lesson.sectionId]?.[lesson.id]
									?.completed ? (
									<div
										className="border-style-black bg-white"
										style={{ width: '20px', height: '20px' }}
									/>
								) : (
									<div className="checkmark-box">
										<div className="checkmark" />
									</div>
								)
							) : showPendingOrFailedQuizStatus?.status === QuizResultStatusEnum.FAILED &&
							  !allowReattemptQuiz ? (
								<Image src={toAbsoluteUrl('/media/course-detail-images/failed-quiz.svg')} />
							) : showPendingOrFailedQuizStatus?.status === QuizResultStatusEnum.FAILED &&
							  allowReattemptQuiz ? (
								<Image src={toAbsoluteUrl('/media/course-detail-images/reattempt-quiz.svg')} />
							) : (
								<Image
									src={toAbsoluteUrl(
										`/media/course-detail-images/square-${
											lesson.isLock || isCertificateDisabledAdditionalCondition
												? 'lock.svg'
												: 'unlock.svg'
										}`,
									)}
								/>
							)}
						</>
					) : (
						<button className="btn type-btn pe-none">{LessonTypeToText[lesson.type]}</button>
					)}
				</div>
			);
		},
		[
			currentSelectedLessonId,
			quizReattemptList,
			handleLessonChange,
			localStoragePreRecordedCourseData,
		],
	);

	return (
		<div className="accordion-body">
			{lessons.map((lesson) => (
				<div key={lesson.id} className="content-list">
					{listItemRenderer(lesson)}
				</div>
			))}
		</div>
	);
};

export default AccordionBody;
