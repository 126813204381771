import React, { useEffect } from 'react';
import { useUserContext } from 'context/UserContext';
import { removeChatStickyFromUi } from 'utils/helpers';
import {
	ENROLL_URL,
	PAYMENT_DETAILS_NO_USER,
	VIEW_COURSE_DATA_URL,
	ZOOM_MEETING,
} from 'pages/routing/routePages';

declare global {
	interface Window {
		RocketChat: any;
	}
}

const ChatStickyUi = () => {
	const { user } = useUserContext();
	const pathname = window.location.pathname;

	useEffect(() => {
		const CHAT_URL = process.env.REACT_APP_ROCKET_CHAT_URL;
		const SCRIPT_URL = process.env.REACT_APP_ROCKET_CHAT_SCRIPT;
		const loadRocketChat = () => {
			return new Promise<void>((resolve, reject) => {
				(function (w, d, s, u) {
					if (w.RocketChat) {
						console.log('Rocket.Chat already loaded.');
						resolve();
						return;
					}

					w.RocketChat = function (c) {
						w.RocketChat._.push(c);
					};
					w.RocketChat._ = [];
					w.RocketChat.url = u;

					const h = d.getElementsByTagName(s)[0];
					const j = d.createElement(s) as HTMLScriptElement;
					j.async = true;
					j.src = CHAT_URL;

					j.onload = () => {
						console.log('Rocket.Chat script loaded successfully.');
						resolve();
					};
					j.onerror = () => {
						console.error('Rocket.Chat script failed to load.');
						reject(new Error('Rocket.Chat script failed to load'));
					};

					h.parentNode?.insertBefore(j, h);
				})(window, document, 'script', SCRIPT_URL);
			});
		};

		const waitForRocketChat = () => {
			return new Promise<void>((resolve, reject) => {
				let attempts = 0;
				const maxAttempts = 5;
				const intervalId = setInterval(() => {
					if (window.RocketChat) {
						clearInterval(intervalId);
						console.log('Rocket.Chat instance is now available.');
						resolve();
					} else if (attempts >= maxAttempts) {
						clearInterval(intervalId);
						reject(new Error('Rocket.Chat instance is not available after multiple attempts.'));
					} else {
						console.log(`Attempt ${attempts + 1}: Waiting for Rocket.Chat instance...`);
						attempts++;
					}
				}, 500);
			});
		};

		const registerGuestUser = () => {
			const name = user?.fullName;
			const email = user?.email;

			window.RocketChat(function () {
				this.registerGuest({
					name: name,
					email: email,
				});
			});
		};

		loadRocketChat()
			.then(waitForRocketChat)
			.then(registerGuestUser)
			.then(() => {
				if (
					[ENROLL_URL, PAYMENT_DETAILS_NO_USER, VIEW_COURSE_DATA_URL, ZOOM_MEETING].some((url) =>
						pathname.includes(url),
					)
				) {
					removeChatStickyFromUi();
				}
			})
			.catch((error) => console.error(error.message));
	}, [user]);

	return <div> </div>;
};

export default ChatStickyUi;
