import { useEffect } from 'react';
import { AUTH_LOCAL_STORAGE_KEY } from 'pages/auth/core/AuthHelpers';
import { BASE_PAGE_URL } from 'pages/routing/routePages';

const useLocalStorageSync = (setUser) => {
	useEffect(() => {
		const handleStorageChange = (event) => {
			if (event.key === AUTH_LOCAL_STORAGE_KEY) {
				const updatedUser = JSON.parse(localStorage.getItem('user'));
				setUser(updatedUser);
				if (!updatedUser) {
					window.location.href = BASE_PAGE_URL;
				}
			}
		};

		window.addEventListener('storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, [setUser]);
};

export default useLocalStorageSync;
