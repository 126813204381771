import { quizLocalStorageKey } from 'context/QuizContext';
import moment from 'moment';

const removeChatStickyFromUi = () => {
	const rocketChatInstance = document.querySelector('.rocketchat-widget');
	if (rocketChatInstance) {
		const styleAttributes = rocketChatInstance.getAttribute('style');
		const newStyleAttributes = styleAttributes + 'display: none;';
		rocketChatInstance.setAttribute('style', newStyleAttributes);
	}
};

const reShowChatStickyOnUi = () => {
	const rocketChatInstance = document.querySelector('.rocketchat-widget');
	if (rocketChatInstance) {
		const styleAttributes = rocketChatInstance.getAttribute('style');
		const newStyleAttributes = styleAttributes.replace('display: none;', 'display: block;');
		rocketChatInstance.setAttribute('style', newStyleAttributes);
	}
};

const isAnyQuizAlreadyStarted = () => {
	const sessionStorageItemForAnyExistingQuiz =
		JSON.parse(sessionStorage.getItem(quizLocalStorageKey)) || {};
	if (Object.keys(sessionStorageItemForAnyExistingQuiz).length > 0) {
		if (moment().isAfter(moment(sessionStorageItemForAnyExistingQuiz.endTime))) {
			sessionStorage.removeItem(quizLocalStorageKey);
			return false;
		}
		return true;
	}
	return false;
};

export { removeChatStickyFromUi, reShowChatStickyOnUi, isAnyQuizAlreadyStarted };
