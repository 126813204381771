import { ZoomMtg } from '@zoom/meetingsdk';
import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import {
	ZoomMeetingApiResponse,
	ZoomMeetingDetails,
} from 'components/_v2/ViewCourseData/LessonTypesComponents/type';
import {
	COURSE_URL,
	getDetailsSubUrl,
	getViewCourseWithSearchSlug,
	getViewWebinarWithSearchSlug,
	MY_COURSE_URL,
} from 'pages/routing/routePages';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useCourseContext } from 'context/CourseContext';
import { useZoomMeeting } from 'context/ZoomMeetingContext';
import Loading from 'components/_v2/CommonComponents/Loading';
import { removeChatStickyFromUi } from 'utils/helpers';
import { useUserContext } from 'context/UserContext';
import useCourses from 'hooks/useCourses';
import { GetZoomMeetingResponse } from 'generated/types';

const GET_ZOOM_MEETING_DETAILS = gql`
	query GetZoomMeetingDetails($lessonID: String!) {
		getZoomMeetingDetails(lessonID: $lessonID) {
			username
			email
			signature
			sdkKey
			meetingNumber
			password
		}
	}
`;

const GET_ZOOM_MEETING_WEBINAR_DETAILS = gql`
	query GetWebinarZoomMeetingDetails($courseID: String!) {
		getWebinarZoomMeetingDetails(courseID: $courseID) {
			username
			email
			signature
			sdkKey
			meetingNumber
			password
		}
	}
`;

const ZoomMeeting = () => {
	useLayoutEffect(() => {
		ZoomMtg.preLoadWasm();
		ZoomMtg.prepareWebSDK();
	}, []);

	const { user, loadingUser } = useUserContext();
	const location = useLocation();
	const { setIsZoomVisited } = useZoomMeeting();
	const { search } = location;
	const lessonID: string = new URLSearchParams(search).get('lessonId');
	const courseId: string = new URLSearchParams(search).get('courseId');
	const isWebinar: string = new URLSearchParams(search).get('isWebinar');

	const { userCourses, courses } = useCourseContext();
	const { loadingPublicCourses, loadingStudentCourses } = useCourses();

	const [zoomDetails, setZoomDetails] = useState<ZoomMeetingDetails>();

	const [getZoomDetails, { loading }] = useLazyQuery<ZoomMeetingApiResponse>(
		GET_ZOOM_MEETING_DETAILS,
		{
			variables: { lessonID },
			onCompleted: (data: ZoomMeetingApiResponse) => {
				setZoomDetails(data.getZoomMeetingDetails);
				setIsZoomVisited(true);
			},
			onError: (err: ApolloError) => {
				toast.error(err.message);
				window.open(user ? MY_COURSE_URL : COURSE_URL, '_self');
			},
		},
	);

	const [getZoomWebinarDetails, { loading: loadingWebinar }] = useLazyQuery<{
		getWebinarZoomMeetingDetails: GetZoomMeetingResponse;
	}>(GET_ZOOM_MEETING_WEBINAR_DETAILS, {
		variables: {
			courseID: courseId,
		},
		onCompleted: (data) => {
			setZoomDetails(data.getWebinarZoomMeetingDetails);
			setIsZoomVisited(true);
		},
		onError: (err: ApolloError) => {
			toast.error(err.message);
			window.open(user ? MY_COURSE_URL : COURSE_URL, '_self');
		},
	});

	useEffect(() => {
		if (
			loadingUser ||
			loadingPublicCourses ||
			loadingStudentCourses ||
			(user && userCourses.length === 0) ||
			courses.length === 0
		) {
			return;
		}
		if (!user) {
			toast.error('You are not authorised');
			const courseDetails = courses.find((course) => course?.id === courseId);
			window.open(getDetailsSubUrl(courseDetails?.slug), '_self');
		} else {
			const doesCourseExistInUserCourses = userCourses.find((course) => course.id === courseId);
			if (!doesCourseExistInUserCourses) {
				window.open(MY_COURSE_URL, '_self');
			} else {
				if (doesCourseExistInUserCourses?.completed) {
					window.open(getViewCourseWithSearchSlug(doesCourseExistInUserCourses?.slug), '_self');
					return;
				}
				if (isWebinar) {
					getZoomWebinarDetails();
				} else {
					getZoomDetails();
				}
			}
		}
	}, [user, courseId, userCourses, loadingUser, courses]);

	function startMeeting() {
		document.getElementById('zmmtg-root').style.display = 'block';

		const baseURL = window.location.origin;
		const courseSlug = userCourses.find((course) => course.id === courseId)?.slug;
		const leaveUrl = `${baseURL}${
			isWebinar ? getViewWebinarWithSearchSlug(courseSlug) : getViewCourseWithSearchSlug(courseSlug)
		}`;
		// const leaveUrl = `${baseURL}${MY_COURSE_URL}`;

		ZoomMtg.init({
			leaveUrl,
			patchJsMedia: true,
			meetingInfo: [],
			leaveOnPageUnload: true,
			success: () => {
				removeChatStickyFromUi();
				ZoomMtg.join({
					signature: zoomDetails.signature,
					sdkKey: zoomDetails.sdkKey,
					meetingNumber: zoomDetails.meetingNumber,
					passWord: zoomDetails.password,
					userName: zoomDetails.username,
					userEmail: zoomDetails.email,
					// tk: registrantToken,
					// zak: zakToken,
					success: (success) => {
						ZoomMtg.getAttendeeslist({});
						ZoomMtg.getCurrentUser({
							success: function (res) {
								console.log('success getCurrentUser', res.result.currentUser);
							},
						});
					},
					error: (error) => {
						console.log(error);
						toast.error(error.message);
						window.open(MY_COURSE_URL);
					},
				});
			},
			error: (error) => {
				console.log(error);
				toast.error(error.message);
				window.open(MY_COURSE_URL);
			},
		});
	}

	useEffect(() => {
		const element = document.getElementById('zmmtg-root');

		if (element) {
			element.style.display = 'none';
		}

		if (zoomDetails) {
			startMeeting();
		}
	}, [zoomDetails]);

	if (loading || loadingWebinar) {
		return <Loading width={250} height="100vh" />;
	}

	return <></>;
	//
	// return (
	// 	<div className="test" style={{ width: 300, height: 300 }}>
	// 		<main>
	// 			<h1>Zoom Meeting SDK Sample React</h1>
	//
	// 			<button onClick={startMeeting}>Join Meeting</button>
	// 		</main>
	// 	</div>
	// );
};

export default ZoomMeeting;
