import React, { useState } from 'react';
import './MyCourse.css';
import { SelectedTabType } from 'components/_v2/CommonComponents/types';
import CommonTabView from 'components/_v2/CommonComponents/CommonTabView';
import { useLocation } from 'react-router-dom';

const MyCourse = () => {
	const { search } = useLocation();
	const selectedTabType = new URLSearchParams(search).get('courseMode') as SelectedTabType;

	return (
		<section style={{ backgroundColor: 'white' }}>
			{/*<div className="container-fluid">*/}
			{/*	<ul className="px-0" style={{ marginBottom: '-5px' }} id="myTab" role="tablist">*/}
			{/*		<li className="nav-item nav_item" role="presentation">*/}
			{/*			<button*/}
			{/*				className={`nav-style g_mid ${activeTab === 'ongoing' && 'active'}`}*/}
			{/*				type="button"*/}
			{/*				onClick={() => handleTabChange('ongoing')}>*/}
			{/*				Ongoing*/}
			{/*			</button>*/}
			{/*		</li>*/}
			{/*		<li className="nav-item nav_item" style={{ marginLeft: '-40px' }} role="presentation">*/}
			{/*			<button*/}
			{/*				className={`nav-style g_mid ${activeTab === 'completed' && 'active'}`}*/}
			{/*				type="button"*/}
			{/*				onClick={() => handleTabChange('completed')}>*/}
			{/*				Completed*/}
			{/*			</button>*/}
			{/*		</li>*/}
			{/*	</ul>*/}
			{/*	<div id="myTabContent">*/}
			{/*		<div*/}
			{/*			className={`tab-pane fade ${activeTab === 'ongoing' && 'show active'}`}*/}
			{/*			id="home-tab-pane"*/}
			{/*			role="tabpanel">*/}
			{/*			{activeTab === 'ongoing' && (*/}
			{/*				<OnGoingCourse enrollmentCourses={userCourseData.inProgressCourses} />*/}
			{/*			)}*/}
			{/*		</div>*/}
			{/*		<div*/}
			{/*			className={`tab-pane fade ${activeTab === 'completed' && 'show active'}`}*/}
			{/*			id="profile-tab-pane"*/}
			{/*			role="tabpanel">*/}
			{/*			{activeTab === 'completed' && (*/}
			{/*				<CompletedCourse*/}
			{/*					enrollmentCourses={userCourseData.completedCourses}*/}
			{/*					hasPurchasedAnyCourse={*/}
			{/*						!!userCourseData.inProgressCourses.length ||*/}
			{/*						!!userCourseData.completedCourses.length*/}
			{/*					}*/}
			{/*					onGoToMyCourse={() => handleTabChange('ongoing')}*/}
			{/*				/>*/}
			{/*			)}*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}

			<CommonTabView
				isUserSpecificContent={true}
				selectedTabType={selectedTabType || SelectedTabType.ALL}
			/>
		</section>
	);
};

export default MyCourse;
