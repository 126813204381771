// src/layouts/DashboardLayout.jsx
import React, { FC, ReactNode } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// import { AsideDefault } from '../../_web3tek/layout/components/aside/AsideDefault';
// import { AsideDefault } from '../../assets/aside/AsideDefault';
import './DashboardLayout.css';
import Sidebar from './Sidebar';
import HGHeader from 'components/_v2/Header/HGHeader';
import { VIEW_COURSE_DATA_URL, VIEW_WEBINAR_DATA_URL } from 'pages/routing/routePages';
import ViewCourseHeader from 'components/_v2/Header/ViewCourseHeader';
import useCourses from 'hooks/useCourses';
import ViewCourseData from 'components/_v2/ViewCourseData/ViewCourseData';
import { QuizProvider } from 'context/QuizContext';
import useIsSmallScreen from '../../hooks/useIsSmallScreen';
import HGFooter from 'components/_v2/Footer/HGFooter';
import { CourseTypeEnum } from 'components/_v2/Course/type';
import ViewWebinarData from 'components/_v2/ViewCourseData/ViewWebinarData';

type WithChildren = {
	children?: ReactNode;
	isHomePage: boolean;
	isCourseDetails: boolean;
};

const Content: FC<WithChildren> = ({ isHomePage, children, isCourseDetails }: any) => {
	return (
		<div
			id="kt_content_container"
			className={`w-100 ${!isHomePage ? 'mt-5' : ''}`}
			style={{ top: isCourseDetails ? -50 : 50, position: 'relative' }}>
			{children}
		</div>
	);
};

const DashboardLayout = () => {
	const { pathname } = useLocation();
	const { getCourseFromSlug } = useCourses();
	const { search } = useLocation();
	const courseSlug = new URLSearchParams(search).get('course-slug');
	const isTabletView = useIsSmallScreen(1200);

	const isViewCourseURL = [VIEW_COURSE_DATA_URL, VIEW_WEBINAR_DATA_URL].some((url) =>
		pathname.includes(url),
	)
		? {
				isViewCourse: true,
				course: getCourseFromSlug(courseSlug),
		  }
		: {
				isViewCourse: false,
				course: undefined,
		  };

	return (
		<div>
			{!isViewCourseURL.isViewCourse ? (
				<>
					<HGHeader />
					<div className="container pb-3">
						<div className="row student-layout mx-xxl-5 mx-xl-3">
							<div className="col-xl-3 sidebar mt-5">
								<Sidebar />
							</div>

							<div className="col-xl-9 mt-xl-5 elementMinHeight">
								<Outlet />
							</div>
						</div>
					</div>
					<HGFooter />
				</>
			) : (
				<>
					{isTabletView && <HGHeader />}
					<QuizProvider>
						<ViewCourseHeader course={isViewCourseURL.course} />
						<div className="padding-layout mt-xl-0 container mt-5 pt-5">
							{isViewCourseURL?.course?.courseType?.slug === CourseTypeEnum.WEBINAR ? (
								<ViewWebinarData />
							) : (
								<ViewCourseData />
							)}
						</div>
					</QuizProvider>
				</>
			)}
		</div>
	);
};

export { DashboardLayout };
