import Select, { StylesConfig } from 'react-select';
import React from 'react';
import { FormikProps } from 'formik';
import { components } from 'react-select';
import { FaChevronDown, FaSearch, FaChevronUp } from 'react-icons/fa';

interface OptionType {
	value: string;
	label: string;
}

const customSelectStyles: StylesConfig<OptionType, false> = {
	control: (provided, state) => ({
		...provided,
		backgroundColor: 'white',
		borderColor: 'var(--bs-border-width) solid var(--bs-border-color)',
		boxShadow: state.isFocused ? '0 0 0 1px #ddd' : 'none',
		'&:hover': { cursor: 'pointer' },
		padding: '7px 0',
		fontSize: 'var(--fs-14)',
		lineHeight: '21px',
		fontFamily: 'g_reg',
		'&:not(:placeholder-shown)': {
			borderColor: 'var(--color16)',
		},
	}),
	menu: (provided) => ({
		...provided,
		backgroundColor: '#fff',
		borderRadius: '4px',
		borderColor: '#ccc',
		padding: 0,
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isFocused ? '#f0f0f0' : '#fff',
		color: '#000',
		padding: '10px 15px',
		'&:active': {
			backgroundColor: 'var(--color2)',
			color: 'black',
		},
		fontSize: 'var(--fs-14)',
		lineHeight: '21px',
		fontFamily: 'g_reg',
	}),
	singleValue: (provided) => ({
		...provided,
		color: 'var(--color16)', // Text color in the control when selected,
		fontSize: 'var(--fs-14)',
		lineHeight: '21px',
		fontFamily: 'g_reg',
	}),
};

export const CustomDropdownIndicator = (props: any) => {
	const { menuIsOpen, isSearchable } = props.selectProps;
	return (
		<components.DropdownIndicator {...props}>
			{isSearchable && <FaSearch style={{ marginRight: '8px' }} />}
			{menuIsOpen ? <FaChevronUp /> : <FaChevronDown />}
		</components.DropdownIndicator>
	);
};

const CommonSelect = ({
	handleChange,
	options,
	id,
	formik,
	isSearchable,
	isRequired = false,
	label,
}: {
	handleChange: (selectedOption: OptionType) => void;
	options: any[];
	id: string;
	formik: FormikProps<any>;
	isSearchable: boolean;
	isRequired?: boolean;
	label: string;
}) => {
	const selectedOption = options?.find((option) => option.value === formik.values[id]) || null;

	return (
		<>
			<label htmlFor={id} className="profile-labels g_mid">
				{label}
			</label>
			{isRequired && <span className="text-danger">*</span>}
			<br />
			<Select
				id={id}
				name={id}
				options={options}
				value={selectedOption}
				onChange={handleChange}
				placeholder={`Select ${label}`}
				classNamePrefix="react-select"
				styles={customSelectStyles}
				isSearchable={isSearchable}
				components={{
					DropdownIndicator: CustomDropdownIndicator, // Use the custom dropdown indicator
				}}
			/>
			{formik.touched[id] && formik.errors[id] ? (
				<div className="text-danger fs-7 mt-1">{formik.errors[id] as string}</div>
			) : null}
		</>
	);
};

export default CommonSelect;
