import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { LessonType } from 'components/_v2/Course/type';
import { Image } from 'react-bootstrap';
import LessonTypeRenderer from './LessonTypeRenderer';

const LessonContent = ({
	lessonData,
	courseId,
	courseType,
}: {
	lessonData: LessonType;
	courseId: string;
	courseType: string;
}) => {
	const isLocked = lessonData.isLock;

	if (isLocked) {
		return <Image src={toAbsoluteUrl('/media/course-detail-images/locked-lesson.svg')} />;
	}
	return <LessonTypeRenderer lessonData={lessonData} courseId={courseId} courseType={courseType} />;
};

export default LessonContent;
