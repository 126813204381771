import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { LessonStatusEnum, LessonType, LessonTypeEnum } from 'components/_v2/Course/type';
import { useQuizContext } from 'context/QuizContext';
import { Button, Image } from 'react-bootstrap';
import moment from 'moment';
import useQuiz from 'hooks/useQuiz';
import { useNavigate } from 'react-router-dom';
import { ZOOM_MEETING } from 'pages/routing/routePages';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { useCourseContext } from 'context/CourseContext';
import { QuestionAnswerInputType } from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';
import { PRE_RECORDED_COURSE_KEY } from 'pages/auth/core/AuthHelpers';
import { toast } from 'react-hot-toast';
import React, { useState } from 'react';
import Loading from 'components/_v2/CommonComponents/Loading';
import { useMutation } from '@apollo/client';
import { UPSERT_COURSE_PROGRESS } from 'components/Courses-Page/core/requests';
import { isAnyQuizAlreadyStarted } from 'utils/helpers';

const ButtonRenderer = ({
	bgColor,
	content,
	onClickAction,
	disabled,
}: {
	bgColor: string;
	content: JSX.Element | string;
	onClickAction: () => void;
	disabled?: boolean;
}) => {
	return (
		<Button
			style={{
				backgroundColor: bgColor,
				borderRadius: '12px',
				borderColor: 'transparent',
				lineHeight: '21px',
				fontSize: '14px',
			}}
			disabled={disabled}
			className="d-flex align-items-center gap-2"
			onClick={onClickAction}>
			{content}
		</Button>
	);
};

const LessonTypeRenderer = ({
	lessonData,
	courseId,
	courseType,
}: {
	lessonData: LessonType;
	courseId: string;
	courseType: string;
}) => {
	const {
		setLessonId,
		setRemainingTime,
		setAllQuestionAnswers,
		setEndTime,
		setQuizResult,
		setAnswer,
	} = useQuizContext();
	const navigate = useNavigate();
	const { userCourses } = useCourseContext();
	const { handleQuizSubmit } = useQuiz();
	const isMobileScreen = useIsSmallScreen(576);
	const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

	const [upsertCourse, { loading: loadingUpsert, error: errorUpsert }] =
		useMutation(UPSERT_COURSE_PROGRESS);

	const isQuizStartDisabled = isAnyQuizAlreadyStarted();

	if (lessonData.isLock) {
		return null;
	}

	const handleDownloadFile = async (downloadContentUrl: string) => {
		// const link = document.createElement('a');
		// link.href = downloadContentUrl;
		// const courseName = userCourses.find((course) => course.id === courseId)?.name;
		// const fileName = courseName + ':' + lessonData.name;
		// link.download = fileName;
		// document.body.appendChild(link);
		// link.click();
		// document.body.removeChild(link);
		setIsDownloadingPdf(true);
		try {
			const response = await fetch(downloadContentUrl);
			const blob = await response.blob();
			const courseName = userCourses.find((course) => course.id === courseId)?.name;
			const fileName = `${courseName}:${lessonData.name}`;
			const blobUrl = window.URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(blobUrl); // Cleanup
			upsertCourse({
				variables: {
					upsertCourseProgressInput: {
						courseId,
						lessonId: lessonData.id,
						sectionId: lessonData.sectionId,
						duration: 100,
						isLessonCompleted: true,
					},
				},
				onCompleted: () => {
					const localStorageForPreRecorded = JSON.parse(
						localStorage.getItem(PRE_RECORDED_COURSE_KEY),
					);
					const recordedCoursePdfInLocalStorage =
						localStorageForPreRecorded?.[courseId]?.[lessonData.sectionId]?.[lessonData.id];
					if (recordedCoursePdfInLocalStorage) {
						recordedCoursePdfInLocalStorage.completed = true;
						recordedCoursePdfInLocalStorage.completedDuration = 100;
					}
					localStorage.setItem(PRE_RECORDED_COURSE_KEY, JSON.stringify(localStorageForPreRecorded));
				},
				onError: () => {
					toast.error('Error updating progress!');
				},
			});
			setIsDownloadingPdf(false);
		} catch (e) {
			setIsDownloadingPdf(false);
			toast.error('Could not download the PDF.');
		}
	};

	const handleQuizStart = (lessonId: string) => {
		setAllQuestionAnswers([]);
		const allQuestions: QuestionAnswerInputType[] = lessonData.quiz.questions.map((question) => ({
			questionId: question.id,
			answer: [],
			correctAnswer: question.answers[0],
			mark: String(question.mark),
		}));
		allQuestions.forEach((item) => setAnswer(item.questionId, item.answer));
		setLessonId(lessonId);
		handleQuizSubmit({
			courseId,
			isSubmit: false,
			lessonId: lessonId,
			questionAnswer: allQuestions,
			quizId: lessonData.quizId,
			sectionId: lessonData.sectionId,
			is_start: true,
			callbackFunction: (response) => {
				const endTime = response.submitQuiz.endTime;
				const remainingTime = moment(endTime).diff(moment(), 'seconds');
				setEndTime(endTime);
				setRemainingTime(remainingTime);
				setAllQuestionAnswers(allQuestions || []);
				setQuizResult((prevState) => {
					return { ...prevState, quizStartTime: moment().toISOString() };
				});
			},
		});
	};

	const commonClasses = 'd-flex flex-column align-items-center gap-4 pb-2 pb-md-0';

	switch (lessonData.type) {
		case LessonTypeEnum.DOCS:
			if (!lessonData.contentUrl) {
				return null;
			}
			const contentUrlArray = lessonData.contentUrl.split('/');
			const pdfName = contentUrlArray[contentUrlArray.length - 1];
			// return
			// !isMobileScreen ? (
			// 	<DocumentReader
			// 		file={lessonData.contentUrl}
			// 		courseId={courseId}
			// 		lessonData={lessonData}
			// 		courseType={courseType}
			// 	/>
			// ) : (
			return (
				<div className={commonClasses}>
					<Image
						src={toAbsoluteUrl('/media/course-detail-images/file-download.svg')}
						width={128}
						height={128}
					/>
					<ButtonRenderer
						bgColor="#3CC878"
						content={
							<>
								{isDownloadingPdf ? (
									<Loading width="20px" />
								) : (
									<Image src={toAbsoluteUrl('/media/course-detail-images/download-icon.svg')} />
								)}
								Download {pdfName}
							</>
						}
						disabled={!lessonData.contentUrl || isDownloadingPdf}
						onClickAction={() => handleDownloadFile(lessonData.contentUrl)}
					/>
				</div>
			);
		// );
		case LessonTypeEnum.QUIZ:
			return (
				<div className={commonClasses}>
					<Image src={toAbsoluteUrl('/media/course-detail-images/quiz.svg')} />
					<button
						className={`quiz-start-btn-style g_mid border-1`}
						disabled={isQuizStartDisabled}
						onClick={() => handleQuizStart(lessonData.id)}>
						Start Quiz
					</button>
					{isQuizStartDisabled && (
						<span className="fs-14 g_reg text-danger">
							You already have an existing active quiz. Please complete that before attempting a new
							quiz.
						</span>
					)}
				</div>
			);

		case LessonTypeEnum.MEETING:
			const meetingCompleted = lessonData.status === LessonStatusEnum.COMPLETED;
			if (meetingCompleted) {
				return (
					<div className={commonClasses}>
						<div className="d-flex flex-column align-items-center gap-1">
							<span className="fs-24 fw-medium">Missed Your Class?</span>
							<span>Don't worry! Reach out to our admin team for assistance</span>
						</div>
						<div className="w-100 d-flex justify-content-center align-items-center card-border-bottom-style m-0 gap-2 p-2">
							<Image src={toAbsoluteUrl('/media/course/mail.svg')} />
							<a href="mailto:hogrowth@gmail.com" className="black g_mid fs-16">
								hogrowth@gmail.com
							</a>
						</div>
					</div>
				);
			}
			return (
				<div className={commonClasses}>
					<Image src={toAbsoluteUrl('/media/course-detail-images/zoom-workplace.svg')} />
					<Button
						onClick={() => {
							!isMobileScreen
								? navigate(`${ZOOM_MEETING}?lessonId=${lessonData.id}&courseId=${courseId}`)
								: window.open(lessonData.meetingUrl);
						}}
						disabled={meetingCompleted}>
						{meetingCompleted ? 'Meeting Completed' : 'Join Meeting'}
					</Button>
				</div>
			);

		default:
			break;
	}

	return <></>;
};

export default LessonTypeRenderer;
