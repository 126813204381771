import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Course, CourseCostType, CourseTypeEnum } from 'components/_v2/Course/type';
import { useUserContext } from 'context/UserContext';
import {
	getCoursePaymentSubUrl,
	getViewCourseWithSearchSlug,
	PAYMENT_DETAILS_NO_USER,
	ZOOM_MEETING,
} from 'pages/routing/routePages';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RUPEE_SYMBOL } from 'utils/consts';
import './DetailHeroSection.css';
import { useCourseContext } from 'context/CourseContext';
import Button from 'components/_v2/CommonComponents/Button';
import { ButtonBorderType, ButtonType } from 'components/_v2/CommonComponents/types';
import moment from 'moment';
import { Image } from 'react-bootstrap';
import WebinarTimer from 'components/_v2/CourseDetail/DetailHeroSection/WebinarTimer';
import { useZoomMeeting } from 'context/ZoomMeetingContext';

const PopoverCourseDetailCard = ({ course }: { course: Course }) => {
	const { isZoomVisited } = useZoomMeeting();
	const { user } = useUserContext();
	const { userCourses } = useCourseContext();
	const navigate = useNavigate();
	const isCurrentDateBehindCourseStartDate = moment(course?.startDatetime).isAfter(moment());
	const videoRef = useRef(null);
	const [mutedVideo, setMutedVideo] = useState(false);
	const [shouldShowTimer, setShouldShowTimer] = useState<boolean>(
		moment(course.startDatetime).diff(moment(), 'seconds') > 0,
	);

	const handlePlaying = useCallback(() => {
		const playButton = document?.querySelector(`.circle-play-b`) as HTMLElement | null;
		if (playButton) {
			playButton.style.opacity = '0';
		}
	}, [videoRef]);

	const handlePause = useCallback(() => {
		const playButton = document?.querySelector(`.circle-play-b`) as HTMLElement | null;
		if (playButton) {
			playButton.style.opacity = '1';
		}
	}, [videoRef]);

	const togglePlay = useCallback(async () => {
		const videoElement = videoRef.current;
		if (videoElement) {
			if (videoElement.paused || videoElement.ended) {
				await videoElement.play();
			} else {
				videoElement.pause();
			}
		}
	}, [videoRef]);

	const toggleFullscreen = () => {
		if (videoRef.current.requestFullscreen) {
			videoRef.current.requestFullscreen();
		}
	};

	const toggleMute = () => {
		if (videoRef.current) {
			const newMuteStatus = !mutedVideo;
			videoRef.current.muted = newMuteStatus;
			setMutedVideo(newMuteStatus);
		}
	};

	const isCourseSubscribed = userCourses.some((userCourse) => userCourse.id === course.id);
	const isCourseWebinar = course.courseType.slug === CourseTypeEnum.WEBINAR;

	const handleContinueLearning = useCallback(() => {
		if (course.courseType.slug === CourseTypeEnum.WEBINAR) {
			navigate(`${ZOOM_MEETING}?courseId=${course.id}&isWebinar=true`);
		} else {
			navigate(getViewCourseWithSearchSlug(course.slug));
		}
	}, [course]);

	const handleEnrollPageReroute = useCallback(() => {
		if (user) {
			navigate(getCoursePaymentSubUrl(course.slug));
		} else {
			navigate(`${PAYMENT_DETAILS_NO_USER}/${course.slug}`);
		}
	}, [course.slug, navigate, user]);

	useEffect(() => {
		const video = videoRef.current;

		const handleVolumeChange = () => {
			setMutedVideo(video.muted);
		};

		video?.addEventListener('volumechange', handleVolumeChange);

		return () => {
			video?.removeEventListener('volumechange', handleVolumeChange);
		};
	}, []);

	useEffect(() => {
		const videoElement = videoRef.current;
		if (videoElement) {
			videoElement.addEventListener('playing', () => handlePlaying());
			videoElement.addEventListener('pause', () => handlePause());
			videoElement.addEventListener('error', (e) => {
				console.error(`Error loading video:`, e);
			});

			return () => {
				if (videoElement) {
					videoElement.removeEventListener('playing', () => handlePlaying());
					videoElement.removeEventListener('pause', () => handlePause());
					videoElement.addEventListener('error', (e) => {
						console.error(`Error loading video:`, e);
					});
				}
			};
		}
	}, [handlePlaying, handlePause]);

	if (isZoomVisited) {
		return null;
	}

	return (
		<div
			className="card coursedetail-card footer-stop coursedetail-sticky-card z-3 d-none d-lg-block "
			style={{ position: 'fixed', top: '150px' }}>
			{course?.previewVideo && (
				<>
					<video
						className="card-img-top pop-over-video"
						ref={(el) => {
							videoRef.current = el;
						}}
						muted={mutedVideo}
						controlsList="nodownload">
						<source src={course.previewVideo} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
					<div className="video-controls">
						<button onClick={toggleMute}>
							<Image
								src={toAbsoluteUrl(
									`/media/course-detail-images/${mutedVideo ? 'mute' : 'unmute'}.svg`,
								)}
								width={20}
							/>
						</button>
						<button onClick={toggleFullscreen}>
							<Image
								src={toAbsoluteUrl('/media/course-detail-images/fullscreen-video.svg')}
								width={20}
							/>
						</button>
					</div>
					<div
						className={`video-play-button circle-play-b`}
						style={{ top: '20%' }}
						onClick={(e) => {
							e.stopPropagation();
							togglePlay();
						}}>
						<span />
					</div>
				</>
			)}
			<div className="card-body detail-inner-desc-layout">
				{!isCourseSubscribed && (
					<div className="d-flex pricing-layout flex-wrap">
						{course?.costType === CourseCostType.FREE ? (
							<div className="card-detail-sub-discount-price g_semi">Free</div>
						) : (
							<>
								<div className="card-detail-sub-discount-price g_semi">
									<span>
										{RUPEE_SYMBOL}
										{course.sellPrice}/-
									</span>
								</div>
								<div className="card-detail-sub-actual-price g_reg">
									<del>
										<span>
											{RUPEE_SYMBOL}
											{course.amount}/-
										</span>
									</del>
								</div>
								<div className="card-detail-sub-actual-price g_reg">
									<span>
										({(((course?.amount - course?.sellPrice) / course?.amount) * 100).toFixed(0)}%
										off)
									</span>
								</div>
							</>
						)}
					</div>
				)}
				<div className="mb-4">
					{isCurrentDateBehindCourseStartDate && !isCourseSubscribed && (
						<div className="d-flex align-items-center mb-2 gap-1">
							<Image src={toAbsoluteUrl('/media/course-detail-images/timer-left.svg')} />
							{moment(course?.startDatetime).diff(moment(), 'days')} &nbsp; Days Left to Enroll
						</div>
					)}
					<Button
						buttonText={
							isCourseSubscribed ? (isCourseWebinar ? 'Join Now' : 'Keep Learning') : 'Enroll Now'
						}
						buttonType={ButtonType.PRIMARY_BUTTON}
						classes="g_mid w-100 border-2"
						handleClick={isCourseSubscribed ? handleContinueLearning : handleEnrollPageReroute}
						buttonBorderType={ButtonBorderType.BLACK_BORDER}
						disabled={
							isCourseWebinar &&
							isCourseSubscribed &&
							(moment(course.startDatetime).diff(moment(), 'seconds') > 1800 ||
								moment().isAfter(moment(course.endDatetime)))
						}
					/>
				</div>
				{course?.courseSettings?.dateVisible && course?.startDatetime && course?.endDatetime && (
					<div className="d-flex align-items-center card-inner-layout">
						<div>
							<img
								src={toAbsoluteUrl('/media/detail-hero-section/cdate.png')}
								alt="Date"
								className="between-space"
							/>
						</div>
						<div>
							<span className="card-sticky-content g_reg">
								{moment.parseZone(course.startDatetime).format("DD MMM 'YY")} -{' '}
								{moment.parseZone(course.endDatetime).format("DD MMM 'YY")}
							</span>
						</div>
					</div>
				)}
				{course?.courseSettings?.timeVisible && course?.startDatetime && course?.endDatetime && (
					<div className="d-flex align-items-center card-inner-layout">
						<div>
							<img
								src={toAbsoluteUrl('/media/detail-hero-section/ctime.png')}
								alt="Time"
								className="between-space"
							/>
						</div>
						<div>
							<span className="card-sticky-content g_reg">
								{moment(course.startDatetime).format('h:mm A')} -{' '}
								{moment(course.endDatetime).format('h:mm A')}
							</span>
						</div>
					</div>
				)}
				{course?.courseSettings?.languageVisible && course?.courseLanguages?.[0]?.name && (
					<div className="d-flex align-items-center card-inner-layout">
						<div>
							<img
								src={toAbsoluteUrl('/media/detail-hero-section/clanguage.png')}
								alt="Language"
								className="between-space"
							/>
						</div>
						<div>
							<span className="card-sticky-content g_reg">
								{course.courseLanguages.map((language, index) => (
									<span key={index}>
										{language.name}
										{index < course.courseLanguages.length - 1 && ', '}
									</span>
								))}
							</span>
						</div>
					</div>
				)}
				{course?.courseSettings?.levelVisible && course?.courseLevel?.name && (
					<div className="d-flex align-items-center card-inner-layout">
						<div>
							<img
								src={toAbsoluteUrl('/media/detail-hero-section/clevel.png')}
								alt="Level"
								className="between-space"
							/>
						</div>
						<div>
							<span className="card-sticky-content g_reg">{course.courseLevel.name}</span>
						</div>
					</div>
				)}
				{course.hasCertificate && (
					<div className="d-flex align-items-center card-inner-layout">
						<Image
							src={toAbsoluteUrl('/media/detail-hero-section/certificate.svg')}
							alt="certificate"
							className="between-space"
						/>
						<span className="card-sticky-content g_reg">Certificate of Completion</span>
					</div>
				)}
				{course.courseType.slug !== CourseTypeEnum.RECORDED_COURSE && (
					<div className="d-flex align-items-center card-inner-layout">
						<div>
							<img
								src={toAbsoluteUrl(
									'/media/detail-hero-section/' +
										(course?.courseMode?.name === 'Online' ||
										course?.courseType?.slug === CourseTypeEnum.WEBINAR
											? 'zoom'
											: 'clocation') +
										'.png',
								)}
								alt="Location"
								className="between-space"
							/>
						</div>

						<div>
							<span className="card-sticky-content g_reg">
								{course?.courseMode?.name === 'Online' || course?.courseType?.slug
									? 'Zoom'
									: course?.address}
							</span>
						</div>
					</div>
				)}
				{isCourseWebinar && isCourseSubscribed && shouldShowTimer && (
					<div className="d-flex flex-column gap-3">
						<span className="g_mid fs-16">Webinar Starts In</span>
						<div className="container">
							<div className="row">
								<WebinarTimer
									startDateTime={course.startDatetime}
									onTimerEnd={() => setShouldShowTimer(false)}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default PopoverCourseDetailCard;
