import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import './stories.scss';
import { useQuery, gql } from '@apollo/client';
import Slider, { Settings } from 'react-slick';
import { STORIES_SECTION } from 'utils/consts';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import Loading from '../CommonComponents/Loading';
import { Image } from 'react-bootstrap';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { TestimonialsType } from '../CourseDetail/CourseReviews/type';

const GET_PUBLIC_TESTIMONIALS = gql`
	query GetPublicTestimonials {
		getPublicTestimonials(where: { content_type: { eq: VIDEO } }, paginate: {}) {
			records {
				id
				name
				designation
				content
				contentType
				image
			}
		}
	}
`;

interface SliderContent extends TestimonialsType {
	key: string;
	title: string;
}

const NoStories = () => {
	return (
		<div className="d-flex w-100 justify-content-center flex-column align-items-center pb-5">
			<Image className="img-fluid" src={toAbsoluteUrl('/media/nodata/stories-nodata.svg')} />
			<span className="g_light fs-18 color-17">Coming Soon</span>
		</div>
	);
};

const breakpointValue = 992;

const Stories = () => {
	const isSmallScreen = useIsSmallScreen(breakpointValue);
	const settings: Settings = {
		centerMode: true,
		centerPadding: '20px',
		slidesToShow: 3,
		focusOnSelect: true,
		infinite: true,
		speed: 500,
		cssEase: 'ease-in-out',
		initialSlide: 0,
		swipeToSlide: true,
		swipe: true,
		autoplay: isSmallScreen,
		arrows: !isSmallScreen,
		prevArrow: (
			<button className="leftsilder">
				<img src={toAbsoluteUrl('/media/stories-images/leftc.png')} alt="Left_Slider" />
			</button>
		),
		nextArrow: (
			<button>
				<img src={toAbsoluteUrl('/media/stories-images/rightc.png')} alt="Right_Slider" />
			</button>
		),
		responsive: [
			{
				breakpoint: 1600,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: breakpointValue,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	const { loading, error, data } = useQuery(GET_PUBLIC_TESTIMONIALS);
	const [currentSlide, setCurrentSlide] = useState(0);
	const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

	const slides: SliderContent[] = useMemo(() => {
		if (!data) return [];
		return data.getPublicTestimonials.records.map((record: TestimonialsType) => ({
			key: record.id,
			video: record.content,
			name: record.name,
			title: record.designation,
			image: record.image,
		}));
	}, [data]);

	const handlePlaying = useCallback(
		(index: number) => {
			const playButton = document
				.querySelector('.studentStories')
				?.querySelector('.slick-current')
				?.querySelector(`.circle-play-b-${index}`) as HTMLElement | null;
			if (playButton) {
				playButton.style.opacity = '0';
			}
		},
		[currentSlide],
	);

	const handlePause = useCallback(
		(index: number) => {
			const playButton = document
				.querySelector('.studentStories')
				?.querySelector('.slick-current')
				?.querySelector(`.circle-play-b-${index}`) as HTMLElement | null;
			if (playButton) {
				playButton.style.opacity = '1';
			}
		},
		[currentSlide],
	);

	const togglePlay = useCallback(async (index: number) => {
		const videoElement = videoRefs.current[index];
		if (videoElement) {
			if (videoElement.paused || videoElement.ended) {
				await videoElement.play();
			} else {
				videoElement.pause();
			}
		}
	}, []);

	useEffect(() => {
		(slides.length < 3 ? [...slides, ...slides, ...slides] : slides).forEach((_, index) => {
			const videoElement = videoRefs.current[index];
			if (videoElement) {
				videoElement.addEventListener('playing', () => handlePlaying(index));
				videoElement.addEventListener('pause', () => handlePause(index));
				videoElement.addEventListener('error', (e) => {
					console.error(`Error loading video ${index}:`, e);
				});
			}
		});

		return () => {
			(slides.length < 3 ? [...slides, ...slides, ...slides] : slides).forEach((_, index) => {
				const videoElement = videoRefs.current[index];
				if (videoElement) {
					videoElement.removeEventListener('playing', () => handlePlaying(index));
					videoElement.removeEventListener('pause', () => handlePause(index));
					videoElement.addEventListener('error', (e) => {
						console.error(`Error loading video ${index}:`, e);
					});
				}
			});
		};
	}, [slides, handlePlaying, handlePause]);

	const sliderChangeHandler = (currentSlide: number) => {
		setCurrentSlide(currentSlide);
	};

	const sliderBeforeChangeHandler = (oldSlideNumber: number) => {
		const videoElement = videoRefs.current[oldSlideNumber];
		if (videoElement) {
			videoElement.pause();
		}
	};

	if (loading) return <Loading width={200} />;

	return (
		<section className="stories-wrapper" id={STORIES_SECTION}>
			<div className="containerHeight container">
				<div className="row">
					<div className="layout-set stories-inner-layout">
						<div className="text-center ">
							<h2 className="stories-title ibm_semi  py-2">Experience Shared By Our Students</h2>
							<p className="stories-subtitle p_reg">Learn from the Experienced Pro Traders</p>
						</div>
						<div className="slider-container studentStories mt-5">
							{error || slides.length === 0 ? (
								<NoStories />
							) : (
								<Slider
									afterChange={sliderChangeHandler}
									beforeChange={sliderBeforeChangeHandler}
									swipe={false}
									className="center"
									{...settings}>
									{(slides.length < 3 ? [...slides, ...slides, ...slides] : slides)
										.filter((slide) => slide.image)
										.map((slide, index) => {
											return (
												<div
													key={slide.key}
													className={`slide ${index === currentSlide ? 'active' : ''}`}>
													<video
														ref={(el) => {
															const referenceIndex: any =
																el?.parentElement?.parentElement?.parentElement?.getAttribute(
																	'data-index',
																);
															if (referenceIndex) {
																videoRefs.current[referenceIndex] = el;
															}
														}}
														width="100%"
														style={{ maxHeight: '300px' }}
														className="radius"
														loop>
														<source src={slide.image} type="video/mp4" />
													</video>

													{index === currentSlide && (
														<>
															<div className="descriptionField">
																<span className="g_mid fs-16">{slide.name}</span>
																<br />
																<span className="g_reg fs-12">{slide.title}</span>
															</div>
															<div
																className={`video-play-button circle-play-b-${index}`}
																data-play-button-index={index}
																onClick={(e) => {
																	e.stopPropagation();
																	togglePlay(index);
																}}>
																<span />
															</div>
														</>
													)}
												</div>
											);
										})}
								</Slider>
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Stories;
