import { ApolloError, gql, useLazyQuery, useMutation } from '@apollo/client';
import {
	QuestionAnswerInputType,
	QuizReattemptDetails,
	QuizResultResponse,
	QuizSubmitResponse,
	QuizSubmitType,
	UserQuizResultStatusListObjectType,
} from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';
import { useQuizContext } from 'context/QuizContext';
import { useUserContext } from 'context/UserContext';
import moment from 'moment';
import { SUBMIT_QUIZ_PROGRESS } from 'pages/auth/core/_requests';
import React from 'react';
import { toast } from 'react-hot-toast';

const quizLocalStorageKey = 'quizDetails';

export const QUIZ_REATTEMPT = gql`
	query getStudentQuizResultStatusList($courseId: String!) {
		getStudentQuizResultStatusList(courseId: $courseId) {
			quizDetails {
				sectionId
				lessonId
				quizId
				status
				allowReAttempt
			}
		}
	}
`;

export const GET_QUIZ_RESULT = gql`
	query GetQuizResult(
		$courseId: String!
		$quizId: String!
		$sectionId: String!
		$lessonId: String!
	) {
		getQuizResult(
			courseId: $courseId
			quizId: $quizId
			sectionId: $sectionId
			lessonId: $lessonId
		) {
			id
			userId
			isSubmit
			courseId
			quizId
			quizStartTime
			quizEndTime
			quizSubmitTime
			questionAnswer {
				answer
				correctAnswer
				mark
				questionId
			}
			createdAt
			updatedAt
		}
	}
`;

const useQuiz = () => {
	const {
		setQuizResult,
		setEndTime,
		setRemainingTime,
		setLoadingGetQuizReattempt,
		setLoadingGetQuizResult,
		setAllQuestionAnswers,
		resetQuizResultState,
		setAnswer,
	} = useQuizContext();
	const [startQuiz] = useMutation(SUBMIT_QUIZ_PROGRESS);

	const [getQuizResult] = useLazyQuery<QuizResultResponse>(GET_QUIZ_RESULT, {
		fetchPolicy: 'network-only',
	});

	const [getQuizReattempt, { loading: loadingGetQuizReattempt }] =
		useLazyQuery<UserQuizResultStatusListObjectType>(QUIZ_REATTEMPT);

	const handleQuizReattempt = async (
		courseId: string,
		setResult: React.Dispatch<React.SetStateAction<QuizReattemptDetails[]>>,
	) => {
		setLoadingGetQuizReattempt(true);
		const { data, error } = await getQuizReattempt({
			variables: {
				courseId,
			},
			fetchPolicy: 'network-only',
		});
		setLoadingGetQuizReattempt(false);
		if (!error) {
			setResult(data.getStudentQuizResultStatusList.quizDetails);
		} else {
			toast.error(error.message);
		}
	};

	const handleGetQuizResult = async ({
		courseId,
		lessonId,
		quizId,
		sectionId,
	}: {
		courseId: string;
		quizId: string;
		sectionId: string;
		lessonId: string;
	}) => {
		setLoadingGetQuizResult(true);
		const { data, error } = await getQuizResult({
			variables: {
				courseId,
				lessonId,
				quizId,
				sectionId,
			},
		});
		if (error || !data) {
			setQuizResult((prevState) => ({
				...prevState,
				isSubmit: false,
				questionAnswer: [],
			}));
			setLoadingGetQuizResult(false);
		} else {
			const quizResult = data.getQuizResult;
			setQuizResult(quizResult);
			if (quizResult.quizEndTime) {
				setEndTime(quizResult.quizEndTime);
				let timeDifferenceRemaining = moment(quizResult.quizEndTime).diff(moment(), 'seconds');
				setRemainingTime(timeDifferenceRemaining > 0 ? timeDifferenceRemaining : 0);
			}
			setLoadingGetQuizResult(false);
		}
	};

	const handleQuizSubmit = ({
		courseId,
		isSubmit,
		lessonId,
		questionAnswer,
		quizId,
		sectionId,
		is_start = false,
		callbackFunction,
		isInSameCourse = true,
	}: QuizSubmitType) =>
		startQuiz({
			variables: {
				input: {
					courseId,
					isSubmit,
					lessonId,
					questionAnswer,
					quizId,
					sectionId,
					is_start,
				},
			},
			onCompleted: (data: QuizSubmitResponse) => {
				const existingLocalStorageData = JSON.parse(sessionStorage.getItem(quizLocalStorageKey));
				const questionAnswers: QuestionAnswerInputType[] = data.submitQuiz.questionAnswer.map(
					(item) => ({
						questionId: item.questionId,
						answer: item.answer,
						correctAnswer: questionAnswer.find(
							(question) => question.questionId === item.questionId,
						).correctAnswer,
						mark: item.mark,
					}),
				);
				if (isInSameCourse) {
					setQuizResult((prevState) => ({
						...prevState,
						isSubmit: data.submitQuiz.isSubmit,
						questionAnswer: questionAnswers,
						quizSubmitTime: data.submitQuiz.quizSubmitTime,
					}));
					(questionAnswers || []).forEach((item) => setAnswer(item.questionId, item.answer));
					if (data.submitQuiz.isSubmit) {
						setAllQuestionAnswers([]);
					}
					if (!isSubmit) {
						sessionStorage.setItem(
							quizLocalStorageKey,
							JSON.stringify({
								...existingLocalStorageData,
								[lessonId]: {
									courseId,
									quizId,
									sectionId,
									endTime: is_start
										? data.submitQuiz.endTime || moment().add('100', 'years').toISOString()
										: existingLocalStorageData[lessonId].endTime,
									questionAnswer: questionAnswers,
								},
							}),
						);
					} else {
						setAllQuestionAnswers([]);
					}
				}
				if (callbackFunction) {
					callbackFunction(data);
				}
			},
			onError: (err: ApolloError) => {
				toast.error(err.message);
				sessionStorage.removeItem(quizLocalStorageKey);
				window.location.reload();
			},
		});
	return {
		handleQuizSubmit,
		handleGetQuizResult,
		handleQuizReattempt,
	};
};

export default useQuiz;
