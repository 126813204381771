import React, { FC, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FcGoogle } from 'react-icons/fc';
import * as Yup from 'yup';
import { useGoogleLogin } from '@react-oauth/google';
import { Formik } from 'formik';
import axios from 'axios';
import { STUDENTLOGINWITHSOCIALMEDIA } from './core/_requests';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import './registration.css';
import { useUserContext } from 'context/UserContext';
import { AUTH_LOCAL_STORAGE_KEY, VERIFY_USER_STORAGE_KEY } from './core/AuthHelpers';
import Loading from 'components/_v2/CommonComponents/Loading';
import Button from 'components/_v2/CommonComponents/Button';
import { ButtonType } from 'components/_v2/CommonComponents/types';
import { ErrorMessage, Field } from 'formik';
import CommonPasswordInput from 'components/_v2/CommonComponents/CommonPasswordInput';
import { useAuthModalContext } from 'context/AuthContext';
import { RegistrationUserOutput } from 'generated/types';

type Props = {
	registerStudentMutation: ({
		email,
		password,
		mobileNumber,
		fullName,
		onCompleted,
	}: {
		email: string;
		password: string;
		mobileNumber: string;
		fullName: string;
		onCompleted?: (data: { newStudentRegister: RegistrationUserOutput }) => void;
	}) => Promise<RegistrationUserOutput | undefined>;
};

const initialValues = {
	fullName: '',
	email: '',
	password: '',
	confirmPassword: '',
	mobileNo: '',
	mobileNoStdCode: '',
};

// eslint-disable-next-line
const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/;
// eslint-disable-next-line
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,20}$/;

const registrationSchema = Yup.object().shape({
	fullName: Yup.string()
		.min(3, 'Minimum 3 characters')
		.max(35, 'Maximum 35 characters')
		.matches(/^([A-Z][a-z]*)(\s[A-Z][a-z]*)*$/, 'Only Alphabets are allowed')
		.required('Please enter your full name'),
	email: Yup.string()
		.email('Please enter valid email address')
		.min(3, 'Minimum 3 characters')
		.max(50, 'Maximum 50 characters')
		.required('Please enter your email address'),
	mobileNumber: Yup.string()
		.length(10, 'Mobile number must be of 10 digits')
		.matches(/^[6-9]\d{9}$/, 'Mobile number must start with 6-9')
		.required('Please enter your mobile number'),
	password: Yup.string()
		.required('Please create a password')
		.matches(
			passwordRegExp,
			'Password must include at least 8 character, one special character, one digit, one uppercase and one lowercase letter',
		),
});

export const Register: FC<Props> = ({ registerStudentMutation }) => {
	const formikRef = useRef(null);
	const { refetchLocalStorageTokenDetails } = useUserContext();
	const [studentSignInWithSocialAccount] = useMutation(STUDENTLOGINWITHSOCIALMEDIA);
	const { toggleRegisterModal, toggleVerificationModal, toggleLoginModal, setOpenRegister } =
		useAuthModalContext();
	const [loading, setLoading] = useState(false);

	const loginGoogle = useGoogleLogin({
		onSuccess: async (codeResponse: any) => {
			try {
				const userInfoResponse = await axios.get(
					`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
					{
						headers: {
							Authorization: `Bearer ${codeResponse.access_token}`,
							Accept: 'application/json',
						},
					},
				);
				await studentSignInWithSocialAccount({
					variables: {
						email: userInfoResponse.data.email,
						name: userInfoResponse.data.name,
						signinType: 'GOOGLE',
					},
					onCompleted: (data: any) => {
						localStorage.setItem(
							AUTH_LOCAL_STORAGE_KEY,
							JSON.stringify(data?.studentSignInWithSocialAccount),
						);
						refetchLocalStorageTokenDetails();
						toast.success('Successfully signed in with the correct credentials.');
					},
					onError: (err: any) => {
						toast.error(err.message);
					},
				});
			} catch (error) {
				toast.error('Error', error);
				return;
			}
		},
		onError: (error: any) => {
			toast.error(error.message);
			toast.error('Please select a valid account for Google sign-in.');
		},
	});

	const handleRegistration = async (values, { setSubmitting }) => {
		setLoading(true);
		try {
			const newStudentRegister = await registerStudentMutation({
				email: values.email,
				password: values.password,
				mobileNumber: values.mobileNumber,
				fullName: values.fullName,
			});
			if (newStudentRegister?.is_success) {
				localStorage.setItem(VERIFY_USER_STORAGE_KEY, JSON.stringify(newStudentRegister));
				setLoading(false);
				setOpenRegister(false);
				toggleVerificationModal();
			} else {
				toast.error(newStudentRegister?.message);
				localStorage.removeItem(VERIFY_USER_STORAGE_KEY);
				setSubmitting(false);
				setLoading(false);
			}
		} catch (error: any) {
			toast.error(error.message);
			setLoading(false);
		}
	};

	return (
		<>
			<Modal centered show={true} onHide={() => toggleRegisterModal()}>
				<Modal.Header className="header-top-layout">
					<Modal.Title className="border-0">
						<div className="row">
							<div className="col-md-12 lora login-title">Sign Up</div>
							<div className="login-new-label g_reg">
								Already have an Account? &nbsp;
								<span
									className="login-new-label-span g_reg cursor-pointer"
									onClick={() => {
										toggleRegisterModal();
										toggleLoginModal();
									}}>
									Sign In
								</span>
							</div>
						</div>
					</Modal.Title>
					<div
						className="btn btn-icon btn-sm btn-active-icon-primary close-btn-style g_reg"
						onClick={() => toggleRegisterModal()}>
						<i className="fa fa-close" />
					</div>
				</Modal.Header>
				<Modal.Body className="body-layout">
					<Formik
						initialValues={initialValues}
						validationSchema={registrationSchema}
						onSubmit={handleRegistration}
						innerRef={formikRef}>
						{({ status, isSubmitting, isValid, touched, errors, handleSubmit }) => (
							<div>
								<div className="row">
									{status && (
										<div className="col-12 mb-3">
											<div className="mb-lg-15 alert alert-danger">
												<div className="alert-text font-weight-bold">{status}</div>
											</div>
										</div>
									)}

									<div className="col-12">
										<label className="label-style g_reg">
											Full Name <span style={{ color: 'red' }}>*</span>
										</label>
										<Field
											name="fullName"
											type="text"
											placeholder="Enter Full Name"
											autoComplete="off"
											onInput={(e) => {
												e.target.value = e.target.value
													.replace(/[^A-Za-z\s]/g, '')
													.trimStart()
													.replace(/\s+/g, ' ')
													.split(' ')
													.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
													.join(' ');
											}}
											className={`form-control input-inner-style g_reg w-100 ${
												!touched.fullName
													? ''
													: touched.fullName && errors.fullName
													? 'is-invalid'
													: 'is-valid'
											}`}
										/>
										<ErrorMessage name="fullName">
											{(msg) => (
												<div className="text-danger fs-7 mt-2">
													<i className="fa fa-exclamation-circle me-1" /> {msg}
												</div>
											)}
										</ErrorMessage>
									</div>

									<div className="col-12 mt-3">
										<label className="label-style g_reg">
											Email <span style={{ color: 'red' }}>*</span>
										</label>
										<Field
											name="email"
											placeholder="Enter Email Address"
											type="email"
											autoComplete="off"
											className={`form-control input-inner-style g_reg w-100 ${
												!touched.email
													? ''
													: touched.email && errors.email
													? 'is-invalid'
													: 'is-valid'
											}`}
										/>
										<ErrorMessage name="email">
											{(msg) => (
												<div className="text-danger fs-7 mt-2">
													<i className="fa fa-exclamation-circle me-1" /> {msg}
												</div>
											)}
										</ErrorMessage>
									</div>

									<div className="col-12 mt-3">
										<label className="label-style g_reg">
											Mobile Number <span className="text-danger">*</span>
										</label>
										<Field
											name="mobileNumber"
											type="tel"
											maxLength={10}
											placeholder="Enter Mobile Number (e.g. - 6123456789)"
											autoComplete="off"
											onInput={(e) => {
												e.target.value = e.target.value.replace(/[^0-9]/g, '');
											}}
											className={`form-control input-box-style g_reg w-100 ${
												!touched.mobileNumber
													? ''
													: touched.mobileNumber && errors.mobileNumber
													? 'is-invalid'
													: 'is-valid'
											}`}
										/>
										<ErrorMessage name="mobileNumber">
											{(msg) => (
												<div className="text-danger fs-7 mt-2">
													<i className="fa fa-exclamation-circle me-1" /> {msg}
												</div>
											)}
										</ErrorMessage>
									</div>

									<div className="col-12 mt-3">
										<CommonPasswordInput
											name="password"
											disabled={false}
											placeholder="Enter Your Password"
											label="Password"
											labelColor="fs-16"
											showValidOrInvalidOutlineClass={
												!touched.password
													? ''
													: touched.password && errors.password
													? 'is-invalid'
													: 'is-valid'
											}
											handleSubmit={handleSubmit}
										/>
									</div>

									<div className="col-12 btn-registyle">
										<Button
											buttonText={
												<>
													{!loading && <span className="g_mid">Continue</span>}
													{loading && (
														<span className="d-flex w-100 justify-content-center gap-2">
															<Loading width={50} />
															Please wait...
														</span>
													)}
												</>
											}
											buttonType={ButtonType.PRIMARY_BUTTON}
											classes="w-100 mb-3 g_mid fs-16"
											disabled={isSubmitting || !isValid}
											handleClick={handleSubmit}
										/>
									</div>

									<div className="divider">Or</div>

									<div className="col-12 mt-2">
										<button
											type="button"
											className="btn cwg-regi-style w-100 g_mid"
											onClick={() => {
												toggleRegisterModal();
												loginGoogle();
											}}>
											<FcGoogle className="me-2 fs-2" />
											Continue with Google
										</button>
									</div>
								</div>
							</div>
						)}
					</Formik>
				</Modal.Body>
			</Modal>
		</>
	);
};
