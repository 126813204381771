import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
};

export type AddUserCourseRatingsInput = {
  /** CourseId of course ratings */
  courseId: Scalars['String']['input'];
  /** review of course */
  review: Scalars['String']['input'];
  /** star count of course ratings */
  starCount: Scalars['Float']['input'];
};

/** The age range enum */
export enum AgeRangeEnum {
  Above_45 = 'ABOVE_45',
  Between_18And_24 = 'BETWEEN_18_AND_24',
  Between_25To_34 = 'BETWEEN_25_TO_34',
  Between_35To_44 = 'BETWEEN_35_TO_44',
  Under_18 = 'UNDER_18'
}

export type BannerImagesObjectType = {
  __typename?: 'BannerImagesObjectType';
  large?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  original?: Maybe<Scalars['String']['output']>;
  thumb?: Maybe<Scalars['String']['output']>;
};

export type BannerListObjectType = {
  __typename?: 'BannerListObjectType';
  pagination: BannerPaginationType;
  records: Array<BannerObjectType>;
};

export type BannerMedia = {
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type BannerObjectType = {
  __typename?: 'BannerObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** Description of banner */
  description: Scalars['String']['output'];
  /** id of the banner type */
  id: Scalars['String']['output'];
  /** images url of banner */
  images: BannerImagesObjectType;
  /** Name of banner */
  name: Scalars['String']['output'];
  /** Navigation link for banner */
  navigationLink?: Maybe<Scalars['String']['output']>;
  /** Sort number of banner */
  sortNumber: Scalars['Float']['output'];
  /** status of the banner */
  status: BannerStatusEnum;
  /** Type of banner */
  type: BannerTypeEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type BannerPaginationType = {
  __typename?: 'BannerPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type BannerPublicObjectType = {
  __typename?: 'BannerPublicObjectType';
  /** Description of banner */
  description: Scalars['String']['output'];
  /** id of the banner type */
  id: Scalars['String']['output'];
  /** images url of banner */
  images: BannerImagesObjectType;
  /** Name of banner */
  name: Scalars['String']['output'];
  /** Navigation link for banner */
  navigationLink?: Maybe<Scalars['String']['output']>;
  /** Sort number of banner */
  sortNumber: Scalars['Float']['output'];
};

export type BannerResolver_GetBannersFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
  sort_number?: InputMaybe<Number_PropertyFilterInputType>;
  status?: InputMaybe<BannerStatusEnum_PropertyFilterInputType>;
  type?: InputMaybe<BannerTypeEnum_PropertyFilterInputType>;
};

export type BannerResolver_GetBanners_FilterInputType = {
  and?: InputMaybe<Array<BannerResolver_GetBannersFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<BannerResolver_GetBannersFilterInputType>>;
  sort_number?: InputMaybe<Number_PropertyFilterInputType>;
  status?: InputMaybe<BannerStatusEnum_PropertyFilterInputType>;
  type?: InputMaybe<BannerTypeEnum_PropertyFilterInputType>;
};

/** status of banner */
export enum BannerStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type BannerStatusEnum_PropertyFilterInputType = {
  /** status of the banner */
  eq?: InputMaybe<BannerStatusEnum>;
  /** status of the banner */
  in?: InputMaybe<Array<BannerStatusEnum>>;
  /** status of the banner */
  neq?: InputMaybe<BannerStatusEnum>;
  /** status of the banner */
  notin?: InputMaybe<Array<BannerStatusEnum>>;
  /** status of the banner */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Type of banner */
export enum BannerTypeEnum {
  CoursesHome = 'COURSES_HOME',
  Home = 'HOME',
  MasterclassHome = 'MASTERCLASS_HOME',
  ProfileHome = 'PROFILE_HOME',
  SeminarWebinarHome = 'SEMINAR_WEBINAR_HOME',
  Usps = 'USPS',
  WorkshopHome = 'WORKSHOP_HOME'
}

export type BannerTypeEnum_PropertyFilterInputType = {
  /** Type of banner */
  eq?: InputMaybe<BannerTypeEnum>;
  /** Type of banner */
  in?: InputMaybe<Array<BannerTypeEnum>>;
  /** Type of banner */
  neq?: InputMaybe<BannerTypeEnum>;
  /** Type of banner */
  notin?: InputMaybe<Array<BannerTypeEnum>>;
  /** Type of banner */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BlogCategoryListObjectType = {
  __typename?: 'BlogCategoryListObjectType';
  pagination: BlogPaginationType;
  records: Array<BlogCategoryObjectType>;
};

export type BlogCategoryObjectType = {
  __typename?: 'BlogCategoryObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** id of blog category */
  id: Scalars['String']['output'];
  /** image for the blog category */
  image?: Maybe<Scalars['String']['output']>;
  /** name of the blog category */
  name: Scalars['String']['output'];
  parent?: Maybe<BlogCategoryObjectType>;
  parentId: Scalars['String']['output'];
  /** slug of blog category */
  slug: Scalars['String']['output'];
  /** The blog category status */
  status: BlogCategoryStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type BlogCategoryResolver_GetBlogCategoryListFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
};

export type BlogCategoryResolver_GetBlogCategoryList_FilterInputType = {
  and?: InputMaybe<Array<BlogCategoryResolver_GetBlogCategoryListFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<BlogCategoryResolver_GetBlogCategoryListFilterInputType>>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
};

/** The enum for blog category */
export enum BlogCategoryStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type BlogListObjectType = {
  __typename?: 'BlogListObjectType';
  pagination: BlogPaginationType;
  records: Array<BlogObjectType>;
};

export type BlogMedia = {
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type BlogObjectType = {
  __typename?: 'BlogObjectType';
  author: UserObjectType;
  authorId: Scalars['String']['output'];
  categories: Array<BlogCategoryObjectType>;
  /** Content of the blog */
  content: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** Featured image of the blog */
  featuredImage: Scalars['String']['output'];
  /** The flag to determind about comment section of blog */
  hasCommentEnabled: Scalars['Boolean']['output'];
  /** id of the blog */
  id: Scalars['String']['output'];
  /** The date and time of blog published */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** SEO description of the blog */
  seoDescription: Scalars['String']['output'];
  /** SEO title of the blog */
  seoTitle: Scalars['String']['output'];
  /** Short description of the blog */
  shortDescription: Scalars['String']['output'];
  /** slug of the blog */
  slug: Scalars['String']['output'];
  /** The blog status */
  status: BlogStatusEnum;
  tags: Array<BlogTagObjectType>;
  /** Title of the blog */
  title: Scalars['String']['output'];
  /** The blog type */
  type: BlogTypeStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type BlogPaginationType = {
  __typename?: 'BlogPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type BlogResolver_GetBlogListFilterInputType = {
  content?: InputMaybe<String_PropertyFilterInputType>;
  seoDescription?: InputMaybe<String_PropertyFilterInputType>;
  seoTitle?: InputMaybe<String_PropertyFilterInputType>;
  shortDescription?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<BlogStatusEnum_PropertyFilterInputType>;
  title?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<BlogTypeStatusEnum_PropertyFilterInputType>;
};

export type BlogResolver_GetBlogList_FilterInputType = {
  and?: InputMaybe<Array<BlogResolver_GetBlogListFilterInputType>>;
  content?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<BlogResolver_GetBlogListFilterInputType>>;
  seoDescription?: InputMaybe<String_PropertyFilterInputType>;
  seoTitle?: InputMaybe<String_PropertyFilterInputType>;
  shortDescription?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<BlogStatusEnum_PropertyFilterInputType>;
  title?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<BlogTypeStatusEnum_PropertyFilterInputType>;
};

/** The enum for blog status */
export enum BlogStatusEnum {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED'
}

export type BlogStatusEnum_PropertyFilterInputType = {
  /** The blog status */
  eq?: InputMaybe<BlogStatusEnum>;
  /** The blog status */
  in?: InputMaybe<Array<BlogStatusEnum>>;
  /** The blog status */
  neq?: InputMaybe<BlogStatusEnum>;
  /** The blog status */
  notin?: InputMaybe<Array<BlogStatusEnum>>;
  /** The blog status */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BlogTagListObjectType = {
  __typename?: 'BlogTagListObjectType';
  pagination: BlogPaginationType;
  records: Array<BlogTagObjectType>;
};

export type BlogTagObjectType = {
  __typename?: 'BlogTagObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** id of blog tag */
  id: Scalars['String']['output'];
  /** name of the blog tag */
  name: Scalars['String']['output'];
  /** slug of blog tag */
  slug: Scalars['String']['output'];
  /** The blog tag status */
  status: BlogTagStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type BlogTagResolver_GetBlogTagListFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
};

export type BlogTagResolver_GetBlogTagList_FilterInputType = {
  and?: InputMaybe<Array<BlogTagResolver_GetBlogTagListFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<BlogTagResolver_GetBlogTagListFilterInputType>>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
};

/** The enum for blog tag */
export enum BlogTagStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** The enum for blog type */
export enum BlogTypeStatusEnum {
  CaseStudy = 'CASE_STUDY',
  Regular = 'REGULAR'
}

export type BlogTypeStatusEnum_PropertyFilterInputType = {
  /** The blog type */
  eq?: InputMaybe<BlogTypeStatusEnum>;
  /** The blog type */
  in?: InputMaybe<Array<BlogTypeStatusEnum>>;
  /** The blog type */
  neq?: InputMaybe<BlogTypeStatusEnum>;
  /** The blog type */
  notin?: InputMaybe<Array<BlogTypeStatusEnum>>;
  /** The blog type */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Boolean_PropertyFilterInputType = {
  /** for course has cerificate or not */
  between?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  /** for course has cerificate or not */
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  /** for course has cerificate or not */
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  /** for course has cerificate or not */
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  /** for course has cerificate or not */
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  /** for course has cerificate or not */
  like?: InputMaybe<Scalars['String']['input']>;
  /** for course has cerificate or not */
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  /** for course has cerificate or not */
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  /** for course has cerificate or not */
  neq?: InputMaybe<Scalars['Boolean']['input']>;
  /** for course has cerificate or not */
  notbetween?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  /** for course has cerificate or not */
  notin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  /** for course has cerificate or not */
  notlike?: InputMaybe<Scalars['String']['input']>;
  /** for course has cerificate or not */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BulkFaqInput = {
  /** Answer of FAQ */
  answer: Scalars['String']['input'];
  /** Question of FAQ */
  question: Scalars['String']['input'];
  /** Sort Order of FAQ */
  sortOrder: Scalars['Float']['input'];
};

export type CartObjectType = {
  __typename?: 'CartObjectType';
  course: CoursePublicObjectType;
  /** course id of cart */
  courseId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** id of the cart */
  id: Scalars['String']['output'];
  /** total seat for seminar */
  noOfSeat: Scalars['Float']['output'];
  /** price of course */
  price: Scalars['Float']['output'];
  /** total tax amount on course */
  taxAmount: Scalars['Float']['output'];
  /** tax rate on course */
  taxRate: Scalars['Float']['output'];
  /** total price of course including tax */
  totalPrice: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** user id of cart */
  userId: Scalars['String']['output'];
};

/** for category status */
export enum CategoryStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type CategoryStatus_PropertyFilterInputType = {
  /** status of category */
  eq?: InputMaybe<CategoryStatus>;
  /** status of category */
  in?: InputMaybe<Array<CategoryStatus>>;
  /** status of category */
  neq?: InputMaybe<CategoryStatus>;
  /** status of category */
  notin?: InputMaybe<Array<CategoryStatus>>;
  /** status of category */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChangePasswordInput = {
  /** confirmNewPassword of the user */
  confirmNewPassword: Scalars['String']['input'];
  /** newPassword of the user */
  newPassword: Scalars['String']['input'];
  /** oldPassword of the user */
  oldPassword: Scalars['String']['input'];
};

export type CityObjectType = {
  __typename?: 'CityObjectType';
  /** City name */
  cityName: Scalars['String']['output'];
  /** id of the city */
  id: Scalars['String']['output'];
  /** id of the state */
  stateId: Scalars['String']['output'];
};

export type CmsListObjectType = {
  __typename?: 'CmsListObjectType';
  pagination: CmsPaginationType;
  records: Array<CmsObjectType>;
};

export type CmsObjectType = {
  __typename?: 'CmsObjectType';
  content: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** id of the CMS */
  id: Scalars['String']['output'];
  /** Keyword of CMS */
  keywords: Scalars['String']['output'];
  /** Name of CMS */
  name: Scalars['String']['output'];
  /** Description of CMS */
  seoDescription: Scalars['String']['output'];
  /** Title of CMS */
  seoTitle: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  /** Status of the CMS */
  status: CmsStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type CmsPaginationType = {
  __typename?: 'CmsPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type CmsPublicObjectType = {
  __typename?: 'CmsPublicObjectType';
  content: Scalars['String']['output'];
  /** id of the CMS */
  id: Scalars['String']['output'];
  /** Keyword of CMS */
  keywords: Scalars['String']['output'];
  /** Name of CMS */
  name: Scalars['String']['output'];
  /** Description of CMS */
  seoDescription: Scalars['String']['output'];
  /** Title of CMS */
  seoTitle: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type CmsResolver_GetCmsFilterInputType = {
  keywords?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  seo_title?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<CmsStatusEnum_PropertyFilterInputType>;
};

export type CmsResolver_GetCms_FilterInputType = {
  and?: InputMaybe<Array<CmsResolver_GetCmsFilterInputType>>;
  keywords?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CmsResolver_GetCmsFilterInputType>>;
  seo_title?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<CmsStatusEnum_PropertyFilterInputType>;
};

/** status of CMS */
export enum CmsStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type CmsStatusEnum_PropertyFilterInputType = {
  /** Status of the CMS */
  eq?: InputMaybe<CmsStatusEnum>;
  /** Status of the CMS */
  in?: InputMaybe<Array<CmsStatusEnum>>;
  /** Status of the CMS */
  neq?: InputMaybe<CmsStatusEnum>;
  /** Status of the CMS */
  notin?: InputMaybe<Array<CmsStatusEnum>>;
  /** Status of the CMS */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContactUsListObjectType = {
  __typename?: 'ContactUsListObjectType';
  pagination: ContactUsPaginationType;
  records: Array<ContactUsObjectType>;
};

export type ContactUsObjectType = {
  __typename?: 'ContactUsObjectType';
  /** Contact number of user who submit contact us form */
  contactNo: Scalars['String']['output'];
  /** Date of contact us form submission */
  createdAt: Scalars['DateTime']['output'];
  /** email of user who submit contact us form */
  email: Scalars['String']['output'];
  /** id of contact us record */
  id: Scalars['String']['output'];
  /** Message of user who submit contact us form */
  message?: Maybe<Scalars['String']['output']>;
  /** name of user who submit contact us form */
  name: Scalars['String']['output'];
  /** The const us Status status */
  status: ContactUsStatusEnum;
  /** Contact no std code of user who submit contact us form */
  stdCodeOfContactNo?: Maybe<Scalars['String']['output']>;
  /** Subject of user who submit contact us form */
  subject?: Maybe<Scalars['String']['output']>;
};

export type ContactUsPaginationType = {
  __typename?: 'ContactUsPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type ContactUsResolver_GetContactUsListFilterInputType = {
  contactNo?: InputMaybe<String_PropertyFilterInputType>;
  contactNoStdCode?: InputMaybe<String_PropertyFilterInputType>;
  email?: InputMaybe<String_PropertyFilterInputType>;
  message?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
};

export type ContactUsResolver_GetContactUsList_FilterInputType = {
  and?: InputMaybe<Array<ContactUsResolver_GetContactUsListFilterInputType>>;
  contactNo?: InputMaybe<String_PropertyFilterInputType>;
  contactNoStdCode?: InputMaybe<String_PropertyFilterInputType>;
  email?: InputMaybe<String_PropertyFilterInputType>;
  message?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<ContactUsResolver_GetContactUsListFilterInputType>>;
};

/** status of contact us */
export enum ContactUsStatusEnum {
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  Resolved = 'RESOLVED'
}

export type CountryObjectType = {
  __typename?: 'CountryObjectType';
  /** code of the country */
  countryCode: Scalars['String']['output'];
  /** name of the country */
  countryName: Scalars['String']['output'];
  /** Country's std code */
  countryStdCode: Scalars['String']['output'];
  /** id of the country code */
  id: Scalars['String']['output'];
  states?: Maybe<Array<StateObjectType>>;
};

export type CourseCategoriesResolver_GetCourseCategoriesFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
  parent_id?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  sort_number?: InputMaybe<Int_PropertyFilterInputType>;
  status?: InputMaybe<CategoryStatus_PropertyFilterInputType>;
};

export type CourseCategoriesResolver_GetCourseCategories_FilterInputType = {
  and?: InputMaybe<Array<CourseCategoriesResolver_GetCourseCategoriesFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CourseCategoriesResolver_GetCourseCategoriesFilterInputType>>;
  parent_id?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  sort_number?: InputMaybe<Int_PropertyFilterInputType>;
  status?: InputMaybe<CategoryStatus_PropertyFilterInputType>;
};

export type CourseCategoriesResolver_GetPublicCourseCategoriesFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
  parent_id?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  sort_number?: InputMaybe<Int_PropertyFilterInputType>;
  status?: InputMaybe<CategoryStatus_PropertyFilterInputType>;
};

export type CourseCategoriesResolver_GetPublicCourseCategories_FilterInputType = {
  and?: InputMaybe<Array<CourseCategoriesResolver_GetPublicCourseCategoriesFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CourseCategoriesResolver_GetPublicCourseCategoriesFilterInputType>>;
  parent_id?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  sort_number?: InputMaybe<Int_PropertyFilterInputType>;
  status?: InputMaybe<CategoryStatus_PropertyFilterInputType>;
};

export type CourseCategoryDto = {
  __typename?: 'CourseCategoryDto';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** id of the category */
  id: Scalars['String']['output'];
  /** image url of category */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** name of the category */
  name: Scalars['String']['output'];
  parent?: Maybe<CourseCategoryDto>;
  /** parent_id url of category */
  parentId?: Maybe<Scalars['String']['output']>;
  /** slug url of category */
  slug: Scalars['String']['output'];
  /** sort number of category */
  sortNumber: Scalars['Int']['output'];
  /** status of category */
  status: CategoryStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type CourseCategoryListObjectType = {
  __typename?: 'CourseCategoryListObjectType';
  pagination: CourseCategoryPaginationType;
  records: Array<CourseCategoryDto>;
};

export type CourseCategoryPaginationType = {
  __typename?: 'CourseCategoryPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type CourseCategoryPublicDto = {
  __typename?: 'CourseCategoryPublicDto';
  /** id of the category */
  id: Scalars['String']['output'];
  /** image url of category */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** name of the category */
  name: Scalars['String']['output'];
  parent?: Maybe<CourseCategoryPublicDto>;
  /** parent_id url of category */
  parentId?: Maybe<Scalars['String']['output']>;
  /** slug url of category */
  slug: Scalars['String']['output'];
  /** sort number of category */
  sortNumber: Scalars['Int']['output'];
};

/** for course cost type */
export enum CourseCostTypeEnum {
  Free = 'FREE',
  Paid = 'PAID'
}

export type CourseCostTypeEnum_PropertyFilterInputType = {
  /** cost type of course */
  eq?: InputMaybe<CourseCostTypeEnum>;
  /** cost type of course */
  in?: InputMaybe<Array<CourseCostTypeEnum>>;
  /** cost type of course */
  neq?: InputMaybe<CourseCostTypeEnum>;
  /** cost type of course */
  notin?: InputMaybe<Array<CourseCostTypeEnum>>;
  /** cost type of course */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CourseEnrollmentListObjectType = {
  __typename?: 'CourseEnrollmentListObjectType';
  pagination: CourseEnrollmentPaginationType;
  records: Array<CourseEnrollmentObjectType>;
};

export type CourseEnrollmentObjectType = {
  __typename?: 'CourseEnrollmentObjectType';
  allow_certificate_download?: Maybe<Scalars['Boolean']['output']>;
  completedDatetime?: Maybe<Scalars['DateTime']['output']>;
  course: CoursePublicObjectType;
  /** Course id for which student has enroll */
  courseId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endDatetime?: Maybe<Scalars['DateTime']['output']>;
  /** id of the enrollment record */
  id: Scalars['String']['output'];
  order: OrderObjectType;
  /** Order id for the course enrollment */
  orderId: Scalars['String']['output'];
  startDatetime?: Maybe<Scalars['DateTime']['output']>;
  /** Enrolled course status */
  status: CourseEnrollmentStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: UserPublicObjectType;
  /** User's id of student who enroll for course */
  userId: Scalars['String']['output'];
};

export type CourseEnrollmentPaginationType = {
  __typename?: 'CourseEnrollmentPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

/** The course enrollment status */
export enum CourseEnrollmentStatusEnum {
  Completed = 'COMPLETED',
  Enrolled = 'ENROLLED',
  InProgress = 'IN_PROGRESS'
}

export type CourseEnrollmentStatusEnum_PropertyFilterInputType = {
  eq?: InputMaybe<CourseEnrollmentStatusEnum>;
  in?: InputMaybe<Array<CourseEnrollmentStatusEnum>>;
  neq?: InputMaybe<CourseEnrollmentStatusEnum>;
  notin?: InputMaybe<Array<CourseEnrollmentStatusEnum>>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CourseHighlightsObjectType = {
  __typename?: 'CourseHighlightsObjectType';
  /** Id of course with which highlights is associated */
  courseId: Scalars['String']['output'];
  /** Course highlights icon url */
  highlightIcon?: Maybe<Scalars['String']['output']>;
  /** Course highlights text */
  highlightText: Scalars['String']['output'];
  /** id of highlight records */
  id: Scalars['String']['output'];
};

export type CourseImagesObjectType = {
  __typename?: 'CourseImagesObjectType';
  large?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  original?: Maybe<Scalars['String']['output']>;
  thumb?: Maybe<Scalars['String']['output']>;
};

export type CourseLanguageListObjectType = {
  __typename?: 'CourseLanguageListObjectType';
  pagination: CourseLanguagePaginationType;
  records: Array<CourseLanguageObjectType>;
};

export type CourseLanguageObjectType = {
  __typename?: 'CourseLanguageObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** Id of course language */
  id: Scalars['String']['output'];
  /** Name of course language */
  name: Scalars['String']['output'];
  /** Short code of course language */
  shortCode: Scalars['String']['output'];
  /** slug of course langauge */
  slug: Scalars['String']['output'];
  /** Status of course language */
  status: CourseLanguageStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type CourseLanguagePaginationType = {
  __typename?: 'CourseLanguagePaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type CourseLanguagePublicListObjectType = {
  __typename?: 'CourseLanguagePublicListObjectType';
  pagination: CourseLanguagePaginationType;
  records: Array<CourseLanguagePublicObjectType>;
};

export type CourseLanguagePublicObjectType = {
  __typename?: 'CourseLanguagePublicObjectType';
  /** Id of course language */
  id: Scalars['String']['output'];
  /** Name of course language */
  name: Scalars['String']['output'];
  /** Short code of course language */
  shortCode: Scalars['String']['output'];
  /** slug of course langauge */
  slug: Scalars['String']['output'];
};

/** enum for course language status */
export enum CourseLanguageStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type CourseLanguagesResolver_GetCourseLanguagesListFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
  short_code?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
};

export type CourseLanguagesResolver_GetCourseLanguagesList_FilterInputType = {
  and?: InputMaybe<Array<CourseLanguagesResolver_GetCourseLanguagesListFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CourseLanguagesResolver_GetCourseLanguagesListFilterInputType>>;
  short_code?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
};

export type CourseLanguagesResolver_GetPublicCourseLanguagesListFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
};

export type CourseLanguagesResolver_GetPublicCourseLanguagesList_FilterInputType = {
  and?: InputMaybe<Array<CourseLanguagesResolver_GetPublicCourseLanguagesListFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CourseLanguagesResolver_GetPublicCourseLanguagesListFilterInputType>>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
};

export type CourseLessonListObjectType = {
  __typename?: 'CourseLessonListObjectType';
  pagination: CourseLessonPaginationType;
  records: Array<CourseLessonObjectType>;
};

export type CourseLessonMediaInput = {
  /** content type media */
  contentType: Scalars['String']['input'];
  /** file name for content */
  fileName: Scalars['String']['input'];
  /** type of media */
  mediaType: CourseLessonMediaTypeEnum;
};

/** for lesson media type */
export enum CourseLessonMediaTypeEnum {
  Docs = 'DOCS',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type CourseLessonObjectType = {
  __typename?: 'CourseLessonObjectType';
  /** is_lesson_completed of course lesson */
  completed: Scalars['Boolean']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** completed duration of course lesson */
  completedDuration?: Maybe<Scalars['Float']['output']>;
  contentUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** description of lesson */
  description: Scalars['String']['output'];
  /** duration of lesson */
  duration?: Maybe<Scalars['Float']['output']>;
  /** id of the lesson */
  id: Scalars['String']['output'];
  /** introduction of lesson */
  introduction: Scalars['String']['output'];
  /** lesson lock status */
  isLock: Scalars['Boolean']['output'];
  meetingStartTime?: Maybe<Scalars['String']['output']>;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  /** name of the lesson */
  name: Scalars['String']['output'];
  /** lesson quiz */
  quiz?: Maybe<QuizObjectType>;
  /** quiz id of the lesson */
  quizId?: Maybe<Scalars['String']['output']>;
  section: CourseSectionObjectType;
  /** course id */
  sectionId: Scalars['String']['output'];
  /** Sort Order of lesson */
  sortNumber?: Maybe<Scalars['Float']['output']>;
  /** lesson start date */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** status of lesson */
  status: LessonStatusEnum;
  /** type of lesson */
  type: LessonTypeEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type CourseLessonPaginationType = {
  __typename?: 'CourseLessonPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type CourseLevelListObjectType = {
  __typename?: 'CourseLevelListObjectType';
  pagination: CourseLevelPaginationType;
  records: Array<CourseLevelObjectType>;
};

export type CourseLevelObjectType = {
  __typename?: 'CourseLevelObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** id of the course level */
  id: Scalars['String']['output'];
  /** name of the course level */
  name: Scalars['String']['output'];
  /** slug of the course level */
  slug: Scalars['String']['output'];
  /** status of the course level */
  status: CourseLevelsStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type CourseLevelPaginationType = {
  __typename?: 'CourseLevelPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type CourseLevelPublicListObjectType = {
  __typename?: 'CourseLevelPublicListObjectType';
  pagination: CourseLevelPaginationType;
  records: Array<CourseLevelPublicObjectType>;
};

export type CourseLevelPublicObjectType = {
  __typename?: 'CourseLevelPublicObjectType';
  /** id of the course level */
  id: Scalars['String']['output'];
  /** name of the course level */
  name: Scalars['String']['output'];
  /** slug of the course level */
  slug: Scalars['String']['output'];
};

export type CourseLevelsResolver_GetCourseLevelListFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
};

export type CourseLevelsResolver_GetCourseLevelList_FilterInputType = {
  and?: InputMaybe<Array<CourseLevelsResolver_GetCourseLevelListFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CourseLevelsResolver_GetCourseLevelListFilterInputType>>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
};

export type CourseLevelsResolver_GetPublicCourseLevelListFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
};

export type CourseLevelsResolver_GetPublicCourseLevelList_FilterInputType = {
  and?: InputMaybe<Array<CourseLevelsResolver_GetPublicCourseLevelListFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CourseLevelsResolver_GetPublicCourseLevelListFilterInputType>>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
};

/** Course levels status enum */
export enum CourseLevelsStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type CourseListObjectType = {
  __typename?: 'CourseListObjectType';
  pagination: CoursePaginationType;
  records: Array<CourseObjectType>;
};

export type CourseLogObjectType = {
  __typename?: 'CourseLogObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** id of the log */
  id: Scalars['String']['output'];
  mentor?: Maybe<UserObjectType>;
  /** mentor id */
  mentorId?: Maybe<Scalars['String']['output']>;
  /** new value */
  newValue: Scalars['String']['output'];
  /** previous value */
  previousValue: Scalars['String']['output'];
  /** type of the log */
  type: CourseLogTypeEnum;
};

/** for course log type */
export enum CourseLogTypeEnum {
  CommissionChange = 'COMMISSION_CHANGE',
  PriceChange = 'PRICE_CHANGE'
}

export type CourseMedia = {
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CourseModeListObjectType = {
  __typename?: 'CourseModeListObjectType';
  pagination: CourseModePaginationType;
  records: Array<CourseModeObjectType>;
};

export type CourseModeObjectType = {
  __typename?: 'CourseModeObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** id of the course mode */
  id: Scalars['String']['output'];
  /** name of the course mode */
  name: Scalars['String']['output'];
  /** slug of the course mode */
  slug: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type CourseModePaginationType = {
  __typename?: 'CourseModePaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type CourseModePublicListObjectType = {
  __typename?: 'CourseModePublicListObjectType';
  pagination: CourseModePaginationType;
  records: Array<CourseModePublicObjectType>;
};

export type CourseModePublicObjectType = {
  __typename?: 'CourseModePublicObjectType';
  /** id of the course mode */
  id: Scalars['String']['output'];
  /** name of the course mode */
  name: Scalars['String']['output'];
};

export type CourseModeResolver_GetCourseModesFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
};

export type CourseModeResolver_GetCourseModes_FilterInputType = {
  and?: InputMaybe<Array<CourseModeResolver_GetCourseModesFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CourseModeResolver_GetCourseModesFilterInputType>>;
};

export type CourseModeResolver_GetPublicCourseModesFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
};

export type CourseModeResolver_GetPublicCourseModes_FilterInputType = {
  and?: InputMaybe<Array<CourseModeResolver_GetPublicCourseModesFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CourseModeResolver_GetPublicCourseModesFilterInputType>>;
};

export type CourseObjectType = {
  __typename?: 'CourseObjectType';
  /** address of seminar  */
  address?: Maybe<Scalars['String']['output']>;
  /** amount of course */
  amount?: Maybe<Scalars['Float']['output']>;
  /** cost type of course */
  costType?: Maybe<CourseCostTypeEnum>;
  /** course category */
  courseCategory?: Maybe<CourseCategoryDto>;
  /** category id of course */
  courseCategoryId?: Maybe<Scalars['String']['output']>;
  /** language of course */
  courseLanguages: Array<CourseLanguageObjectType>;
  /** course level */
  courseLevel?: Maybe<CourseLevelObjectType>;
  /** level id of course */
  courseLevelId?: Maybe<Scalars['String']['output']>;
  /** mode of course */
  courseMode?: Maybe<CourseModeObjectType>;
  /** mode id of course */
  courseModeId?: Maybe<Scalars['String']['output']>;
  /** course quiz */
  courseQuiz?: Maybe<QuizObjectType>;
  /** quiz id of course */
  courseQuizId?: Maybe<Scalars['String']['output']>;
  courseRatings?: Maybe<CourseRatingsObjectType>;
  courseSettings?: Maybe<CourseSettingsObjectType>;
  /** skills for course */
  courseSkills?: Maybe<Array<CourseSkillObjectType>>;
  /** course sub category */
  courseSubCategory?: Maybe<CourseCategoryDto>;
  /** course sub category id */
  courseSubCategoryId?: Maybe<Scalars['String']['output']>;
  /** type id of course */
  courseType?: Maybe<CourseTypeObjectType>;
  /** type id of course */
  courseTypeId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** description of course */
  description?: Maybe<Scalars['String']['output']>;
  /** Eligible percentage for download cerificate */
  eligiblePercentageForCertificate?: Maybe<Scalars['Float']['output']>;
  /** end data and time of course */
  endDatetime?: Maybe<Scalars['DateTime']['output']>;
  enrollments: Array<CourseEnrollmentObjectType>;
  /** for course has certificate or not */
  hasCertificate?: Maybe<Scalars['Boolean']['output']>;
  /** for course has quiz or not */
  hasQuiz?: Maybe<Scalars['Boolean']['output']>;
  /** highlights of the course */
  highlights: Array<CourseHighlightsObjectType>;
  /** id of the course */
  id: Scalars['String']['output'];
  /** images url of course */
  images?: Maybe<Array<CourseImagesObjectType>>;
  lessonQuizes: Array<QuizObjectType>;
  /** Course logs for commission and price change */
  logs?: Maybe<Array<CourseLogObjectType>>;
  /** meeting link for webinar */
  meetingLink?: Maybe<Scalars['String']['output']>;
  /** meeting number */
  meetingNumber?: Maybe<Scalars['String']['output']>;
  /** meeting password for webinar */
  meetingPassword?: Maybe<Scalars['String']['output']>;
  mentors: Array<MentorCommission>;
  /** name of the course */
  name: Scalars['String']['output'];
  objectives: Array<CourseObjectiveObjectType>;
  /** Preview video for course */
  previewVideo?: Maybe<Scalars['String']['output']>;
  /** requirements for course */
  requirements?: Maybe<Scalars['String']['output']>;
  sections: Array<CourseSectionObjectType>;
  /** Sell price of course */
  sellPrice?: Maybe<Scalars['Float']['output']>;
  /** short description of course */
  shortDescription?: Maybe<Scalars['String']['output']>;
  /** slug url of course */
  slug: Scalars['String']['output'];
  /** sort order of course */
  sortOrder?: Maybe<Scalars['String']['output']>;
  /** start data and time of course */
  startDatetime?: Maybe<Scalars['DateTime']['output']>;
  /** status of course */
  status: CourseStatusEnum;
  /** total seat of course */
  totalSeat?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userCourseRatings: Array<UserCourseRatingsObjectType>;
};

export type CourseObjectiveObjectType = {
  __typename?: 'CourseObjectiveObjectType';
  /** Objective of the course */
  objective: Scalars['String']['output'];
};

export type CoursePaginationType = {
  __typename?: 'CoursePaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type CourseProgressObjectType = {
  __typename?: 'CourseProgressObjectType';
  /** course details */
  course: CoursePublicObjectType;
  /** Course id */
  courseId: Scalars['String']['output'];
  /** course lesson details */
  courseLesson: CourseLessonObjectType;
  /** course quiz details */
  courseQuiz?: Maybe<QuizObjectType>;
  /** course section details */
  courseSection?: Maybe<CourseSectionObjectType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** duration of course progress */
  duration?: Maybe<Scalars['Float']['output']>;
  /** id of the tracking  */
  id: Scalars['String']['output'];
  /** is_course_completed of course progress */
  isCourseCompleted?: Maybe<Scalars['Boolean']['output']>;
  /** is_lesson_completed of course progress */
  isLessonCompleted?: Maybe<Scalars['Boolean']['output']>;
  /** is_quiz_completed of course progress */
  isQuizCompleted?: Maybe<Scalars['Boolean']['output']>;
  /** is_section_completed of course progress */
  isSectionCompleted?: Maybe<Scalars['Boolean']['output']>;
  /** lession id */
  lessonId: Scalars['String']['output'];
  /** quiz_id id */
  quizId?: Maybe<Scalars['String']['output']>;
  /** section_id id */
  sectionId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User id */
  userId: Scalars['String']['output'];
};

export type CoursePublicListObjectType = {
  __typename?: 'CoursePublicListObjectType';
  pagination: CoursePaginationType;
  records: Array<CoursePublicObjectType>;
};

export type CoursePublicObjectType = {
  __typename?: 'CoursePublicObjectType';
  CourseCompletedAt: Scalars['String']['output'];
  InCart: Scalars['Boolean']['output'];
  /** address of seminar  */
  address?: Maybe<Scalars['String']['output']>;
  /** amount of course */
  amount: Scalars['Float']['output'];
  averageStarCount?: Maybe<Scalars['Float']['output']>;
  /** is_course_completed of course */
  completed: Scalars['Boolean']['output'];
  /** cost type of course */
  costType: CourseCostTypeEnum;
  /** course category */
  courseCategory: CourseCategoryPublicDto;
  /** category id of course */
  courseCategoryId?: Maybe<Scalars['String']['output']>;
  /** Total enrolled student count */
  courseEnrollmentCount?: Maybe<Scalars['Float']['output']>;
  /** language of course */
  courseLanguages: Array<CourseLanguageObjectType>;
  /** course level */
  courseLevel: CourseLevelPublicObjectType;
  /** level id of course */
  courseLevelId: Scalars['String']['output'];
  /** mode of course */
  courseMode?: Maybe<CourseModePublicObjectType>;
  /** mode id of course */
  courseModeId?: Maybe<Scalars['String']['output']>;
  courseProgress: Array<CourseProgressObjectType>;
  courseRatings?: Maybe<CourseRatingsObjectType>;
  courseSettings?: Maybe<CourseSettingsObjectType>;
  /** skills for course */
  courseSkills?: Maybe<Array<CourseSkillObjectType>>;
  /** course sub category */
  courseSubCategory: CourseCategoryDto;
  /** course sub category id */
  courseSubCategoryId: Scalars['String']['output'];
  /** type id of course */
  courseType: CourseTypePublicObjectType;
  /** type id of course */
  courseTypeId: Scalars['String']['output'];
  currentChapter?: Maybe<CourseLessonObjectType>;
  /** description of course */
  description: Scalars['String']['output'];
  /** Eligible precentage for certificate */
  eligiblePercentageForCertificate?: Maybe<Scalars['Float']['output']>;
  /** end data and time of course */
  endDatetime?: Maybe<Scalars['DateTime']['output']>;
  /** enrollementStatus of course */
  enrollmentStatus: Scalars['Boolean']['output'];
  /** for course has certificate or not */
  hasCertificate: Scalars['Boolean']['output'];
  /** for course has quiz or not */
  hasQuiz?: Maybe<Scalars['Boolean']['output']>;
  /** highlights of the course */
  highlights: Array<CourseHighlightsObjectType>;
  /** id of the course */
  id: Scalars['String']['output'];
  /** images url of course */
  images?: Maybe<Array<CourseImagesObjectType>>;
  /** meeting link for webinar */
  meetingLink?: Maybe<Scalars['String']['output']>;
  /** meeting number */
  meetingNumber?: Maybe<Scalars['String']['output']>;
  /** meeting password for webinar */
  meetingPassword?: Maybe<Scalars['String']['output']>;
  mentors: Array<PublicCourseMentor>;
  myCourseRatings?: Maybe<UserCourseRatingsObjectType>;
  /** name of the course */
  name: Scalars['String']['output'];
  nextChapter?: Maybe<CourseLessonObjectType>;
  objectives: Array<CourseObjectiveObjectType>;
  /** Preview video for course */
  previewVideo?: Maybe<Scalars['String']['output']>;
  progressPercentage: Scalars['Float']['output'];
  ratingCounts: Scalars['Float']['output'];
  relatedCoursesUId?: Maybe<Array<Scalars['String']['output']>>;
  /** requirements for course */
  requirements?: Maybe<Scalars['String']['output']>;
  sections: Array<CourseSectionObjectType>;
  /** Sell price of course */
  sellPrice?: Maybe<Scalars['Float']['output']>;
  /** short description of course */
  shortDescription: Scalars['String']['output'];
  /** slug url of course */
  slug: Scalars['String']['output'];
  /** sort order of course */
  sortOrder: Scalars['String']['output'];
  /** start data and time of course */
  startDatetime?: Maybe<Scalars['DateTime']['output']>;
  /** status of course */
  status: CourseStatusEnum;
  /** total seat of course */
  totalSeat?: Maybe<Scalars['Float']['output']>;
  totalUserRatings?: Maybe<Scalars['Float']['output']>;
  userCourseRatings?: Maybe<Array<UserCourseRatingsObjectType>>;
  /** wishlist of course */
  wishlist: Scalars['Boolean']['output'];
};

export type CourseRatingListObjectType = {
  __typename?: 'CourseRatingListObjectType';
  pagination: CourseRatingPaginationType;
  records: Array<UserCourseRatingsObjectType>;
};

export type CourseRatingPaginationType = {
  __typename?: 'CourseRatingPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type CourseRatingsObjectType = {
  __typename?: 'CourseRatingsObjectType';
  /** courseId of course ratings */
  courseId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<UserObjectType>;
  createdById?: Maybe<Scalars['String']['output']>;
  /** Id of course ratings */
  id: Scalars['String']['output'];
  /** ratings of course ratings */
  ratings: Scalars['Float']['output'];
  /** star count of course ratings */
  starCount: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type CourseRatingsResolver_GetCourseRatingFilterInputType = {
  course?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<RatingStatusEnum_PropertyFilterInputType>;
  user?: InputMaybe<String_PropertyFilterInputType>;
};

export type CourseRatingsResolver_GetCourseRating_FilterInputType = {
  and?: InputMaybe<Array<CourseRatingsResolver_GetCourseRatingFilterInputType>>;
  course?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CourseRatingsResolver_GetCourseRatingFilterInputType>>;
  status?: InputMaybe<RatingStatusEnum_PropertyFilterInputType>;
  user?: InputMaybe<String_PropertyFilterInputType>;
};

export type CourseSectionListObjectType = {
  __typename?: 'CourseSectionListObjectType';
  pagination: CourseSectionPaginationType;
  records: Array<CourseSectionObjectType>;
};

export type CourseSectionObjectType = {
  __typename?: 'CourseSectionObjectType';
  /** is_section_completed of course section */
  completed: Scalars['Boolean']['output'];
  course: CourseObjectType;
  /** course id */
  courseId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** id of the section */
  id: Scalars['String']['output'];
  /** introduction of the section */
  introduction: Scalars['String']['output'];
  lessons: Array<CourseLessonObjectType>;
  /** name of the section */
  name: Scalars['String']['output'];
  /** sort order of section */
  sortNumber: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type CourseSectionPaginationType = {
  __typename?: 'CourseSectionPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type CourseSettingsInput = {
  courseId: Scalars['String']['input'];
  courseTypeVisible?: Scalars['Boolean']['input'];
  dateVisible?: Scalars['Boolean']['input'];
  durationVisible?: Scalars['Boolean']['input'];
  languageVisible?: Scalars['Boolean']['input'];
  levelVisible?: Scalars['Boolean']['input'];
  timeVisible?: Scalars['Boolean']['input'];
};

export type CourseSettingsObjectType = {
  __typename?: 'CourseSettingsObjectType';
  course: CoursePublicObjectType;
  courseId: Scalars['String']['output'];
  courseTypeVisible: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<UserObjectType>;
  dateVisible: Scalars['Boolean']['output'];
  durationVisible: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  languageVisible: Scalars['Boolean']['output'];
  levelVisible: Scalars['Boolean']['output'];
  timeVisible: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
};

export type CourseSkillListObjectType = {
  __typename?: 'CourseSkillListObjectType';
  pagination: CourseSkillPaginationType;
  records: Array<CourseSkillObjectType>;
};

export type CourseSkillObjectType = {
  __typename?: 'CourseSkillObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** Id of course skill */
  id: Scalars['String']['output'];
  /** Name of course skill */
  name: Scalars['String']['output'];
  /** slug of course skill */
  slug: Scalars['String']['output'];
  /** Status of course skill */
  status: CourseSkillStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type CourseSkillPaginationType = {
  __typename?: 'CourseSkillPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

/** enum for course skill status */
export enum CourseSkillStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type CourseSkillStatusEnum_PropertyFilterInputType = {
  /** Status of course skill */
  eq?: InputMaybe<CourseSkillStatusEnum>;
  /** Status of course skill */
  in?: InputMaybe<Array<CourseSkillStatusEnum>>;
  /** Status of course skill */
  neq?: InputMaybe<CourseSkillStatusEnum>;
  /** Status of course skill */
  notin?: InputMaybe<Array<CourseSkillStatusEnum>>;
  /** Status of course skill */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CourseSkillsResolver_GetCourseSkillsFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<CourseSkillStatusEnum_PropertyFilterInputType>;
};

export type CourseSkillsResolver_GetCourseSkills_FilterInputType = {
  and?: InputMaybe<Array<CourseSkillsResolver_GetCourseSkillsFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CourseSkillsResolver_GetCourseSkillsFilterInputType>>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<CourseSkillStatusEnum_PropertyFilterInputType>;
};

/** for course status */
export enum CourseStatusEnum {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED',
  ReadyForPublish = 'READY_FOR_PUBLISH',
  ReadyForReview = 'READY_FOR_REVIEW'
}

export type CourseStatusEnum_PropertyFilterInputType = {
  eq?: InputMaybe<CourseStatusEnum>;
  in?: InputMaybe<Array<CourseStatusEnum>>;
  neq?: InputMaybe<CourseStatusEnum>;
  notin?: InputMaybe<Array<CourseStatusEnum>>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CourseTypeListObjectType = {
  __typename?: 'CourseTypeListObjectType';
  pagination: CourseTypePaginationType;
  records: Array<CourseTypeObjectType>;
};

export type CourseTypeObjectType = {
  __typename?: 'CourseTypeObjectType';
  /** allow multiple quantities for course type */
  allowMultipleQuantities?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** id of the course type */
  id: Scalars['String']['output'];
  /** name of the course type */
  name: Scalars['String']['output'];
  /** slug of the course type */
  slug: Scalars['String']['output'];
  /** status of the course type */
  status: CourseTypeStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type CourseTypePaginationType = {
  __typename?: 'CourseTypePaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type CourseTypePublicListObjectType = {
  __typename?: 'CourseTypePublicListObjectType';
  pagination: CourseTypePaginationType;
  records: Array<CourseTypePublicObjectType>;
};

export type CourseTypePublicObjectType = {
  __typename?: 'CourseTypePublicObjectType';
  /** id of the course type */
  id: Scalars['String']['output'];
  /** name of the course type */
  name: Scalars['String']['output'];
  /** slug of the course type */
  slug: Scalars['String']['output'];
};

export type CourseTypeResolver_GetCourseTypesListFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
};

export type CourseTypeResolver_GetCourseTypesList_FilterInputType = {
  and?: InputMaybe<Array<CourseTypeResolver_GetCourseTypesListFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CourseTypeResolver_GetCourseTypesListFilterInputType>>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
};

export type CourseTypeResolver_GetPublicCourseTypesFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
};

export type CourseTypeResolver_GetPublicCourseTypes_FilterInputType = {
  and?: InputMaybe<Array<CourseTypeResolver_GetPublicCourseTypesFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CourseTypeResolver_GetPublicCourseTypesFilterInputType>>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
};

export type CoursesLessonResolver_GetCourseLessonsFilterInputType = {
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  section_id?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<LessonTypeEnum_PropertyFilterInputType>;
};

export type CoursesLessonResolver_GetCourseLessons_FilterInputType = {
  and?: InputMaybe<Array<CoursesLessonResolver_GetCourseLessonsFilterInputType>>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CoursesLessonResolver_GetCourseLessonsFilterInputType>>;
  section_id?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<LessonTypeEnum_PropertyFilterInputType>;
};

export type CoursesPublicResolver_GetPublicCoursesFilterInputType = {
  amount?: InputMaybe<Number_PropertyFilterInputType>;
  cost_type?: InputMaybe<CourseCostTypeEnum_PropertyFilterInputType>;
  course_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_language_id?: InputMaybe<String_PropertyFilterInputType>;
  course_level_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mentor_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mode_id?: InputMaybe<String_PropertyFilterInputType>;
  course_type_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
};

export type CoursesPublicResolver_GetPublicCourses_FilterInputType = {
  amount?: InputMaybe<Number_PropertyFilterInputType>;
  and?: InputMaybe<Array<CoursesPublicResolver_GetPublicCoursesFilterInputType>>;
  cost_type?: InputMaybe<CourseCostTypeEnum_PropertyFilterInputType>;
  course_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_language_id?: InputMaybe<String_PropertyFilterInputType>;
  course_level_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mentor_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mode_id?: InputMaybe<String_PropertyFilterInputType>;
  course_type_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CoursesPublicResolver_GetPublicCoursesFilterInputType>>;
};

export type CoursesPublicResolver_GetPublicNewArrivalCoursesFilterInputType = {
  amount?: InputMaybe<Number_PropertyFilterInputType>;
  cost_type?: InputMaybe<CourseCostTypeEnum_PropertyFilterInputType>;
  course_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_language_id?: InputMaybe<String_PropertyFilterInputType>;
  course_level_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mentor_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mode_id?: InputMaybe<String_PropertyFilterInputType>;
  course_type_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
};

export type CoursesPublicResolver_GetPublicNewArrivalCourses_FilterInputType = {
  amount?: InputMaybe<Number_PropertyFilterInputType>;
  and?: InputMaybe<Array<CoursesPublicResolver_GetPublicNewArrivalCoursesFilterInputType>>;
  cost_type?: InputMaybe<CourseCostTypeEnum_PropertyFilterInputType>;
  course_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_language_id?: InputMaybe<String_PropertyFilterInputType>;
  course_level_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mentor_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mode_id?: InputMaybe<String_PropertyFilterInputType>;
  course_type_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CoursesPublicResolver_GetPublicNewArrivalCoursesFilterInputType>>;
};

export type CoursesPublicResolver_GetPublicRecommendedCoursesFilterInputType = {
  amount?: InputMaybe<Number_PropertyFilterInputType>;
  cost_type?: InputMaybe<CourseCostTypeEnum_PropertyFilterInputType>;
  course_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_language_id?: InputMaybe<String_PropertyFilterInputType>;
  course_level_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mentor_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mode_id?: InputMaybe<String_PropertyFilterInputType>;
  course_type_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
};

export type CoursesPublicResolver_GetPublicRecommendedCourses_FilterInputType = {
  amount?: InputMaybe<Number_PropertyFilterInputType>;
  and?: InputMaybe<Array<CoursesPublicResolver_GetPublicRecommendedCoursesFilterInputType>>;
  cost_type?: InputMaybe<CourseCostTypeEnum_PropertyFilterInputType>;
  course_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_language_id?: InputMaybe<String_PropertyFilterInputType>;
  course_level_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mentor_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mode_id?: InputMaybe<String_PropertyFilterInputType>;
  course_type_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CoursesPublicResolver_GetPublicRecommendedCoursesFilterInputType>>;
};

export type CoursesPublicResolver_GetPublicTopSellingCoursesFilterInputType = {
  amount?: InputMaybe<Number_PropertyFilterInputType>;
  cost_type?: InputMaybe<CourseCostTypeEnum_PropertyFilterInputType>;
  course_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_language_id?: InputMaybe<String_PropertyFilterInputType>;
  course_level_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mentor_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mode_id?: InputMaybe<String_PropertyFilterInputType>;
  course_type_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
};

export type CoursesPublicResolver_GetPublicTopSellingCourses_FilterInputType = {
  amount?: InputMaybe<Number_PropertyFilterInputType>;
  and?: InputMaybe<Array<CoursesPublicResolver_GetPublicTopSellingCoursesFilterInputType>>;
  cost_type?: InputMaybe<CourseCostTypeEnum_PropertyFilterInputType>;
  course_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_language_id?: InputMaybe<String_PropertyFilterInputType>;
  course_level_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mentor_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mode_id?: InputMaybe<String_PropertyFilterInputType>;
  course_type_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CoursesPublicResolver_GetPublicTopSellingCoursesFilterInputType>>;
};

export type CoursesPublicResolver_GetPublicUpcomingCoursesFilterInputType = {
  amount?: InputMaybe<Number_PropertyFilterInputType>;
  cost_type?: InputMaybe<CourseCostTypeEnum_PropertyFilterInputType>;
  course_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_language_id?: InputMaybe<String_PropertyFilterInputType>;
  course_level_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mentor_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mode_id?: InputMaybe<String_PropertyFilterInputType>;
  course_type_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
};

export type CoursesPublicResolver_GetPublicUpcomingCourses_FilterInputType = {
  amount?: InputMaybe<Number_PropertyFilterInputType>;
  and?: InputMaybe<Array<CoursesPublicResolver_GetPublicUpcomingCoursesFilterInputType>>;
  cost_type?: InputMaybe<CourseCostTypeEnum_PropertyFilterInputType>;
  course_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_language_id?: InputMaybe<String_PropertyFilterInputType>;
  course_level_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mentor_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mode_id?: InputMaybe<String_PropertyFilterInputType>;
  course_type_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CoursesPublicResolver_GetPublicUpcomingCoursesFilterInputType>>;
};

export type CoursesResolver_GetCoursesFilterInputType = {
  address?: InputMaybe<String_PropertyFilterInputType>;
  amount?: InputMaybe<Number_PropertyFilterInputType>;
  cost_type?: InputMaybe<CourseCostTypeEnum_PropertyFilterInputType>;
  course_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_level_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mode_id?: InputMaybe<String_PropertyFilterInputType>;
  course_sub_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_type_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  end_datetime?: InputMaybe<Date_PropertyFilterInputType>;
  has_certificate?: InputMaybe<Boolean_PropertyFilterInputType>;
  has_quiz?: InputMaybe<Boolean_PropertyFilterInputType>;
  id?: InputMaybe<String_PropertyFilterInputType>;
  meeting_number?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  start_datetime?: InputMaybe<Date_PropertyFilterInputType>;
  status?: InputMaybe<CourseStatusEnum_PropertyFilterInputType>;
  total_seat?: InputMaybe<Number_PropertyFilterInputType>;
};

export type CoursesResolver_GetCourses_FilterInputType = {
  address?: InputMaybe<String_PropertyFilterInputType>;
  amount?: InputMaybe<Number_PropertyFilterInputType>;
  and?: InputMaybe<Array<CoursesResolver_GetCoursesFilterInputType>>;
  cost_type?: InputMaybe<CourseCostTypeEnum_PropertyFilterInputType>;
  course_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_level_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mode_id?: InputMaybe<String_PropertyFilterInputType>;
  course_sub_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_type_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  end_datetime?: InputMaybe<Date_PropertyFilterInputType>;
  has_certificate?: InputMaybe<Boolean_PropertyFilterInputType>;
  has_quiz?: InputMaybe<Boolean_PropertyFilterInputType>;
  id?: InputMaybe<String_PropertyFilterInputType>;
  meeting_number?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CoursesResolver_GetCoursesFilterInputType>>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  start_datetime?: InputMaybe<Date_PropertyFilterInputType>;
  status?: InputMaybe<CourseStatusEnum_PropertyFilterInputType>;
  total_seat?: InputMaybe<Number_PropertyFilterInputType>;
};

export type CoursesSectionResolver_GetCourseSectionsFilterInputType = {
  course_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  introduction?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  sort_number?: InputMaybe<String_PropertyFilterInputType>;
};

export type CoursesSectionResolver_GetCourseSections_FilterInputType = {
  and?: InputMaybe<Array<CoursesSectionResolver_GetCourseSectionsFilterInputType>>;
  course_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  introduction?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<CoursesSectionResolver_GetCourseSectionsFilterInputType>>;
  sort_number?: InputMaybe<String_PropertyFilterInputType>;
};

export type CreateBannerInput = {
  /** Description of banner */
  description: Scalars['String']['input'];
  /** image of banner */
  images: BannerMedia;
  /** Name of banner */
  name: Scalars['String']['input'];
  /** Navigation link for banner */
  navigationLink?: InputMaybe<Scalars['String']['input']>;
  /** Sort number of banner */
  sortNumber: Scalars['Float']['input'];
  /** status of the banner */
  status: BannerStatusEnum;
  /** Type of banner */
  type: BannerTypeEnum;
};

export type CreateBlogCategoryInput = {
  /** Image for blog category */
  image?: InputMaybe<BlogMedia>;
  /** Name of blog category */
  name: Scalars['String']['input'];
  /** parent category of blog category */
  parent_id?: InputMaybe<Scalars['String']['input']>;
  /** status of blog category */
  status: BlogCategoryStatusEnum;
};

export type CreateBlogInput = {
  author_id: Scalars['String']['input'];
  /** Categories of the blog */
  categories: Array<Scalars['String']['input']>;
  /** Content of the blog */
  content: Scalars['String']['input'];
  /** Featured image of the blog */
  featured_image: BlogMedia;
  /** The flag to determind about comment section of blog */
  has_comment_enabled: Scalars['Boolean']['input'];
  /** SEO description of the blog */
  seo_description: Scalars['String']['input'];
  /** SEO title of the blog */
  seo_title: Scalars['String']['input'];
  /** Short description of the blog */
  short_description: Scalars['String']['input'];
  /** The blog status */
  status: BlogStatusEnum;
  /** Tags of the blog */
  tags: Array<Scalars['String']['input']>;
  /** Title of the blog */
  title: Scalars['String']['input'];
  /** The blog type */
  type: BlogTypeStatusEnum;
};

export type CreateBlogTagInput = {
  /** Name of blog tag */
  name: Scalars['String']['input'];
  /** status of blog tag */
  status: BlogTagStatusEnum;
};

export type CreateBulkFaqsInput = {
  /** Course id for course FAQ */
  courseID?: InputMaybe<Scalars['String']['input']>;
  /** FAQs */
  faq: Array<BulkFaqInput>;
  /** Sort Order of FAQ */
  sortOrder: Scalars['Float']['input'];
  /** status of the faq type */
  status: FaqStatusEnum;
  /** Type of FAQ */
  type: FaqTypeEnum;
};

export type CreateCmsInput = {
  content: Scalars['String']['input'];
  /** Keyword of CMS */
  keywords: Scalars['String']['input'];
  /** Name of CMS */
  name: Scalars['String']['input'];
  /** Description of CMS */
  seoDescription: Scalars['String']['input'];
  /** Title of CMS */
  seoTitle: Scalars['String']['input'];
  /** Status of the CMS */
  status: CmsStatusEnum;
};

export type CreateContactUsInput = {
  /** Contact number of user who submit contact us form */
  contactNo: Scalars['String']['input'];
  /** email of user who submit contact us form */
  email: Scalars['String']['input'];
  /** Message of user who submit contact us form */
  message: Scalars['String']['input'];
  /** name of user who submit contact us form */
  name: Scalars['String']['input'];
  /** Contact no std code of user who submit contact us form */
  stdCodeOfContactNo: Scalars['String']['input'];
};

export type CreateCourseCategoryInput = {
  /** image of the category */
  image?: InputMaybe<Media>;
  /** name of the category */
  name: Scalars['String']['input'];
  /** parent_id of the category */
  parentId?: InputMaybe<Scalars['String']['input']>;
  /** sort_number of the category */
  sortNumber: Scalars['Float']['input'];
  /** status of the category */
  status: CategoryStatus;
};

export type CreateCourseHighlightsInput = {
  /** The icon for course highlights */
  highlight_icon?: InputMaybe<Scalars['String']['input']>;
  /** The highlights for course */
  highlight_text: Scalars['String']['input'];
};

export type CreateCourseInput = {
  /** address of seminar  */
  address?: InputMaybe<Scalars['String']['input']>;
  /** amount of course */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** cost type of course */
  costType?: InputMaybe<CourseCostTypeEnum>;
  /** category id of course */
  courseCategoryId?: InputMaybe<Scalars['String']['input']>;
  /** language id of course */
  courseLanguageIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** level id of course */
  courseLevelId?: InputMaybe<Scalars['String']['input']>;
  /** mode id of course */
  courseModeId?: InputMaybe<Scalars['String']['input']>;
  /** quiz id of course */
  courseQuizId?: InputMaybe<Scalars['String']['input']>;
  /** skill ids for course */
  courseSkillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** sub category id of course */
  courseSubCategoryId?: InputMaybe<Scalars['String']['input']>;
  /** type id of course */
  courseTypeId?: InputMaybe<Scalars['String']['input']>;
  /** description of course */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Eligible percentage for download cerificate */
  eligiblePercentageForCertificate?: InputMaybe<Scalars['Float']['input']>;
  /** end data and time of course */
  endDatetime?: InputMaybe<Scalars['DateTime']['input']>;
  /** for course has certificate or not */
  hasCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  /** for course has quiz or not */
  hasQuiz?: InputMaybe<Scalars['Boolean']['input']>;
  /** course highlights */
  highlights?: InputMaybe<Array<CreateCourseHighlightsInput>>;
  /** images url of course */
  images?: InputMaybe<Array<CourseMedia>>;
  /** meeting link for webinar */
  meetingLink?: InputMaybe<Scalars['String']['input']>;
  /** meeting number */
  meetingNumber?: InputMaybe<Scalars['String']['input']>;
  /** meeting password for webinar */
  meetingPassword?: InputMaybe<Scalars['String']['input']>;
  /** mentor id with there commission */
  mentors?: InputMaybe<Array<MentorCommissionInput>>;
  /** name of the course */
  name: Scalars['String']['input'];
  /** Course objectives */
  objectives?: InputMaybe<Array<Scalars['String']['input']>>;
  /** preview video of seminar  */
  previewVideo?: InputMaybe<Scalars['String']['input']>;
  /** requirements for course */
  requirements?: InputMaybe<Scalars['String']['input']>;
  /** sell price of course */
  sellPrice?: InputMaybe<Scalars['Float']['input']>;
  /** short description of course */
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  /** sort order of course */
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  /** start data and time of course */
  startDatetime?: InputMaybe<Scalars['DateTime']['input']>;
  /** status of course */
  status: CourseStatusEnum;
  /** total seat of course */
  totalSeat?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateCourseLanguageInput = {
  /** name of the course language */
  name: Scalars['String']['input'];
  /** Short code of the course language */
  shortCode: Scalars['String']['input'];
  /** Status of the course language */
  status: CourseLanguageStatusEnum;
};

export type CreateCourseLessonInput = {
  /** Meeting Agenda */
  agenda?: InputMaybe<Scalars['String']['input']>;
  /** content urls of lesson */
  contentUrl?: InputMaybe<Scalars['String']['input']>;
  /** description of lesson */
  description: Scalars['String']['input'];
  /** duration of lesson */
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** introduction of lesson */
  introduction: Scalars['String']['input'];
  /** Meeting Duration */
  meeting_duration?: InputMaybe<Scalars['String']['input']>;
  /** Meeting Start Time */
  meeting_start_time?: InputMaybe<Scalars['String']['input']>;
  /** name of the lesson */
  name: Scalars['String']['input'];
  /** allowed no. of attempt quiz */
  no_of_attempt_allowed?: InputMaybe<Scalars['Float']['input']>;
  /** quiz id of the lesson */
  quizId?: InputMaybe<Scalars['String']['input']>;
  /** section id */
  sectionId: Scalars['String']['input'];
  /** lesson sort */
  sortNumber: Scalars['Float']['input'];
  /** lesson start date */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** status of lesson */
  status?: LessonStatusEnum;
  /** Meeting Topic */
  topic?: InputMaybe<Scalars['String']['input']>;
  /** type of lesson */
  type: LessonTypeEnum;
};

export type CreateCourseLevelInput = {
  /** Course level name */
  name: Scalars['String']['input'];
  /** Course level status enum */
  status: CourseLevelsStatusEnum;
};

export type CreateCourseModeInput = {
  /** name of course mode */
  name: Scalars['String']['input'];
};

export type CreateCourseRatingsInput = {
  /** CourseId of course ratings */
  courseId: Scalars['String']['input'];
  /** ratings of course ratings */
  ratings: Scalars['Float']['input'];
  /** star count of course ratings */
  starCount: Scalars['Float']['input'];
};

export type CreateCourseSectionInput = {
  /** course id */
  courseId: Scalars['String']['input'];
  /** introduction of the section */
  introduction: Scalars['String']['input'];
  /** name of the section */
  name: Scalars['String']['input'];
  /** sort order of section */
  sortNumber: Scalars['Float']['input'];
};

export type CreateCourseSkillInput = {
  /** name of the course skill */
  name: Scalars['String']['input'];
  /** Status of the course skill */
  status: CourseSkillStatusEnum;
};

export type CreateCourseTypeInput = {
  /** allow multiple quantities for course type */
  allowMultipleQuantities?: InputMaybe<Scalars['Boolean']['input']>;
  /** Course Type name */
  name: Scalars['String']['input'];
  /** Course type status enum */
  status: CourseTypeStatus;
};

export type CreateFaqInput = {
  /** Answer of FAQ */
  answer: Scalars['String']['input'];
  /** Course id for course FAQ */
  courseID?: InputMaybe<Scalars['String']['input']>;
  /** Question of FAQ */
  question: Scalars['String']['input'];
  /** Sort Order of FAQ */
  sortOrder: Scalars['Float']['input'];
  /** status of the faq type */
  status: FaqStatusEnum;
  /** Type of FAQ */
  type: FaqTypeEnum;
};

export type CreateFreeCourseEnrollmentInput = {
  /** Country of user who placed order */
  countryId?: InputMaybe<Scalars['String']['input']>;
  /** Id of free course to enroll */
  courseId: Scalars['String']['input'];
  /** State of user who placed order */
  stateId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGalleryInput = {
  /** Content url of the gallery */
  contentUrl: Scalars['String']['input'];
  /** Heading of the gallery */
  heading: Scalars['String']['input'];
  /** Gallery status */
  status: GalleryStatusEnum;
  /** Sub heading of the gallery */
  subHeading: Scalars['String']['input'];
  tags: Array<Scalars['String']['input']>;
  /** Gallery type */
  type: GalleryTypeEnum;
};

export type CreateGalleryTagInput = {
  /** Name of gallery tag */
  name: Scalars['String']['input'];
  /** status of gallery tag */
  status: GalleryTagStatusEnum;
};

export type CreateGoalInput = {
  /** Goal name */
  name: Scalars['String']['input'];
  /** Goal status enum */
  status: GoalStatusEnum;
};

export type CreateInstructorInput = {
  /** User's country name */
  countryId: Scalars['String']['input'];
  /** User's description about themself */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Email of the mentor */
  email: Scalars['String']['input'];
  /** First name of the mentor */
  firstName: Scalars['String']['input'];
  /** Full name of the mentor */
  fullName: Scalars['String']['input'];
  /** User's gender */
  gender: UserGenderEnum;
  /** User's Profile picture */
  image?: InputMaybe<UserImageMedia>;
  /** User's instagram profile URL */
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the mentor */
  lastName: Scalars['String']['input'];
  /** User's LinkedIn profile URL */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** user's mobile number */
  mobileNo: Scalars['String']['input'];
  /** User's mobile number std coude according to their country */
  mobileNoStdCode: Scalars['String']['input'];
  /** Password of the mentor */
  password: Scalars['String']['input'];
  /** User's qualification */
  qualification: Scalars['String']['input'];
  /** status of instructor account */
  status: UserStatusEnum;
  /** User's twitter profile URL */
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateJobVacancyInput = {
  /** Job vacancy details ed information */
  content: Scalars['String']['input'];
  /** Location of the job */
  jobLocation: Scalars['String']['input'];
  /** Mode of the job in contrast of working place */
  jobMode: JobVacancyModeEnum;
  /** Type of job in contrast of time */
  jobType: JobVacancyTypeEnum;
  /** Maximum required experience for job */
  maxRequiredExperience: Scalars['Float']['input'];
  /** Minimum required experience for job */
  minRequiredExperience: Scalars['Float']['input'];
  /** Numbers of opening for the job */
  noOfOpening: Scalars['Int']['input'];
  /** Position for which job vacancy is open */
  position: Scalars['String']['input'];
  /** Status of the job vacancy */
  status: JobVacancyStatusEnum;
};

export type CreateNewInstructorInput = {
  /** User's country name */
  countryId: Scalars['String']['input'];
  /** User's description about themself */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Email of the mentor */
  email: Scalars['String']['input'];
  /** User's gender */
  gender: UserGenderEnum;
  /** User's Profile picture */
  image?: InputMaybe<UserImageMedia>;
  /** User's instagram profile URL */
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  /** User's LinkedIn profile URL */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** user's mobile number */
  mobileNo: Scalars['String']['input'];
  /** User's mobile number std coude according to their country */
  mobileNoStdCode: Scalars['String']['input'];
  /** Name of the mentor */
  name: Scalars['String']['input'];
  /** Password of the mentor */
  password: Scalars['String']['input'];
  /** User's qualification */
  qualification: Scalars['String']['input'];
  /** status of instructor account */
  status: UserStatusEnum;
  /** User's twitter profile URL */
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNotificationsInput = {
  /** Action of notification */
  action?: InputMaybe<NotificationActionEnum>;
  /** CourseId for coursespecific Notification purpose */
  courseId?: InputMaybe<Scalars['String']['input']>;
  /** Notification of message */
  message: Scalars['String']['input'];
  /** Status of notification */
  status: NotificationStatusEnum;
  /** Notification of title */
  title: Scalars['String']['input'];
  /** Type of notification */
  type: NotificationTypeEnum;
};

export type CreateOrderAdminFreeEnrollDetailsInput = {
  /** Discount Amount of course order */
  couponDiscountAmount?: InputMaybe<Scalars['Float']['input']>;
  /** course id for order */
  courseId: Scalars['String']['input'];
  /** Discount type of course order */
  discountType?: InputMaybe<Scalars['String']['input']>;
  /** Discounted value of course order */
  discountValue?: InputMaybe<Scalars['Float']['input']>;
  /** Booked number of seat */
  noOfSeat?: Scalars['Float']['input'];
  /** price of course order */
  price: Scalars['Float']['input'];
  /** Sub total of course order */
  subTotal: Scalars['Float']['input'];
  /** tax % of course order */
  tax: Scalars['Float']['input'];
  /** Tax amount on course order */
  taxAmount: Scalars['Float']['input'];
  /** total price of course order */
  totalPrice: Scalars['Float']['input'];
};

export type CreateOrderAdminFreeEnrollInput = {
  /** Address of user who placed order */
  address?: InputMaybe<Scalars['String']['input']>;
  /** address id for current order */
  address_id?: InputMaybe<Scalars['String']['input']>;
  /** address id for current order */
  cartitems?: InputMaybe<Array<CreateOrderAdminFreeEnrollDetailsInput>>;
  /** City of user who placed order */
  city?: InputMaybe<Scalars['String']['input']>;
  /** company name for current order */
  companyName?: InputMaybe<Scalars['String']['input']>;
  /** course id for order */
  courseId?: InputMaybe<Scalars['String']['input']>;
  /** First Name of user who placed order */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Full Name of user who placed order */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** GST No of user who placed order */
  gstNo?: InputMaybe<Scalars['String']['input']>;
  /** Last Name of user who placed order */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** register address for current order */
  registerAddress?: InputMaybe<Scalars['String']['input']>;
  /** State of user who placed order */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Total discount on the order */
  totalDiscount?: InputMaybe<Scalars['Float']['input']>;
  /** user id who placed order */
  userId: Scalars['String']['input'];
  /** Zip Code of user who placed order */
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrderAdminInput = {
  /** Address of user who placed order */
  address?: InputMaybe<Scalars['String']['input']>;
  /** address id for current order */
  address_id?: InputMaybe<Scalars['String']['input']>;
  /** address id for current order */
  cartitems?: InputMaybe<Array<CreateOrderDetailsInput>>;
  /** City of user who placed order */
  city?: InputMaybe<Scalars['String']['input']>;
  /** company name for current order */
  companyName?: InputMaybe<Scalars['String']['input']>;
  /** First Name of user who placed order */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Full Name of user who placed order */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** GST No of user who placed order */
  gstNo?: InputMaybe<Scalars['String']['input']>;
  /** Last Name of user who placed order */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** payment date for current order */
  paymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** payment mode for current order */
  paymentMode?: InputMaybe<PaymentModeEnum>;
  /** register address for current order */
  registerAddress?: InputMaybe<Scalars['String']['input']>;
  /** State of user who placed order */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Total discount on the order */
  totalDiscount?: InputMaybe<Scalars['Float']['input']>;
  /** user id who placed order */
  userId: Scalars['String']['input'];
  /** Zip Code of user who placed order */
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrderByCartIdInput = {
  /** Address of user who placed order */
  address?: InputMaybe<Scalars['String']['input']>;
  /** address id for current order */
  address_id?: InputMaybe<Scalars['String']['input']>;
  /** City of user who placed order */
  city?: InputMaybe<Scalars['String']['input']>;
  /** company name for current order */
  companyName?: InputMaybe<Scalars['String']['input']>;
  /** First Name of user who placed order */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** GST No of user who placed order */
  gstNo?: InputMaybe<Scalars['String']['input']>;
  /** Last Name of user who placed order */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** register address for current order */
  registerAddress?: InputMaybe<Scalars['String']['input']>;
  /** State of user who placed order */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Total discount on the order */
  totalDiscount?: InputMaybe<Scalars['Float']['input']>;
  /** Zip Code of user who placed order */
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrderCartIdsInput = {
  /** cart ids for order */
  cartIds: Array<Scalars['String']['input']>;
};

export type CreateOrderDetailsInput = {
  /** Discount Amount of course order */
  couponDiscountAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Course ids which ordered */
  courseId: Scalars['String']['input'];
  /** Discount type of course order */
  discountType?: InputMaybe<Scalars['String']['input']>;
  /** Discounted value of course order */
  discountValue?: InputMaybe<Scalars['Float']['input']>;
  /** Booked number of seat */
  noOfSeat?: Scalars['Float']['input'];
  /** price of course order */
  price: Scalars['Float']['input'];
  /** Sub total of course order */
  subTotal: Scalars['Float']['input'];
  /** tax % of course order */
  tax: Scalars['Float']['input'];
  /** Tax amount on course order */
  taxAmount: Scalars['Float']['input'];
  /** total price of course order */
  totalPrice: Scalars['Float']['input'];
};

export type CreateOrderInput = {
  /** Address of user who placed order */
  address?: InputMaybe<Scalars['String']['input']>;
  /** address id for current order */
  address_id?: InputMaybe<Scalars['String']['input']>;
  /** address id for current order */
  cartitems?: InputMaybe<Array<CreateOrderDetailsInput>>;
  /** City of user who placed order */
  city?: InputMaybe<Scalars['String']['input']>;
  /** company name for current order */
  companyName?: InputMaybe<Scalars['String']['input']>;
  /** Country of user who placed order */
  countryId?: InputMaybe<Scalars['String']['input']>;
  /** First Name of user who placed order */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Full Name of user who placed order */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** GST No of user who placed order */
  gstNo?: InputMaybe<Scalars['String']['input']>;
  /** Last Name of user who placed order */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** register address for current order */
  registerAddress?: InputMaybe<Scalars['String']['input']>;
  /** State of user who placed order */
  state?: InputMaybe<Scalars['String']['input']>;
  /** State of user who placed order */
  stateId?: InputMaybe<Scalars['String']['input']>;
  /** Total discount on the order */
  totalDiscount?: InputMaybe<Scalars['Float']['input']>;
  /** Zip Code of user who placed order */
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrderNewUserInput = {
  /** Address of user who placed order */
  address?: InputMaybe<Scalars['String']['input']>;
  /** address id for current order */
  cartitems?: InputMaybe<Array<CreateOrderDetailsInput>>;
  /** company name for current order */
  companyName?: InputMaybe<Scalars['String']['input']>;
  /** City of user who placed order */
  countryId?: InputMaybe<Scalars['String']['input']>;
  /** Email of user who placed order */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Full Name of user who placed order */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** GST No of user who placed order */
  gstNo?: InputMaybe<Scalars['String']['input']>;
  /** Mobile No. of user who placed order */
  mobileNo?: InputMaybe<Scalars['String']['input']>;
  /** register address for current order */
  registerAddress?: InputMaybe<Scalars['String']['input']>;
  /** State of user who placed order */
  stateId?: InputMaybe<Scalars['String']['input']>;
  /** Total discount on the order */
  totalDiscount?: InputMaybe<Scalars['Float']['input']>;
};

export type CreatePaymentGatewayInput = {
  /** Private key of payment gateway */
  livePrivateKey: Scalars['String']['input'];
  /** Secret key of payment gateway */
  liveSecretKey: Scalars['String']['input'];
  /** Mode of payment gateway */
  mode: PaymentGatewayModeEnum;
  /** Name of the payment gateway */
  name: Scalars['String']['input'];
  /** Test private key of the payment gateway */
  sandboxPrivateKey: Scalars['String']['input'];
  /** Test secret key of the payment gateway */
  sandboxSecretKey: Scalars['String']['input'];
};

export type CreatePermissionInput = {
  /** permission name */
  name: Scalars['String']['input'];
};

export type CreatePublicJobApplicationInput = {
  /** Job applicant message */
  coverLatter: Scalars['String']['input'];
  /** Email of the job applicant */
  email: Scalars['String']['input'];
  /** Job applicant's experience of field */
  experience: Scalars['Float']['input'];
  /** Job Vacancy id for which job application created */
  jobVacancyId?: InputMaybe<Scalars['String']['input']>;
  /** Mobile number of job applicant */
  mobileNo: Scalars['String']['input'];
  /** Name of the job applicant */
  name: Scalars['String']['input'];
  /** Position for which job applicant apply */
  position: Scalars['String']['input'];
  /** Resume url of job applicant */
  resumeUrl: Scalars['String']['input'];
  /** Std code of job applicant mobile number */
  stdCodeOfMobileNo: Scalars['String']['input'];
};

export type CreateQuizInput = {
  /** duration of quiz */
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** Needed mark to earn certificate */
  eligibleMarksForCertificate: Scalars['Float']['input'];
  /** flag to determine whether quiz has time limit */
  hasTimeLimit: Scalars['Boolean']['input'];
  questions?: InputMaybe<Array<QuizQuestionInput>>;
  /** Status of quiz */
  status: QuizStatusEnum;
  /** Title of the quiz */
  title: Scalars['String']['input'];
  /** Type of quiz */
  type: QuizTypesEnum;
};

export type CreateRoleInput = {
  /** role name */
  name: Scalars['String']['input'];
  /** permissions */
  permissions: Array<Scalars['String']['input']>;
};

export type CreateStudentInput = {
  /** User's city */
  cityId?: InputMaybe<Scalars['String']['input']>;
  /** User's country name */
  countryId: Scalars['String']['input'];
  /** user Date of birth */
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  /** Email of the mentor */
  email: Scalars['String']['input'];
  /** First name of the mentor */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Full name of the mentor */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** User's gender */
  gender: UserGenderEnum;
  /** User goal to join hogrowth */
  goalId?: InputMaybe<Scalars['String']['input']>;
  /** User's Profile picture */
  image?: InputMaybe<UserImageMedia>;
  /** User's instagram profile URL */
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  /** User Verification Mobile Number Status */
  isMobileNumberVerified?: Scalars['Boolean']['input'];
  /** User Verification Email Status */
  isVerified?: Scalars['Boolean']['input'];
  /** Last name of the mentor */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User's LinkedIn profile URL */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** user's mobile number */
  mobileNo: Scalars['String']['input'];
  /** User's mobile number std coude according to their country */
  mobileNoStdCode: Scalars['String']['input'];
  /** Password of the mentor */
  password: Scalars['String']['input'];
  /** User's qualification */
  qualification?: InputMaybe<Scalars['String']['input']>;
  /** User's state */
  stateId?: InputMaybe<Scalars['String']['input']>;
  /** status of instructor account */
  status: UserStatusEnum;
  /** User's twitter profile URL */
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTestimonialInput = {
  /** Content of testimonial If video then video URL should be here */
  content: Scalars['String']['input'];
  /** content type of the testimonial */
  contentType: TestimonialContentTypeEnum;
  /** Designation of testimonial */
  designation?: InputMaybe<Scalars['String']['input']>;
  /** image url of Testimonial */
  image?: InputMaybe<Scalars['String']['input']>;
  /** Name of testimonial */
  name: Scalars['String']['input'];
  /** reference id for instructor or course */
  referenceId?: InputMaybe<Scalars['String']['input']>;
  /** status of the testimonial */
  status: TestimonialStatusEnum;
  /** type of the testimonial */
  type: TestimonialTypeEnum;
};

export type CreateUserInput = {
  /** email of the user */
  email: Scalars['String']['input'];
  /** first name of the user */
  firstName: Scalars['String']['input'];
  /** full name of the user */
  fullName: Scalars['String']['input'];
  /** User Verification Mobile Number Status */
  isMobileNumberVerified?: Scalars['Boolean']['input'];
  /** User Verification Email Status */
  isVerified?: Scalars['Boolean']['input'];
  /** last name of the user */
  lastName: Scalars['String']['input'];
  /** password of the user */
  password: Scalars['String']['input'];
  /** role of the user */
  roleId: Scalars['String']['input'];
  /** status of user account */
  status: UserStatusEnum;
  /** type of user */
  userType: UserTypeEnum;
};

export type CreateZoomMeetingInput = {
  /** Meeting Agenda */
  agenda: Scalars['String']['input'];
  /** Meeting Duration */
  meeting_duration: Scalars['String']['input'];
  /** Meeting Start Time */
  meeting_start_time: Scalars['String']['input'];
  /** Meeting Topic */
  topic: Scalars['String']['input'];
};

export type Date_PropertyFilterInputType = {
  /** start data and time of course */
  between?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  /** start data and time of course */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** start data and time of course */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** start data and time of course */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** start data and time of course */
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  /** start data and time of course */
  like?: InputMaybe<Scalars['String']['input']>;
  /** start data and time of course */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** start data and time of course */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** start data and time of course */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  /** start data and time of course */
  notbetween?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  /** start data and time of course */
  notin?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  /** start data and time of course */
  notlike?: InputMaybe<Scalars['String']['input']>;
  /** start data and time of course */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DecodeVideoObjectType = {
  __typename?: 'DecodeVideoObjectType';
  /** 1 */
  _1: Scalars['String']['output'];
  /** 2 */
  _2: Scalars['String']['output'];
  /** 3 */
  _3: Scalars['String']['output'];
  /** 4 */
  _4: Scalars['String']['output'];
};

export type DecodevideoInput = {
  /** video decode base64 */
  decodevideo?: InputMaybe<Scalars['String']['input']>;
};

export type EmailVerificationInput = {
  /** verification token of the user */
  verification_token: Scalars['String']['input'];
};

export type FaqListObjectType = {
  __typename?: 'FaqListObjectType';
  pagination: FaqPaginationType;
  records: Array<FaqObjectType>;
};

export type FaqObjectType = {
  __typename?: 'FaqObjectType';
  /** Answer of FAQ */
  answer: Scalars['String']['output'];
  /** Course details objects */
  course?: Maybe<CourseObjectType>;
  /** Course id for course FAQ */
  courseId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** id of the course type */
  id: Scalars['String']['output'];
  /** Question of FAQ */
  question: Scalars['String']['output'];
  /** Sort Order of FAQ */
  sortOrder: Scalars['Float']['output'];
  /** status of the faq type */
  status: FaqStatusEnum;
  /** Type of FAQ */
  type: FaqTypeEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type FaqPaginationType = {
  __typename?: 'FaqPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type FaqPublicObjectType = {
  __typename?: 'FaqPublicObjectType';
  /** Answer of FAQ */
  answer: Scalars['String']['output'];
  /** Course id for course FAQ */
  courseID?: Maybe<Scalars['String']['output']>;
  /** id of the course type */
  id: Scalars['String']['output'];
  /** Question of FAQ */
  question: Scalars['String']['output'];
  /** Sort Order of FAQ */
  sortOrder: Scalars['Float']['output'];
};

export type FaqResolver_GetFaqsFilterInputType = {
  course_id?: InputMaybe<String_PropertyFilterInputType>;
  question?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<FaqStatusEnum_PropertyFilterInputType>;
  type?: InputMaybe<FaqTypeEnum_PropertyFilterInputType>;
};

export type FaqResolver_GetFaqs_FilterInputType = {
  and?: InputMaybe<Array<FaqResolver_GetFaqsFilterInputType>>;
  course_id?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<FaqResolver_GetFaqsFilterInputType>>;
  question?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<FaqStatusEnum_PropertyFilterInputType>;
  type?: InputMaybe<FaqTypeEnum_PropertyFilterInputType>;
};

export type FaqResolver_GetPublicFaqsListFilterInputType = {
  course_id?: InputMaybe<String_PropertyFilterInputType>;
  question?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<FaqStatusEnum_PropertyFilterInputType>;
  type?: InputMaybe<FaqTypeEnum_PropertyFilterInputType>;
};

export type FaqResolver_GetPublicFaqsList_FilterInputType = {
  and?: InputMaybe<Array<FaqResolver_GetPublicFaqsListFilterInputType>>;
  course_id?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<FaqResolver_GetPublicFaqsListFilterInputType>>;
  question?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<FaqStatusEnum_PropertyFilterInputType>;
  type?: InputMaybe<FaqTypeEnum_PropertyFilterInputType>;
};

/** status of FAQ */
export enum FaqStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type FaqStatusEnum_PropertyFilterInputType = {
  /** status of the faq type */
  eq?: InputMaybe<FaqStatusEnum>;
  /** status of the faq type */
  in?: InputMaybe<Array<FaqStatusEnum>>;
  /** status of the faq type */
  neq?: InputMaybe<FaqStatusEnum>;
  /** status of the faq type */
  notin?: InputMaybe<Array<FaqStatusEnum>>;
  /** status of the faq type */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Type of FAQ */
export enum FaqTypeEnum {
  Course = 'COURSE',
  General = 'GENERAL'
}

export type FaqTypeEnum_PropertyFilterInputType = {
  /** Type of FAQ */
  eq?: InputMaybe<FaqTypeEnum>;
  /** Type of FAQ */
  in?: InputMaybe<Array<FaqTypeEnum>>;
  /** Type of FAQ */
  neq?: InputMaybe<FaqTypeEnum>;
  /** Type of FAQ */
  notin?: InputMaybe<Array<FaqTypeEnum>>;
  /** Type of FAQ */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ForgotPasswordInput = {
  /** email of the user */
  email: Scalars['String']['input'];
  /** device type of the user */
  type: UserDeviceTypeEnum;
};

export type GalleryListObjectType = {
  __typename?: 'GalleryListObjectType';
  pagination: GalleryPaginationType;
  records: Array<GalleryObjectType>;
};

export type GalleryMediaInput = {
  /** content type media */
  contentType: Scalars['String']['input'];
  /** file name for content */
  fileName: Scalars['String']['input'];
  /** type of media */
  mediaType: GalleryTypeEnum;
};

export type GalleryObjectType = {
  __typename?: 'GalleryObjectType';
  /** Content url of the gallery */
  contentUrl: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** Heading of the gallery */
  heading: Scalars['String']['output'];
  /** id of the gallery */
  id: Scalars['String']['output'];
  /** Gallery status */
  status: GalleryStatusEnum;
  /** Sub heading of the gallery */
  subHeading: Scalars['String']['output'];
  tags: Array<GalleryTagObjectType>;
  /** Gallery type */
  type: GalleryTypeEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type GalleryPaginationType = {
  __typename?: 'GalleryPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type GalleryResolver_GetGalleriesFilterInputType = {
  heading?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<GalleryStatusEnum_PropertyFilterInputType>;
  type?: InputMaybe<GalleryTypeEnum_PropertyFilterInputType>;
};

export type GalleryResolver_GetGalleries_FilterInputType = {
  and?: InputMaybe<Array<GalleryResolver_GetGalleriesFilterInputType>>;
  heading?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<GalleryResolver_GetGalleriesFilterInputType>>;
  status?: InputMaybe<GalleryStatusEnum_PropertyFilterInputType>;
  type?: InputMaybe<GalleryTypeEnum_PropertyFilterInputType>;
};

/** Enum for gallery status */
export enum GalleryStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type GalleryStatusEnum_PropertyFilterInputType = {
  /** Gallery status */
  eq?: InputMaybe<GalleryStatusEnum>;
  /** Gallery status */
  in?: InputMaybe<Array<GalleryStatusEnum>>;
  /** Gallery status */
  neq?: InputMaybe<GalleryStatusEnum>;
  /** Gallery status */
  notin?: InputMaybe<Array<GalleryStatusEnum>>;
  /** Gallery status */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GalleryTagListObjectType = {
  __typename?: 'GalleryTagListObjectType';
  pagination: GalleryTagPaginationType;
  records: Array<GalleryTagObjectType>;
};

export type GalleryTagObjectType = {
  __typename?: 'GalleryTagObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** id of blog tag */
  id: Scalars['String']['output'];
  /** name of the blog tag */
  name: Scalars['String']['output'];
  /** slug of blog tag */
  slug: Scalars['String']['output'];
  /** The blog tag status */
  status: GalleryTagStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type GalleryTagPaginationType = {
  __typename?: 'GalleryTagPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type GalleryTagResolver_GetGalleryTagsFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<GalleryTagStatusEnum_PropertyFilterInputType>;
};

export type GalleryTagResolver_GetGalleryTags_FilterInputType = {
  and?: InputMaybe<Array<GalleryTagResolver_GetGalleryTagsFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<GalleryTagResolver_GetGalleryTagsFilterInputType>>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<GalleryTagStatusEnum_PropertyFilterInputType>;
};

/** Enum for gallery tag status */
export enum GalleryTagStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type GalleryTagStatusEnum_PropertyFilterInputType = {
  /** The blog tag status */
  eq?: InputMaybe<GalleryTagStatusEnum>;
  /** The blog tag status */
  in?: InputMaybe<Array<GalleryTagStatusEnum>>;
  /** The blog tag status */
  neq?: InputMaybe<GalleryTagStatusEnum>;
  /** The blog tag status */
  notin?: InputMaybe<Array<GalleryTagStatusEnum>>;
  /** The blog tag status */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Enum for gallery type */
export enum GalleryTypeEnum {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type GalleryTypeEnum_PropertyFilterInputType = {
  /** Gallery type */
  eq?: InputMaybe<GalleryTypeEnum>;
  /** Gallery type */
  in?: InputMaybe<Array<GalleryTypeEnum>>;
  /** Gallery type */
  neq?: InputMaybe<GalleryTypeEnum>;
  /** Gallery type */
  notin?: InputMaybe<Array<GalleryTypeEnum>>;
  /** Gallery type */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetZoomMeetingResponse = {
  __typename?: 'GetZoomMeetingResponse';
  email: Scalars['String']['output'];
  meetingNumber: Scalars['Float']['output'];
  password: Scalars['String']['output'];
  sdkKey: Scalars['String']['output'];
  signature: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type GoalObjectType = {
  __typename?: 'GoalObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** id of the goal */
  id: Scalars['String']['output'];
  /** name of the goal */
  name: Scalars['String']['output'];
  /** status of the goal */
  status: GoalStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type GoalPaginationType = {
  __typename?: 'GoalPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

/** The goal status */
export enum GoalStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type GoalStatusEnum_PropertyFilterInputType = {
  /** status of the goal */
  eq?: InputMaybe<GoalStatusEnum>;
  /** status of the goal */
  in?: InputMaybe<Array<GoalStatusEnum>>;
  /** status of the goal */
  neq?: InputMaybe<GoalStatusEnum>;
  /** status of the goal */
  notin?: InputMaybe<Array<GoalStatusEnum>>;
  /** status of the goal */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GoalsListObjectType = {
  __typename?: 'GoalsListObjectType';
  pagination: GoalPaginationType;
  records: Array<GoalObjectType>;
};

export type GoalsResolver_GetGoalListFilterInputType = {
  createdAt?: InputMaybe<Date_PropertyFilterInputType>;
  createdById?: InputMaybe<String_PropertyFilterInputType>;
  id?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<GoalStatusEnum_PropertyFilterInputType>;
  updatedAt?: InputMaybe<Date_PropertyFilterInputType>;
  updatedById?: InputMaybe<String_PropertyFilterInputType>;
};

export type GoalsResolver_GetGoalList_FilterInputType = {
  and?: InputMaybe<Array<GoalsResolver_GetGoalListFilterInputType>>;
  createdAt?: InputMaybe<Date_PropertyFilterInputType>;
  createdById?: InputMaybe<String_PropertyFilterInputType>;
  id?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<GoalsResolver_GetGoalListFilterInputType>>;
  status?: InputMaybe<GoalStatusEnum_PropertyFilterInputType>;
  updatedAt?: InputMaybe<Date_PropertyFilterInputType>;
  updatedById?: InputMaybe<String_PropertyFilterInputType>;
};

export type ImageUploadInput = {
  /** user Profile picture */
  newImage: UserImageMedia;
};

export type InstructorCoursesLessonResolver_GetInstructorCourseLessonsFilterInputType = {
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  section_id?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<LessonTypeEnum_PropertyFilterInputType>;
};

export type InstructorCoursesLessonResolver_GetInstructorCourseLessons_FilterInputType = {
  and?: InputMaybe<Array<InstructorCoursesLessonResolver_GetInstructorCourseLessonsFilterInputType>>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<InstructorCoursesLessonResolver_GetInstructorCourseLessonsFilterInputType>>;
  section_id?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<LessonTypeEnum_PropertyFilterInputType>;
};

export type InstructorCoursesResolver_GetCoursesCreatedByInstructorFilterInputType = {
  address?: InputMaybe<String_PropertyFilterInputType>;
  amount?: InputMaybe<Number_PropertyFilterInputType>;
  cost_type?: InputMaybe<CourseCostTypeEnum_PropertyFilterInputType>;
  course_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_level_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mode_id?: InputMaybe<String_PropertyFilterInputType>;
  course_sub_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_type_id?: InputMaybe<String_PropertyFilterInputType>;
  end_datetime?: InputMaybe<Date_PropertyFilterInputType>;
  has_certificate?: InputMaybe<Boolean_PropertyFilterInputType>;
  has_quiz?: InputMaybe<Boolean_PropertyFilterInputType>;
  meeting_number?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  start_datetime?: InputMaybe<Date_PropertyFilterInputType>;
  status?: InputMaybe<CourseStatusEnum_PropertyFilterInputType>;
  total_seat?: InputMaybe<Number_PropertyFilterInputType>;
};

export type InstructorCoursesResolver_GetCoursesCreatedByInstructor_FilterInputType = {
  address?: InputMaybe<String_PropertyFilterInputType>;
  amount?: InputMaybe<Number_PropertyFilterInputType>;
  and?: InputMaybe<Array<InstructorCoursesResolver_GetCoursesCreatedByInstructorFilterInputType>>;
  cost_type?: InputMaybe<CourseCostTypeEnum_PropertyFilterInputType>;
  course_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_level_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mode_id?: InputMaybe<String_PropertyFilterInputType>;
  course_sub_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_type_id?: InputMaybe<String_PropertyFilterInputType>;
  end_datetime?: InputMaybe<Date_PropertyFilterInputType>;
  has_certificate?: InputMaybe<Boolean_PropertyFilterInputType>;
  has_quiz?: InputMaybe<Boolean_PropertyFilterInputType>;
  meeting_number?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<InstructorCoursesResolver_GetCoursesCreatedByInstructorFilterInputType>>;
  start_datetime?: InputMaybe<Date_PropertyFilterInputType>;
  status?: InputMaybe<CourseStatusEnum_PropertyFilterInputType>;
  total_seat?: InputMaybe<Number_PropertyFilterInputType>;
};

export type InstructorCoursesResolver_InstructorGetCoursesFilterInputType = {
  address?: InputMaybe<String_PropertyFilterInputType>;
  amount?: InputMaybe<Number_PropertyFilterInputType>;
  cost_type?: InputMaybe<CourseCostTypeEnum_PropertyFilterInputType>;
  course_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_level_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mode_id?: InputMaybe<String_PropertyFilterInputType>;
  course_sub_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_type_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  end_datetime?: InputMaybe<Date_PropertyFilterInputType>;
  has_certificate?: InputMaybe<Boolean_PropertyFilterInputType>;
  has_quiz?: InputMaybe<Boolean_PropertyFilterInputType>;
  id?: InputMaybe<String_PropertyFilterInputType>;
  meeting_number?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  start_datetime?: InputMaybe<Date_PropertyFilterInputType>;
  status?: InputMaybe<CourseStatusEnum_PropertyFilterInputType>;
  total_seat?: InputMaybe<Number_PropertyFilterInputType>;
};

export type InstructorCoursesResolver_InstructorGetCourses_FilterInputType = {
  address?: InputMaybe<String_PropertyFilterInputType>;
  amount?: InputMaybe<Number_PropertyFilterInputType>;
  and?: InputMaybe<Array<InstructorCoursesResolver_InstructorGetCoursesFilterInputType>>;
  cost_type?: InputMaybe<CourseCostTypeEnum_PropertyFilterInputType>;
  course_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_level_id?: InputMaybe<String_PropertyFilterInputType>;
  course_mode_id?: InputMaybe<String_PropertyFilterInputType>;
  course_sub_category_id?: InputMaybe<String_PropertyFilterInputType>;
  course_type_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  end_datetime?: InputMaybe<Date_PropertyFilterInputType>;
  has_certificate?: InputMaybe<Boolean_PropertyFilterInputType>;
  has_quiz?: InputMaybe<Boolean_PropertyFilterInputType>;
  id?: InputMaybe<String_PropertyFilterInputType>;
  meeting_number?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<InstructorCoursesResolver_InstructorGetCoursesFilterInputType>>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  start_datetime?: InputMaybe<Date_PropertyFilterInputType>;
  status?: InputMaybe<CourseStatusEnum_PropertyFilterInputType>;
  total_seat?: InputMaybe<Number_PropertyFilterInputType>;
};

export type InstructorCoursesSectionResolver_GetInstructorCourseSectionsFilterInputType = {
  course_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  introduction?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  sort_number?: InputMaybe<String_PropertyFilterInputType>;
};

export type InstructorCoursesSectionResolver_GetInstructorCourseSections_FilterInputType = {
  and?: InputMaybe<Array<InstructorCoursesSectionResolver_GetInstructorCourseSectionsFilterInputType>>;
  course_id?: InputMaybe<String_PropertyFilterInputType>;
  created_by?: InputMaybe<String_PropertyFilterInputType>;
  introduction?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<InstructorCoursesSectionResolver_GetInstructorCourseSectionsFilterInputType>>;
  sort_number?: InputMaybe<String_PropertyFilterInputType>;
};

export type InstructorCreateCourseInput = {
  /** address of seminar  */
  address?: InputMaybe<Scalars['String']['input']>;
  /** amount of course */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** cost type of course */
  costType?: InputMaybe<CourseCostTypeEnum>;
  /** category id of course */
  courseCategoryId?: InputMaybe<Scalars['String']['input']>;
  /** language id of course */
  courseLanguageIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** level id of course */
  courseLevelId?: InputMaybe<Scalars['String']['input']>;
  /** mode id of course */
  courseModeId?: InputMaybe<Scalars['String']['input']>;
  /** quiz id of course */
  courseQuizId?: InputMaybe<Scalars['String']['input']>;
  /** skill ids for course */
  courseSkillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** sub category id of course */
  courseSubCategoryId?: InputMaybe<Scalars['String']['input']>;
  /** type id of course */
  courseTypeId?: InputMaybe<Scalars['String']['input']>;
  /** description of course */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Eligible percentage for download cerificate */
  eligiblePercentageForCertificate?: InputMaybe<Scalars['Float']['input']>;
  /** end data and time of course */
  endDatetime?: InputMaybe<Scalars['DateTime']['input']>;
  /** for course has certificate or not */
  hasCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  /** for course has quiz or not */
  hasQuiz?: InputMaybe<Scalars['Boolean']['input']>;
  /** course highlights */
  highlights?: InputMaybe<Array<CreateCourseHighlightsInput>>;
  /** images url of course */
  images?: InputMaybe<Array<CourseMedia>>;
  /** meeting link for webinar */
  meetingLink?: InputMaybe<Scalars['String']['input']>;
  /** meeting number */
  meetingNumber?: InputMaybe<Scalars['String']['input']>;
  /** meeting password for webinar */
  meetingPassword?: InputMaybe<Scalars['String']['input']>;
  /** Other mentor id */
  mentors?: InputMaybe<Array<InstructorMentorCommissionInput>>;
  /** name of the course */
  name: Scalars['String']['input'];
  /** Course objectives */
  objectives?: InputMaybe<Array<Scalars['String']['input']>>;
  /** preview video of seminar  */
  previewVideo?: InputMaybe<Scalars['String']['input']>;
  /** requirements for course */
  requirements?: InputMaybe<Scalars['String']['input']>;
  /** sell price of course */
  sellPrice?: InputMaybe<Scalars['Float']['input']>;
  /** short description of course */
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  /** sort order of course */
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  /** start data and time of course */
  startDatetime?: InputMaybe<Scalars['DateTime']['input']>;
  /** total seat of course */
  totalSeat?: InputMaybe<Scalars['Float']['input']>;
};

export type InstructorMentorCommissionInput = {
  /** The mentor ID */
  mentorId: Scalars['String']['input'];
};

export type InstructorRegisterInput = {
  /** Instructor's country name */
  countryId: Scalars['String']['input'];
  /** Instructor's description about themselves */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Email of the instructor */
  email: Scalars['String']['input'];
  /** First name of the instructor */
  firstName: Scalars['String']['input'];
  /** Instructor's gender */
  gender: UserGenderEnum;
  /** Instructor's Profile picture */
  image?: InputMaybe<UserImageMedia>;
  /** Instructor's instagram profile URL */
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the instructor */
  lastName: Scalars['String']['input'];
  /** Instructor's LinkedIn profile URL */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** Instructor's mobile number */
  mobileNo: Scalars['String']['input'];
  /** Instructor's mobile number std code according to their country */
  mobileNoStdCode: Scalars['String']['input'];
  /** Password of the instructor */
  password: Scalars['String']['input'];
  /** Instructor's qualification */
  qualification?: InputMaybe<Scalars['String']['input']>;
  /** Instructor's twitter profile URL */
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
};

export type InstructorResolver_GetInstructorListFilterInputType = {
  course_mode?: InputMaybe<String_PropertyFilterInputType>;
  course_type?: InputMaybe<String_PropertyFilterInputType>;
  created_at?: InputMaybe<String_PropertyFilterInputType>;
  email?: InputMaybe<String_PropertyFilterInputType>;
  first_name?: InputMaybe<String_PropertyFilterInputType>;
  last_name?: InputMaybe<String_PropertyFilterInputType>;
  mobile_no?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  role_id?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<UserStatusEnum_PropertyFilterInputType>;
  username?: InputMaybe<String_PropertyFilterInputType>;
};

export type InstructorResolver_GetInstructorList_FilterInputType = {
  and?: InputMaybe<Array<InstructorResolver_GetInstructorListFilterInputType>>;
  course_mode?: InputMaybe<String_PropertyFilterInputType>;
  course_type?: InputMaybe<String_PropertyFilterInputType>;
  created_at?: InputMaybe<String_PropertyFilterInputType>;
  email?: InputMaybe<String_PropertyFilterInputType>;
  first_name?: InputMaybe<String_PropertyFilterInputType>;
  last_name?: InputMaybe<String_PropertyFilterInputType>;
  mobile_no?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<InstructorResolver_GetInstructorListFilterInputType>>;
  role_id?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<UserStatusEnum_PropertyFilterInputType>;
  username?: InputMaybe<String_PropertyFilterInputType>;
};

export type InstructorSignInWithEmailInput = {
  /** Email of the instructor */
  email: Scalars['String']['input'];
  /** Password of the instructor */
  password: Scalars['String']['input'];
};

export type InstructorSignInWithSocialAccountInput = {
  /** Email of the instructor */
  email: Scalars['String']['input'];
  /** Full name of the instructor */
  name: Scalars['String']['input'];
  /** signin type of user */
  signinType: UserSignInType;
};

export type InstructorUpdateCourseInput = {
  /** address of seminar  */
  address?: InputMaybe<Scalars['String']['input']>;
  /** amount of course */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** cost type of course */
  costType?: InputMaybe<CourseCostTypeEnum>;
  /** category id of course */
  courseCategoryId?: InputMaybe<Scalars['String']['input']>;
  /** language id of course */
  courseLanguageIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** level id of course */
  courseLevelId?: InputMaybe<Scalars['String']['input']>;
  /** mode id of course */
  courseModeId?: InputMaybe<Scalars['String']['input']>;
  /** quiz id of course */
  courseQuizId?: InputMaybe<Scalars['String']['input']>;
  /** skill ids for course */
  courseSkillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** sub category id of course */
  courseSubCategoryId?: InputMaybe<Scalars['String']['input']>;
  /** type id of course */
  courseTypeId?: InputMaybe<Scalars['String']['input']>;
  /** description of course */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Eligible percentage for download cerificate */
  eligiblePercentageForCertificate?: InputMaybe<Scalars['Float']['input']>;
  /** end data and time of course */
  endDatetime?: InputMaybe<Scalars['DateTime']['input']>;
  /** for course has certificate or not */
  hasCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  /** for course has quiz or not */
  hasQuiz?: InputMaybe<Scalars['Boolean']['input']>;
  /** course highlights */
  highlights?: InputMaybe<Array<UpdateCourseHighlightInput>>;
  id: Scalars['String']['input'];
  /** current image url of course */
  imageUrls?: InputMaybe<Array<UpdateCourseImageInput>>;
  /** new image of course */
  images?: InputMaybe<Array<CourseMedia>>;
  /** meeting link for webinar */
  meetingLink?: InputMaybe<Scalars['String']['input']>;
  /** meeting number */
  meetingNumber?: InputMaybe<Scalars['String']['input']>;
  /** meeting password for webinar */
  meetingPassword?: InputMaybe<Scalars['String']['input']>;
  /** mentor id with there commission */
  mentors?: InputMaybe<Array<InstructorUpdateMentorCommissionInput>>;
  /** name of the course */
  name: Scalars['String']['input'];
  /** Course objectives */
  objectives?: InputMaybe<Array<Scalars['String']['input']>>;
  /** preview video of seminar  */
  preview_video?: InputMaybe<Scalars['String']['input']>;
  /** requirements for course */
  requirements?: InputMaybe<Scalars['String']['input']>;
  /** sell price of course */
  sellPrice?: InputMaybe<Scalars['Float']['input']>;
  /** short description of course */
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  /** sort order of course */
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  /** start data and time of course */
  startDatetime?: InputMaybe<Scalars['DateTime']['input']>;
  /** total seat of course */
  totalSeat?: InputMaybe<Scalars['Float']['input']>;
};

export type InstructorUpdateMentorCommissionInput = {
  /** The commission value for each mentor */
  commission?: InputMaybe<Scalars['Float']['input']>;
  /** The mentor ID */
  mentorId: Scalars['String']['input'];
};

export type Int_PropertyFilterInputType = {
  /** sort number of category */
  between?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** sort number of category */
  eq?: InputMaybe<Scalars['Int']['input']>;
  /** sort number of category */
  gt?: InputMaybe<Scalars['Int']['input']>;
  /** sort number of category */
  gte?: InputMaybe<Scalars['Int']['input']>;
  /** sort number of category */
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** sort number of category */
  like?: InputMaybe<Scalars['String']['input']>;
  /** sort number of category */
  lt?: InputMaybe<Scalars['Int']['input']>;
  /** sort number of category */
  lte?: InputMaybe<Scalars['Int']['input']>;
  /** sort number of category */
  neq?: InputMaybe<Scalars['Int']['input']>;
  /** sort number of category */
  notbetween?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** sort number of category */
  notin?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** sort number of category */
  notlike?: InputMaybe<Scalars['String']['input']>;
  /** sort number of category */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type JobApplicationListObjectType = {
  __typename?: 'JobApplicationListObjectType';
  pagination: JobApplicationPagination;
  records: Array<JobApplicationObjectType>;
};

export type JobApplicationObjectType = {
  __typename?: 'JobApplicationObjectType';
  /** Job applicant message */
  coverLatter: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  /** Email of the job applicant */
  email: Scalars['String']['output'];
  /** Job applicant's experience of field */
  experience: Scalars['String']['output'];
  /** id of job application record */
  id: Scalars['String']['output'];
  /** Job Vacancy id for which job application created */
  jobVacancyId?: Maybe<Scalars['String']['output']>;
  /** Mobile number of job applicant */
  mobileNo: Scalars['String']['output'];
  /** Name of the job applicant */
  name: Scalars['String']['output'];
  /** Reason for rejecting job application */
  note?: Maybe<Scalars['String']['output']>;
  /** Position for which job applicant apply */
  position: Scalars['String']['output'];
  /** Resume url of job applicant */
  resumeUrl: Scalars['String']['output'];
  /** Status of job application */
  status: JobApplicationStatusEnum;
  /** Std code of job applicant mobile number */
  stdCodeOfMobileNo: Scalars['String']['output'];
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type JobApplicationPagination = {
  __typename?: 'JobApplicationPagination';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type JobApplicationResolver_GetJobApplicationsListFilterInputType = {
  email?: InputMaybe<String_PropertyFilterInputType>;
  experience?: InputMaybe<String_PropertyFilterInputType>;
  mobileNo?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  position?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<JobApplicationStatusEnum_PropertyFilterInputType>;
};

export type JobApplicationResolver_GetJobApplicationsList_FilterInputType = {
  and?: InputMaybe<Array<JobApplicationResolver_GetJobApplicationsListFilterInputType>>;
  email?: InputMaybe<String_PropertyFilterInputType>;
  experience?: InputMaybe<String_PropertyFilterInputType>;
  mobileNo?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<JobApplicationResolver_GetJobApplicationsListFilterInputType>>;
  position?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<JobApplicationStatusEnum_PropertyFilterInputType>;
};

/** Status of Job Application */
export enum JobApplicationStatusEnum {
  Accepted = 'ACCEPTED',
  New = 'NEW',
  Rejected = 'REJECTED'
}

export type JobApplicationStatusEnum_PropertyFilterInputType = {
  /** Status of job application */
  eq?: InputMaybe<JobApplicationStatusEnum>;
  /** Status of job application */
  in?: InputMaybe<Array<JobApplicationStatusEnum>>;
  /** Status of job application */
  neq?: InputMaybe<JobApplicationStatusEnum>;
  /** Status of job application */
  notin?: InputMaybe<Array<JobApplicationStatusEnum>>;
  /** Status of job application */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type JobVacancyListObjectType = {
  __typename?: 'JobVacancyListObjectType';
  pagination: JobVacancyPagination;
  records: Array<JobVacancyObjectType>;
};

/** Mode of Job vacancy */
export enum JobVacancyModeEnum {
  Hybrid = 'HYBRID',
  WorkFromHome = 'WORK_FROM_HOME',
  WorkFromOffice = 'WORK_FROM_OFFICE'
}

export type JobVacancyModeEnum_PropertyFilterInputType = {
  /** Mode of the job in contrast of working place */
  eq?: InputMaybe<JobVacancyModeEnum>;
  /** Mode of the job in contrast of working place */
  in?: InputMaybe<Array<JobVacancyModeEnum>>;
  /** Mode of the job in contrast of working place */
  neq?: InputMaybe<JobVacancyModeEnum>;
  /** Mode of the job in contrast of working place */
  notin?: InputMaybe<Array<JobVacancyModeEnum>>;
  /** Mode of the job in contrast of working place */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type JobVacancyObjectType = {
  __typename?: 'JobVacancyObjectType';
  /** Job vacancy details ed information */
  content: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** id of job vacancy record */
  id: Scalars['String']['output'];
  /** Location of the job */
  jobLocation: Scalars['String']['output'];
  /** Mode of the job in contrast of working place */
  jobMode: JobVacancyModeEnum;
  /** Type of job in contrast of time */
  jobType: JobVacancyTypeEnum;
  /** Maximum required experience for job */
  maxRequiredExperience: Scalars['Float']['output'];
  /** Minimum required experience for job */
  minRequiredExperience: Scalars['Float']['output'];
  /** Numbers of opening for the job */
  noOfOpening: Scalars['Int']['output'];
  /** Position for which job vacancy is open */
  position: Scalars['String']['output'];
  /** Status of the job vacancy */
  status: JobVacancyStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type JobVacancyPagination = {
  __typename?: 'JobVacancyPagination';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type JobVacancyResolver_GetJobVacancyListFilterInputType = {
  content?: InputMaybe<String_PropertyFilterInputType>;
  job_location?: InputMaybe<String_PropertyFilterInputType>;
  job_mode?: InputMaybe<JobVacancyModeEnum_PropertyFilterInputType>;
  job_type?: InputMaybe<JobVacancyTypeEnum_PropertyFilterInputType>;
  max_required_experience?: InputMaybe<Number_PropertyFilterInputType>;
  min_required_experience?: InputMaybe<Number_PropertyFilterInputType>;
  no_of_opening?: InputMaybe<Int_PropertyFilterInputType>;
  position?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<JobVacancyStatusEnum_PropertyFilterInputType>;
};

export type JobVacancyResolver_GetJobVacancyList_FilterInputType = {
  and?: InputMaybe<Array<JobVacancyResolver_GetJobVacancyListFilterInputType>>;
  content?: InputMaybe<String_PropertyFilterInputType>;
  job_location?: InputMaybe<String_PropertyFilterInputType>;
  job_mode?: InputMaybe<JobVacancyModeEnum_PropertyFilterInputType>;
  job_type?: InputMaybe<JobVacancyTypeEnum_PropertyFilterInputType>;
  max_required_experience?: InputMaybe<Number_PropertyFilterInputType>;
  min_required_experience?: InputMaybe<Number_PropertyFilterInputType>;
  no_of_opening?: InputMaybe<Int_PropertyFilterInputType>;
  or?: InputMaybe<Array<JobVacancyResolver_GetJobVacancyListFilterInputType>>;
  position?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<JobVacancyStatusEnum_PropertyFilterInputType>;
};

export type JobVacancyResolver_GetPublicJobVacancyListFilterInputType = {
  content?: InputMaybe<String_PropertyFilterInputType>;
  job_location?: InputMaybe<String_PropertyFilterInputType>;
  job_mode?: InputMaybe<JobVacancyModeEnum_PropertyFilterInputType>;
  job_type?: InputMaybe<JobVacancyTypeEnum_PropertyFilterInputType>;
  max_required_experience?: InputMaybe<Number_PropertyFilterInputType>;
  min_required_experience?: InputMaybe<Number_PropertyFilterInputType>;
  no_of_opening?: InputMaybe<Int_PropertyFilterInputType>;
  position?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<JobVacancyStatusEnum_PropertyFilterInputType>;
};

export type JobVacancyResolver_GetPublicJobVacancyList_FilterInputType = {
  and?: InputMaybe<Array<JobVacancyResolver_GetPublicJobVacancyListFilterInputType>>;
  content?: InputMaybe<String_PropertyFilterInputType>;
  job_location?: InputMaybe<String_PropertyFilterInputType>;
  job_mode?: InputMaybe<JobVacancyModeEnum_PropertyFilterInputType>;
  job_type?: InputMaybe<JobVacancyTypeEnum_PropertyFilterInputType>;
  max_required_experience?: InputMaybe<Number_PropertyFilterInputType>;
  min_required_experience?: InputMaybe<Number_PropertyFilterInputType>;
  no_of_opening?: InputMaybe<Int_PropertyFilterInputType>;
  or?: InputMaybe<Array<JobVacancyResolver_GetPublicJobVacancyListFilterInputType>>;
  position?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<JobVacancyStatusEnum_PropertyFilterInputType>;
};

/** Status of Job vacancy */
export enum JobVacancyStatusEnum {
  Close = 'CLOSE',
  Open = 'OPEN'
}

export type JobVacancyStatusEnum_PropertyFilterInputType = {
  /** Status of the job vacancy */
  eq?: InputMaybe<JobVacancyStatusEnum>;
  /** Status of the job vacancy */
  in?: InputMaybe<Array<JobVacancyStatusEnum>>;
  /** Status of the job vacancy */
  neq?: InputMaybe<JobVacancyStatusEnum>;
  /** Status of the job vacancy */
  notin?: InputMaybe<Array<JobVacancyStatusEnum>>;
  /** Status of the job vacancy */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Type of Job vacancy */
export enum JobVacancyTypeEnum {
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME'
}

export type JobVacancyTypeEnum_PropertyFilterInputType = {
  /** Type of job in contrast of time */
  eq?: InputMaybe<JobVacancyTypeEnum>;
  /** Type of job in contrast of time */
  in?: InputMaybe<Array<JobVacancyTypeEnum>>;
  /** Type of job in contrast of time */
  neq?: InputMaybe<JobVacancyTypeEnum>;
  /** Type of job in contrast of time */
  notin?: InputMaybe<Array<JobVacancyTypeEnum>>;
  /** Type of job in contrast of time */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

/** for lesson status */
export enum LessonStatusEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

/** for lesson type */
export enum LessonTypeEnum {
  Docs = 'DOCS',
  Meeting = 'MEETING',
  Quiz = 'QUIZ',
  Video = 'VIDEO'
}

export type LessonTypeEnum_PropertyFilterInputType = {
  /** type of lesson */
  eq?: InputMaybe<LessonTypeEnum>;
  /** type of lesson */
  in?: InputMaybe<Array<LessonTypeEnum>>;
  /** type of lesson */
  neq?: InputMaybe<LessonTypeEnum>;
  /** type of lesson */
  notin?: InputMaybe<Array<LessonTypeEnum>>;
  /** type of lesson */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoggedUserOutput = {
  __typename?: 'LoggedUserOutput';
  /** Generated access_token of the user */
  access_token: Scalars['String']['output'];
  /** User */
  message?: Maybe<Scalars['String']['output']>;
  /** User */
  user: UserObjectType;
};

export type LoginInput = {
  /** email of the user */
  email: Scalars['String']['input'];
  /** password of the user */
  password: Scalars['String']['input'];
};

export type Media = {
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MediaInput = {
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MentorCommission = {
  __typename?: 'MentorCommission';
  /** The commission value for mentor */
  commission: Scalars['Float']['output'];
  /** Course mentor */
  mentor: UserObjectType;
};

export type MentorCommissionInput = {
  /** The commission value for each mentor */
  commission: Scalars['Float']['input'];
  /** The mentor ID */
  mentorId: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  NewUpdateStudentProfile: Scalars['Boolean']['output'];
  NewchangePasswordViaMobile: Scalars['Boolean']['output'];
  UpdateImage: Scalars['Boolean']['output'];
  acceptJobApplication: Scalars['Boolean']['output'];
  activatePaymentGateway: Scalars['Boolean']['output'];
  addToCart: Scalars['Boolean']['output'];
  addToWishlist: Scalars['Boolean']['output'];
  addUserCourseRating: UserCourseRatingsObjectType;
  applyForJobApplication: PublicJobApplicationObjectType;
  changePassword: Scalars['Boolean']['output'];
  completeLesson: Scalars['Boolean']['output'];
  createBanner: BannerObjectType;
  createBlog: BlogObjectType;
  createBlogCategory: BlogCategoryObjectType;
  createBlogTag: BlogTagObjectType;
  createBulkFaqs: Array<FaqObjectType>;
  createCms: CmsObjectType;
  createContactUs: ContactUsObjectType;
  createCourse: CourseObjectType;
  createCourseCategory: CourseCategoryDto;
  createCourseLanguage: CourseLanguageObjectType;
  createCourseLesson: CourseLessonObjectType;
  createCourseLevel: CourseLevelObjectType;
  createCourseMode: CourseModeObjectType;
  createCourseRatings: CourseRatingsObjectType;
  createCourseSection: CourseSectionObjectType;
  createCourseSkill: CourseSkillObjectType;
  createCourseType: CourseTypeObjectType;
  createFaq: FaqObjectType;
  createGallery: GalleryObjectType;
  createGalleryTag: GalleryTagObjectType;
  createGoal: GoalObjectType;
  createInstructor: UserObjectType;
  createJobVacancy: JobVacancyObjectType;
  createNewInstructor: UserObjectType;
  createNotification: NotificationsObjectType;
  createOrder: RazorpayResponseObject;
  createOrderAdmin: RazorpayResponseObjectNew;
  createOrderAdminFreeEnroll: Scalars['Boolean']['output'];
  createOrderByCartId: RazorpayResponseObject;
  createOrderFromAPP: RazorpayResponseObject;
  createOrderNewUser: RazorpayResponseObject;
  createPaymentGateway: PaymentGatewayObjectType;
  createPermission: PermissionObjectType;
  createQuiz: QuizObjectType;
  createRole: RoleObjectType;
  createStudent: UserObjectType;
  createTestimonial: TestimonialObjectType;
  createUser: UserObjectType;
  createZoomMeting: Scalars['String']['output'];
  cronSubmitQuiz: Scalars['Boolean']['output'];
  deleteBanner: Scalars['Boolean']['output'];
  deleteBlog: Scalars['Boolean']['output'];
  deleteBlogCategory: Scalars['Boolean']['output'];
  deleteBlogTag: Scalars['Boolean']['output'];
  deleteCms: Scalars['Boolean']['output'];
  deleteCourse: Scalars['Boolean']['output'];
  deleteCourseCategory: Scalars['Boolean']['output'];
  deleteCourseLanguage: Scalars['Boolean']['output'];
  deleteCourseLesson: Scalars['Boolean']['output'];
  deleteCourseLevel: Scalars['Boolean']['output'];
  deleteCourseMode: Scalars['Boolean']['output'];
  deleteCourseProgress: Scalars['String']['output'];
  deleteCourseSection: Scalars['Boolean']['output'];
  deleteCourseSkill: Scalars['Boolean']['output'];
  deleteCourseType: Scalars['Boolean']['output'];
  deleteFaq: Scalars['Boolean']['output'];
  deleteGallery: Scalars['Boolean']['output'];
  deleteGalleryTag: Scalars['Boolean']['output'];
  deleteGoal: Scalars['Boolean']['output'];
  deleteJobVacancy: Scalars['Boolean']['output'];
  deleteNotification: Scalars['Boolean']['output'];
  deleteOrder: Scalars['Boolean']['output'];
  deleteOrderById: Scalars['Boolean']['output'];
  deleteQuiz: Scalars['Boolean']['output'];
  deleteQuizQuestion: Scalars['Boolean']['output'];
  deleteSubmitQuiz: Scalars['Boolean']['output'];
  deleteTestimonial: Scalars['Boolean']['output'];
  downloadCertificate: Scalars['String']['output'];
  downloadInvoice: Scalars['String']['output'];
  downloadInvoiceById: Scalars['String']['output'];
  enrollFreeCourse: Scalars['Boolean']['output'];
  everyDayCronVerifyRazorpayPayment: Scalars['Boolean']['output'];
  getUserCourseRating: UserCourseRatingsObjectType;
  insertSeats: Scalars['Boolean']['output'];
  instructorCompleteCourse: Scalars['Boolean']['output'];
  instructorCreateCourse: CourseObjectType;
  instructorCreateCourseLesson: CourseLessonObjectType;
  instructorCreateCourseSection: CourseSectionObjectType;
  instructorDeleteCourseLesson: Scalars['Boolean']['output'];
  instructorDeleteCourseSection: Scalars['Boolean']['output'];
  instructorInCompleteCourse: Scalars['Boolean']['output'];
  instructorRegister: Scalars['Boolean']['output'];
  instructorSignInWithEmail: LoggedUserOutput;
  instructorSignInWithSocialAccount: LoggedUserOutput;
  instructorUpdateCourse: CourseObjectType;
  instructorUpdateCourseLesson: CourseLessonObjectType;
  instructorUpdateCourseSection: CourseSectionObjectType;
  instructorUpdateCourseStatusDarft: Scalars['Boolean']['output'];
  instructorUpdateCourseStatusReadyForReview: Scalars['Boolean']['output'];
  instructorUploadCourseLessonMedia: Scalars['String']['output'];
  loginUser: LoggedUserOutput;
  newChangePassword: Scalars['Boolean']['output'];
  newCreateContactUs: Scalars['Boolean']['output'];
  newCreateUser: UserObjectType;
  newStudentRegister: RegistrationUserOutput;
  newStudentverification: RegistrationUserOutput;
  newUploadCourseLessonMedia: Scalars['String']['output'];
  readAllNotifications: Scalars['Boolean']['output'];
  rejectJobApplication: Scalars['Boolean']['output'];
  removeFromCart: Scalars['Boolean']['output'];
  removeFromWishlist: Scalars['Boolean']['output'];
  resetForgotPassword: Scalars['Boolean']['output'];
  resetForgotPasswordPublic: Scalars['Boolean']['output'];
  resetPasswordToken: Scalars['Boolean']['output'];
  sendEmailOtp: Scalars['Boolean']['output'];
  sendEmailVerificationToken: Scalars['Boolean']['output'];
  sendInvoice: Scalars['String']['output'];
  sendNotification: Scalars['Boolean']['output'];
  sendOtp: Scalars['Boolean']['output'];
  sendTestMail: Scalars['Boolean']['output'];
  setOrUnsetOccupationDefault: Scalars['Boolean']['output'];
  setOrUnsetPlatformReferralsDefault: Scalars['Boolean']['output'];
  studentNotificationStatusUpdate: Scalars['Boolean']['output'];
  studentRegister: LoggedUserOutput;
  studentSignInWithSocialAccount: LoggedUserOutput;
  submitQuiz: SubmitQuizObjectType;
  syncCart: Scalars['Boolean']['output'];
  updateBanner: BannerObjectType;
  updateBannerStatus: BannerObjectType;
  updateBlog: Scalars['Boolean']['output'];
  updateBlogCategory: Scalars['Boolean']['output'];
  updateBlogCategoryStatus: Scalars['Boolean']['output'];
  updateBlogStatus: Scalars['Boolean']['output'];
  updateBlogTag: Scalars['Boolean']['output'];
  updateBlogTagStatus: Scalars['Boolean']['output'];
  updateCms: CmsObjectType;
  updateCmsStatus: CmsObjectType;
  updateContactUsStatus: Scalars['Boolean']['output'];
  updateCourse: CourseObjectType;
  updateCourseCategory: CourseCategoryDto;
  updateCourseCategoryStatus: CourseCategoryDto;
  updateCourseLanguage: Scalars['Boolean']['output'];
  updateCourseLanguageStatus: Scalars['Boolean']['output'];
  updateCourseLesson: CourseLessonObjectType;
  updateCourseLevelName: Scalars['Boolean']['output'];
  updateCourseLevelStatus: Scalars['Boolean']['output'];
  updateCourseMentors: Scalars['Boolean']['output'];
  updateCourseModeName: CourseModeObjectType;
  updateCourseRatings: Scalars['Boolean']['output'];
  updateCourseSection: CourseSectionObjectType;
  updateCourseSettings: CourseSettingsObjectType;
  updateCourseSkill: CourseSkillObjectType;
  updateCourseSkillStatus: CourseSkillObjectType;
  updateCourseStatus: CourseObjectType;
  updateCourseType: Scalars['Boolean']['output'];
  updateCourseTypeStatus: Scalars['Boolean']['output'];
  updateFaq: FaqObjectType;
  updateFaqOrder: FaqObjectType;
  updateFaqStatus: FaqObjectType;
  updateGallery: GalleryObjectType;
  updateGalleryStatus: GalleryObjectType;
  updateGalleryTag: GalleryTagObjectType;
  updateGalleryTagStatus: GalleryTagObjectType;
  updateGoal: Scalars['Boolean']['output'];
  updateGoalStatus: Scalars['Boolean']['output'];
  updateInstructor: Scalars['Boolean']['output'];
  updateInstructorEmail: Scalars['Boolean']['output'];
  updateInstructorProfile: Scalars['Boolean']['output'];
  updateInstructorStatus: Scalars['Boolean']['output'];
  updateJobVacancy: Scalars['Boolean']['output'];
  updateJobVacancyStatus: Scalars['Boolean']['output'];
  updateLessonLockStatus: Scalars['Boolean']['output'];
  updateNotification: Scalars['Boolean']['output'];
  updateNotificationStatus: Scalars['Boolean']['output'];
  updatePaymentGateway: Scalars['Boolean']['output'];
  updatePaymentGatewayMode: Scalars['Boolean']['output'];
  updateQuiz: Scalars['Boolean']['output'];
  updateQuizQuestion: Array<QuizQuestionObjectType>;
  updateQuizStatus: Scalars['Boolean']['output'];
  updateRole: Scalars['Boolean']['output'];
  updateRolePermissions: RoleObjectType;
  updateSetting: SettingObjectType;
  updateStudent: Scalars['Boolean']['output'];
  updateStudentBasicQuestionInProfile: Scalars['Boolean']['output'];
  updateStudentEmail: Scalars['Boolean']['output'];
  updateStudentProfile: Scalars['Boolean']['output'];
  updateStudentStatus: Scalars['Boolean']['output'];
  updateStudentVerificationManually: Scalars['Boolean']['output'];
  updateTestimonial: TestimonialObjectType;
  updateTestimonialStatus: TestimonialObjectType;
  updateUser: UserObjectType;
  updateUserCourseRating: Scalars['Boolean']['output'];
  updateUserPassword: Scalars['Boolean']['output'];
  updateUserStatus: Scalars['Boolean']['output'];
  uploadCourseLessonMedia: Scalars['String']['output'];
  uploadGalleryMedia: Scalars['String']['output'];
  uploadQuizOptionImage: Scalars['String']['output'];
  uploadQuizQuestionImage: Scalars['String']['output'];
  uploadResume: Scalars['String']['output'];
  uploadTestimonialMedia: Scalars['String']['output'];
  upsertCourseProgress: Scalars['String']['output'];
  verifyEmail: Scalars['Boolean']['output'];
  verifyEmailOtp: Scalars['Boolean']['output'];
  verifyOrder: OrderObjectType;
  verifyOrderAdmin: OrderObjectType;
  verifyOrderNewUser: NewUserOrderObjectType;
  verifyOtp: Scalars['Boolean']['output'];
  verifyRazorpayPayment: Scalars['Boolean']['output'];
};


export type MutationNewUpdateStudentProfileArgs = {
  NewupdateStudentProfileInput: NewUpdateStudentProfileInput;
};


export type MutationNewchangePasswordViaMobileArgs = {
  changePasswordInput: NewChangePasswordInput;
};


export type MutationUpdateImageArgs = {
  imageUploadInput: ImageUploadInput;
};


export type MutationAcceptJobApplicationArgs = {
  id: Scalars['String']['input'];
};


export type MutationActivatePaymentGatewayArgs = {
  id: Scalars['String']['input'];
};


export type MutationAddToCartArgs = {
  CourseId: Scalars['String']['input'];
};


export type MutationAddToWishlistArgs = {
  courseId: Scalars['String']['input'];
};


export type MutationAddUserCourseRatingArgs = {
  addUserCourseRatingsInput: AddUserCourseRatingsInput;
};


export type MutationApplyForJobApplicationArgs = {
  createPublicJobApplication: CreatePublicJobApplicationInput;
};


export type MutationChangePasswordArgs = {
  changePasswordInput: ChangePasswordInput;
};


export type MutationCompleteLessonArgs = {
  lessonId: Scalars['String']['input'];
};


export type MutationCreateBannerArgs = {
  CreateBannerInput: CreateBannerInput;
};


export type MutationCreateBlogArgs = {
  createBlogInput: CreateBlogInput;
};


export type MutationCreateBlogCategoryArgs = {
  createBlogCategoryInput: CreateBlogCategoryInput;
};


export type MutationCreateBlogTagArgs = {
  createBlogTagInput: CreateBlogTagInput;
};


export type MutationCreateBulkFaqsArgs = {
  CreateBulkFaqsInput: CreateBulkFaqsInput;
};


export type MutationCreateCmsArgs = {
  CreateCmsInput: CreateCmsInput;
};


export type MutationCreateContactUsArgs = {
  createContactUsInput: CreateContactUsInput;
};


export type MutationCreateCourseArgs = {
  CreateCourseInput: CreateCourseInput;
};


export type MutationCreateCourseCategoryArgs = {
  CreateCourseCategoryInput: CreateCourseCategoryInput;
};


export type MutationCreateCourseLanguageArgs = {
  createCourseLanguage: CreateCourseLanguageInput;
};


export type MutationCreateCourseLessonArgs = {
  CreateLessonSectionInput: CreateCourseLessonInput;
};


export type MutationCreateCourseLevelArgs = {
  createCourseLevelInput: CreateCourseLevelInput;
};


export type MutationCreateCourseModeArgs = {
  CreateCourseModeInput: CreateCourseModeInput;
};


export type MutationCreateCourseRatingsArgs = {
  createCourseRatingsInput: CreateCourseRatingsInput;
};


export type MutationCreateCourseSectionArgs = {
  CreateCourseSectionInput: CreateCourseSectionInput;
};


export type MutationCreateCourseSkillArgs = {
  createCourseSkillInput: CreateCourseSkillInput;
};


export type MutationCreateCourseTypeArgs = {
  createCourseTypeInput: CreateCourseTypeInput;
};


export type MutationCreateFaqArgs = {
  CreateFaqInput: CreateFaqInput;
};


export type MutationCreateGalleryArgs = {
  createGalleryInput: CreateGalleryInput;
};


export type MutationCreateGalleryTagArgs = {
  createGalleryTagInput: CreateGalleryTagInput;
};


export type MutationCreateGoalArgs = {
  createGoalInput: CreateGoalInput;
};


export type MutationCreateInstructorArgs = {
  createInstructorInput: CreateInstructorInput;
};


export type MutationCreateJobVacancyArgs = {
  createJobVacancyInput: CreateJobVacancyInput;
};


export type MutationCreateNewInstructorArgs = {
  createInstructorInput: CreateNewInstructorInput;
};


export type MutationCreateNotificationArgs = {
  createNotificationsInput: CreateNotificationsInput;
};


export type MutationCreateOrderArgs = {
  createOrderInput: CreateOrderInput;
};


export type MutationCreateOrderAdminArgs = {
  createOrderInput: CreateOrderAdminInput;
};


export type MutationCreateOrderAdminFreeEnrollArgs = {
  createOrderInput: CreateOrderAdminFreeEnrollInput;
};


export type MutationCreateOrderByCartIdArgs = {
  cartIds: CreateOrderCartIdsInput;
  createOrderInput: CreateOrderByCartIdInput;
};


export type MutationCreateOrderFromAppArgs = {
  createOrderInput: CreateOrderInput;
  platform: Scalars['String']['input'];
};


export type MutationCreateOrderNewUserArgs = {
  CreateOrderNewUserInput: CreateOrderNewUserInput;
};


export type MutationCreatePaymentGatewayArgs = {
  createPaymentGatewayInput: CreatePaymentGatewayInput;
};


export type MutationCreatePermissionArgs = {
  createPermissionInput: CreatePermissionInput;
};


export type MutationCreateQuizArgs = {
  createQuizInput: CreateQuizInput;
};


export type MutationCreateRoleArgs = {
  createRoleInput: CreateRoleInput;
};


export type MutationCreateStudentArgs = {
  createStudentInput: CreateStudentInput;
};


export type MutationCreateTestimonialArgs = {
  CreateTestimonialInput: CreateTestimonialInput;
};


export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};


export type MutationCreateZoomMetingArgs = {
  createZoomMeetingInput: CreateZoomMeetingInput;
};


export type MutationDeleteBannerArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteBlogArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteBlogCategoryArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteBlogTagArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCmsArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCourseArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCourseCategoryArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCourseLanguageArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCourseLessonArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCourseLevelArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCourseModeArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCourseProgressArgs = {
  course_id: Scalars['String']['input'];
};


export type MutationDeleteCourseSectionArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCourseSkillArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCourseTypeArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteFaqArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteGalleryArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteGalleryTagArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteGoalArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteJobVacancyArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteOrderArgs = {
  orderId: Scalars['String']['input'];
};


export type MutationDeleteOrderByIdArgs = {
  orderId: Scalars['String']['input'];
};


export type MutationDeleteQuizArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteQuizQuestionArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSubmitQuizArgs = {
  courseId: Scalars['String']['input'];
  lessonId: Scalars['String']['input'];
  quizId: Scalars['String']['input'];
  sectionId: Scalars['String']['input'];
};


export type MutationDeleteTestimonialArgs = {
  id: Scalars['String']['input'];
};


export type MutationDownloadCertificateArgs = {
  course_id: Scalars['String']['input'];
};


export type MutationDownloadInvoiceArgs = {
  order_id: Scalars['String']['input'];
};


export type MutationDownloadInvoiceByIdArgs = {
  order_id: Scalars['String']['input'];
};


export type MutationEnrollFreeCourseArgs = {
  createFreeCourseEnrollmentInput: CreateFreeCourseEnrollmentInput;
};


export type MutationGetUserCourseRatingArgs = {
  courseId: Scalars['String']['input'];
};


export type MutationInsertSeatsArgs = {
  CartId: Scalars['String']['input'];
  SeatCount: Scalars['Float']['input'];
};


export type MutationInstructorCompleteCourseArgs = {
  course_id: Scalars['String']['input'];
};


export type MutationInstructorCreateCourseArgs = {
  InstructorCreateCourseInput: InstructorCreateCourseInput;
};


export type MutationInstructorCreateCourseLessonArgs = {
  InstructorCreateLessonSectionInput: CreateCourseLessonInput;
};


export type MutationInstructorCreateCourseSectionArgs = {
  InstructorCreateCourseSectionInput: CreateCourseSectionInput;
};


export type MutationInstructorDeleteCourseLessonArgs = {
  id: Scalars['String']['input'];
};


export type MutationInstructorDeleteCourseSectionArgs = {
  id: Scalars['String']['input'];
};


export type MutationInstructorInCompleteCourseArgs = {
  course_id: Scalars['String']['input'];
};


export type MutationInstructorRegisterArgs = {
  instructorRegisterInput: InstructorRegisterInput;
};


export type MutationInstructorSignInWithEmailArgs = {
  instructorSignUpAndInWithEmail: InstructorSignInWithEmailInput;
};


export type MutationInstructorSignInWithSocialAccountArgs = {
  instructorSignUpAndInWithSocialAccount: InstructorSignInWithSocialAccountInput;
};


export type MutationInstructorUpdateCourseArgs = {
  InstructorUpdateCourseInput: InstructorUpdateCourseInput;
};


export type MutationInstructorUpdateCourseLessonArgs = {
  InstructorUpdateCourseLessonInput: UpdateCourseLessonInput;
};


export type MutationInstructorUpdateCourseSectionArgs = {
  InstructorUpdateCourseSectionInput: UpdateCourseSectionInput;
};


export type MutationInstructorUpdateCourseStatusDarftArgs = {
  id: Scalars['String']['input'];
};


export type MutationInstructorUpdateCourseStatusReadyForReviewArgs = {
  id: Scalars['String']['input'];
};


export type MutationInstructorUploadCourseLessonMediaArgs = {
  CourseLessonMediaInput: CourseLessonMediaInput;
};


export type MutationLoginUserArgs = {
  loginUserInput: LoginInput;
};


export type MutationNewChangePasswordArgs = {
  newChangePasswordInput: NewChangePasswordInput;
};


export type MutationNewCreateContactUsArgs = {
  createContactUsInput: NewCreateContactUsInput;
};


export type MutationNewCreateUserArgs = {
  createUserInput: NewCreateUserInput;
};


export type MutationNewStudentRegisterArgs = {
  studentRegisterInput: StudentRegisterInput;
};


export type MutationNewStudentverificationArgs = {
  studentRegisterVerificationInput: StudentRegisterVerificationInput;
};


export type MutationNewUploadCourseLessonMediaArgs = {
  CourseLessonMediaInput: NewCourseLessonMediaInput;
};


export type MutationRejectJobApplicationArgs = {
  rejectJobApplicationInput: RejectJobApplicationInput;
};


export type MutationRemoveFromCartArgs = {
  CartId: Scalars['String']['input'];
};


export type MutationRemoveFromWishlistArgs = {
  courseId: Scalars['String']['input'];
};


export type MutationResetForgotPasswordArgs = {
  resetForgotPasswordInput: ResetForgotPasswordInput;
};


export type MutationResetForgotPasswordPublicArgs = {
  resetForgotPasswordInput: ResetForgotPasswordInput;
};


export type MutationResetPasswordTokenArgs = {
  forgotPasswordInput: ForgotPasswordInput;
};


export type MutationSendInvoiceArgs = {
  order_id: Scalars['String']['input'];
};


export type MutationSendNotificationArgs = {
  notificationId: Scalars['String']['input'];
};


export type MutationSendOtpArgs = {
  mobileNo: Scalars['String']['input'];
};


export type MutationSendTestMailArgs = {
  email: Scalars['String']['input'];
};


export type MutationSetOrUnsetOccupationDefaultArgs = {
  id: Scalars['String']['input'];
};


export type MutationSetOrUnsetPlatformReferralsDefaultArgs = {
  id: Scalars['String']['input'];
};


export type MutationStudentNotificationStatusUpdateArgs = {
  notificationId: Scalars['String']['input'];
};


export type MutationStudentRegisterArgs = {
  studentRegisterInput: StudentRegisterInput;
};


export type MutationStudentSignInWithSocialAccountArgs = {
  studentSignInWithSocialAccountInput: StudentSignInWithSocialAccountInput;
};


export type MutationSubmitQuizArgs = {
  userQuizResultInputType: UserQuizResultInputType;
};


export type MutationSyncCartArgs = {
  SyncCartInput: Array<SyncCartInput>;
};


export type MutationUpdateBannerArgs = {
  UpdateBannerInput: UpdateBannerInput;
};


export type MutationUpdateBannerStatusArgs = {
  UpdateBannerStatusInput: UpdateBannerStatusInput;
};


export type MutationUpdateBlogArgs = {
  updateBlogInput: UpdateBlogInput;
};


export type MutationUpdateBlogCategoryArgs = {
  updateBlogCategoryInput: UpdateBlogCategoryInput;
};


export type MutationUpdateBlogCategoryStatusArgs = {
  updateBlogCategoryStatusInput: UpdateBlogCategoryStatusInput;
};


export type MutationUpdateBlogStatusArgs = {
  updateBlogStatusInput: UpdateBlogStatusInput;
};


export type MutationUpdateBlogTagArgs = {
  updateBlogTagInput: UpdateBlogTagInput;
};


export type MutationUpdateBlogTagStatusArgs = {
  updateBlogTagStatusInput: UpdateBlogTagStatusInput;
};


export type MutationUpdateCmsArgs = {
  UpdateCmsInput: UpdateCmsInput;
};


export type MutationUpdateCmsStatusArgs = {
  UpdateCmsStatusInput: UpdateCmsStatusInput;
};


export type MutationUpdateContactUsStatusArgs = {
  updateContactUsStatusInput: UpdateContactUsStatusInput;
};


export type MutationUpdateCourseArgs = {
  UpdateCourseInput: UpdateCourseInput;
};


export type MutationUpdateCourseCategoryArgs = {
  UpdateCourseCategoryInput: UpdateCourseCategoryInput;
};


export type MutationUpdateCourseCategoryStatusArgs = {
  UpdateCourseCategoryStatusInput: UpdateCourseCategoryStatusInput;
};


export type MutationUpdateCourseLanguageArgs = {
  updateCourselanguageInput: UpdateCourseLanguageInput;
};


export type MutationUpdateCourseLanguageStatusArgs = {
  updateCourseLanguageStatus: UpdateCourselanguageStatusInput;
};


export type MutationUpdateCourseLessonArgs = {
  UpdateCourseLessonInput: UpdateCourseLessonInput;
};


export type MutationUpdateCourseLevelNameArgs = {
  updateCourseLevelNameInput: UpdateCourseLevelNameInput;
};


export type MutationUpdateCourseLevelStatusArgs = {
  updateCourseLevelStatusInput: UpdateCourseLevelStatusInput;
};


export type MutationUpdateCourseMentorsArgs = {
  UpdateCourseMentorsInput: UpdateCourseMentorsInput;
};


export type MutationUpdateCourseModeNameArgs = {
  UpdateCourseModeInput: UpdateCourseModeInput;
};


export type MutationUpdateCourseRatingsArgs = {
  updateCourseRatingsInput: UpdateCourseRatingsInput;
};


export type MutationUpdateCourseSectionArgs = {
  UpdateCourseSectionInput: UpdateCourseSectionInput;
};


export type MutationUpdateCourseSettingsArgs = {
  courseSettingsInput: CourseSettingsInput;
};


export type MutationUpdateCourseSkillArgs = {
  updateCourseSkillInput: UpdateCourseSkillInput;
};


export type MutationUpdateCourseSkillStatusArgs = {
  updateCourseSkillStatus: UpdateCourseSkillStatusInput;
};


export type MutationUpdateCourseStatusArgs = {
  UpdateCourseStatusInput: UpdateCourseStatusInput;
};


export type MutationUpdateCourseTypeArgs = {
  updateCourseTypeInput: UpdateCourseTypeInput;
};


export type MutationUpdateCourseTypeStatusArgs = {
  updateCourseTypeStatusInput: UpdateCourseTypeStatusInput;
};


export type MutationUpdateFaqArgs = {
  UpdateFaqInput: UpdateFaqInput;
};


export type MutationUpdateFaqOrderArgs = {
  UpdateFaqOrderInput: UpdateFaqOrderInput;
};


export type MutationUpdateFaqStatusArgs = {
  UpdateFaqStatusInput: UpdateFaqStatusInput;
};


export type MutationUpdateGalleryArgs = {
  updateGalleryInput: UpdateGalleryInput;
};


export type MutationUpdateGalleryStatusArgs = {
  updateGalleryStatusInput: UpdateGalleryStatusInput;
};


export type MutationUpdateGalleryTagArgs = {
  updateGalleryTagInput: UpdateGalleryTagInput;
};


export type MutationUpdateGalleryTagStatusArgs = {
  updateGalleryTagStatusInput: UpdateGalleryTagStatusInput;
};


export type MutationUpdateGoalArgs = {
  updateGoalInput: UpdateGoalInput;
};


export type MutationUpdateGoalStatusArgs = {
  updateGoalStatusInput: UpdateGoalStatusInput;
};


export type MutationUpdateInstructorArgs = {
  updateInstructorInput: UpdateInstructorInput;
};


export type MutationUpdateInstructorEmailArgs = {
  updateInstructorEmailInput: UpdateInstructorEmailInput;
};


export type MutationUpdateInstructorProfileArgs = {
  updateInstructorProfileInput: UpdateInstructorProfileInput;
};


export type MutationUpdateInstructorStatusArgs = {
  updateInstructorStatusInput: UpdateUserStatusInput;
};


export type MutationUpdateJobVacancyArgs = {
  updateJobVacancyInput: UpdateJobVacancyInput;
};


export type MutationUpdateJobVacancyStatusArgs = {
  updateJobVacancyStatusInput: UpdateJobVacancyStatusInput;
};


export type MutationUpdateLessonLockStatusArgs = {
  CourseLessonMediaInput: UpdateCourseLessonStatusInput;
};


export type MutationUpdateNotificationArgs = {
  updateNotificationsInput: UpdateNotificationsInput;
};


export type MutationUpdateNotificationStatusArgs = {
  updateNotificationStatusInput: UpdateNotificationStatusInput;
};


export type MutationUpdatePaymentGatewayArgs = {
  updatePaymentGatewayInput: UpdatePaymentGatewayInput;
};


export type MutationUpdatePaymentGatewayModeArgs = {
  updatePaymentGatewayModeInput: UpdatePaymentGatewayModeInput;
};


export type MutationUpdateQuizArgs = {
  updateQuizInput: UpdateQuizInput;
};


export type MutationUpdateQuizQuestionArgs = {
  updateQuizQuestionInput: Array<UpdateQuizQuestionInput>;
};


export type MutationUpdateQuizStatusArgs = {
  updateQuizStatusInput: UpdateQuizStatusInput;
};


export type MutationUpdateRoleArgs = {
  updateRoleInput: UpdateRoleInput;
};


export type MutationUpdateRolePermissionsArgs = {
  updateRolePermissions: UpdateRolePermissionsInput;
};


export type MutationUpdateSettingArgs = {
  UpdateSettingInput: UpdateSettingInput;
};


export type MutationUpdateStudentArgs = {
  updateStudentInput: UpdateStudentInput;
};


export type MutationUpdateStudentBasicQuestionInProfileArgs = {
  updateStudentBasicQuestionInput: UpdateStudentBasicQuestionInputType;
};


export type MutationUpdateStudentEmailArgs = {
  updateStudentEmailInput: UpdateStudentEmailInput;
};


export type MutationUpdateStudentProfileArgs = {
  updateStudentProfileInput: UpdateStudentProfileInput;
};


export type MutationUpdateStudentStatusArgs = {
  updateStudentStatusInput: UpdateUserStatusInput;
};


export type MutationUpdateStudentVerificationManuallyArgs = {
  updateStudentVerificationInput: UpdateStudentVerificationInput;
};


export type MutationUpdateTestimonialArgs = {
  UpdateTestimonialInput: UpdateTestimonialInput;
};


export type MutationUpdateTestimonialStatusArgs = {
  UpdateTestimonialStatusInput: UpdateTestimonialStatusInput;
};


export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUpdateUserCourseRatingArgs = {
  updateUserCourseRatingsInput: UpdateUserCourseRatingsInput;
};


export type MutationUpdateUserPasswordArgs = {
  updateUserPasswordInput: UpdateUserPasswordInput;
};


export type MutationUpdateUserStatusArgs = {
  updateUserStatusInput: UpdateUserStatusInput;
};


export type MutationUploadCourseLessonMediaArgs = {
  CourseLessonMediaInput: CourseLessonMediaInput;
};


export type MutationUploadGalleryMediaArgs = {
  GalleryMediaInput: GalleryMediaInput;
};


export type MutationUploadQuizOptionImageArgs = {
  uploadQuizImage: UploadQuizImage;
};


export type MutationUploadQuizQuestionImageArgs = {
  uploadQuizImage: UploadQuizImage;
};


export type MutationUploadResumeArgs = {
  uploadResumeInput: UploadResumeInput;
};


export type MutationUploadTestimonialMediaArgs = {
  TestimonialMediaInput: TestimonialMediaInput;
};


export type MutationUpsertCourseProgressArgs = {
  upsertCourseProgressInput: UpsertCourseProgressInput;
};


export type MutationVerifyEmailArgs = {
  emailVerificationInput: EmailVerificationInput;
};


export type MutationVerifyEmailOtpArgs = {
  otp: Scalars['String']['input'];
};


export type MutationVerifyOrderArgs = {
  verifyOrderInput: VerifyOrderInput;
};


export type MutationVerifyOrderAdminArgs = {
  verifyOrderInput: VerifyOrderInput;
};


export type MutationVerifyOrderNewUserArgs = {
  verifyOrderInput: VerifyOrderInput;
};


export type MutationVerifyOtpArgs = {
  otp: Scalars['String']['input'];
};

export type NewChangePasswordInput = {
  /** confirmNewPassword of the user */
  confirmNewPassword: Scalars['String']['input'];
  /** newPassword of the user */
  newPassword: Scalars['String']['input'];
  /** newPassword of the user */
  otp: Scalars['String']['input'];
};

export type NewCourseLessonMediaInput = {
  /** content base64 string */
  content: Scalars['String']['input'];
  /** type of media */
  mediaType: CourseLessonMediaTypeEnum;
  /** file name for content */
  name: Scalars['String']['input'];
};

export type NewCreateContactUsInput = {
  /** Contact number of user who submit contact us form */
  contactNo: Scalars['String']['input'];
  /** email of user who submit contact us form */
  email: Scalars['String']['input'];
  /** Message of user who submit contact us form */
  message?: InputMaybe<Scalars['String']['input']>;
  /** name of user who submit contact us form */
  name: Scalars['String']['input'];
  /** Contact no std code of user who submit contact us form */
  stdCodeOfContactNo?: InputMaybe<Scalars['String']['input']>;
  /** Subject of user who submit contact us form */
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type NewCreateUserInput = {
  /** email of the user */
  email: Scalars['String']['input'];
  /** first name of the user */
  name: Scalars['String']['input'];
  /** password of the user */
  password: Scalars['String']['input'];
  /** role of the user */
  roleId: Scalars['String']['input'];
  /** status of user account */
  status: UserStatusEnum;
  /** type of user */
  userType: UserTypeEnum;
};

export type NewUpdateStudentProfileInput = {
  /** user's Address */
  address?: InputMaybe<Scalars['String']['input']>;
  /** user's Address */
  address_id?: InputMaybe<Scalars['String']['input']>;
  /** user's city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** user's city id */
  cityId?: InputMaybe<Scalars['String']['input']>;
  /** Student's country name */
  countryId?: InputMaybe<Scalars['String']['input']>;
  /** Student's description about themself */
  description?: InputMaybe<Scalars['String']['input']>;
  /** user Date of birth */
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  /** Student's email id */
  email: Scalars['String']['input'];
  /** Student's gender */
  gender?: InputMaybe<UserGenderEnum>;
  /** Student's goal to join hogrowth */
  goalId?: InputMaybe<Scalars['String']['input']>;
  /** Student's profile image url */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Student's instagram profile URL */
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  /** Student's LinkedIn profile URL */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** Student's mobile number */
  mobileNo?: InputMaybe<Scalars['String']['input']>;
  /** Student's mobile number std code according to their country */
  mobileNoStdCode?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the student */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Student's Profile picture */
  newImage?: InputMaybe<UserImageMedia>;
  /** user's pincode */
  pincode?: InputMaybe<Scalars['String']['input']>;
  /** Student's qualification */
  qualification?: InputMaybe<Scalars['String']['input']>;
  /** user's state */
  state?: InputMaybe<Scalars['String']['input']>;
  /** user's state id */
  stateId?: InputMaybe<Scalars['String']['input']>;
  /** Student's twitter profile URL */
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
};

export type NewUserOrderDetailObjectType = {
  __typename?: 'NewUserOrderDetailObjectType';
  /** Discount get from coupon */
  couponDiscountAmount: Scalars['Float']['output'];
  /** courses of Order */
  course: CoursePublicObjectType;
  /** Course id which ordered */
  courseId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Discounted value of course order */
  discountValue: Scalars['Float']['output'];
  /** id of the order details */
  id: Scalars['String']['output'];
  /** Booked number of seat */
  noOfSeat: Scalars['Float']['output'];
  /** order of Order details */
  order: NewUserOrderObjectType;
  /** Order id with this order details related */
  orderId: Scalars['String']['output'];
  /** Price of the course which ordered */
  price: Scalars['Float']['output'];
  /** Sub total of course order */
  subTotal?: Maybe<Scalars['Float']['output']>;
  /** Tax on course order */
  tax: Scalars['Float']['output'];
  /** Tax amount on course order */
  taxAmount?: Maybe<Scalars['Float']['output']>;
  /** Total price of the course which ordered */
  totalPrice: Scalars['Float']['output'];
};

export type NewUserOrderObjectType = {
  __typename?: 'NewUserOrderObjectType';
  access_token?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<UserObjectType>;
  createdById?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  /** GST No of user who placed order */
  gstNo?: Maybe<Scalars['String']['output']>;
  /** id of order */
  id: Scalars['String']['output'];
  /** Invoice status */
  invoiceStatus?: Maybe<Scalars['Boolean']['output']>;
  mobileNo?: Maybe<Scalars['String']['output']>;
  /** Details of Order */
  orderDetails: Array<NewUserOrderDetailObjectType>;
  /** Payment gateway id */
  paymentGatewayId?: Maybe<Scalars['String']['output']>;
  /** Payment gateway mode */
  paymentGatewayMode?: Maybe<PaymentGatewayModeEnum>;
  paymentLink?: Maybe<Scalars['String']['output']>;
  platform: PlatformTypeEnum;
  registerAddress?: Maybe<Scalars['String']['output']>;
  stateId?: Maybe<Scalars['String']['output']>;
  /** Status of order */
  status: OrderStatusEnum;
  /** Sub total of order */
  subTotal: Scalars['Float']['output'];
  /** Tax on order */
  tax: Scalars['Float']['output'];
  /** Total of order */
  total: Scalars['String']['output'];
  /** Total discount on the order */
  totalDiscount?: Maybe<Scalars['Float']['output']>;
  /** Transaction date and time for this order */
  transactionDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** Transaction id for this order's payment */
  transactionId?: Maybe<Scalars['String']['output']>;
  /** Transaction status for this order */
  transactionStatus?: Maybe<Scalars['String']['output']>;
  /** Type of the order */
  type: OrderTypeEnum;
};

/** The Notification Action */
export enum NotificationActionEnum {
  Create = 'CREATE',
  Sent = 'SENT'
}

export type NotificationListObjectType = {
  __typename?: 'NotificationListObjectType';
  pagination: NotificationPaginationObjectType;
  records: Array<NotificationsObjectType>;
};

export type NotificationPaginationObjectType = {
  __typename?: 'NotificationPaginationObjectType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type NotificationPayload = {
  __typename?: 'NotificationPayload';
  created_at: Scalars['String']['output'];
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** The Notification Sent Status */
export enum NotificationSentStatusEnum {
  NotSent = 'NOT_SENT',
  Sent = 'SENT'
}

export type NotificationSentStatusEnum_PropertyFilterInputType = {
  /** sent status of the notification */
  eq?: InputMaybe<NotificationSentStatusEnum>;
  /** sent status of the notification */
  in?: InputMaybe<Array<NotificationSentStatusEnum>>;
  /** sent status of the notification */
  neq?: InputMaybe<NotificationSentStatusEnum>;
  /** sent status of the notification */
  notin?: InputMaybe<Array<NotificationSentStatusEnum>>;
  /** sent status of the notification */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The Notification Status */
export enum NotificationStatusEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Rejected = 'REJECTED'
}

export type NotificationStatusEnum_PropertyFilterInputType = {
  /** status of the notification */
  eq?: InputMaybe<NotificationStatusEnum>;
  /** status of the notification */
  in?: InputMaybe<Array<NotificationStatusEnum>>;
  /** status of the notification */
  neq?: InputMaybe<NotificationStatusEnum>;
  /** status of the notification */
  notin?: InputMaybe<Array<NotificationStatusEnum>>;
  /** status of the notification */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The Notification type */
export enum NotificationTypeEnum {
  CourseSpecific = 'COURSE_SPECIFIC',
  EnrolledUser = 'ENROLLED_USER',
  General = 'GENERAL',
  GuestUser = 'GUEST_USER'
}

export type NotificationTypeEnum_PropertyFilterInputType = {
  /** type of the notification */
  eq?: InputMaybe<NotificationTypeEnum>;
  /** type of the notification */
  in?: InputMaybe<Array<NotificationTypeEnum>>;
  /** type of the notification */
  neq?: InputMaybe<NotificationTypeEnum>;
  /** type of the notification */
  notin?: InputMaybe<Array<NotificationTypeEnum>>;
  /** type of the notification */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationsObjectType = {
  __typename?: 'NotificationsObjectType';
  /** courses of notification */
  course?: Maybe<CoursePublicObjectType>;
  courseId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<UserObjectType>;
  createdById?: Maybe<Scalars['String']['output']>;
  /** id of notification */
  id: Scalars['String']['output'];
  /** Notification of message */
  message: Scalars['String']['output'];
  readStatus: Scalars['Boolean']['output'];
  sentBy?: Maybe<Scalars['String']['output']>;
  /** Sent Status of notification */
  sentStatus: NotificationSentStatusEnum;
  /** Notification sent time */
  sentTime?: Maybe<Scalars['String']['output']>;
  /** Status of notification */
  status: NotificationStatusEnum;
  /** Notification of title */
  title: Scalars['String']['output'];
  /** Type of notification */
  type: NotificationTypeEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type NotificationsResolver_GetNotificationsListFilterInputType = {
  course?: InputMaybe<String_PropertyFilterInputType>;
  created_at?: InputMaybe<Date_PropertyFilterInputType>;
  sent_status?: InputMaybe<NotificationSentStatusEnum_PropertyFilterInputType>;
  status?: InputMaybe<NotificationStatusEnum_PropertyFilterInputType>;
  title?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<NotificationTypeEnum_PropertyFilterInputType>;
};

export type NotificationsResolver_GetNotificationsList_FilterInputType = {
  and?: InputMaybe<Array<NotificationsResolver_GetNotificationsListFilterInputType>>;
  course?: InputMaybe<String_PropertyFilterInputType>;
  created_at?: InputMaybe<Date_PropertyFilterInputType>;
  or?: InputMaybe<Array<NotificationsResolver_GetNotificationsListFilterInputType>>;
  sent_status?: InputMaybe<NotificationSentStatusEnum_PropertyFilterInputType>;
  status?: InputMaybe<NotificationStatusEnum_PropertyFilterInputType>;
  title?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<NotificationTypeEnum_PropertyFilterInputType>;
};

export type NotificationsResolver_GetStudentNotificationListFilterInputType = {
  course?: InputMaybe<String_PropertyFilterInputType>;
  created_at?: InputMaybe<Date_PropertyFilterInputType>;
  sent_status?: InputMaybe<NotificationSentStatusEnum_PropertyFilterInputType>;
  status?: InputMaybe<NotificationStatusEnum_PropertyFilterInputType>;
  title?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<NotificationTypeEnum_PropertyFilterInputType>;
};

export type NotificationsResolver_GetStudentNotificationList_FilterInputType = {
  and?: InputMaybe<Array<NotificationsResolver_GetStudentNotificationListFilterInputType>>;
  course?: InputMaybe<String_PropertyFilterInputType>;
  created_at?: InputMaybe<Date_PropertyFilterInputType>;
  or?: InputMaybe<Array<NotificationsResolver_GetStudentNotificationListFilterInputType>>;
  sent_status?: InputMaybe<NotificationSentStatusEnum_PropertyFilterInputType>;
  status?: InputMaybe<NotificationStatusEnum_PropertyFilterInputType>;
  title?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<NotificationTypeEnum_PropertyFilterInputType>;
};

export type NotificationsResolver_StudentNotificationListFilterInputType = {
  course?: InputMaybe<String_PropertyFilterInputType>;
  created_at?: InputMaybe<Date_PropertyFilterInputType>;
  sent_status?: InputMaybe<NotificationSentStatusEnum_PropertyFilterInputType>;
  status?: InputMaybe<NotificationStatusEnum_PropertyFilterInputType>;
  title?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<NotificationTypeEnum_PropertyFilterInputType>;
};

export type NotificationsResolver_StudentNotificationList_FilterInputType = {
  and?: InputMaybe<Array<NotificationsResolver_StudentNotificationListFilterInputType>>;
  course?: InputMaybe<String_PropertyFilterInputType>;
  created_at?: InputMaybe<Date_PropertyFilterInputType>;
  or?: InputMaybe<Array<NotificationsResolver_StudentNotificationListFilterInputType>>;
  sent_status?: InputMaybe<NotificationSentStatusEnum_PropertyFilterInputType>;
  status?: InputMaybe<NotificationStatusEnum_PropertyFilterInputType>;
  title?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<NotificationTypeEnum_PropertyFilterInputType>;
};

export type Number_PropertyFilterInputType = {
  /** amout of course */
  between?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** amout of course */
  eq?: InputMaybe<Scalars['Float']['input']>;
  /** amout of course */
  gt?: InputMaybe<Scalars['Float']['input']>;
  /** amout of course */
  gte?: InputMaybe<Scalars['Float']['input']>;
  /** amout of course */
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** amout of course */
  like?: InputMaybe<Scalars['String']['input']>;
  /** amout of course */
  lt?: InputMaybe<Scalars['Float']['input']>;
  /** amout of course */
  lte?: InputMaybe<Scalars['Float']['input']>;
  /** amout of course */
  neq?: InputMaybe<Scalars['Float']['input']>;
  /** amout of course */
  notbetween?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** amout of course */
  notin?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** amout of course */
  notlike?: InputMaybe<Scalars['String']['input']>;
  /** amout of course */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OccupationListObjectType = {
  __typename?: 'OccupationListObjectType';
  pagination: OccupationPaginationType;
  records: Array<OccupationObjectType>;
};

export type OccupationObjectType = {
  __typename?: 'OccupationObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** id of occupation record */
  id: Scalars['String']['output'];
  /** flag to determine default occupation to show users */
  isDefault: Scalars['Boolean']['output'];
  /** name of occupation record */
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OccupationPaginationType = {
  __typename?: 'OccupationPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type OccupationResolver_GetOccupationsFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
};

export type OccupationResolver_GetOccupations_FilterInputType = {
  and?: InputMaybe<Array<OccupationResolver_GetOccupationsFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<OccupationResolver_GetOccupationsFilterInputType>>;
};

export type OrderDetailObjectType = {
  __typename?: 'OrderDetailObjectType';
  /** Discount get from coupon */
  couponDiscountAmount: Scalars['Float']['output'];
  /** courses of Order */
  course: CoursePublicObjectType;
  /** Course id which ordered */
  courseId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Discounted value of course order */
  discountValue: Scalars['Float']['output'];
  /** id of the order details */
  id: Scalars['String']['output'];
  /** Booked number of seat */
  noOfSeat: Scalars['Float']['output'];
  /** order of Order details */
  order: OrderObjectType;
  /** Order id with this order details related */
  orderId: Scalars['String']['output'];
  /** Price of the course which ordered */
  price: Scalars['Float']['output'];
  /** Sub total of course order */
  subTotal?: Maybe<Scalars['Float']['output']>;
  /** Tax on course order */
  tax: Scalars['Float']['output'];
  /** Tax amount on course order */
  taxAmount?: Maybe<Scalars['Float']['output']>;
  /** Total price of the course which ordered */
  totalPrice: Scalars['Float']['output'];
};

export type OrderListObjectType = {
  __typename?: 'OrderListObjectType';
  pagination: OrderPaginationType;
  records: Array<OrderObjectType>;
};

export type OrderObjectType = {
  __typename?: 'OrderObjectType';
  address_id?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** GST No of user who placed order */
  gstNo?: Maybe<Scalars['String']['output']>;
  /** id of order */
  id: Scalars['String']['output'];
  /** Invoice status */
  invoiceStatus?: Maybe<Scalars['Boolean']['output']>;
  /** Details of Order */
  orderDetails: Array<OrderDetailObjectType>;
  /** Payment gateway id */
  paymentGatewayId?: Maybe<Scalars['String']['output']>;
  /** Payment gateway mode */
  paymentGatewayMode?: Maybe<PaymentGatewayModeEnum>;
  paymentLink?: Maybe<Scalars['String']['output']>;
  platform: PlatformTypeEnum;
  registerAddress?: Maybe<Scalars['String']['output']>;
  /** Status of order */
  status: OrderStatusEnum;
  /** Sub total of order */
  subTotal: Scalars['Float']['output'];
  /** Tax on order */
  tax: Scalars['Float']['output'];
  /** Total of order */
  total: Scalars['String']['output'];
  /** Total discount on the order */
  totalDiscount?: Maybe<Scalars['Float']['output']>;
  /** Transaction date and time for this order */
  transactionDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** Transaction id for this order's payment */
  transactionId?: Maybe<Scalars['String']['output']>;
  /** Transaction status for this order */
  transactionStatus?: Maybe<Scalars['String']['output']>;
  /** Type of the order */
  type: OrderTypeEnum;
  /** Billing user of Order */
  user?: Maybe<UserObjectType>;
  /** Billing Address of Order */
  userAddress?: Maybe<UserAddressesObjectType>;
  /** User id who place order */
  userId: Scalars['String']['output'];
};

export type OrderPaginationType = {
  __typename?: 'OrderPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type OrderResolver_GetOrdersFilterInputType = {
  created_at?: InputMaybe<Date_PropertyFilterInputType>;
  gst_no?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  payment_gateway_mode?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<OrderStatusEnum_PropertyFilterInputType>;
  total?: InputMaybe<Number_PropertyFilterInputType>;
  transaction_date_time?: InputMaybe<String_PropertyFilterInputType>;
  transaction_status?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<OrderTypeEnum_PropertyFilterInputType>;
  user_id?: InputMaybe<String_PropertyFilterInputType>;
};

export type OrderResolver_GetOrders_FilterInputType = {
  and?: InputMaybe<Array<OrderResolver_GetOrdersFilterInputType>>;
  created_at?: InputMaybe<Date_PropertyFilterInputType>;
  gst_no?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<OrderResolver_GetOrdersFilterInputType>>;
  payment_gateway_mode?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<OrderStatusEnum_PropertyFilterInputType>;
  total?: InputMaybe<Number_PropertyFilterInputType>;
  transaction_date_time?: InputMaybe<String_PropertyFilterInputType>;
  transaction_status?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<OrderTypeEnum_PropertyFilterInputType>;
  user_id?: InputMaybe<String_PropertyFilterInputType>;
};

/** The order status */
export enum OrderStatusEnum {
  Failed = 'FAILED',
  Paid = 'PAID',
  Pending = 'PENDING'
}

export type OrderStatusEnum_PropertyFilterInputType = {
  /** Status of order */
  eq?: InputMaybe<OrderStatusEnum>;
  /** Status of order */
  in?: InputMaybe<Array<OrderStatusEnum>>;
  /** Status of order */
  neq?: InputMaybe<OrderStatusEnum>;
  /** Status of order */
  notin?: InputMaybe<Array<OrderStatusEnum>>;
  /** Status of order */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The order type */
export enum OrderTypeEnum {
  Free = 'FREE',
  Paid = 'PAID'
}

export type OrderTypeEnum_PropertyFilterInputType = {
  /** Type of the order */
  eq?: InputMaybe<OrderTypeEnum>;
  /** Type of the order */
  in?: InputMaybe<Array<OrderTypeEnum>>;
  /** Type of the order */
  neq?: InputMaybe<OrderTypeEnum>;
  /** Type of the order */
  notin?: InputMaybe<Array<OrderTypeEnum>>;
  /** Type of the order */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The enum for wich service Otp will be send */
export enum OtpVia {
  Email = 'EMAIL',
  Sms = 'SMS',
  Whatsapp = 'WHATSAPP'
}

export type PaginationType = {
  __typename?: 'PaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type PaginatorArgs = {
  page?: Scalars['Int']['input'];
  per_page?: Scalars['Int']['input'];
};

export type PaymentGatewayListObjectType = {
  __typename?: 'PaymentGatewayListObjectType';
  pagination: PaymentGatewayPaginationType;
  records: Array<PaymentGatewayObjectType>;
};

/** The payment gateway modes */
export enum PaymentGatewayModeEnum {
  Live = 'LIVE',
  Test = 'TEST'
}

export type PaymentGatewayObjectType = {
  __typename?: 'PaymentGatewayObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** Payment gateway id */
  id: Scalars['String']['output'];
  /** Mode of payment gateway */
  mode: PaymentGatewayModeEnum;
  /** Name of the Payment Gateway */
  name: Scalars['String']['output'];
  /** Private key of the payment gateway */
  privateKey: Scalars['String']['output'];
  /** Sandbox private key of the payment gateway */
  sandboxPrivateKey: Scalars['String']['output'];
  /** Sandbox secret key of the payment gateway */
  sandboxSecretKey: Scalars['String']['output'];
  /** Secret key of the payment gateway */
  secretKey: Scalars['String']['output'];
  /** slug for the payment gateway */
  slug: Scalars['String']['output'];
  /** Status of payment gateway */
  status: PaymentGatewayStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type PaymentGatewayPaginationType = {
  __typename?: 'PaymentGatewayPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

/** The payment gateway status */
export enum PaymentGatewayStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type PaymentGatewaysResolver_GetPaymentGatewaysFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
};

export type PaymentGatewaysResolver_GetPaymentGateways_FilterInputType = {
  and?: InputMaybe<Array<PaymentGatewaysResolver_GetPaymentGatewaysFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<PaymentGatewaysResolver_GetPaymentGatewaysFilterInputType>>;
};

/** The payment mode */
export enum PaymentModeEnum {
  Cash = 'CASH',
  Online = 'ONLINE'
}

export type PermissionObjectType = {
  __typename?: 'PermissionObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** id of the permission */
  id: Scalars['String']['output'];
  /** name of the permission */
  name: Scalars['String']['output'];
  /** slug of the permission */
  slug: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PlatformReferralListObjectType = {
  __typename?: 'PlatformReferralListObjectType';
  pagination: PlatformReferralPaginationType;
  records: Array<PlatformReferralObjectType>;
};

export type PlatformReferralObjectType = {
  __typename?: 'PlatformReferralObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** id of platform referral record */
  id: Scalars['String']['output'];
  /** flag to determine default platform referral to show users */
  isDefault: Scalars['Boolean']['output'];
  /** name of platform referral record */
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PlatformReferralPaginationType = {
  __typename?: 'PlatformReferralPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type PlatformReferralsResolver_GetPlatformReferralsFilterInputType = {
  name?: InputMaybe<String_PropertyFilterInputType>;
};

export type PlatformReferralsResolver_GetPlatformReferrals_FilterInputType = {
  and?: InputMaybe<Array<PlatformReferralsResolver_GetPlatformReferralsFilterInputType>>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<PlatformReferralsResolver_GetPlatformReferralsFilterInputType>>;
};

/** The type of platform */
export enum PlatformTypeEnum {
  App = 'APP',
  Web = 'WEB'
}

export type PublicCourseMentor = {
  __typename?: 'PublicCourseMentor';
  /** Course mentor */
  mentor: UserPublicObjectType;
};

export type PublicJobApplicationObjectType = {
  __typename?: 'PublicJobApplicationObjectType';
  /** Job applicant message */
  coverLatter: Scalars['String']['output'];
  /** Email of the job applicant */
  email: Scalars['String']['output'];
  /** Job applicant's experience of field */
  experience: Scalars['String']['output'];
  /** id of job application record */
  id: Scalars['String']['output'];
  /** Job Vacancy id for which job application created */
  jobVacancyId?: Maybe<Scalars['String']['output']>;
  /** Mobile number of job applicant */
  mobileNo: Scalars['String']['output'];
  /** Name of the job applicant */
  name: Scalars['String']['output'];
  /** Position for which job applicant apply */
  position: Scalars['String']['output'];
  /** Resume url of job applicant */
  resumeUrl: Scalars['String']['output'];
  /** Status of job application */
  status: JobApplicationStatusEnum;
  /** Std code of job applicant mobile number */
  stdCodeOfMobileNo: Scalars['String']['output'];
};

export type PublicJobVacancyListObjectType = {
  __typename?: 'PublicJobVacancyListObjectType';
  pagination: JobVacancyPagination;
  records: Array<PublicJobVacancyObjectType>;
};

export type PublicJobVacancyObjectType = {
  __typename?: 'PublicJobVacancyObjectType';
  /** Job vacancy details ed information */
  content: Scalars['String']['output'];
  /** id of job vacancy record */
  id: Scalars['String']['output'];
  /** Location of the job */
  jobLocation: Scalars['String']['output'];
  /** Mode of the job in contrast of working place */
  jobMode: JobVacancyModeEnum;
  /** Type of job in contrast of time */
  jobType: JobVacancyTypeEnum;
  /** Maximum required experience for job */
  maxRequiredExperience: Scalars['Float']['output'];
  /** Minimum required experience for job */
  minRequiredExperience: Scalars['Float']['output'];
  /** Numbers of opening for the job */
  noOfOpening: Scalars['Int']['output'];
  /** Position for which job vacancy is open */
  position: Scalars['String']['output'];
  /** Status of the job vacancy */
  status: JobVacancyStatusEnum;
};

export type Query = {
  __typename?: 'Query';
  Decodevideo: DecodeVideoObjectType;
  addStatesCitiesData: Array<CountryObjectType>;
  canCreateUser: Scalars['Boolean']['output'];
  checkQuizSubmitStatus: Scalars['Boolean']['output'];
  checkStudentCertificateEligibility: UserAllQuizResultObjectType;
  getBannerById: BannerObjectType;
  getBanners: BannerListObjectType;
  getBlogById: BlogObjectType;
  getBlogCategoryById: BlogCategoryObjectType;
  getBlogCategoryList: BlogCategoryListObjectType;
  getBlogList: BlogListObjectType;
  getBlogTagById: BlogTagObjectType;
  getBlogTagList: BlogTagListObjectType;
  getBlogs: Array<ShopifyBlogObjectType>;
  getCart: Array<CartObjectType>;
  getCms: CmsListObjectType;
  getCmsById: CmsObjectType;
  getCmsDetailBySlug: CmsPublicObjectType;
  getContactUsList: ContactUsListObjectType;
  getCountriesWithCode: Array<CountryObjectType>;
  getCourseById: CourseObjectType;
  getCourseCategories: CourseCategoryListObjectType;
  getCourseCategoryByID: CourseCategoryDto;
  getCourseEnrollmentStatus: Scalars['Boolean']['output'];
  getCourseLanguageById: CourseLanguageObjectType;
  getCourseLanguagesList: CourseLanguageListObjectType;
  getCourseLessonById: CourseLessonObjectType;
  getCourseLessons: CourseLessonListObjectType;
  getCourseLevelById: CourseLevelObjectType;
  getCourseLevelList: CourseLevelListObjectType;
  getCourseModeByID: CourseModeObjectType;
  getCourseModes: CourseModeListObjectType;
  getCourseParentCategories: Array<CourseCategoryDto>;
  getCourseQuizzes: Array<QuizObjectType>;
  getCourseRating: CourseRatingListObjectType;
  getCourseRatingById: CourseRatingsObjectType;
  getCourseSectionById: CourseSectionObjectType;
  getCourseSections: CourseSectionListObjectType;
  getCourseSkillById: CourseSkillObjectType;
  getCourseSkills: CourseSkillListObjectType;
  getCourseTypeById: CourseTypeObjectType;
  getCourseTypesList: CourseTypeListObjectType;
  getCourses: CourseListObjectType;
  getCoursesCreatedByInstructor: CourseListObjectType;
  getEnrolledCoursesByStudentId: Array<CourseEnrollmentObjectType>;
  getFaqById: FaqObjectType;
  getFaqs: FaqListObjectType;
  getGalleries: GalleryListObjectType;
  getGalleryById: GalleryObjectType;
  getGalleryTagById: GalleryTagObjectType;
  getGalleryTags: GalleryTagListObjectType;
  getGoalById: GoalObjectType;
  getGoalList: GoalsListObjectType;
  getHomeBanners: Array<BannerPublicObjectType>;
  getInstructorById: UserObjectType;
  getInstructorCourseLessonById: CourseLessonObjectType;
  getInstructorCourseLessons: CourseLessonListObjectType;
  getInstructorCourseSectionById: CourseSectionObjectType;
  getInstructorCourseSections: CourseSectionListObjectType;
  getInstructorLessonsBySectionId: Array<CourseLessonObjectType>;
  getInstructorList: UserListObjectType;
  getInstructorSectionByCourseId: Array<CourseSectionObjectType>;
  getJobApplicationById: JobApplicationObjectType;
  getJobApplicationsList: JobApplicationListObjectType;
  getJobVacancyById: JobVacancyObjectType;
  getJobVacancyList: JobVacancyListObjectType;
  getLessonQuizzes: Array<QuizObjectType>;
  getLessonsBySectionId: Array<CourseLessonObjectType>;
  getMe: UserObjectType;
  getMyProfile: UserObjectType;
  getNotificationsById: NotificationsObjectType;
  getNotificationsList: NotificationListObjectType;
  getOccupations: OccupationListObjectType;
  getOrders: OrderListObjectType;
  getPaymentGatewayById: PaymentGatewayObjectType;
  getPaymentGateways: PaymentGatewayListObjectType;
  getPermissions: Array<PermissionObjectType>;
  getPlatformReferrals: PlatformReferralListObjectType;
  getProgresss: CourseProgressObjectType;
  getPublicCourseById: CoursePublicObjectType;
  getPublicCourseBySlug: CoursePublicObjectType;
  getPublicCourseCategories: CourseCategoryListObjectType;
  getPublicCourseLanguagesList: CourseLanguagePublicListObjectType;
  getPublicCourseLevelList: CourseLevelPublicListObjectType;
  getPublicCourseModes: CourseModePublicListObjectType;
  getPublicCourseTypes: CourseTypePublicListObjectType;
  getPublicCourses: CoursePublicListObjectType;
  getPublicFaqs: Array<FaqPublicObjectType>;
  getPublicFaqsList: FaqListObjectType;
  getPublicInstructor: UserPublicObjectType;
  getPublicInstructors: UserPublicListObjectType;
  getPublicJobVacancyById: PublicJobVacancyObjectType;
  getPublicJobVacancyList: PublicJobVacancyListObjectType;
  getPublicMentorDetailsById: UserPublicObjectType;
  getPublicNewArrivalCourses: CoursePublicListObjectType;
  getPublicRecommendedCourses: CoursePublicListObjectType;
  getPublicTestimonials: TestimonialPublicListObjectType;
  getPublicTopSellingCourses: CoursePublicListObjectType;
  getPublicUpcomingCourses: CoursePublicListObjectType;
  getQuizById: QuizObjectType;
  getQuizList: QuizListObjectType;
  getQuizResult: UserQuizResultForGetObjectType;
  getQuizResultByQuizId: Array<UserQuizResultObjectType>;
  getQuizResultByResultId: Array<UserQuizResultObjectType>;
  getQuizResultByStudentId: Array<UserQuizResultObjectType>;
  getQuizResultList: UserQuizListObjectType;
  getQuizzesByCourseId: QuizListObjectType;
  getRole: RoleObjectType;
  getRoles: Array<RoleObjectType>;
  getSectionByCourseId: Array<CourseSectionObjectType>;
  getSettings: Array<SettingObjectType>;
  getStates: Array<StateObjectType>;
  getStudentById: UserObjectType;
  getStudentCourses: CourseEnrollmentListObjectType;
  getStudentList: UserListObjectType;
  getStudentNotificationList: NotificationListObjectType;
  getStudentOrderHistory: OrderListObjectType;
  getStudentQuizById: QuizObjectType;
  getStudentQuizResultList: UserQuizListObjectType;
  getStudentQuizResultStatusList: UserQuizResultStatusListObjectType;
  getTestimonialById: TestimonialObjectType;
  getTestimonials: TestimonialListObjectType;
  getUserById: UserObjectType;
  getUserByResetPasswordToken: UserResetPasswordOutput;
  getUsers: UserListObjectType;
  getUsps: Array<BannerPublicObjectType>;
  getWebinarZoomMeetingDetails: GetZoomMeetingResponse;
  getWishlists: WishlistListObjectType;
  getZoomMeetingDetails: GetZoomMeetingResponse;
  instructorGetCourseById: CourseObjectType;
  instructorGetCourses: CourseListObjectType;
  studentNotificationList: NotificationListObjectType;
  update: CourseSettingsObjectType;
};


export type QueryDecodevideoArgs = {
  decodevideoInput: DecodevideoInput;
};


export type QueryCheckQuizSubmitStatusArgs = {
  courseId: Scalars['String']['input'];
  lessonId: Scalars['String']['input'];
  quizId: Scalars['String']['input'];
  sectionId: Scalars['String']['input'];
};


export type QueryCheckStudentCertificateEligibilityArgs = {
  course_id: Scalars['String']['input'];
};


export type QueryGetBannerByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetBannersArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<BannerResolver_GetBanners_FilterInputType>;
};


export type QueryGetBlogByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetBlogCategoryByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetBlogCategoryListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<BlogCategoryResolver_GetBlogCategoryList_FilterInputType>;
};


export type QueryGetBlogListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<BlogResolver_GetBlogList_FilterInputType>;
};


export type QueryGetBlogTagByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetBlogTagListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<BlogTagResolver_GetBlogTagList_FilterInputType>;
};


export type QueryGetCmsArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CmsResolver_GetCms_FilterInputType>;
};


export type QueryGetCmsByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCmsDetailBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryGetContactUsListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<ContactUsResolver_GetContactUsList_FilterInputType>;
};


export type QueryGetCourseByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCourseCategoriesArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CourseCategoriesResolver_GetCourseCategories_FilterInputType>;
};


export type QueryGetCourseCategoryByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCourseEnrollmentStatusArgs = {
  course_id: Scalars['String']['input'];
};


export type QueryGetCourseLanguageByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCourseLanguagesListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CourseLanguagesResolver_GetCourseLanguagesList_FilterInputType>;
};


export type QueryGetCourseLessonByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCourseLessonsArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CoursesLessonResolver_GetCourseLessons_FilterInputType>;
};


export type QueryGetCourseLevelByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCourseLevelListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CourseLevelsResolver_GetCourseLevelList_FilterInputType>;
};


export type QueryGetCourseModeByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCourseModesArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CourseModeResolver_GetCourseModes_FilterInputType>;
};


export type QueryGetCourseRatingArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CourseRatingsResolver_GetCourseRating_FilterInputType>;
};


export type QueryGetCourseRatingByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCourseSectionByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCourseSectionsArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CoursesSectionResolver_GetCourseSections_FilterInputType>;
};


export type QueryGetCourseSkillByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCourseSkillsArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CourseSkillsResolver_GetCourseSkills_FilterInputType>;
};


export type QueryGetCourseTypeByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCourseTypesListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CourseTypeResolver_GetCourseTypesList_FilterInputType>;
};


export type QueryGetCoursesArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CoursesResolver_GetCourses_FilterInputType>;
};


export type QueryGetCoursesCreatedByInstructorArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<InstructorCoursesResolver_GetCoursesCreatedByInstructor_FilterInputType>;
};


export type QueryGetEnrolledCoursesByStudentIdArgs = {
  studentId: Scalars['String']['input'];
};


export type QueryGetFaqByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetFaqsArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<FaqResolver_GetFaqs_FilterInputType>;
};


export type QueryGetGalleriesArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<GalleryResolver_GetGalleries_FilterInputType>;
};


export type QueryGetGalleryByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetGalleryTagByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetGalleryTagsArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<GalleryTagResolver_GetGalleryTags_FilterInputType>;
};


export type QueryGetGoalByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetGoalListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<GoalsResolver_GetGoalList_FilterInputType>;
};


export type QueryGetInstructorByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetInstructorCourseLessonByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetInstructorCourseLessonsArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<InstructorCoursesLessonResolver_GetInstructorCourseLessons_FilterInputType>;
};


export type QueryGetInstructorCourseSectionByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetInstructorCourseSectionsArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<InstructorCoursesSectionResolver_GetInstructorCourseSections_FilterInputType>;
};


export type QueryGetInstructorLessonsBySectionIdArgs = {
  sectionId: Scalars['String']['input'];
};


export type QueryGetInstructorListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<InstructorResolver_GetInstructorList_FilterInputType>;
};


export type QueryGetInstructorSectionByCourseIdArgs = {
  courseId: Scalars['String']['input'];
};


export type QueryGetJobApplicationByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetJobApplicationsListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<JobApplicationResolver_GetJobApplicationsList_FilterInputType>;
};


export type QueryGetJobVacancyByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetJobVacancyListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<JobVacancyResolver_GetJobVacancyList_FilterInputType>;
};


export type QueryGetLessonsBySectionIdArgs = {
  sectionId: Scalars['String']['input'];
};


export type QueryGetNotificationsByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetNotificationsListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<NotificationsResolver_GetNotificationsList_FilterInputType>;
};


export type QueryGetOccupationsArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<OccupationResolver_GetOccupations_FilterInputType>;
};


export type QueryGetOrdersArgs = {
  isExcel?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<OrderResolver_GetOrders_FilterInputType>;
};


export type QueryGetPaymentGatewayByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetPaymentGatewaysArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<PaymentGatewaysResolver_GetPaymentGateways_FilterInputType>;
};


export type QueryGetPlatformReferralsArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<PlatformReferralsResolver_GetPlatformReferrals_FilterInputType>;
};


export type QueryGetPublicCourseByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetPublicCourseBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryGetPublicCourseCategoriesArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CourseCategoriesResolver_GetPublicCourseCategories_FilterInputType>;
};


export type QueryGetPublicCourseLanguagesListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CourseLanguagesResolver_GetPublicCourseLanguagesList_FilterInputType>;
};


export type QueryGetPublicCourseLevelListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CourseLevelsResolver_GetPublicCourseLevelList_FilterInputType>;
};


export type QueryGetPublicCourseModesArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CourseModeResolver_GetPublicCourseModes_FilterInputType>;
};


export type QueryGetPublicCourseTypesArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CourseTypeResolver_GetPublicCourseTypes_FilterInputType>;
};


export type QueryGetPublicCoursesArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CoursesPublicResolver_GetPublicCourses_FilterInputType>;
};


export type QueryGetPublicFaqsListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<FaqResolver_GetPublicFaqsList_FilterInputType>;
};


export type QueryGetPublicInstructorArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetPublicInstructorsArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<UsersResolver_GetPublicInstructors_FilterInputType>;
};


export type QueryGetPublicJobVacancyByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetPublicJobVacancyListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<JobVacancyResolver_GetPublicJobVacancyList_FilterInputType>;
};


export type QueryGetPublicMentorDetailsByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetPublicNewArrivalCoursesArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CoursesPublicResolver_GetPublicNewArrivalCourses_FilterInputType>;
};


export type QueryGetPublicRecommendedCoursesArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CoursesPublicResolver_GetPublicRecommendedCourses_FilterInputType>;
};


export type QueryGetPublicTestimonialsArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<TestimonialResolver_GetPublicTestimonials_FilterInputType>;
};


export type QueryGetPublicTopSellingCoursesArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CoursesPublicResolver_GetPublicTopSellingCourses_FilterInputType>;
};


export type QueryGetPublicUpcomingCoursesArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<CoursesPublicResolver_GetPublicUpcomingCourses_FilterInputType>;
};


export type QueryGetQuizByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetQuizListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<QuizResolver_GetQuizList_FilterInputType>;
};


export type QueryGetQuizResultArgs = {
  courseId: Scalars['String']['input'];
  lessonId: Scalars['String']['input'];
  quizId: Scalars['String']['input'];
  sectionId: Scalars['String']['input'];
};


export type QueryGetQuizResultByQuizIdArgs = {
  course_id: Scalars['String']['input'];
  quiz_id: Scalars['String']['input'];
};


export type QueryGetQuizResultByResultIdArgs = {
  result_id: Scalars['String']['input'];
};


export type QueryGetQuizResultByStudentIdArgs = {
  course_id: Scalars['String']['input'];
  quiz_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type QueryGetQuizResultListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<QuizResolver_GetQuizResultList_FilterInputType>;
};


export type QueryGetQuizzesByCourseIdArgs = {
  course_id: Scalars['String']['input'];
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<QuizResolver_GetQuizzesByCourseId_FilterInputType>;
};


export type QueryGetRoleArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetSectionByCourseIdArgs = {
  courseId: Scalars['String']['input'];
};


export type QueryGetStudentByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetStudentCoursesArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<StudentProfileResolver_GetStudentCourses_FilterInputType>;
};


export type QueryGetStudentListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<StudentsResolver_GetStudentList_FilterInputType>;
};


export type QueryGetStudentNotificationListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<NotificationsResolver_GetStudentNotificationList_FilterInputType>;
};


export type QueryGetStudentOrderHistoryArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<StudentProfileResolver_GetStudentOrderHistory_FilterInputType>;
};


export type QueryGetStudentQuizByIdArgs = {
  course_id: Scalars['String']['input'];
  lesson_id: Scalars['String']['input'];
  quiz_id: Scalars['String']['input'];
  section_id: Scalars['String']['input'];
};


export type QueryGetStudentQuizResultListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<StudentProfileResolver_GetStudentQuizResultList_FilterInputType>;
};


export type QueryGetStudentQuizResultStatusListArgs = {
  courseId: Scalars['String']['input'];
};


export type QueryGetTestimonialByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetTestimonialsArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<TestimonialResolver_GetTestimonials_FilterInputType>;
};


export type QueryGetUserByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetUserByResetPasswordTokenArgs = {
  userResetPasswordInput: UserResetPasswordInput;
};


export type QueryGetUsersArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<UsersResolver_GetUsers_FilterInputType>;
};


export type QueryGetWebinarZoomMeetingDetailsArgs = {
  courseID: Scalars['String']['input'];
};


export type QueryGetWishlistsArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
};


export type QueryGetZoomMeetingDetailsArgs = {
  lessonID: Scalars['String']['input'];
};


export type QueryInstructorGetCourseByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryInstructorGetCoursesArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<InstructorCoursesResolver_InstructorGetCourses_FilterInputType>;
};


export type QueryStudentNotificationListArgs = {
  paginate?: InputMaybe<PaginatorArgs>;
  where?: InputMaybe<NotificationsResolver_StudentNotificationList_FilterInputType>;
};

export type QuestionAnswerInputType = {
  /** answer of the QuestionAnswer */
  answer?: InputMaybe<Array<Scalars['String']['input']>>;
  /** correctAnswer of the QuestionAnswer */
  correctAnswer?: InputMaybe<Scalars['String']['input']>;
  /** correctAnswer of the QuestionAnswer */
  mark?: InputMaybe<Scalars['String']['input']>;
  /** question id of the QuestionAnswer */
  questionId: Scalars['String']['input'];
};

export type QuizListObjectType = {
  __typename?: 'QuizListObjectType';
  pagination: QuizPaginationObjectType;
  records: Array<QuizObjectType>;
};

export type QuizObjectType = {
  __typename?: 'QuizObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** duration of Quiz */
  duration?: Maybe<Scalars['Float']['output']>;
  /** Needed mark to earn certificate */
  eligibleMarksForCertificate: Scalars['Float']['output'];
  /** flag to determine whether the quiz has time limit */
  hasTimeLimit: Scalars['Boolean']['output'];
  /** id of the quiz */
  id: Scalars['String']['output'];
  questions?: Maybe<Array<QuizQuestionObjectType>>;
  reattemptLimit?: Maybe<Scalars['Float']['output']>;
  /** Status of the quiz */
  status: QuizStatusEnum;
  /** Title of the quiz */
  title: Scalars['String']['output'];
  /** Total marks of the quiz */
  totalMarks: Scalars['Float']['output'];
  /** Type of the quiz */
  type: QuizTypesEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type QuizPaginationObjectType = {
  __typename?: 'QuizPaginationObjectType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type QuizQuestionInput = {
  /** Correct answers of the question of quiz */
  answers: Array<Scalars['String']['input']>;
  /** Duration or time in seconds to completed question of quiz */
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** flag to determine question has multiple answers */
  hasMultipleAnswers: Scalars['Boolean']['input'];
  /** mark of the question of quiz */
  mark: Scalars['Float']['input'];
  /** Options for the question of quiz */
  options: Array<QuizQuestionOptions>;
  /** question of quiz question */
  question: Scalars['String']['input'];
  /** question's image  of quiz question */
  questionImages?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Question number or question order number in quiz */
  questionOrder: Scalars['Float']['input'];
};

export type QuizQuestionObjectType = {
  __typename?: 'QuizQuestionObjectType';
  /** Correct answers of the question of quiz */
  answers?: Maybe<Array<Scalars['String']['output']>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** Duration or time in seconds to completed question of quiz */
  duration?: Maybe<Scalars['Float']['output']>;
  /** flag to determine question has multiple answers */
  hasMultipleAnswers: Scalars['Boolean']['output'];
  /** Id of the quiz question */
  id: Scalars['String']['output'];
  /** mark of the question of quiz */
  mark: Scalars['Float']['output'];
  /** Options for the question of quiz */
  options: Array<QuizQuestionOptionsObjectType>;
  /** question of quiz question */
  question: Scalars['String']['output'];
  /** images related to the quiz question */
  questionImages?: Maybe<Array<Scalars['String']['output']>>;
  /** Question number or question order number in quiz */
  questionOrder: Scalars['Float']['output'];
  /** quiz Id of quiz with question is related */
  quizId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type QuizQuestionOptions = {
  optionImage?: InputMaybe<Scalars['String']['input']>;
  optionText?: InputMaybe<Scalars['String']['input']>;
};

export type QuizQuestionOptionsObjectType = {
  __typename?: 'QuizQuestionOptionsObjectType';
  optionImage?: Maybe<Scalars['String']['output']>;
  optionText?: Maybe<Scalars['String']['output']>;
};

export type QuizResolver_GetQuizListFilterInputType = {
  status?: InputMaybe<QuizStatusEnum_PropertyFilterInputType>;
  title?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<QuizTypesEnum_PropertyFilterInputType>;
};

export type QuizResolver_GetQuizList_FilterInputType = {
  and?: InputMaybe<Array<QuizResolver_GetQuizListFilterInputType>>;
  or?: InputMaybe<Array<QuizResolver_GetQuizListFilterInputType>>;
  status?: InputMaybe<QuizStatusEnum_PropertyFilterInputType>;
  title?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<QuizTypesEnum_PropertyFilterInputType>;
};

export type QuizResolver_GetQuizResultListFilterInputType = {
  status?: InputMaybe<QuizStatusEnum_PropertyFilterInputType>;
  title?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<QuizTypesEnum_PropertyFilterInputType>;
  user_id?: InputMaybe<String_PropertyFilterInputType>;
};

export type QuizResolver_GetQuizResultList_FilterInputType = {
  and?: InputMaybe<Array<QuizResolver_GetQuizResultListFilterInputType>>;
  or?: InputMaybe<Array<QuizResolver_GetQuizResultListFilterInputType>>;
  status?: InputMaybe<QuizStatusEnum_PropertyFilterInputType>;
  title?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<QuizTypesEnum_PropertyFilterInputType>;
  user_id?: InputMaybe<String_PropertyFilterInputType>;
};

export type QuizResolver_GetQuizzesByCourseIdFilterInputType = {
  status?: InputMaybe<QuizStatusEnum_PropertyFilterInputType>;
  title?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<QuizTypesEnum_PropertyFilterInputType>;
};

export type QuizResolver_GetQuizzesByCourseId_FilterInputType = {
  and?: InputMaybe<Array<QuizResolver_GetQuizzesByCourseIdFilterInputType>>;
  or?: InputMaybe<Array<QuizResolver_GetQuizzesByCourseIdFilterInputType>>;
  status?: InputMaybe<QuizStatusEnum_PropertyFilterInputType>;
  title?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<QuizTypesEnum_PropertyFilterInputType>;
};

/** Enum for quiz's result status */
export enum QuizResultStatusEnum {
  Failed = 'FAILED',
  Pass = 'PASS',
  Pending = 'PENDING'
}

/** Enum for quiz's status */
export enum QuizStatusEnum {
  Archive = 'ARCHIVE',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED',
  ReadyForPublish = 'READY_FOR_PUBLISH',
  ReadyForReview = 'READY_FOR_REVIEW'
}

export type QuizStatusEnum_PropertyFilterInputType = {
  /** status of the quiz */
  eq?: InputMaybe<QuizStatusEnum>;
  /** status of the quiz */
  in?: InputMaybe<Array<QuizStatusEnum>>;
  /** status of the quiz */
  neq?: InputMaybe<QuizStatusEnum>;
  /** status of the quiz */
  notin?: InputMaybe<Array<QuizStatusEnum>>;
  /** status of the quiz */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Enum for quiz's types */
export enum QuizTypesEnum {
  CourseQuiz = 'COURSE_QUIZ',
  GeneralQuiz = 'GENERAL_QUIZ',
  LessonQuiz = 'LESSON_QUIZ'
}

export type QuizTypesEnum_PropertyFilterInputType = {
  /** type of the quiz */
  eq?: InputMaybe<QuizTypesEnum>;
  /** type of the quiz */
  in?: InputMaybe<Array<QuizTypesEnum>>;
  /** type of the quiz */
  neq?: InputMaybe<QuizTypesEnum>;
  /** type of the quiz */
  notin?: InputMaybe<Array<QuizTypesEnum>>;
  /** type of the quiz */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

/** for rating status */
export enum RatingStatusEnum {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type RatingStatusEnum_PropertyFilterInputType = {
  eq?: InputMaybe<RatingStatusEnum>;
  in?: InputMaybe<Array<RatingStatusEnum>>;
  neq?: InputMaybe<RatingStatusEnum>;
  notin?: InputMaybe<Array<RatingStatusEnum>>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RazorpayResponsNotify = {
  __typename?: 'RazorpayResponsNotify';
  email: Scalars['Boolean']['output'];
  sms: Scalars['Boolean']['output'];
  whatsapp: Scalars['Boolean']['output'];
};

export type RazorpayResponsPayments = {
  __typename?: 'RazorpayResponsPayments';
  amount: Scalars['Float']['output'];
  created_at: Scalars['Float']['output'];
  method: Scalars['String']['output'];
  payment_id: Scalars['String']['output'];
  plink_id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updated_at: Scalars['Float']['output'];
};

export type RazorpayResponsReminder = {
  __typename?: 'RazorpayResponsReminder';
  status?: Maybe<Scalars['String']['output']>;
};

export type RazorpayResponseCustomer = {
  __typename?: 'RazorpayResponseCustomer';
  contact?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RazorpayResponseNotes = {
  __typename?: 'RazorpayResponseNotes';
  policy_name: Scalars['String']['output'];
};

export type RazorpayResponseObject = {
  __typename?: 'RazorpayResponseObject';
  /** Razorpay response */
  accept_partial: Scalars['Boolean']['output'];
  /** Razorpay response */
  amount: Scalars['Float']['output'];
  /** Razorpay response */
  amount_paid: Scalars['Float']['output'];
  /** Razorpay response */
  callback_method: Scalars['String']['output'];
  /** Razorpay response */
  callback_url: Scalars['String']['output'];
  /** Razorpay response */
  cancelled_at: Scalars['Float']['output'];
  /** Razorpay response */
  created_at: Scalars['Float']['output'];
  /** Razorpay response */
  currency: Scalars['String']['output'];
  /** Razorpay response */
  customer: RazorpayResponseCustomer;
  /** Razorpay response */
  description: Scalars['String']['output'];
  /** Razorpay response */
  expire_by: Scalars['Float']['output'];
  /** Razorpay response */
  expired_at: Scalars['Float']['output'];
  /** Razorpay response */
  first_min_partial_amount: Scalars['Float']['output'];
  /** Razorpay response */
  id: Scalars['String']['output'];
  /** Razorpay response */
  notes: RazorpayResponseNotes;
  /** Razorpay response */
  notify: RazorpayResponsNotify;
  /** Razorpay response */
  payments?: Maybe<RazorpayResponsPayments>;
  /** Razorpay response */
  reference_id: Scalars['String']['output'];
  /** Razorpay response */
  reminder_enable: Scalars['Boolean']['output'];
  /** Razorpay response */
  reminders: RazorpayResponsReminder;
  /** Razorpay response */
  short_url: Scalars['String']['output'];
  /** Razorpay response */
  status: Scalars['String']['output'];
  /** Razorpay response */
  updated_at: Scalars['Float']['output'];
  /** Razorpay response */
  upi_link: Scalars['Boolean']['output'];
  /** Razorpay response */
  user_id: Scalars['String']['output'];
  /** Razorpay response */
  whatsapp_link: Scalars['Boolean']['output'];
};

export type RazorpayResponseObjectNew = {
  __typename?: 'RazorpayResponseObjectNew';
  /** Razorpay response */
  accept_partial?: Maybe<Scalars['Boolean']['output']>;
  /** Razorpay response */
  amount?: Maybe<Scalars['Float']['output']>;
  /** Razorpay response */
  amount_paid?: Maybe<Scalars['Float']['output']>;
  /** Razorpay response */
  callback_method?: Maybe<Scalars['String']['output']>;
  /** Razorpay response */
  callback_url?: Maybe<Scalars['String']['output']>;
  /** Razorpay response */
  cancelled_at?: Maybe<Scalars['Float']['output']>;
  /** Razorpay response */
  created_at?: Maybe<Scalars['Float']['output']>;
  /** Razorpay response */
  currency?: Maybe<Scalars['String']['output']>;
  /** Razorpay response */
  customer?: Maybe<RazorpayResponseCustomer>;
  /** Razorpay response */
  description?: Maybe<Scalars['String']['output']>;
  /** User email verification status */
  email_verified?: Maybe<Scalars['Boolean']['output']>;
  /** Razorpay response */
  expire_by?: Maybe<Scalars['Float']['output']>;
  /** Razorpay response */
  expired_at?: Maybe<Scalars['Float']['output']>;
  /** Razorpay response */
  first_min_partial_amount?: Maybe<Scalars['Float']['output']>;
  /** Razorpay response */
  id?: Maybe<Scalars['String']['output']>;
  /** Razorpay response */
  notes?: Maybe<RazorpayResponseNotes>;
  /** Razorpay response */
  notify?: Maybe<RazorpayResponsNotify>;
  /** Order response */
  order?: Maybe<OrderObjectType>;
  /** Razorpay response */
  payments?: Maybe<RazorpayResponsPayments>;
  /** Razorpay response */
  reference_id?: Maybe<Scalars['String']['output']>;
  /** Razorpay response */
  reminder_enable?: Maybe<Scalars['Boolean']['output']>;
  /** Razorpay response */
  reminders?: Maybe<RazorpayResponsReminder>;
  /** Razorpay response */
  short_url?: Maybe<Scalars['String']['output']>;
  /** Razorpay response */
  status?: Maybe<Scalars['String']['output']>;
  /** Razorpay response */
  updated_at?: Maybe<Scalars['Float']['output']>;
  /** Razorpay response */
  upi_link?: Maybe<Scalars['Boolean']['output']>;
  /** Razorpay response */
  user_id?: Maybe<Scalars['String']['output']>;
  /** Razorpay response */
  whatsapp_link?: Maybe<Scalars['Boolean']['output']>;
};

export type ReferenceUnion = CourseObjectType | UserObjectType;

export type RegistrationUserOutput = {
  __typename?: 'RegistrationUserOutput';
  /** Register User Access Token */
  access_token?: Maybe<Scalars['String']['output']>;
  /** Response Status */
  is_success?: Maybe<Scalars['Boolean']['output']>;
  /** Response Message */
  message?: Maybe<Scalars['String']['output']>;
  /** User */
  user?: Maybe<UserObjectType>;
};

export type RejectJobApplicationInput = {
  /** id of job application record */
  id: Scalars['String']['input'];
  /** Reason behind job application rejection */
  note: Scalars['String']['input'];
};

export type ResetForgotPasswordInput = {
  /** confirmNewPassword of the user */
  confirmNewPassword: Scalars['String']['input'];
  /** newPassword of the user */
  newPassword: Scalars['String']['input'];
  /** newPassword of the user */
  resetPasswordKey: Scalars['String']['input'];
};

export type RoleObjectType = {
  __typename?: 'RoleObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** id of the role */
  id: Scalars['String']['output'];
  /** name of the role */
  name: Scalars['String']['output'];
  permissions?: Maybe<Array<PermissionObjectType>>;
  /** slug of the role */
  slug: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SettingObjectType = {
  __typename?: 'SettingObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** id of setting */
  id: Scalars['String']['output'];
  /** Setting key */
  settingKey: Scalars['String']['output'];
  /** Setting value */
  settingValue: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type ShopifyBlogObjectType = {
  __typename?: 'ShopifyBlogObjectType';
  /** Url of the blog */
  blogUrl: Scalars['String']['output'];
  /** description of the blog */
  description: Scalars['String']['output'];
  /** handle of the blog */
  handle: Scalars['String']['output'];
  /** id of the blog */
  id: Scalars['String']['output'];
  /** Featured image of the blog */
  imageUrl: Scalars['String']['output'];
  /** Short description of the blog */
  summaryHtml: Scalars['String']['output'];
  tags: Scalars['String']['output'];
  /** Title of the blog */
  title: Scalars['String']['output'];
};

export type StateObjectType = {
  __typename?: 'StateObjectType';
  cities?: Maybe<Array<CityObjectType>>;
  /** id of the country */
  countryId: Scalars['String']['output'];
  /** id of the state */
  id: Scalars['String']['output'];
  /** code of the state */
  stateCode: Scalars['String']['output'];
  /** State name */
  stateName: Scalars['String']['output'];
};

export type String_PropertyFilterInputType = {
  between?: InputMaybe<Array<Scalars['String']['input']>>;
  eq?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  like?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notbetween?: InputMaybe<Array<Scalars['String']['input']>>;
  notin?: InputMaybe<Array<Scalars['String']['input']>>;
  notlike?: InputMaybe<Scalars['String']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StudentProfileResolver_GetStudentCoursesFilterInputType = {
  course_id?: InputMaybe<String_PropertyFilterInputType>;
  created_at?: InputMaybe<Date_PropertyFilterInputType>;
  end_datetime?: InputMaybe<Date_PropertyFilterInputType>;
  id?: InputMaybe<String_PropertyFilterInputType>;
  order_id?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  start_datetime?: InputMaybe<Date_PropertyFilterInputType>;
  status?: InputMaybe<CourseEnrollmentStatusEnum_PropertyFilterInputType>;
  updated_at?: InputMaybe<Date_PropertyFilterInputType>;
};

export type StudentProfileResolver_GetStudentCourses_FilterInputType = {
  and?: InputMaybe<Array<StudentProfileResolver_GetStudentCoursesFilterInputType>>;
  course_id?: InputMaybe<String_PropertyFilterInputType>;
  created_at?: InputMaybe<Date_PropertyFilterInputType>;
  end_datetime?: InputMaybe<Date_PropertyFilterInputType>;
  id?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<StudentProfileResolver_GetStudentCoursesFilterInputType>>;
  order_id?: InputMaybe<String_PropertyFilterInputType>;
  slug?: InputMaybe<String_PropertyFilterInputType>;
  start_datetime?: InputMaybe<Date_PropertyFilterInputType>;
  status?: InputMaybe<CourseEnrollmentStatusEnum_PropertyFilterInputType>;
  updated_at?: InputMaybe<Date_PropertyFilterInputType>;
};

export type StudentProfileResolver_GetStudentOrderHistoryFilterInputType = {
  created_at?: InputMaybe<Date_PropertyFilterInputType>;
  gst_no?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  payment_gateway_mode?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<OrderStatusEnum_PropertyFilterInputType>;
  total?: InputMaybe<Number_PropertyFilterInputType>;
  transaction_date_time?: InputMaybe<String_PropertyFilterInputType>;
  transaction_status?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<OrderTypeEnum_PropertyFilterInputType>;
  user_id?: InputMaybe<String_PropertyFilterInputType>;
};

export type StudentProfileResolver_GetStudentOrderHistory_FilterInputType = {
  and?: InputMaybe<Array<StudentProfileResolver_GetStudentOrderHistoryFilterInputType>>;
  created_at?: InputMaybe<Date_PropertyFilterInputType>;
  gst_no?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<StudentProfileResolver_GetStudentOrderHistoryFilterInputType>>;
  payment_gateway_mode?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<OrderStatusEnum_PropertyFilterInputType>;
  total?: InputMaybe<Number_PropertyFilterInputType>;
  transaction_date_time?: InputMaybe<String_PropertyFilterInputType>;
  transaction_status?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<OrderTypeEnum_PropertyFilterInputType>;
  user_id?: InputMaybe<String_PropertyFilterInputType>;
};

export type StudentProfileResolver_GetStudentQuizResultListFilterInputType = {
  status?: InputMaybe<QuizStatusEnum_PropertyFilterInputType>;
  title?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<QuizTypesEnum_PropertyFilterInputType>;
  user_id?: InputMaybe<String_PropertyFilterInputType>;
};

export type StudentProfileResolver_GetStudentQuizResultList_FilterInputType = {
  and?: InputMaybe<Array<StudentProfileResolver_GetStudentQuizResultListFilterInputType>>;
  or?: InputMaybe<Array<StudentProfileResolver_GetStudentQuizResultListFilterInputType>>;
  status?: InputMaybe<QuizStatusEnum_PropertyFilterInputType>;
  title?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<QuizTypesEnum_PropertyFilterInputType>;
  user_id?: InputMaybe<String_PropertyFilterInputType>;
};

export type StudentQuestionAnswerObjectType = {
  __typename?: 'StudentQuestionAnswerObjectType';
  /** answer of the QuestionAnswer */
  answer: Array<Scalars['String']['output']>;
  /** correctAnswer of the QuestionAnswer */
  mark?: Maybe<Scalars['String']['output']>;
  /** question  of the QuestionAnswer */
  question: Scalars['String']['output'];
  /** question id of the QuestionAnswer */
  questionId: Scalars['String']['output'];
};

export type StudentRegisterInput = {
  /** Confirm Password field */
  confirmPassword?: InputMaybe<Scalars['String']['input']>;
  /** Student's country name */
  countryId?: InputMaybe<Scalars['String']['input']>;
  /** Student's description about themselves */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Email of the student */
  email: Scalars['String']['input'];
  /** First name of the student */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Student's gender */
  gender?: InputMaybe<UserGenderEnum>;
  /** Student's goal to join hogrowth */
  goalId?: InputMaybe<Scalars['String']['input']>;
  /** Student's Profile picture */
  image?: InputMaybe<UserImageMedia>;
  /** Student's instagram profile URL */
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  /** New user flag */
  isNewUser?: InputMaybe<Scalars['Boolean']['input']>;
  /** Last name of the student */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Student's LinkedIn profile URL */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** Student's mobile number */
  mobileNo?: InputMaybe<Scalars['String']['input']>;
  /** Student's mobile number std code according to their country */
  mobileNoStdCode?: InputMaybe<Scalars['String']['input']>;
  /** full name of the student - use for google signup */
  name?: InputMaybe<Scalars['String']['input']>;
  /** OTP Send Through */
  otpVia?: InputMaybe<OtpVia>;
  /** Password of the student */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Student's qualification */
  qualification?: InputMaybe<Scalars['String']['input']>;
  /** User Sign In type */
  signinType?: InputMaybe<UserSignInType>;
  /** Student's state id */
  stateId?: InputMaybe<Scalars['String']['input']>;
  /** Student's twitter profile URL */
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
};

export type StudentRegisterVerificationInput = {
  /** New user flag */
  isNewUser?: InputMaybe<Scalars['Boolean']['input']>;
  /** OTP */
  otp: Scalars['String']['input'];
};

export type StudentSignInWithSocialAccountInput = {
  /** Email of the student */
  email: Scalars['String']['input'];
  /** Full name of the student */
  name: Scalars['String']['input'];
  /** signin type of student */
  signinType: UserSignInType;
};

export type StudentsResolver_GetStudentListFilterInputType = {
  course_mode?: InputMaybe<String_PropertyFilterInputType>;
  course_type?: InputMaybe<String_PropertyFilterInputType>;
  created_at?: InputMaybe<String_PropertyFilterInputType>;
  email?: InputMaybe<String_PropertyFilterInputType>;
  first_name?: InputMaybe<String_PropertyFilterInputType>;
  last_name?: InputMaybe<String_PropertyFilterInputType>;
  mobile_no?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  role_id?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<UserStatusEnum_PropertyFilterInputType>;
  username?: InputMaybe<String_PropertyFilterInputType>;
};

export type StudentsResolver_GetStudentList_FilterInputType = {
  and?: InputMaybe<Array<StudentsResolver_GetStudentListFilterInputType>>;
  course_mode?: InputMaybe<String_PropertyFilterInputType>;
  course_type?: InputMaybe<String_PropertyFilterInputType>;
  created_at?: InputMaybe<String_PropertyFilterInputType>;
  email?: InputMaybe<String_PropertyFilterInputType>;
  first_name?: InputMaybe<String_PropertyFilterInputType>;
  last_name?: InputMaybe<String_PropertyFilterInputType>;
  mobile_no?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<StudentsResolver_GetStudentListFilterInputType>>;
  role_id?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<UserStatusEnum_PropertyFilterInputType>;
  username?: InputMaybe<String_PropertyFilterInputType>;
};

export type Subscription = {
  __typename?: 'Subscription';
  getNotifications: NotificationPayload;
};


export type SubscriptionGetNotificationsArgs = {
  courseSlug?: InputMaybe<Scalars['String']['input']>;
  topic: Scalars['String']['input'];
};

export type SyncCartInput = {
  /** id of course */
  courseId: Scalars['String']['input'];
  /** number of seat */
  noOfSeat?: InputMaybe<Scalars['Float']['input']>;
};

/** content type of testimonial */
export enum TestimonialContentTypeEnum {
  Text = 'TEXT',
  Video = 'VIDEO'
}

export type TestimonialContentTypeEnum_PropertyFilterInputType = {
  eq?: InputMaybe<TestimonialContentTypeEnum>;
  in?: InputMaybe<Array<TestimonialContentTypeEnum>>;
  neq?: InputMaybe<TestimonialContentTypeEnum>;
  notin?: InputMaybe<Array<TestimonialContentTypeEnum>>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TestimonialListObjectType = {
  __typename?: 'TestimonialListObjectType';
  pagination: TestimonialPaginationType;
  records: Array<TestimonialObjectType>;
};

export type TestimonialMediaInput = {
  /** file name for content */
  file: MediaInput;
  /** type of media */
  mediaType: GalleryTypeEnum;
};

export type TestimonialObjectType = {
  __typename?: 'TestimonialObjectType';
  /** Content of testimonial */
  content: Scalars['String']['output'];
  /** content type of the testimonial */
  contentType: TestimonialContentTypeEnum;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: UserObjectType;
  createdById: Scalars['String']['output'];
  /** Designation of testimonial */
  designation?: Maybe<Scalars['String']['output']>;
  /** id of the testimonial */
  id: Scalars['String']['output'];
  /** image of testimonial */
  image?: Maybe<Scalars['String']['output']>;
  /** Name of testimonial */
  name: Scalars['String']['output'];
  reference?: Maybe<Array<ReferenceUnion>>;
  /** reference id for instructor or course */
  referenceId?: Maybe<Scalars['String']['output']>;
  /** status of the testimonial */
  status: TestimonialStatusEnum;
  /** type of the testimonial */
  type?: Maybe<TestimonialTypeEnum>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type TestimonialPaginationType = {
  __typename?: 'TestimonialPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type TestimonialPublicListObjectType = {
  __typename?: 'TestimonialPublicListObjectType';
  pagination: TestimonialPaginationType;
  records: Array<TestimonialPublicObjectType>;
};

export type TestimonialPublicObjectType = {
  __typename?: 'TestimonialPublicObjectType';
  /** Content of testimonial */
  content: Scalars['String']['output'];
  /** Content of testimonial */
  contentType: TestimonialContentTypeEnum;
  /** Designation of testimonial */
  designation: Scalars['String']['output'];
  /** id of the testimonial */
  id: Scalars['String']['output'];
  /** image of testimonial */
  image?: Maybe<Scalars['String']['output']>;
  /** Name of testimonial */
  name: Scalars['String']['output'];
};

export type TestimonialResolver_GetPublicTestimonialsFilterInputType = {
  content_type?: InputMaybe<TestimonialContentTypeEnum_PropertyFilterInputType>;
  designation?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  reference_id?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<TestimonialTypeEnum_PropertyFilterInputType>;
};

export type TestimonialResolver_GetPublicTestimonials_FilterInputType = {
  and?: InputMaybe<Array<TestimonialResolver_GetPublicTestimonialsFilterInputType>>;
  content_type?: InputMaybe<TestimonialContentTypeEnum_PropertyFilterInputType>;
  designation?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<TestimonialResolver_GetPublicTestimonialsFilterInputType>>;
  reference_id?: InputMaybe<String_PropertyFilterInputType>;
  type?: InputMaybe<TestimonialTypeEnum_PropertyFilterInputType>;
};

export type TestimonialResolver_GetTestimonialsFilterInputType = {
  content_type?: InputMaybe<TestimonialContentTypeEnum_PropertyFilterInputType>;
  designation?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  reference_id?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<TestimonialStatusEnum_PropertyFilterInputType>;
  type?: InputMaybe<TestimonialTypeEnum_PropertyFilterInputType>;
};

export type TestimonialResolver_GetTestimonials_FilterInputType = {
  and?: InputMaybe<Array<TestimonialResolver_GetTestimonialsFilterInputType>>;
  content_type?: InputMaybe<TestimonialContentTypeEnum_PropertyFilterInputType>;
  designation?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<TestimonialResolver_GetTestimonialsFilterInputType>>;
  reference_id?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<TestimonialStatusEnum_PropertyFilterInputType>;
  type?: InputMaybe<TestimonialTypeEnum_PropertyFilterInputType>;
};

/** status of testimonial */
export enum TestimonialStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type TestimonialStatusEnum_PropertyFilterInputType = {
  eq?: InputMaybe<TestimonialStatusEnum>;
  in?: InputMaybe<Array<TestimonialStatusEnum>>;
  neq?: InputMaybe<TestimonialStatusEnum>;
  notin?: InputMaybe<Array<TestimonialStatusEnum>>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

/** type of testimonial */
export enum TestimonialTypeEnum {
  Course = 'COURSE',
  General = 'GENERAL',
  Instructor = 'INSTRUCTOR'
}

export type TestimonialTypeEnum_PropertyFilterInputType = {
  eq?: InputMaybe<TestimonialTypeEnum>;
  in?: InputMaybe<Array<TestimonialTypeEnum>>;
  neq?: InputMaybe<TestimonialTypeEnum>;
  notin?: InputMaybe<Array<TestimonialTypeEnum>>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The understanding level enum */
export enum UnderstandingLevelEnum {
  Advanced = 'ADVANCED',
  Beginner = 'BEGINNER',
  Intermediate = 'INTERMEDIATE'
}

export type UpdateBannerImageInput = {
  large: Scalars['String']['input'];
  medium: Scalars['String']['input'];
  original: Scalars['String']['input'];
  thumb: Scalars['String']['input'];
};

export type UpdateBannerInput = {
  /** Description of banner */
  description: Scalars['String']['input'];
  /** id of the banner type */
  id: Scalars['String']['input'];
  /** current image url of banner */
  imageUrls?: InputMaybe<UpdateBannerImageInput>;
  /** new image of banner */
  images?: InputMaybe<BannerMedia>;
  /** Name of banner */
  name: Scalars['String']['input'];
  /** Navigation link for banner */
  navigationLink?: InputMaybe<Scalars['String']['input']>;
  /** Sort number of banner */
  sortNumber: Scalars['Float']['input'];
  /** status of the banner */
  status: BannerStatusEnum;
  /** Type of banner */
  type: BannerTypeEnum;
};

export type UpdateBannerStatusInput = {
  /** Id of banner */
  id: Scalars['String']['input'];
  /** status of the banner */
  status: BannerStatusEnum;
};

export type UpdateBlogCategoryInput = {
  /** id of blog category */
  id: Scalars['String']['input'];
  /** Blog category image url */
  image_url?: InputMaybe<Scalars['String']['input']>;
  /** Name of blog category */
  name: Scalars['String']['input'];
  /** New image for blog category */
  new_image?: InputMaybe<BlogMedia>;
  /** parent category of blog category */
  parent_id?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBlogCategoryStatusInput = {
  /** id of blog category */
  id: Scalars['String']['input'];
  /** status of blog category */
  status: BlogCategoryStatusEnum;
};

export type UpdateBlogInput = {
  author_id: Scalars['String']['input'];
  /** Category ids of the blog */
  categories: Array<Scalars['String']['input']>;
  /** Content of the blog */
  content: Scalars['String']['input'];
  /** Featured image url of the blog */
  featured_image_url?: InputMaybe<Scalars['String']['input']>;
  /** The flag to determind about comment section of blog */
  has_comment_enabled: Scalars['Boolean']['input'];
  /** id of blog */
  id: Scalars['String']['input'];
  /** New featured image of the blog */
  new_featured_image?: InputMaybe<BlogMedia>;
  /** SEO description of the blog */
  seo_description: Scalars['String']['input'];
  /** SEO title of the blog */
  seo_title: Scalars['String']['input'];
  /** Short description of the blog */
  short_description: Scalars['String']['input'];
  /** Tag ids of the blog */
  tags: Array<Scalars['String']['input']>;
  /** Title of the blog */
  title: Scalars['String']['input'];
  /** The blog type */
  type: BlogTypeStatusEnum;
};

export type UpdateBlogStatusInput = {
  /** id of blog */
  id: Scalars['String']['input'];
  /** status of blog */
  status: BlogStatusEnum;
};

export type UpdateBlogTagInput = {
  /** id of blog category */
  id: Scalars['String']['input'];
  /** Name of blog category */
  name: Scalars['String']['input'];
};

export type UpdateBlogTagStatusInput = {
  /** id of blog category */
  id: Scalars['String']['input'];
  /** status of blog category */
  status: BlogTagStatusEnum;
};

export type UpdateCmsInput = {
  content: Scalars['String']['input'];
  /** id of the CMS */
  id: Scalars['String']['input'];
  /** Keyword of CMS */
  keywords: Scalars['String']['input'];
  /** Name of CMS */
  name: Scalars['String']['input'];
  /** Description of CMS */
  seoDescription: Scalars['String']['input'];
  /** Title of CMS */
  seoTitle: Scalars['String']['input'];
  /** Status of the CMS */
  status: CmsStatusEnum;
};

export type UpdateCmsStatusInput = {
  /** id of the CMS */
  id: Scalars['String']['input'];
  /** Status of the CMS */
  status: CmsStatusEnum;
};

export type UpdateContactUsStatusInput = {
  /** id of contact us record */
  id: Scalars['String']['input'];
  /** The const us Status status */
  status: ContactUsStatusEnum;
};

export type UpdateCourseCategoryInput = {
  /** id of the category */
  id: Scalars['String']['input'];
  /** New image of category */
  image?: InputMaybe<Media>;
  /** Current image url of category */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Name of category */
  name: Scalars['String']['input'];
  /** ParentId of category */
  parentId?: InputMaybe<Scalars['String']['input']>;
  /** SortNumber of category */
  sortNumber: Scalars['Int']['input'];
  /** Status of category */
  status: CategoryStatus;
};

export type UpdateCourseCategoryStatusInput = {
  /** id of the category */
  id: Scalars['String']['input'];
  /** status */
  status: CategoryStatus;
};

export type UpdateCourseHighlightInput = {
  /** The icon for course highlights */
  highlight_icon?: InputMaybe<Scalars['String']['input']>;
  /** The highlights for course */
  highlight_text: Scalars['String']['input'];
  /** The id of course highlights */
  id?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCourseImageInput = {
  large?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  original?: InputMaybe<Scalars['String']['input']>;
  thumb?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCourseInput = {
  /** address of seminar  */
  address?: InputMaybe<Scalars['String']['input']>;
  /** amount of course */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** cost type of course */
  costType?: InputMaybe<CourseCostTypeEnum>;
  /** category id of course */
  courseCategoryId?: InputMaybe<Scalars['String']['input']>;
  /** language id of course */
  courseLanguageIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** level id of course */
  courseLevelId?: InputMaybe<Scalars['String']['input']>;
  /** mode id of course */
  courseModeId?: InputMaybe<Scalars['String']['input']>;
  /** quiz id of course */
  courseQuizId?: InputMaybe<Scalars['String']['input']>;
  /** skill ids for course */
  courseSkillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** sub category id of course */
  courseSubCategoryId?: InputMaybe<Scalars['String']['input']>;
  /** type id of course */
  courseTypeId?: InputMaybe<Scalars['String']['input']>;
  /** description of course */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Eligible percentage for download cerificate */
  eligiblePercentageForCertificate?: InputMaybe<Scalars['Float']['input']>;
  /** end data and time of course */
  endDatetime?: InputMaybe<Scalars['DateTime']['input']>;
  /** for course has certificate or not */
  hasCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  /** for course has quiz or not */
  hasQuiz?: InputMaybe<Scalars['Boolean']['input']>;
  /** course highlights */
  highlights?: InputMaybe<Array<UpdateCourseHighlightInput>>;
  id: Scalars['String']['input'];
  /** current image url of course */
  imageUrls?: InputMaybe<Array<UpdateCourseImageInput>>;
  /** new image of course */
  images?: InputMaybe<Array<CourseMedia>>;
  /** meeting link for webinar */
  meetingLink?: InputMaybe<Scalars['String']['input']>;
  /** meeting number */
  meetingNumber?: InputMaybe<Scalars['String']['input']>;
  /** meeting password for webinar */
  meetingPassword?: InputMaybe<Scalars['String']['input']>;
  /** mentor id with there commission */
  mentors?: InputMaybe<Array<UpdateMentorCommissionInput>>;
  /** name of the course */
  name: Scalars['String']['input'];
  /** Course objectives */
  objectives?: InputMaybe<Array<Scalars['String']['input']>>;
  /** preview video of seminar  */
  preview_video?: InputMaybe<Scalars['String']['input']>;
  /** requirements for course */
  requirements?: InputMaybe<Scalars['String']['input']>;
  /** sell price of course */
  sellPrice?: InputMaybe<Scalars['Float']['input']>;
  /** short description of course */
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  /** sort order of course */
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  /** start data and time of course */
  startDatetime?: InputMaybe<Scalars['DateTime']['input']>;
  /** total seat of course */
  totalSeat?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCourseLanguageInput = {
  /** Id of the course language */
  id: Scalars['String']['input'];
  /** Name of the course language */
  name: Scalars['String']['input'];
  /** Short code of the course language */
  shortCode: Scalars['String']['input'];
};

export type UpdateCourseLessonInput = {
  /** content of lesson */
  contentUrl?: InputMaybe<Scalars['String']['input']>;
  /** description of lesson */
  description: Scalars['String']['input'];
  /** duration of lesson */
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** id of the lesson */
  id: Scalars['String']['input'];
  /** introduction of lesson */
  introduction: Scalars['String']['input'];
  /** meeting start time */
  meeting_start_time?: InputMaybe<Scalars['String']['input']>;
  /** meeting url */
  meeting_url?: InputMaybe<Scalars['String']['input']>;
  /** name of the lesson */
  name: Scalars['String']['input'];
  /** allowed no. of attempt quiz */
  no_of_attempt_allowed?: InputMaybe<Scalars['Float']['input']>;
  /** quiz id of the lesson */
  quizId?: InputMaybe<Scalars['String']['input']>;
  /** lesson sort */
  sortNumber: Scalars['Float']['input'];
  /** lesson start date */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** status of lesson */
  status?: LessonStatusEnum;
  /** type of lesson */
  type: LessonTypeEnum;
};

export type UpdateCourseLevelNameInput = {
  /** id of course level */
  id: Scalars['String']['input'];
  /** name of course level */
  name: Scalars['String']['input'];
};

export type UpdateCourseLevelStatusInput = {
  /** id of course level */
  id: Scalars['String']['input'];
  /** status of course level */
  status: CourseLevelsStatusEnum;
};

export type UpdateCourseMentorsInput = {
  /** id of the course */
  courseId: Scalars['String']['input'];
  /** mentor id with there commission */
  mentors: Array<UpdateMentorCommissionInput>;
};

export type UpdateCourseModeInput = {
  /** id of course mode name */
  id: Scalars['String']['input'];
  /** name of course mode */
  name: Scalars['String']['input'];
};

export type UpdateCourseRatingsInput = {
  /** Id of course ratings */
  Id: Scalars['String']['input'];
  /** ratings of course ratings */
  ratings: Scalars['Float']['input'];
  /** star count of course ratings */
  starCount: Scalars['Float']['input'];
};

export type UpdateCourseSectionInput = {
  /** id of the section */
  id: Scalars['String']['input'];
  /** introduction of the section */
  introduction: Scalars['String']['input'];
  /** name of the section */
  name: Scalars['String']['input'];
  /** sort order of section */
  sortNumber: Scalars['Float']['input'];
};

export type UpdateCourseSkillInput = {
  /** Id of the course skill */
  id: Scalars['String']['input'];
  /** Name of the course skill */
  name: Scalars['String']['input'];
  /** Status of the course skill */
  status: CourseSkillStatusEnum;
};

export type UpdateCourseSkillStatusInput = {
  /** id of course skill */
  id: Scalars['String']['input'];
  /** Status of course skill */
  status: CourseSkillStatusEnum;
};

export type UpdateCourseStatusInput = {
  /** name of the course */
  id: Scalars['String']['input'];
  /** new status of course */
  status: CourseStatusEnum;
};

export type UpdateCourseTypeInput = {
  /** allow multiple quantities for course type */
  allowMultipleQuantities?: InputMaybe<Scalars['Boolean']['input']>;
  /** id of course type */
  id: Scalars['String']['input'];
  /** name of course Type name */
  name: Scalars['String']['input'];
};

export type UpdateCourseTypeStatusInput = {
  /** id of course type */
  id: Scalars['String']['input'];
  /** status of course Type name */
  status: CourseTypeStatus;
};

export type UpdateCourselanguageStatusInput = {
  /** id of course language */
  id: Scalars['String']['input'];
  /** Status of course language */
  status: CourseLanguageStatusEnum;
};

export type UpdateFaqInput = {
  /** Answer of FAQ */
  answer: Scalars['String']['input'];
  /** Course id for course FAQ */
  courseID?: InputMaybe<Scalars['String']['input']>;
  /** Id of FAQ */
  id: Scalars['String']['input'];
  /** Question of FAQ */
  question: Scalars['String']['input'];
  /** Sort Order of FAQ */
  sortOrder: Scalars['Float']['input'];
  /** status of the faq type */
  status: FaqStatusEnum;
  /** Type of FAQ */
  type: FaqTypeEnum;
};

export type UpdateFaqOrderInput = {
  /** Id of FAQ */
  id: Scalars['String']['input'];
  /** Sort Order of FAQ */
  sortOrder: Scalars['Float']['input'];
};

export type UpdateFaqStatusInput = {
  /** Id of FAQ */
  id: Scalars['String']['input'];
  /** status of the faq type */
  status: FaqStatusEnum;
};

export type UpdateGalleryInput = {
  /** New Content of the gallery */
  contentUrl: Scalars['String']['input'];
  /** Heading of the gallery */
  heading: Scalars['String']['input'];
  /** id of the gallery */
  id: Scalars['String']['input'];
  /** Gallery status */
  status: GalleryStatusEnum;
  /** Sub heading of the gallery */
  subHeading: Scalars['String']['input'];
  tags: Array<Scalars['String']['input']>;
  /** Gallery type */
  type: GalleryTypeEnum;
};

export type UpdateGalleryStatusInput = {
  /** id of the gallery */
  id: Scalars['String']['input'];
  /** Gallery status */
  status: GalleryStatusEnum;
};

export type UpdateGalleryTagInput = {
  /** Id of gallery */
  id: Scalars['String']['input'];
  /** Name of gallery tag */
  name: Scalars['String']['input'];
  /** status of gallery tag */
  status: GalleryTagStatusEnum;
};

export type UpdateGalleryTagStatusInput = {
  /** Id of gallery */
  id: Scalars['String']['input'];
  /** status of gallery tag */
  status: GalleryTagStatusEnum;
};

export type UpdateGoalInput = {
  /** id of goal */
  id: Scalars['String']['input'];
  /** Goal name */
  name: Scalars['String']['input'];
};

export type UpdateGoalStatusInput = {
  /** id of goal */
  id: Scalars['String']['input'];
  /** Goal status enum */
  status: GoalStatusEnum;
};

export type UpdateInstructorEmailInput = {
  /** New email of instructor to be update */
  email: Scalars['String']['input'];
};

export type UpdateInstructorInput = {
  /** User's country name */
  countryId: Scalars['String']['input'];
  /** User's description about themself */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Email of the mentor */
  email: Scalars['String']['input'];
  /** First name of the mentor */
  firstName: Scalars['String']['input'];
  /** Full name of the mentor */
  fullName: Scalars['String']['input'];
  /** User's gender */
  gender: UserGenderEnum;
  /** id of the user */
  id: Scalars['String']['input'];
  /** User's profile image url */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** User's instagram profile URL */
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the mentor */
  lastName: Scalars['String']['input'];
  /** User's LinkedIn profile URL */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** user's mobile number */
  mobileNo: Scalars['String']['input'];
  /** User's mobile number std coude according to their country */
  mobileNoStdCode: Scalars['String']['input'];
  /** User's Profile picture */
  newImage?: InputMaybe<UserImageMedia>;
  /** User's qualification */
  qualification: Scalars['String']['input'];
  /** User's twitter profile URL */
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInstructorProfileInput = {
  /** Instructor's country name */
  countryId: Scalars['String']['input'];
  /** Instructor's description about themselves */
  description?: InputMaybe<Scalars['String']['input']>;
  /** First name of the instructor */
  firstName: Scalars['String']['input'];
  /** Full name of the instructor */
  fullName: Scalars['String']['input'];
  /** Instructor's gender */
  gender: UserGenderEnum;
  /** Instructor's profile image url */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Instructor's instagram profile URL */
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the instructor */
  lastName: Scalars['String']['input'];
  /** Instructor's LinkedIn profile URL */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** Instructor's mobile number */
  mobileNo: Scalars['String']['input'];
  /** Instructor's mobile number std code according to their country */
  mobileNoStdCode: Scalars['String']['input'];
  /** Instructor's Profile picture */
  newImage?: InputMaybe<UserImageMedia>;
  /** Instructor's qualification */
  qualification?: InputMaybe<Scalars['String']['input']>;
  /** Instructor's twitter profile URL */
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateJobVacancyInput = {
  /** Job vacancy details ed information */
  content: Scalars['String']['input'];
  /** id of job vacancy record */
  id: Scalars['String']['input'];
  /** Location of the job */
  jobLocation: Scalars['String']['input'];
  /** Mode of the job in contrast of working place */
  jobMode: JobVacancyModeEnum;
  /** Type of job in contrast of time */
  jobType: JobVacancyTypeEnum;
  /** Maximum required experience for job */
  maxRequiredExperience: Scalars['Float']['input'];
  /** Minimum required experience for job */
  minRequiredExperience: Scalars['Float']['input'];
  /** Numbers of opening for the job */
  noOfOpening: Scalars['Int']['input'];
  /** Position for which job vacancy is open */
  position: Scalars['String']['input'];
};

export type UpdateJobVacancyStatusInput = {
  /** id of job vacancy record */
  id: Scalars['String']['input'];
  /** Status of the job vacancy */
  status: JobVacancyStatusEnum;
};

export type UpdateMentorCommissionInput = {
  /** The commission value for each mentor */
  commission: Scalars['Float']['input'];
  /** The mentor ID */
  mentorId: Scalars['String']['input'];
};

export type UpdateNotificationStatusInput = {
  /** id of notification */
  id: Scalars['String']['input'];
  /** status of notification */
  status: NotificationStatusEnum;
};

export type UpdateNotificationsInput = {
  /** Action of notification */
  action?: InputMaybe<NotificationActionEnum>;
  /** CourseId for coursespecific Notification purpose */
  courseId?: InputMaybe<Scalars['String']['input']>;
  /** id of Notification */
  id: Scalars['String']['input'];
  /** Notification of message */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Status of notification */
  status?: InputMaybe<NotificationStatusEnum>;
  /** Notification of title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Type of notification */
  type?: InputMaybe<NotificationTypeEnum>;
};

export type UpdatePaymentGatewayInput = {
  /** id of the payment gateway */
  id: Scalars['String']['input'];
  /** Private key of payment gateway */
  livePrivateKey: Scalars['String']['input'];
  /** Secret key of payment gateway */
  liveSecretKey: Scalars['String']['input'];
  /** Mode of payment gateway */
  mode: PaymentGatewayModeEnum;
  /** Name of the payment gateway */
  name: Scalars['String']['input'];
  /** Test private key of the payment gateway */
  sandboxPrivateKey: Scalars['String']['input'];
  /** Test secret key of the payment gateway */
  sandboxSecretKey: Scalars['String']['input'];
};

export type UpdatePaymentGatewayModeInput = {
  /** id of the payment gateway */
  id: Scalars['String']['input'];
  /** Mode of payment gateway */
  mode: PaymentGatewayModeEnum;
};

export type UpdateQuizInput = {
  /** duration of quiz */
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** Needed mark to earn certificate */
  eligibleMarksForCertificate: Scalars['Float']['input'];
  /** flag to determine whether quiz has time limit */
  hasTimeLimit: Scalars['Boolean']['input'];
  /** id of quiz */
  id: Scalars['String']['input'];
  /** Title of the quiz */
  title: Scalars['String']['input'];
  /** Total marks of the quiz */
  totalMarks: Scalars['Float']['input'];
  /** Type of quiz */
  type: QuizTypesEnum;
};

export type UpdateQuizQuestionInput = {
  /** Correct answers of the question of quiz */
  answers: Array<Scalars['String']['input']>;
  /** Duration or time in seconds to completed question of quiz */
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** flag to determine question has multiple answers */
  hasMultipleAnswers: Scalars['Boolean']['input'];
  /** id of quiz question */
  id?: InputMaybe<Scalars['String']['input']>;
  /** mark of the question of quiz */
  mark: Scalars['Float']['input'];
  /** Options for the question of quiz */
  options?: InputMaybe<Array<QuizQuestionOptions>>;
  /** question of quiz question */
  question: Scalars['String']['input'];
  /** question's image  of quiz question */
  questionImages?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Question number or question order number in quiz */
  questionOrder: Scalars['Float']['input'];
  /** quiz id of the quiz question */
  quizId: Scalars['String']['input'];
};

export type UpdateQuizStatusInput = {
  /** id of quiz */
  id: Scalars['String']['input'];
  /** status of quiz */
  status: QuizStatusEnum;
};

export type UpdateRoleInput = {
  /** id of the role */
  id: Scalars['String']['input'];
  /** name of the role */
  name: Scalars['String']['input'];
};

export type UpdateRolePermissionsInput = {
  /** permission ids */
  permissions: Array<Scalars['String']['input']>;
  /** role Id */
  roleId: Scalars['String']['input'];
};

export type UpdateSettingInput = {
  /** Setting key */
  settingKey: Scalars['String']['input'];
  /** Setting value */
  settingValue: Scalars['String']['input'];
};

export type UpdateStudentBasicQuestionInputType = {
  /** age range of student */
  ageRange: AgeRangeEnum;
  /** Occupation id which student choose */
  occupationId?: InputMaybe<Scalars['String']['input']>;
  /** Other occupation which student entered! */
  otherOccupation?: InputMaybe<Scalars['String']['input']>;
  /** other platform referral which student entered */
  otherPlatformReferral?: InputMaybe<Scalars['String']['input']>;
  /** id of platform referral which student choose */
  platformReferralId?: InputMaybe<Scalars['String']['input']>;
  /** Understanding level of stock market and trading */
  stockMarketAndTradingUnderstandingLevel: UnderstandingLevelEnum;
};

export type UpdateStudentEmailInput = {
  /** New email of student to update */
  email: Scalars['String']['input'];
};

export type UpdateStudentInput = {
  /** User's city */
  cityId?: InputMaybe<Scalars['String']['input']>;
  /** User's country name */
  countryId: Scalars['String']['input'];
  /** user Date of birth */
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  /** Email of the mentor */
  email: Scalars['String']['input'];
  /** First name of the mentor */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Full name of the mentor */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** User's gender */
  gender: UserGenderEnum;
  /** User goal to join hogrowth */
  goal?: InputMaybe<Scalars['String']['input']>;
  /** id of the user */
  id: Scalars['String']['input'];
  /** User's profile image url */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** User's instagram profile URL */
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the mentor */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User's LinkedIn profile URL */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** user's mobile number */
  mobileNo: Scalars['String']['input'];
  /** User's mobile number std coude according to their country */
  mobileNoStdCode: Scalars['String']['input'];
  /** User's Profile picture */
  newImage?: InputMaybe<UserImageMedia>;
  /** User's qualification */
  qualification?: InputMaybe<Scalars['String']['input']>;
  /** User's state */
  stateId?: InputMaybe<Scalars['String']['input']>;
  /** User's twitter profile URL */
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStudentProfileInput = {
  /** user's Address */
  address?: InputMaybe<Scalars['String']['input']>;
  /** user's Address */
  address_id?: InputMaybe<Scalars['String']['input']>;
  /** user's city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Student's country name */
  countryId?: InputMaybe<Scalars['String']['input']>;
  /** Student's description about themself */
  description?: InputMaybe<Scalars['String']['input']>;
  /** user Date of birth */
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  /** First name of the student */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Student's gender */
  gender?: InputMaybe<UserGenderEnum>;
  /** Student's goal to join hogrowth */
  goalId?: InputMaybe<Scalars['String']['input']>;
  /** Student's profile image url */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Student's instagram profile URL */
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the student */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Student's LinkedIn profile URL */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** Student's mobile number */
  mobileNo?: InputMaybe<Scalars['String']['input']>;
  /** Student's mobile number std code according to their country */
  mobileNoStdCode?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the student */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Student's Profile picture */
  newImage?: InputMaybe<UserImageMedia>;
  /** user's pincode */
  pincode?: InputMaybe<Scalars['String']['input']>;
  /** Student's qualification */
  qualification?: InputMaybe<Scalars['String']['input']>;
  /** user's state */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Student's twitter profile URL */
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStudentVerificationInput = {
  id: Scalars['ID']['input'];
  isVerified: Scalars['Boolean']['input'];
  verificationType: Scalars['String']['input'];
};

export type UpdateTestimonialInput = {
  /** Content of testimonial If video then video URL should be here */
  content: Scalars['String']['input'];
  /** content type of the testimonial */
  contentType: TestimonialContentTypeEnum;
  /** Designation of testimonial */
  designation?: InputMaybe<Scalars['String']['input']>;
  /** id of the testimonial */
  id: Scalars['String']['input'];
  /** new image of testimonial */
  image?: InputMaybe<Scalars['String']['input']>;
  /** Name of testimonial */
  name: Scalars['String']['input'];
  /** reference id for instructor or course */
  referenceId?: InputMaybe<Scalars['String']['input']>;
  /** status of the testimonial */
  status: TestimonialStatusEnum;
  /** type of the testimonial */
  type: TestimonialTypeEnum;
};

export type UpdateTestimonialStatusInput = {
  /** Id of testimonial */
  id: Scalars['String']['input'];
  /** status of the testimonial */
  status: TestimonialStatusEnum;
};

export type UpdateUserCourseRatingsInput = {
  /** ratingId */
  ratingId: Scalars['String']['input'];
  /** ratingId */
  reason?: InputMaybe<Scalars['String']['input']>;
  /** star count of course ratings */
  status: RatingStatusEnum;
};

export type UpdateUserInput = {
  /** email of the user */
  email: Scalars['String']['input'];
  /** first name of the user */
  firstName: Scalars['String']['input'];
  /** full name of the user */
  fullName: Scalars['String']['input'];
  /** first name of the user */
  id: Scalars['String']['input'];
  /** last name of the user */
  lastName: Scalars['String']['input'];
  /** role of the user */
  roleId: Scalars['String']['input'];
  /** status of user account */
  status: UserStatusEnum;
  /** user type */
  userType: UserTypeEnum;
};

export type UpdateUserPasswordInput = {
  /** id of the user */
  id: Scalars['String']['input'];
  /** password */
  password: Scalars['String']['input'];
};

export type UpdateUserStatusInput = {
  /** id of the user */
  id: Scalars['String']['input'];
  /** is_active */
  status: UserStatusEnum;
};

export type UploadQuizImage = {
  /** Quiz image file content base64 */
  imageContent: Scalars['String']['input'];
  /** Quiz image file name for content Ex. Image.png */
  imageName: Scalars['String']['input'];
  /** Quiz image file type Ex.PNG */
  imageType: Scalars['String']['input'];
};

export type UploadResumeInput = {
  /** Resume file name for content */
  fileName: Scalars['String']['input'];
  /** resume file type */
  fileType: Scalars['String']['input'];
};

export type UpsertCourseProgressInput = {
  /** Course table id for tracking */
  courseId: Scalars['String']['input'];
  /** duration of course progress */
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** is_course_completed of course progress */
  isCourseCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_lesson_completed of course progress */
  isLessonCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_quiz_completed of course progress */
  isQuizCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_section_completed of course progress */
  isSectionCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** lession table id for tracking */
  lessonId: Scalars['String']['input'];
  /** quiz_id table id for tracking */
  quizId?: InputMaybe<Scalars['String']['input']>;
  /** section_id table id for tracking */
  sectionId: Scalars['String']['input'];
};

export type UserAddressesObjectType = {
  __typename?: 'UserAddressesObjectType';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  cityId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fullname?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  stateId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type UserAllQuizResultObjectType = {
  __typename?: 'UserAllQuizResultObjectType';
  isEligibleForCertificate: Scalars['Boolean']['output'];
};

export type UserCourseRatingsObjectType = {
  __typename?: 'UserCourseRatingsObjectType';
  course: CoursePublicObjectType;
  /** courseId of course ratings */
  courseId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<UserObjectType>;
  createdById?: Maybe<Scalars['String']['output']>;
  /** Id of course ratings */
  id: Scalars['String']['output'];
  /** reason of rejected review */
  reason?: Maybe<Scalars['String']['output']>;
  /** review of course */
  review?: Maybe<Scalars['String']['output']>;
  /** star count of course ratings */
  starCount?: Maybe<Scalars['Float']['output']>;
  /** status of review */
  status: RatingStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user: UserPublicObjectType;
  /** userId of course ratings */
  userId: Scalars['String']['output'];
};

/** For user Device type */
export enum UserDeviceTypeEnum {
  App = 'APP',
  Web = 'WEB'
}

/** The enum for users gender */
export enum UserGenderEnum {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export type UserImageMedia = {
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UserListObjectType = {
  __typename?: 'UserListObjectType';
  pagination: PaginationType;
  records: Array<UserObjectType>;
};

export type UserObjectType = {
  __typename?: 'UserObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** email of the user */
  email: Scalars['String']['output'];
  /** first name of the user */
  firstName?: Maybe<Scalars['String']['output']>;
  /** full name of the user */
  fullName?: Maybe<Scalars['String']['output']>;
  /** id of the user */
  id: Scalars['String']['output'];
  /** determined user mobile number is verified in out platform */
  isMobileNumberVerified: Scalars['Boolean']['output'];
  /** determined user is verified in out platform */
  isVerified: Scalars['Boolean']['output'];
  /** last name of the user */
  lastName?: Maybe<Scalars['String']['output']>;
  resetPasswordKey?: Maybe<Scalars['String']['output']>;
  role: RoleObjectType;
  roleId?: Maybe<Scalars['String']['output']>;
  /** sign in type of user */
  signinType: UserSignInType;
  /** is_active */
  status: UserStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** user details about them self */
  userAddress?: Maybe<Array<UserAddressesObjectType>>;
  /** user details about them self */
  userProfile: Array<UserProfileObjectType>;
  /** type of user */
  userType: UserTypeEnum;
  /** username of the user */
  username?: Maybe<Scalars['String']['output']>;
};

export type UserProfileObjectType = {
  __typename?: 'UserProfileObjectType';
  /** age range of student */
  ageRange?: Maybe<AgeRangeEnum>;
  /** User's country object */
  country?: Maybe<CountryObjectType>;
  countryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<UserObjectType>;
  createdById?: Maybe<Scalars['String']['output']>;
  /** User description about them self */
  description?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['DateTime']['output']>;
  /** User's gender */
  gender?: Maybe<UserGenderEnum>;
  /** User's goal object to join hogrowth */
  goal?: Maybe<GoalObjectType>;
  /** User's goal's id to join hogrowth */
  goalId?: Maybe<Scalars['String']['output']>;
  /** User profile id */
  id: Scalars['String']['output'];
  /** User's profile picture for hogrowth */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** User's instagram account profile url */
  instagramUrl?: Maybe<Scalars['String']['output']>;
  /** User's linkedin account profile url */
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  /** User's mobile number */
  mobileNo?: Maybe<Scalars['String']['output']>;
  /** User's mobile no std code according their country */
  mobileNoStdCode?: Maybe<Scalars['String']['output']>;
  /** Occupation details */
  occupation?: Maybe<OccupationObjectType>;
  /** Occupation id of student */
  occupationId?: Maybe<Scalars['String']['output']>;
  /** Reference platform details */
  platformReferral?: Maybe<PlatformReferralObjectType>;
  /** Reference platform id from where student get to our platform */
  platformReferralId?: Maybe<Scalars['String']['output']>;
  /** User qualifications */
  qualification?: Maybe<Scalars['String']['output']>;
  /** Student's understanding level of stock market and trading */
  stockMarketAndTradingUnderstandingLevel?: Maybe<UnderstandingLevelEnum>;
  /** User's twitter account profile url */
  twitterUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserObjectType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** User's id of user table */
  userId: Scalars['String']['output'];
};

export type UserPublicListObjectType = {
  __typename?: 'UserPublicListObjectType';
  pagination: PaginationType;
  records: Array<UserPublicObjectType>;
};

export type UserPublicObjectType = {
  __typename?: 'UserPublicObjectType';
  courses: Array<CoursePublicObjectType>;
  /** email of the user */
  email: Scalars['String']['output'];
  /** first name of the user */
  firstName: Scalars['String']['output'];
  /** full name of the user */
  fullName?: Maybe<Scalars['String']['output']>;
  /** id of the user */
  id: Scalars['String']['output'];
  /** last name of the user */
  lastName?: Maybe<Scalars['String']['output']>;
  /** user details about them self */
  userProfile?: Maybe<Array<UserPublicProfileObjectType>>;
};

export type UserPublicProfileObjectType = {
  __typename?: 'UserPublicProfileObjectType';
  /** User's country object */
  country?: Maybe<CountryObjectType>;
  /** User profile id */
  countryId: Scalars['String']['output'];
  /** User description about them self */
  description?: Maybe<Scalars['String']['output']>;
  /** User's gender */
  gender: UserGenderEnum;
  /** User profile id */
  id: Scalars['String']['output'];
  /** User's profile picture for hogrowth */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** User's instagram account profile url */
  instagramUrl?: Maybe<Scalars['String']['output']>;
  /** User's linkedin account profile url */
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  /** User's mobile number */
  mobileNo: Scalars['String']['output'];
  /** User's mobile no std code according their country */
  mobileNoStdCode: Scalars['String']['output'];
  /** Occupation details */
  occupation?: Maybe<OccupationObjectType>;
  /** Occupation id of student */
  occupationId?: Maybe<Scalars['String']['output']>;
  /** User qualifications */
  qualification: Scalars['String']['output'];
  /** User's twitter account profile url */
  twitterUrl?: Maybe<Scalars['String']['output']>;
  /** User's id of user table */
  userId: Scalars['String']['output'];
};

export type UserQuizListObjectType = {
  __typename?: 'UserQuizListObjectType';
  pagination: UserQuizResultPaginationObjectType;
  records: Array<UserQuizResultObjectType>;
};

export type UserQuizResultForGetObjectType = {
  __typename?: 'UserQuizResultForGetObjectType';
  /** course relation */
  course: CoursePublicObjectType;
  /** course_id of the user quiz result */
  courseId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** id of the user quiz result */
  id: Scalars['String']['output'];
  /** status of the user quiz result */
  isSubmit: Scalars['Boolean']['output'];
  /** lesson relation */
  lesson?: Maybe<CourseLessonObjectType>;
  /** no. of attempt quiz count */
  noOfAttempt?: Maybe<Scalars['Float']['output']>;
  /** questionAnswer of the user quiz result */
  questionAnswer: Array<UserQuizResultQuestionAnswerObjectType>;
  /** quiz relation */
  quiz: QuizObjectType;
  /** Quiz End Time */
  quizEndTime?: Maybe<Scalars['DateTime']['output']>;
  /** quiz_id of the user quiz result */
  quizId: Scalars['String']['output'];
  /** Quiz Start Time */
  quizStartTime?: Maybe<Scalars['DateTime']['output']>;
  /** Quiz End Time */
  quizSubmitTime?: Maybe<Scalars['DateTime']['output']>;
  /** section relation */
  section?: Maybe<CourseSectionObjectType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** user_id of the user quiz result */
  userId: Scalars['String']['output'];
};

export type UserQuizResultInputType = {
  /** course_id of the user quiz result */
  courseId: Scalars['String']['input'];
  /** status of quiz submission */
  isSubmit?: Scalars['Boolean']['input'];
  /** quiz reattempt */
  is_retake?: Scalars['Boolean']['input'];
  /** quiz start */
  is_start?: Scalars['Boolean']['input'];
  /** lesson id of course section */
  lessonId: Scalars['String']['input'];
  /** questionAnswer of the user quiz result */
  questionAnswer: Array<QuestionAnswerInputType>;
  /** quiz_id of the user quiz result */
  quizId: Scalars['String']['input'];
  /** section id of selected course */
  sectionId: Scalars['String']['input'];
};

export type UserQuizResultObjectType = {
  __typename?: 'UserQuizResultObjectType';
  /** courses of the user quiz result */
  course?: Maybe<CourseObjectType>;
  /** course_id of the user quiz result */
  courseId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** id of the user quiz result */
  id: Scalars['String']['output'];
  /** status of the user quiz result */
  isSubmit: Scalars['Boolean']['output'];
  /** lesson relation */
  lesson?: Maybe<CourseLessonObjectType>;
  /** No Of Attempt of user quiz result */
  noOfAttempt: Scalars['Float']['output'];
  /** status of the quiz result */
  passingStatus?: Maybe<QuizResultStatusEnum>;
  /** questionAnswer of the user quiz result */
  questionAnswer: Array<UserQuizResultQuestionAnswerObjectType>;
  /** quiz of the user quiz result */
  quiz?: Maybe<QuizObjectType>;
  /** Quiz End Time */
  quizEndTime?: Maybe<Scalars['DateTime']['output']>;
  /** quiz_id of the user quiz result */
  quizId: Scalars['String']['output'];
  /** Quiz Start Time */
  quizStartTime?: Maybe<Scalars['DateTime']['output']>;
  /** Quiz End Time */
  quizSubmitTime?: Maybe<Scalars['DateTime']['output']>;
  /** Total quiz timing of user quiz result */
  quizTime?: Maybe<Scalars['String']['output']>;
  /** section relation */
  section?: Maybe<CourseSectionObjectType>;
  /** Total marks of user quiz result */
  totalMarks?: Maybe<Scalars['Float']['output']>;
  /** Total obtain marks of user quiz result */
  totalObtainMarks?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** user of the user quiz result */
  user?: Maybe<UserObjectType>;
  /** user_id of the user quiz result */
  userId: Scalars['String']['output'];
};

export type UserQuizResultPaginationObjectType = {
  __typename?: 'UserQuizResultPaginationObjectType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

export type UserQuizResultQuestionAnswerObjectType = {
  __typename?: 'UserQuizResultQuestionAnswerObjectType';
  /** answer of the QuestionAnswer */
  answer: Array<Scalars['String']['output']>;
  /** correctAnswer of the QuestionAnswer */
  correctAnswer?: Maybe<Array<Scalars['String']['output']>>;
  /** correctAnswer of the QuestionAnswer */
  mark?: Maybe<Scalars['String']['output']>;
  /** question  of the QuestionAnswer */
  question: Scalars['String']['output'];
  /** question id of the QuestionAnswer */
  questionId: Scalars['String']['output'];
};

export type UserQuizResultStatusListObjectType = {
  __typename?: 'UserQuizResultStatusListObjectType';
  quizDetails: Array<UserQuizResultStatusObjectType>;
};

export type UserQuizResultStatusObjectType = {
  __typename?: 'UserQuizResultStatusObjectType';
  allowReAttempt: Scalars['Boolean']['output'];
  lessonId: Scalars['String']['output'];
  quizId: Scalars['String']['output'];
  sectionId: Scalars['String']['output'];
  status: QuizResultStatusEnum;
};

export type UserResetPasswordInput = {
  /** resetPasswordKey of the user */
  resetPasswordKey: Scalars['String']['input'];
};

export type UserResetPasswordOutput = {
  __typename?: 'UserResetPasswordOutput';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

/** The enum for users gender */
export enum UserSignInType {
  Apple = 'APPLE',
  Email = 'EMAIL',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Mobile = 'MOBILE'
}

/** The enum for users status */
export enum UserStatusEnum {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Inactive = 'INACTIVE',
  InReview = 'IN_REVIEW'
}

export type UserStatusEnum_PropertyFilterInputType = {
  eq?: InputMaybe<UserStatusEnum>;
  in?: InputMaybe<Array<UserStatusEnum>>;
  neq?: InputMaybe<UserStatusEnum>;
  notin?: InputMaybe<Array<UserStatusEnum>>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

/** For user type */
export enum UserTypeEnum {
  Admin = 'ADMIN',
  Instructor = 'INSTRUCTOR',
  Student = 'STUDENT'
}

export type UsersResolver_GetPublicInstructorsFilterInputType = {
  course_mode?: InputMaybe<String_PropertyFilterInputType>;
  course_type?: InputMaybe<String_PropertyFilterInputType>;
  created_at?: InputMaybe<String_PropertyFilterInputType>;
  email?: InputMaybe<String_PropertyFilterInputType>;
  first_name?: InputMaybe<String_PropertyFilterInputType>;
  last_name?: InputMaybe<String_PropertyFilterInputType>;
  mobile_no?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  role_id?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<UserStatusEnum_PropertyFilterInputType>;
  username?: InputMaybe<String_PropertyFilterInputType>;
};

export type UsersResolver_GetPublicInstructors_FilterInputType = {
  and?: InputMaybe<Array<UsersResolver_GetPublicInstructorsFilterInputType>>;
  course_mode?: InputMaybe<String_PropertyFilterInputType>;
  course_type?: InputMaybe<String_PropertyFilterInputType>;
  created_at?: InputMaybe<String_PropertyFilterInputType>;
  email?: InputMaybe<String_PropertyFilterInputType>;
  first_name?: InputMaybe<String_PropertyFilterInputType>;
  last_name?: InputMaybe<String_PropertyFilterInputType>;
  mobile_no?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<UsersResolver_GetPublicInstructorsFilterInputType>>;
  role_id?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<UserStatusEnum_PropertyFilterInputType>;
  username?: InputMaybe<String_PropertyFilterInputType>;
};

export type UsersResolver_GetUsersFilterInputType = {
  course_mode?: InputMaybe<String_PropertyFilterInputType>;
  course_type?: InputMaybe<String_PropertyFilterInputType>;
  created_at?: InputMaybe<String_PropertyFilterInputType>;
  email?: InputMaybe<String_PropertyFilterInputType>;
  first_name?: InputMaybe<String_PropertyFilterInputType>;
  last_name?: InputMaybe<String_PropertyFilterInputType>;
  mobile_no?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  role_id?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<UserStatusEnum_PropertyFilterInputType>;
  username?: InputMaybe<String_PropertyFilterInputType>;
};

export type UsersResolver_GetUsers_FilterInputType = {
  and?: InputMaybe<Array<UsersResolver_GetUsersFilterInputType>>;
  course_mode?: InputMaybe<String_PropertyFilterInputType>;
  course_type?: InputMaybe<String_PropertyFilterInputType>;
  created_at?: InputMaybe<String_PropertyFilterInputType>;
  email?: InputMaybe<String_PropertyFilterInputType>;
  first_name?: InputMaybe<String_PropertyFilterInputType>;
  last_name?: InputMaybe<String_PropertyFilterInputType>;
  mobile_no?: InputMaybe<String_PropertyFilterInputType>;
  name?: InputMaybe<String_PropertyFilterInputType>;
  or?: InputMaybe<Array<UsersResolver_GetUsersFilterInputType>>;
  role_id?: InputMaybe<String_PropertyFilterInputType>;
  status?: InputMaybe<UserStatusEnum_PropertyFilterInputType>;
  username?: InputMaybe<String_PropertyFilterInputType>;
};

export type VerifyOrderInput = {
  /** transcation id of razorpay payment gateway */
  transactionId: Scalars['String']['input'];
};

export type WishlistListObjectType = {
  __typename?: 'WishlistListObjectType';
  pagination: WishlistPaginationType;
  records: Array<WishlistObjectType>;
};

export type WishlistObjectType = {
  __typename?: 'WishlistObjectType';
  /** course detail object */
  courseDetails: CoursePublicObjectType;
  /** Id of the course which user wish to purchase */
  courseId: Scalars['String']['output'];
  /** Id of the user */
  userId: Scalars['String']['output'];
};

export type WishlistPaginationType = {
  __typename?: 'WishlistPaginationType';
  currentPage: Scalars['Float']['output'];
  totalRecords: Scalars['Float']['output'];
};

/** The course type status */
export enum CourseTypeStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type SubmitQuizObjectType = {
  __typename?: 'submitQuizObjectType';
  endTime?: Maybe<Scalars['String']['output']>;
  isSubmit?: Maybe<Scalars['Boolean']['output']>;
  isSuccess: Scalars['Boolean']['output'];
  questionAnswer?: Maybe<Array<StudentQuestionAnswerObjectType>>;
  quizSubmitTime?: Maybe<Scalars['String']['output']>;
};

export type UpdateCourseLessonStatusInput = {
  /** course id of the lesson */
  courseId: Scalars['String']['input'];
  /** content urls of lesson */
  lessonId: Scalars['String']['input'];
  /** quiz id of the lesson */
  quizId?: InputMaybe<Scalars['String']['input']>;
  /** section id of lesson */
  sectionId: Scalars['String']['input'];
  /** Change lock status of the lesson */
  status?: Scalars['Boolean']['input'];
};
