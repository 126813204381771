import './WebinarLandingPage.scss';
import Button from 'components/_v2/CommonComponents/Button';
import {
	ButtonBorderType,
	ButtonType,
	SelectedTabType,
	SliderComponentType,
} from 'components/_v2/CommonComponents/types';
import { useAuthModalContext } from 'context/AuthContext';
import { useUserContext } from 'context/UserContext';
import { Image } from 'react-bootstrap';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import React, { useMemo } from 'react';
import { EXPLORE_RECORDED_SECTION } from 'utils/consts';
import CommonSlider from 'components/_v2/CommonComponents/CommonSlider';
import { COURSE_URL, WEBINAR_URL } from 'pages/routing/routePages';
import { useCourseContext } from 'context/CourseContext';
import { useNavigate } from 'react-router-dom';
import Best from 'components/_v2/BeTheBest/Best';
import Stories from 'components/_v2/Stories/Stories';
import Faq from 'components/_v2/HgFAQ/Faq';
import { CourseTypeEnum } from 'components/_v2/Course/type';

const ImageRenderer = ({ imageName }: { imageName: string }) => {
	return (
		<Image
			srcSet={`${toAbsoluteUrl(`/media/webinar/${imageName}@2x.png`)} 2x, ${toAbsoluteUrl(
				`/media/webinar/${imageName}@3x.png`,
			)} 3x`}
			alt={imageName}
			className="col-12 col-lg-4 img-fluid"
		/>
	);
};

const BlackBoxRenderer = ({
	onClick,
	text,
	image,
}: {
	text: string;
	onClick: () => void;
	image: string;
}) => {
	return (
		<div
			role="button"
			onClick={onClick}
			className="blackBox d-flex flex-column justify-content-center align-items-center radius gap-4 bg-black">
			<Image src={toAbsoluteUrl(`/media/webinar/${image}.svg`)} alt={text} />
			<span className="white">{text}</span>
		</div>
	);
};

const WebinarLandingPage = () => {
	const { toggleRegisterModal } = useAuthModalContext();
	const { user } = useUserContext();
	const { userCourses, courses } = useCourseContext();
	const navigate = useNavigate();

	const navigateToCourseTypesArray = useMemo(
		() => [
			{
				text: 'Offline Courses',
				onClick: () =>
					navigate({ pathname: COURSE_URL, search: `?courseMode=${SelectedTabType.OFFLINE}` }),
				image: 'offline-course',
			},
			{
				text: 'Online Courses',
				onClick: () =>
					navigate({ pathname: COURSE_URL, search: `?courseMode=${SelectedTabType.ONLINE}` }),
				image: 'online-course',
			},
			{
				text: 'Recorded Courses',
				onClick: () =>
					navigate({ pathname: COURSE_URL, search: `?courseMode=${SelectedTabType.RECORDED}` }),
				image: 'recorded-course',
			},
			{
				text: 'Webinar',
				onClick: () => navigate(WEBINAR_URL),
				image: 'webinar',
			},
		],
		[],
	);

	const upcomingWebinars = useMemo(
		() =>
			courses.filter(
				(course) => !course.courseMode && course.courseType.slug === CourseTypeEnum.WEBINAR,
			),
		[courses],
	);

	return (
		<section className="py-5 px-2">
			<div className="align-items-center text-center">
				<div className="d-flex justify-content-center flex-column gap-4">
					<span className="g_mid fs-72 headingText topHeading align-self-center">
						Join HoGrowth to unleash your financial potential
					</span>
					<span className="fs-24 headingText descriptionHeading align-self-center">
						Highly valuable content! The webinar covered advanced topics like chart patterns and
						algorithmic trading. Complex concepts were broken down well, but beginners might find it
						overwhelming.
					</span>
					{!user && (
						<Button
							buttonText="Sign Up Today"
							classes="g_mid fs-20"
							buttonType={ButtonType.PRIMARY_BUTTON}
							buttonBorderType={ButtonBorderType.BLACK_BORDER}
							px="61px"
							py="22px"
							handleClick={toggleRegisterModal}
						/>
					)}
				</div>
			</div>
			<div className="row d-flex justify-content-center mx-0 py-5">
				<div className="col-sm-11 col-xl-9">
					<ImageRenderer imageName="image1" />
					<ImageRenderer imageName="image2" />
					<ImageRenderer imageName="image3" />
				</div>
			</div>
			{upcomingWebinars.length > 0 && (
				<div className="py-3" id={EXPLORE_RECORDED_SECTION}>
					<CommonSlider
						headingText="Upcoming Webinars"
						headingSubText="Learn from the Experienced Pro Traders"
						courses={upcomingWebinars}
						userCourses={userCourses}
						user={user}
						showBackdropShadow={false}
						slidesToShow={4}
						sliderComponentType={SliderComponentType.COURSES}
						showViewAllButton={false}
					/>
				</div>
			)}
			<Best wrapperClass="mt-3 mb-5" />
			<Stories />
			<Faq />
			<div className="row d-flex justify-content-center mx-0 pb-5">
				<div className="col-md-11 col-xl-9">
					<div className="d-flex justify-content-center flex-column gap-5">
						<span className="fs-40 g_mid text-center">Explore All Categories</span>
						<div className="row g-3">
							{navigateToCourseTypesArray.map((item) => (
								<div className="col-12 col-md-6 col-lg-3" key={item.text}>
									<BlackBoxRenderer text={item.text} onClick={item.onClick} image={item.image} />
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default WebinarLandingPage;
