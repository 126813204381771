import React from 'react';
import './CourseProgramOverview.scss';
import { CourseSections, CourseTypeEnum } from 'components/_v2/Course/type';
import { Image } from 'react-bootstrap';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { QuizReattemptDetails } from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import CourseAccordion from 'components/_v2/CourseDetail/CourseContent/CourseAccordion';

const CourseProgramOverview = ({
	sections = [],
	fullScale = false,
	showLockIcon = false,
	showCloseIcon = false,
	onCloseClick,
	handleLessonChange,
	currentSelectedLessonId,
	quizReattemptList,
	noBorderAccordion = false,
	hasCertificate = false,
	courseType,
	currentSelectedSectionId,
}: {
	sections: CourseSections[];
	fullScale?: boolean;
	showLockIcon?: boolean;
	showCloseIcon?: boolean;
	onCloseClick?: () => void;
	handleLessonChange?: (lessonId: string) => void;
	currentSelectedLessonId?: string;
	quizReattemptList?: QuizReattemptDetails[];
	noBorderAccordion?: boolean;
	hasCertificate?: boolean;
	courseType: CourseTypeEnum;
	currentSelectedSectionId: string;
}) => {
	const isSmallScreen = useIsSmallScreen();

	return (
		<section className={fullScale ? '' : isSmallScreen ? 'pt-3' : 'p-3'}>
			<div className="container">
				<div className={`row ${fullScale ? '' : 'px-lg-5 px-sm-3 px-2'}`}>
					<div className={` ${fullScale ? 'col-xl-12' : 'col-lg-7 col-12'}`}>
						<div className="row">
							<div className={`${fullScale ? '' : 'offset-lg-1'} p-lg-0`}>
								<h2
									className={`ibm_semi course-content-title-${
										fullScale ? 'full-scale d-flex justify-content-between' : 'normal-scale'
									} lora`}>
									{fullScale ? 'Course Content' : 'Program Overview'}
									{showCloseIcon && (
										<Image
											onClick={onCloseClick}
											role="button"
											src={toAbsoluteUrl('/media/course-detail-images/cancel.svg')}
										/>
									)}
								</h2>
								<div>
									{/*<div className="accordion" id="accordionExample">*/}
									{!sections.length && fullScale ? (
										<p className="g_semi fs-16 p-2">
											There are currently no lessons in this course
										</p>
									) : (
										<CourseAccordion
											courseType={courseType}
											hasCertificate={hasCertificate}
											currentSelectedLessonId={currentSelectedLessonId}
											fullScale={fullScale}
											handleLessonChange={handleLessonChange}
											quizReattemptList={quizReattemptList}
											sections={sections}
											showLockIcon={showLockIcon}
											noBorderAccordion={noBorderAccordion}
											currentSelectedSectionId={currentSelectedSectionId}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CourseProgramOverview;
