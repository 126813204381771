import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { CountryObjectType } from 'generated/types';
import { useUserContext } from './UserContext';

interface DataArrayType {
	value: string;
	label: string;
}
// Define the shape of the context state
interface UserAddressState {
	countries: DataArrayType[];
	setCountries: React.Dispatch<React.SetStateAction<DataArrayType[]>>;
	allCountryData: CountryObjectType[];
	setAllCountryData: React.Dispatch<React.SetStateAction<CountryObjectType[]>>;
	states: DataArrayType[];
	setStates: React.Dispatch<React.SetStateAction<DataArrayType[]>>;
	cities: DataArrayType[];
	setCities: React.Dispatch<React.SetStateAction<DataArrayType[]>>;
	selectedCountryId: string;
	setSelectedCountryId: React.Dispatch<React.SetStateAction<string>>;
	selectedStateId: string;
	setSelectedStateId: React.Dispatch<React.SetStateAction<string>>;
	selectedCityId: string;
	setSelectedCityId: React.Dispatch<React.SetStateAction<string>>;
	changeCountry: (newCountry: DataArrayType) => void;
	changeState: (newState: DataArrayType) => void;
}

// Create the context with a default value
const UserAddress = createContext<UserAddressState | undefined>(undefined);

// Create a provider component
export const UserAddressProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [allCountryData, setAllCountryData] = useState<CountryObjectType[]>([]);
	const [countries, setCountries] = useState<DataArrayType[]>([]);
	const [states, setStates] = useState<DataArrayType[]>([]);
	const [cities, setCities] = useState<DataArrayType[]>([]);
	const [selectedCountryId, setSelectedCountryId] = useState<string>('');
	const [selectedStateId, setSelectedStateId] = useState<string>('');
	const [selectedCityId, setSelectedCityId] = useState<string>('');
	const { user } = useUserContext();

	const changeCountry = (newCountry: DataArrayType) => {
		setSelectedCountryId(newCountry.value);
		const selectedCountryData = allCountryData.find((country) => country.id === newCountry.value);
		setStates(
			selectedCountryData?.states?.map((state) => ({ label: state?.stateName, value: state?.id })),
		);
	};

	const changeState = (newState: DataArrayType) => {
		setSelectedStateId(newState.value);
		const selectedCountryStates = allCountryData.find(
			(country) => country.id === selectedCountryId,
		)?.states;
		const newStateCity = selectedCountryStates?.find(
			(state) => state?.id === newState.value,
		)?.cities;
		setCities(
			newStateCity?.map((city) => ({
				value: city?.id,
				label: city?.cityName,
			})),
		);
		setSelectedCityId('');
	};

	useEffect(() => {
		if (user?.id) {
			const userProfileDetails = user.userProfile?.[0];
			const userAddressDetails = user.userAddress?.[0];
			changeCountry({
				label: userProfileDetails?.country?.countryName,
				value: userProfileDetails?.countryId,
			});
			changeState({ label: userAddressDetails?.state, value: userAddressDetails?.stateId });
			setSelectedCityId(userAddressDetails?.cityId ?? '');
		}
	}, [user?.id]);

	return (
		<UserAddress.Provider
			value={{
				countries,
				setCountries,
				states,
				setStates,
				cities,
				setCities,
				selectedCountryId,
				setSelectedCountryId,
				selectedStateId,
				setSelectedStateId,
				selectedCityId,
				setSelectedCityId,
				allCountryData,
				setAllCountryData,
				changeCountry,
				changeState,
			}}>
			{children}
		</UserAddress.Provider>
	);
};

// Create a custom hook to use the context
export const useUserAddressContext = (): UserAddressState => {
	const context = useContext(UserAddress);
	if (!context) {
		throw new Error('useUserAddress must be used within a UserAddressProvider');
	}
	return context;
};
