import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import './Sidebar.css';
import { MY_COURSE_URL, MY_PROFILE_URL, PAYMENT_HISTORY_URL } from '../routing/routePages';
import useIsSmallScreen from 'hooks/useIsSmallScreen';

type SidebarItem = {
	label: string;
	icon: string;
	iconActive?: string;
	link: string;
};

const Sidebar: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const isSmallScreen = useIsSmallScreen(1200);
	const [activeIndex, setActiveIndex] = useState<number>(0);

	const items: SidebarItem[] = [
		{
			label: 'My Learnings',
			icon: 'book',
			iconActive: 'bookActive',
			link: MY_COURSE_URL,
		},
		{
			label: 'Payment History',
			icon: 'clock',
			iconActive: 'clockActive',
			link: PAYMENT_HISTORY_URL,
		},
		{
			label: 'My Profile',
			icon: 'user',
			iconActive: 'userActive',
			link: MY_PROFILE_URL,
		},
	];

	useEffect(() => {
		const currentPath = location.pathname;
		const currentIndex = items.findIndex((item) => item.link === currentPath);
		setActiveIndex(currentIndex);
	}, [location.pathname, items]);

	const handleItemClick = (index: number, link: string) => {
		setActiveIndex(index);
		navigate(link);
	};

	return (
		<div className="overflow-x-auto">
			<ul className="sidebar-nav p-xl-4 sidebar-mobile-view mb-0">
				{items.map((item, index) => (
					<li key={index} className="sidebar-nav-item">
						<a
							href={item.link}
							className={`sidebar-nav-link g_reg ${activeIndex === index ? 'active' : ''}`}
							onClick={(e) => {
								e.preventDefault();
								handleItemClick(index, item.link);
							}}>
							<img
								src={toAbsoluteUrl(
									activeIndex === index
										? `/media/sidebar/${item.iconActive}.svg`
										: `/media/sidebar/${item.icon}.svg`,
								)}
								alt={item.label}
								className="me-2 d-none d-lg-inline-block my-1 mb-2"
							/>
							<span className={activeIndex === index && !isSmallScreen ? 'white' : 'black'}>
								{item.label}
							</span>
						</a>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Sidebar;
