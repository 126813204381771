import { BreadcrumbDataProps } from 'types/globalTypes';
import {
	CourseEnrollmentObjectType,
	CourseTypeEnum,
	LessonType,
	LessonTypeEnum,
} from '../Course/type';
import { MY_COURSE_URL } from 'pages/routing/routePages';
import { useCourseContext } from 'context/CourseContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from '../Header/Breadcrumbs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CourseProgramOverview from '../CourseDetail/CourseContent/CourseProgramOverview';
import { Image } from 'react-bootstrap';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import CourseLessonData from './CourseLessonData';
import CourseTabs from './CourseTabs';
import FullScreenComponent from './Fullscreen';
import QuizType from './LessonTypesComponents/QuizType';
import {
	CURRENT_SELECTED_SECTION_LESSON_KEY,
	quizLocalStorageKey,
	useQuizContext,
} from 'context/QuizContext';
import './ViewCourseData.css';
import useQuiz from 'hooks/useQuiz';
import { QuizReattemptDetails } from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';
import { useZoomMeeting } from 'context/ZoomMeetingContext';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { removeChatStickyFromUi, reShowChatStickyOnUi } from 'utils/helpers';
import { PRE_RECORDED_COURSE_KEY } from 'pages/auth/core/AuthHelpers';
import { ApolloError, useMutation } from '@apollo/client';
import { UPSERT_COURSE_PROGRESS } from 'components/Courses-Page/core/requests';
import { toast } from 'react-hot-toast';
import moment from 'moment';

export enum LessonChange {
	NEXT = 'NEXT',
	PREVIOUS = 'PREVIOUS',
}

const ViewCourseData = () => {
	const { userCourses, userCourseData } = useCourseContext();
	const {
		quiz,
		quizResult,
		resetQuizState,
		setQuizResult,
		setCurrentSelectedLessonId,
		setCurrentSelectedSectionId,
		currentSelectedLessonId,
		currentSelectedSectionId,
	} = useQuizContext();
	const { isZoomVisited } = useZoomMeeting();
	const navigate = useNavigate();

	const { handleGetQuizResult, handleQuizReattempt, handleQuizSubmit } = useQuiz();
	const { search } = useLocation();
	const searchSlug = new URLSearchParams(search);
	const courseSlug = searchSlug.get('course-slug');
	const isTabletView = useIsSmallScreen(1200);
	const [showCourseModules, setShowCourseModules] = useState<boolean>(true);
	const [quizReattemptList, setQuizReattemptList] = useState<QuizReattemptDetails[]>([]);
	const [upsertCourse, { loading: loadingUpsert, error: errorUpsert }] =
		useMutation(UPSERT_COURSE_PROGRESS);

	const selectedCourse = useMemo(() => {
		const course = userCourses.find((course) => course.slug === courseSlug);
		const currentChapter = currentSelectedLessonId || quiz.lessonId || course?.currentChapter?.id;
		setCurrentSelectedLessonId(currentChapter ?? course?.sections?.[0]?.lessons?.[0]?.id);
		return course;
	}, [userCourses, courseSlug]);

	const allUserCourses: CourseEnrollmentObjectType[] = [
		...userCourseData.enrolledCourses,
		...userCourseData.inProgressCourses,
		...userCourseData.completedCourses,
	];

	const hasCertificate = allUserCourses.find(
		(userCourse) => userCourse.courseId === selectedCourse?.id,
	)?.course?.hasCertificate;

	const handleSelectLessonId = (newLessonId: string) => {
		if (newLessonId != currentSelectedLessonId) {
			setQuizResult((prevState) => ({ ...prevState, isSubmit: false }));
			resetQuizState();
			setCurrentSelectedLessonId(newLessonId);
		}
	};

	const currentSectionIndex = selectedCourse?.sections?.findIndex(
		(section) => currentSelectedSectionId === section.id,
	);
	const currentSection = selectedCourse?.sections[currentSectionIndex];
	const currentLessonIndex = currentSection?.lessons?.findIndex(
		(lesson) => lesson?.id === currentSelectedLessonId,
	);

	const isFirstLessonOfFirstSection: boolean =
		currentSectionIndex === 0 && currentLessonIndex === 0;

	//If has certificate, second last lesson should be the last lesson, else the last lesson should be the last lesson of the course
	const isLastLessonOfLastSection: boolean =
		currentSectionIndex === selectedCourse?.sections?.length - 1 &&
		currentLessonIndex === currentSection?.lessons?.length - 2;

	const handleNextOrPreviousLessonChange = (changeType: LessonChange) => {
		if (changeType === LessonChange.NEXT) {
			const hasNextLessonInSection = currentSection.lessons.length - 1 > currentLessonIndex;
			if (hasNextLessonInSection) {
				const nextLessonId = currentSection.lessons[currentLessonIndex + 1].id;
				handleSelectLessonId(nextLessonId);
			} else {
				const nextSection = selectedCourse.sections[currentSectionIndex + 1];

				const nextSectionFirstLesson = nextSection.lessons[0].id;
				handleSelectLessonId(nextSectionFirstLesson);
			}
		} else {
			const hasPreviousLessonInSection = currentLessonIndex > 0;
			if (hasPreviousLessonInSection) {
				const previousLessonId = currentSection.lessons[currentLessonIndex - 1].id;
				handleSelectLessonId(previousLessonId);
			} else {
				const previousSection = selectedCourse.sections[currentSectionIndex - 1];
				const previousSectionLastLesson =
					previousSection.lessons[previousSection.lessons.length - 1].id;
				handleSelectLessonId(previousSectionLastLesson);
			}
		}
	};

	const [currentSelectedLessonData, setCurrentSelectedLessonData] = useState<LessonType>();

	useEffect(() => {
		const localStorageDurationData = JSON.parse(localStorage.getItem(PRE_RECORDED_COURSE_KEY));
		if (
			selectedCourse?.courseType?.slug === CourseTypeEnum.RECORDED_COURSE &&
			currentSelectedLessonData?.type === LessonTypeEnum.VIDEO
		) {
			const individualData =
				localStorageDurationData[selectedCourse?.id][currentSelectedSectionId][
					currentSelectedLessonData?.id
				];
			if (!individualData?.completed) {
				individualData.completedDuration = 100;
				individualData.completed = true;
				localStorage.setItem(PRE_RECORDED_COURSE_KEY, JSON.stringify(localStorageDurationData));
				upsertCourse({
					variables: {
						upsertCourseProgressInput: {
							courseId: selectedCourse.id,
							lessonId: currentSelectedLessonData.id,
							sectionId: currentSelectedSectionId,
							duration: 100,
							isLessonCompleted: true,
						},
					},
					onError: (err: ApolloError) => {
						toast.error(err.message);
					},
				});
			}
		}
	}, [currentSelectedLessonData, currentSelectedSectionId, selectedCourse]);

	useEffect(() => {
		if (!selectedCourse || !currentSelectedLessonId) return;

		let foundLesson: LessonType | undefined;

		const foundSection = selectedCourse.sections.find((section) => {
			foundLesson = section?.lessons.find((lesson) => lesson?.id === currentSelectedLessonId);
			return !!foundLesson;
		});

		if (foundSection && foundLesson) {
			setCurrentSelectedSectionId(foundSection.id);
			sessionStorage.setItem(
				CURRENT_SELECTED_SECTION_LESSON_KEY,
				JSON.stringify({ sectionId: foundSection.id, lessonId: foundLesson.id }),
			);
		}

		if (foundLesson) {
			setCurrentSelectedLessonData(foundLesson);
			if (foundLesson.quizId) {
				handleGetQuizResult({
					courseId: selectedCourse?.id,
					lessonId: foundLesson?.id,
					quizId: foundLesson?.quizId,
					sectionId: foundLesson?.sectionId,
				});
			}
		}

		//Quiz Reattempt api data fetch call
		if (selectedCourse) {
			handleQuizReattempt(selectedCourse.id, setQuizReattemptList);
		}
	}, [currentSelectedLessonId, selectedCourse]);

	const [transitionClass, setTransitionClass] = useState('');

	useEffect(() => {
		if (showCourseModules) {
			setTransitionClass('transition-enter');
			requestAnimationFrame(() => {
				setTransitionClass('transition-enter-active');
			});
		} else {
			setTransitionClass('transition-exit');
			requestAnimationFrame(() => {
				setTransitionClass('transition-exit-active');
			});
		}
	}, [showCourseModules]);

	useEffect(() => {
		if (isZoomVisited) {
			window.location.reload();
		}
	}, [isZoomVisited]);

	const breadcrumbData: BreadcrumbDataProps[] = [
		{ name: 'My Courses', href: MY_COURSE_URL },
		{ name: selectedCourse?.name },
		{ name: currentSelectedLessonData?.name },
	];

	const showQuizSection =
		!!currentSelectedLessonData?.quiz &&
		quiz.lessonId &&
		!quizResult.isSubmit &&
		quizResult.quizStartTime &&
		quiz.lessonId === currentSelectedLessonId;

	useEffect(() => {
		if (!selectedCourse && userCourses.length) {
			navigate(MY_COURSE_URL);
		}
	}, [selectedCourse, userCourses]);

	useEffect(() => {
		removeChatStickyFromUi();
		return () => {
			reShowChatStickyOnUi();
			sessionStorage.removeItem(CURRENT_SELECTED_SECTION_LESSON_KEY);
		};
	}, []);

	const quizTimeEndedSubmit = useCallback(() => {
		const activeQuizData = JSON.parse(sessionStorage.getItem(quizLocalStorageKey));
		const lessonId = Object.keys(activeQuizData)[0];

		if (lessonId) {
			handleQuizSubmit({
				courseId: activeQuizData[lessonId].courseId,
				sectionId: activeQuizData[lessonId].sectionId,
				isSubmit: true,
				lessonId,
				quizId: activeQuizData[lessonId].quizId,
				questionAnswer: activeQuizData[lessonId].questionAnswer,
				is_start: false,
				isInSameCourse: activeQuizData[lessonId].courseId === selectedCourse?.id,
				callbackFunction: async () => {
					//Added this condition to check whether the user is in the current course or not, to set the quiz result values.
					if (
						activeQuizData[lessonId].courseId === selectedCourse?.id &&
						lessonId === currentSelectedLessonId
					) {
						await handleQuizReattempt(activeQuizData[lessonId].courseId, setQuizReattemptList);
						await handleGetQuizResult({
							courseId: activeQuizData[lessonId].courseId,
							lessonId: Object.keys(activeQuizData)[0],
							quizId: activeQuizData[lessonId].quizId,
							sectionId: activeQuizData[lessonId].sectionId,
						});
					}
				},
			});

			// Remove the data to avoid repeated submissions
			sessionStorage.removeItem(quizLocalStorageKey);
		}
	}, [selectedCourse?.id, currentSelectedLessonId, quizReattemptList]);

	useEffect(() => {
		if (!selectedCourse?.id) {
			return;
		}
		const checkQuizEndTime = () => {
			const activeQuizData = JSON.parse(sessionStorage.getItem(quizLocalStorageKey) || '{}');
			if (Object.keys(activeQuizData)?.length == 0) {
				return;
			}
			const lessonId = Object.keys(activeQuizData)[0];
			if (activeQuizData && activeQuizData[lessonId].endTime) {
				const endTime = activeQuizData[lessonId].endTime;

				// Check if endTime has passed
				if (moment().isAfter(moment(endTime))) {
					quizTimeEndedSubmit();
				}
			}
		};

		// Set up interval to check every second
		const intervalId = setInterval(checkQuizEndTime, 1000);

		// Cleanup on component unmount
		return () => clearInterval(intervalId);
	}, [selectedCourse?.id]); // Empty dependency array to run this effect once on mount

	return (
		<div className="d-lg-flex">
			<div className={`${showCourseModules && !isTabletView ? 'col-lg-8' : 'col-lg-12'}`}>
				<div className="d-flex justify-content-between">
					{!showQuizSection && <Breadcrumbs breadcrumbData={breadcrumbData} />}
					<div>
						{!showCourseModules && (
							<div
								className="d-flex gap-2"
								role="button"
								onClick={() => setShowCourseModules(true)}>
								<Image src={toAbsoluteUrl('/media/course-detail-images/arrow-left.svg')} />
								Course Content
							</div>
						)}
					</div>
				</div>
				<div
					className={
						currentSelectedLessonData?.type === LessonTypeEnum.VIDEO ? 'pe-3 pe-sm-0' : ''
					}>
					<div
						className={`position-relative ${
							currentSelectedLessonData?.type === LessonTypeEnum.VIDEO ? 'w-100 h-100' : ''
						}`}>
						{showQuizSection ? (
							<FullScreenComponent>
								<QuizType
									lessonQuiz={currentSelectedLessonData?.quiz}
									lessonId={currentSelectedLessonId}
									sectionId={currentSelectedSectionId}
									courseId={selectedCourse.id}
									setQuizReattemptList={setQuizReattemptList}
								/>
							</FullScreenComponent>
						) : (
							<CourseLessonData
								lessonData={currentSelectedLessonData}
								courseId={selectedCourse?.id}
								handleLessonChange={handleNextOrPreviousLessonChange}
								isFirstLessonOfFirstSection={isFirstLessonOfFirstSection}
								isLastLessonOfLastSection={isLastLessonOfLastSection}
								hasCertificate={hasCertificate}
								quizReattemptList={quizReattemptList}
								setQuizReattemptList={setQuizReattemptList}
								course={selectedCourse}
							/>
						)}
					</div>
				</div>
				<CourseTabs
					course={selectedCourse}
					currentSelectedLessonId={currentSelectedLessonId}
					currentSelectedSectionId={currentSelectedSectionId}
					handleLessonChange={handleSelectLessonId}
					quizReattemptList={quizReattemptList}
				/>
			</div>
			{showCourseModules && !isTabletView && (
				<div className={`col-lg-4 mx-3 ${transitionClass}`}>
					<CourseProgramOverview
						sections={selectedCourse?.sections}
						courseType={selectedCourse?.courseType?.slug}
						fullScale
						showLockIcon
						showCloseIcon
						currentSelectedLessonId={currentSelectedLessonId}
						currentSelectedSectionId={currentSelectedSectionId}
						handleLessonChange={handleSelectLessonId}
						quizReattemptList={quizReattemptList}
						hasCertificate={hasCertificate}
						onCloseClick={() => {
							setTransitionClass('transition-exit');
							requestAnimationFrame(() => {
								setTransitionClass('transition-exit-active');
							});
							setTimeout(() => {
								setShowCourseModules(false);
							}, 300);
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default ViewCourseData;
