import { QuestionAnswerInputType } from './type';

export const calculateCorrectMarksFromQuestionAnswers = (
	questionAnswerArray: QuestionAnswerInputType[],
) => {
	return (questionAnswerArray || [])?.reduce((accumulator, currentValue) => {
		let isCorrectAnswer = false;
		currentValue.answer.forEach((givenAnswer: string) => {
			if ((currentValue.correctAnswer || '').includes(givenAnswer)) {
				isCorrectAnswer = true;
			}
		});
		if (isCorrectAnswer) {
			accumulator += parseInt(currentValue.mark);
		}
		return accumulator;
	}, 0);
};

export const calculateTotalMarksForQuiz = (questionAnswerArray: QuestionAnswerInputType[]) => {
	return (questionAnswerArray || [])?.reduce((accumulator, currentValue) => {
		accumulator += parseInt(currentValue.mark);
		return accumulator;
	}, 0);
};
