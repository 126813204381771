import React from 'react';
import './ourcourse.css';
import { ApolloError } from '@apollo/client';
import { Course } from './type';
import { useLocation } from 'react-router-dom';
import { EXPLORE_OFFLINE_SECTION } from 'utils/consts';
import { SelectedTabType } from 'components/_v2/CommonComponents/types';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import Loading from 'components/_v2/CommonComponents/Loading';
import CommonTabView from 'components/_v2/CommonComponents/CommonTabView';

interface Props {
	loading: boolean;
	error: ApolloError | undefined;
	courses: Course[];
	selectedTabType?: SelectedTabType;
}

const OurCourse: React.FC<Props> = ({
	courses,
	error,
	loading,
	selectedTabType = SelectedTabType.ALL,
}) => {
	const location = useLocation();

	if (loading) return <Loading width={200} />;
	if (error) {
		console.error('Error fetching courses:', error);
		return (
			<div className="d-flex justify-content-center my-5">
				There was some error in fetching courses. Please try again is some time.
			</div>
		);
	}

	const isHomePage = location.pathname === '/';

	return (
		<section className="my-4" id={EXPLORE_OFFLINE_SECTION}>
			<div className="container">
				<div className="row">
					<div className="col-lg-10 layout-set">
						{isHomePage && (
							<div className="text-center">
								<h2 className="lora course-section-title">Explore Our Courses</h2>
								<p className="course-section-subtitle g_reg my-3">
									Explore the courses that our learners love the most. Tried, tested, and highly
									recommended for your success.
								</p>
							</div>
						)}
						<div className="row d-flex justify-content-start">
							{!courses.length ? (
								<div className="d-flex flex-column align-items-center gap-2">
									<img
										src={toAbsoluteUrl('/media/course/no-courses.svg')}
										alt=""
										style={{ width: 400 }}
									/>
									<span className="d-flex g_reg fs-20 justify-content-center align-items-center">
										There are no currently active or upcoming Courses. Stay tuned!
									</span>
								</div>
							) : (
								<CommonTabView
									isUserSpecificContent={false}
									selectedTabType={
										selectedTabType === SelectedTabType.ONLINE ||
										selectedTabType === SelectedTabType.OFFLINE
											? SelectedTabType.LIVE
											: selectedTabType || SelectedTabType.ALL
									}
									selectedChildCategory={selectedTabType}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default OurCourse;
