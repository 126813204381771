import { useEffect, useMemo, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import ImageModalOpener from './Quiz/ImageModalOpener';
import { QuestionAnswerInputType, QuizQuestionObjectType } from './Quiz/type';
import { quizLocalStorageKey, useQuizContext } from 'context/QuizContext';
import QuizQuestion from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/QuizQuestion';
import * as _ from 'lodash';

const QuizRenderer = ({ quizContent }: { quizContent: QuizQuestionObjectType }) => {
	const { quiz, setAnswer } = useQuizContext();
	const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);

	useEffect(() => {
		const existingAnswer = quiz?.answersProvided?.find(
			(question) => question?.questionId === quizContent?.id,
		);
		setSelectedAnswers(existingAnswer?.answer || []);
	}, [quizContent, quiz?.answersProvided]);

	const handleOptionChange = ({
		hasMultipleAnswers,
		answer,
		questionId,
	}: {
		answer: string;
		hasMultipleAnswers: boolean;
		questionId: string;
	}) => {
		if (hasMultipleAnswers) {
			if (selectedAnswers.includes(answer)) {
				setSelectedAnswers(selectedAnswers.filter((option) => option !== answer));
			} else {
				setSelectedAnswers((prevAnswers) => {
					const newAnswers = [...prevAnswers, answer];
					setAnswer(questionId, newAnswers);
					return newAnswers;
				});
			}
		} else {
			setSelectedAnswers([answer]);
			setAnswer(questionId, [answer]);
		}
		const sessionStorageData = JSON.parse(sessionStorage.getItem(quizLocalStorageKey));
		const lessonId = Object.keys(sessionStorageData)[0];
		const sessionStorageQuizDataQuestionAnswers: QuestionAnswerInputType[] =
			sessionStorageData[lessonId].questionAnswer;
		const updatedData: QuestionAnswerInputType[] = sessionStorageQuizDataQuestionAnswers.map(
			(item) => {
				if (item.questionId === questionId) {
					return { ...item, answer: [answer] };
				}
				return item;
			},
		);
		sessionStorageData[lessonId].questionAnswer = updatedData;
		sessionStorage.setItem(quizLocalStorageKey, JSON.stringify(sessionStorageData));
	};

	const randomisedQuizOptions = useMemo(() => {
		return _.shuffle(quizContent?.options);
	}, [quiz.lessonId, quizContent?.id]);

	return (
		<Card className="mb-3 border-0">
			<Card.Body>
				<Card.Title className="quiz-question g_reg">
					<QuizQuestion
						quizQuestion={quizContent?.question}
						quizQuestionImages={quizContent?.questionImages}
					/>
				</Card.Title>
				<Form>
					{randomisedQuizOptions.map((option, index) => (
						<Form.Group
							key={index + option.optionText}
							as={Row}
							className="d-flex align-items-center optionBorder my-3 mx-0 py-2">
							<Col xs="auto">
								<Form.Check
									className={quizContent?.hasMultipleAnswers ? 'custom-checkbox' : 'custom-radio'}
									type={quizContent?.hasMultipleAnswers ? 'checkbox' : 'radio'}
									name={`question-${quizContent?.id}`}
									checked={selectedAnswers.includes(option.optionText)}
									onChange={() =>
										handleOptionChange({
											answer: option.optionText,
											hasMultipleAnswers: quizContent?.hasMultipleAnswers,
											questionId: quizContent.id,
										})
									}
								/>
							</Col>
							<Col
								className="d-flex justify-content-between quiz-option-layout"
								onClick={() =>
									handleOptionChange({
										answer: option.optionText,
										hasMultipleAnswers: quizContent?.hasMultipleAnswers,
										questionId: quizContent.id,
									})
								}>
								{option.optionText && (
									<span className="quiz-option g_reg align-content-center">
										{option.optionText}
									</span>
								)}
								{option.optionImage && <ImageModalOpener imageSrc={option.optionImage} />}
							</Col>
						</Form.Group>
					))}
				</Form>
			</Card.Body>
		</Card>
	);
};

export default QuizRenderer;
