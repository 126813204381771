import { useQuizContext } from 'context/QuizContext';
import React, { useEffect, useMemo, useState } from 'react';
import QuizRenderer from './QuizRenderer';
import OngoingQuizButtons from './Quiz/OngoingQuizButtons';
import { QuizObjectType, QuizReattemptDetails } from './Quiz/type';
import './Quiz.css';
import { Modal } from 'react-bootstrap';
import QuizTimeUp from './Quiz/QuizTimeUp';
import useQuiz from 'hooks/useQuiz';
import * as _ from 'lodash';
import { toast } from 'react-hot-toast';

const QuizType = ({
	lessonQuiz,
	lessonId,
	handleFullScreen,
	isFullScreen,
	courseId,
	sectionId,
	setQuizReattemptList,
}: {
	lessonQuiz: QuizObjectType;
	lessonId: string;
	handleFullScreen?: () => void;
	isFullScreen?: boolean;
	sectionId: string;
	courseId: string;
	setQuizReattemptList: React.Dispatch<React.SetStateAction<QuizReattemptDetails[]>>;
}) => {
	const { quiz, setLessonId } = useQuizContext();
	const { handleGetQuizResult, handleQuizSubmit, handleQuizReattempt } = useQuiz();
	const [isTimeFinished, setIsTimeFinished] = useState<boolean>(false);
	const currentQuestionIndex = useMemo(() => quiz.currentQuestion - 1, [quiz]);
	const randomizedQuizQuestions = useMemo(() => {
		return _.shuffle(lessonQuiz.questions);
	}, [lessonQuiz.id]);

	const currentQuestion = useMemo(() => {
		return randomizedQuizQuestions[currentQuestionIndex];
	}, [currentQuestionIndex]);

	useEffect(() => {
		setLessonId(lessonId);
	}, [lessonId]);

	useEffect(() => {
		if (isTimeFinished) {
			toast.success('Your time is up!');
			setIsTimeFinished(false);
		}
	}, [isTimeFinished]);

	return (
		<div className={`courseContainer p-sm-5 container p-0 ${isFullScreen ? 'border-0' : ''}`}>
			<div className="row">
				<QuizRenderer quizContent={currentQuestion} />
				{currentQuestionIndex + 1 <= randomizedQuizQuestions.length && (
					<OngoingQuizButtons
						courseId={courseId}
						sectionId={sectionId}
						isFullScreen={isFullScreen}
						handleFullScreen={handleFullScreen}
						lessonQuiz={lessonQuiz}
						lessonId={lessonId}
						currentQuestion={currentQuestion}
						onTimeFinish={() => {
							setIsTimeFinished(true);
						}}
					/>
				)}
			</div>
			{/*{isTimeFinished && (*/}
			{/*	<Modal*/}
			{/*		show={isTimeFinished}*/}
			{/*		onHide={() => {*/}
			{/*			setIsTimeFinished(false);*/}
			{/*			handleGetQuizResult({*/}
			{/*				courseId,*/}
			{/*				lessonId,*/}
			{/*				quizId: lessonQuiz.id,*/}
			{/*				sectionId,*/}
			{/*			});*/}
			{/*		}}*/}
			{/*		keyboard*/}
			{/*		animation={false}*/}
			{/*		centered*/}
			{/*		size="sm">*/}
			{/*		<QuizTimeUp*/}
			{/*			onSubmit={() => {*/}
			{/*				setIsTimeFinished(false);*/}
			{/*				handleGetQuizResult({*/}
			{/*					courseId,*/}
			{/*					lessonId,*/}
			{/*					quizId: lessonQuiz.id,*/}
			{/*					sectionId,*/}
			{/*				});*/}
			{/*			}}*/}
			{/*		/>*/}
			{/*	</Modal>*/}
			{/*)}*/}
		</div>
	);
};

export default QuizType;
