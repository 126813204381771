import { useCourseContext } from 'context/CourseContext';
import { useZoomMeeting } from 'context/ZoomMeetingContext';
import { useLocation, useNavigate } from 'react-router-dom';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import React, { useEffect, useMemo, useState } from 'react';
import { CourseEnrollmentObjectType } from 'components/_v2/Course/type';
import { BreadcrumbDataProps } from 'types/globalTypes';
import { MY_COURSE_URL, ZOOM_MEETING } from 'pages/routing/routePages';
import Breadcrumbs from 'components/_v2/Header/Breadcrumbs';
import { Button as BootstrapButton, Image } from 'react-bootstrap';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import CourseCompleted from 'components/_v2/ViewCourseData/CourseCompleted';
import RelatedCourses from 'components/_v2/CourseDetail/RelatedCourses/RelatedCourses';
import { ButtonBorderType, ButtonType } from 'components/_v2/CommonComponents/types';
import Button from 'components/_v2/CommonComponents/Button';
import Loading from 'components/_v2/CommonComponents/Loading';
import useCourses from 'hooks/useCourses';
import { removeChatStickyFromUi, reShowChatStickyOnUi } from 'utils/helpers';

const ViewWebinarData = () => {
	const { userCourses, userCourseData } = useCourseContext();
	const { isZoomVisited } = useZoomMeeting();
	const navigate = useNavigate();
	const { search } = useLocation();
	const { downloadCertificateForCourse } = useCourses();
	const searchSlug = new URLSearchParams(search);
	const courseSlug = searchSlug.get('course-slug');
	const isMobileScreen = useIsSmallScreen(576);
	const [loading, setLoading] = useState(false);
	const selectedCourse = useMemo(() => {
		const course = userCourses.find((course) => course.slug === courseSlug);
		return course;
	}, [userCourses, courseSlug]);

	const allUserCourses: CourseEnrollmentObjectType[] = [
		...userCourseData.enrolledCourses,
		...userCourseData.inProgressCourses,
		...userCourseData.completedCourses,
	];

	const hasCertificate = allUserCourses.find(
		(userCourse) => userCourse.courseId === selectedCourse?.id,
	)?.course?.hasCertificate;

	useEffect(() => {
		if (isZoomVisited) {
			window.location.reload();
		}
	}, [isZoomVisited]);

	const breadcrumbData: BreadcrumbDataProps[] = [
		{ name: 'My Courses', href: MY_COURSE_URL },
		{ name: selectedCourse?.name },
	];

	const handleDownloadCertificate = (courseId: string) => {
		setLoading(true);
		downloadCertificateForCourse({
			courseId,
			onCompletedCallback: () => setLoading(false),
			onErrorCallback: () => setLoading(false),
		});
	};

	const RenderMeetingComponent = useMemo(() => {
		return (
			<div className={'d-flex flex-column align-items-center pb-md-0 gap-4 pb-2'}>
				<Image src={toAbsoluteUrl('/media/course-detail-images/zoom-workplace.svg')} />
				<BootstrapButton
					onClick={() => {
						!isMobileScreen
							? navigate(`${ZOOM_MEETING}?courseId=${selectedCourse.id}&isWebinar=true`)
							: window.open(selectedCourse.meetingLink);
					}}>
					Join Meeting
				</BootstrapButton>
			</div>
		);
	}, [isMobileScreen, selectedCourse.id]);

	const RenderWebinarCompletedButtons = useMemo(
		() => (
			<div className="dashedLine w-100 px-4">
				<div className="d-flex w-100 justify-content-between mb-4 mt-3">
					<Button
						buttonText="Go To My Courses"
						buttonType={ButtonType.GREY_BUTTON}
						classes="g_mid fs-12"
						buttonBorderType={ButtonBorderType.GREY_BORDER}
						handleClick={() => navigate(MY_COURSE_URL)}
					/>
					{selectedCourse.hasCertificate && selectedCourse.completed ? (
						<Button
							buttonText={
								<div className="d-flex">
									<img
										src={toAbsoluteUrl('/media/course-certificate-images/certificate-icon.svg')}
										alt="Certificate"
										className="me-2"
									/>
									{loading && selectedCourse.hasCertificate && (
										<Loading width="50px" height="25px" />
									)}
									Download Certificate
								</div>
							}
							buttonType={ButtonType.PRIMARY_BUTTON}
							classes="g_mid fs-12"
							buttonBorderType={ButtonBorderType.BLACK_BORDER}
							disabled={loading}
							handleClick={() => {
								handleDownloadCertificate(selectedCourse.id);
							}}
						/>
					) : (
						<></>
					)}
				</div>
			</div>
		),

		[],
	);

	useEffect(() => {
		if (!selectedCourse && userCourses.length) {
			navigate(MY_COURSE_URL);
		}
	}, [selectedCourse, userCourses]);

	useEffect(() => {
		removeChatStickyFromUi();
		return () => {
			reShowChatStickyOnUi();
		};
	}, []);

	return (
		<div className="d-lg-flex">
			<div className="col-lg-12">
				<div className="d-flex justify-content-between flex-column">
					<Breadcrumbs breadcrumbData={breadcrumbData} />
					<div>
						<div className="position-relative">
							<div className={`${isMobileScreen ? 'borderSmallDevices py-3' : ''}`}>
								<div
									className={`w-100 courseContainer d-flex justify-content-center align-items-center flex-column gap-3 ${
										isMobileScreen ? 'border-0' : ''
									}`}>
									{!selectedCourse?.completed ? (
										RenderMeetingComponent
									) : (
										<CourseCompleted
											hasCertificate={hasCertificate}
											courseId={selectedCourse.id}
											endDate={new Date(selectedCourse.endDatetime)}
										/>
									)}
								</div>
							</div>
							{!isMobileScreen && (
								<div className="d-flex justify-content-end w-100 lectureButtons">
									{RenderWebinarCompletedButtons}
								</div>
							)}
						</div>
					</div>
					<div className="pt-4">
						<RelatedCourses course={selectedCourse} fullWidth removePadding removeStartingPadding />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewWebinarData;
