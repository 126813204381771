import { Accordion } from 'react-bootstrap';
import AccordionItem from 'react-bootstrap/AccordionItem';
import AccordionHeader from 'react-bootstrap/AccordionHeader';
import AccordionBody from 'react-bootstrap/AccordionBody';
import CustomAccordionBody from 'components/_v2/CommonComponents/AccordionBody';
import { CourseSections, CourseTypeEnum } from 'components/_v2/Course/type';
import { QuizReattemptDetails } from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';
import { useEffect, useState } from 'react';

const CourseAccordion = ({
	sections,
	fullScale,
	noBorderAccordion,
	showLockIcon,
	courseType,
	currentSelectedLessonId,
	handleLessonChange,
	quizReattemptList,
	hasCertificate,
	currentSelectedSectionId,
}: {
	noBorderAccordion: boolean;
	sections: CourseSections[];
	courseType: CourseTypeEnum;
	showLockIcon: boolean;
	currentSelectedLessonId?: string;
	quizReattemptList?: QuizReattemptDetails[];
	handleLessonChange?: (lessonId: string) => void;
	fullScale?: boolean;
	hasCertificate?: boolean;
	currentSelectedSectionId: string;
}) => {
	const [activeKey, setActiveKey] = useState(currentSelectedSectionId);

	useEffect(() => {
		setActiveKey(currentSelectedSectionId);
	}, [currentSelectedSectionId]);

	const handleAccordionChange = (eventKey) => {
		setActiveKey((prevKey) => (prevKey === eventKey ? null : eventKey));
	};

	return (
		<>
			{!sections.length && fullScale && (
				<p className="g_semi fs-16 p-2">There are currently no lessons in this course</p>
			)}
			<Accordion activeKey={activeKey} onSelect={handleAccordionChange}>
				{sections?.map((individualSection) => {
					return (
						<AccordionItem
							eventKey={individualSection.id}
							key={individualSection.id}
							className={noBorderAccordion ? 'border-0' : ''}>
							<AccordionHeader>
								<span className="fs-14 p_mid" style={{ lineHeight: '21px' }}>
									{individualSection.name}
								</span>
							</AccordionHeader>
							<AccordionBody className="p-0">
								<CustomAccordionBody
									lessons={individualSection.lessons}
									courseId={individualSection.courseId}
									showLockIcon={showLockIcon}
									courseType={courseType}
									currentSelectedLessonId={currentSelectedLessonId}
									handleLessonChange={handleLessonChange}
									quizReattemptList={quizReattemptList}
									fullScale={fullScale}
									hasCertificate={hasCertificate}
								/>
							</AccordionBody>
						</AccordionItem>
					);
				})}
			</Accordion>
		</>
	);
};

export default CourseAccordion;
