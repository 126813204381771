import {
	BASE_PAGE_URL,
	MY_COURSE_URL,
	MY_PROFILE_URL,
	PAYMENT_HISTORY_URL,
} from 'pages/routing/routePages';
import { Image, ListGroup } from 'react-bootstrap';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useUserContext } from 'context/UserContext';
import { useCourseContext } from 'context/CourseContext';
import { useUserAddressContext } from 'context/UserAddressContext';

const ProfileListItems = ({ handleClick }: { handleClick?: () => void }) => {
	const basePath = '/media/hgheader/';

	const navigate = useNavigate();
	const { logOutUser } = useUserContext();
	const { setSelectedStateId, setSelectedCityId, setSelectedCountryId, setStates, setCities } =
		useUserAddressContext();
	const { setUserCourses } = useCourseContext();

	const getPublicUrlOnly = useCallback((path: string) => window.location.origin + path, []);

	const handleNavigation = useCallback(
		(href: string, index: number) => {
			localStorage.setItem('activeIndex', index.toString());
			if (handleClick) {
				handleClick();
			}
			navigate(href);
		},
		[navigate, handleClick],
	);

	const handleLogout = useCallback(() => {
		logOutUser();
		setSelectedCityId('');
		setSelectedCountryId('');
		setSelectedStateId('');
		setUserCourses([]);
		setStates([]);
		setCities([]);
		navigate(BASE_PAGE_URL);
		toast.success('Logout successfully');
	}, [logOutUser, navigate]);

	const ListItemRenderer = useCallback(
		({
			src,
			heading,
			href,
			index,
			action,
		}: {
			src: string;
			heading: string;
			href: string;
			index: number;
			action?: () => void;
		}) => (
			<ListGroup.Item className="border-0 py-2">
				<div
					onClick={() => (action ? action() : handleNavigation(href, index))}
					className="d-flex align-items-center g_reg cursor-pointer gap-2">
					<Image src={src} width={30} height={30} className="mr-3" />
					{heading}
				</div>
			</ListGroup.Item>
		),
		[handleNavigation],
	);

	const myCoursesIcon = getPublicUrlOnly(basePath + 'my-courses.svg');
	const paymentHistoryIcon = getPublicUrlOnly(basePath + 'payment-history.svg');
	const myProfileIcon = getPublicUrlOnly(basePath + 'my-profile.svg');
	const logOutIcon = getPublicUrlOnly(basePath + 'logout.svg');

	return (
		<ListGroup>
			<ListItemRenderer heading="My Courses" src={myCoursesIcon} href={MY_COURSE_URL} index={1} />
			<ListItemRenderer
				heading="Payment History"
				src={paymentHistoryIcon}
				href={PAYMENT_HISTORY_URL}
				index={2}
			/>
			<ListItemRenderer heading="My Profile" src={myProfileIcon} href={MY_PROFILE_URL} index={3} />
			<ListItemRenderer
				heading="Log Out"
				src={logOutIcon}
				href={BASE_PAGE_URL}
				action={handleLogout}
				index={null}
			/>
		</ListGroup>
	);
};

export default ProfileListItems;
