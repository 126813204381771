import { LessonType } from 'components/_v2/Course/type';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';
import { OnProgressProps } from 'react-player/types/base';
import ReactPlayer from 'react-player';
import { toast } from 'react-hot-toast';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import './RecordedCourses.scss';
import { ApolloError, useMutation } from '@apollo/client';
import { UPSERT_COURSE_PROGRESS } from 'components/Courses-Page/core/requests';
import { PRE_RECORDED_COURSE_KEY } from 'pages/auth/core/AuthHelpers';

const PREVENT_SEEKING_PERCENTAGE = 10;
const DEBOUNCED_API_TIME = 3000;

const RecordedCourses = ({
	lessonData,
	handleNextClick,
	handlePreviousClick,
	isLastLessonOfLastSection,
	isFirstLessonOfFirstSection,
	courseId,
}: {
	lessonData: LessonType;
	handleNextClick: () => void;
	handlePreviousClick: () => void;
	isFirstLessonOfFirstSection: boolean;
	isLastLessonOfLastSection: boolean;
	courseId: string;
}) => {
	const isMobileView = useIsSmallScreen(576);
	const isTabletView = useIsSmallScreen(992);
	const [isPlayerPlaying, setIsPlayerPlaying] = useState(false);
	const [showNextAndPreviousButtons, setShowNextAndPreviousButtons] = useState(false);
	//Handling the manual seek to prevent infinite onSeek in react player
	const isProgrammaticSeek = useRef(false);
	const [upsertCourse, { loading: loadingUpsert, error: errorUpsert }] =
		useMutation(UPSERT_COURSE_PROGRESS);

	useEffect(() => {
		//Resizing the container would display the buttons if set to !isMobileView. So setting it to false default on change of isMobileView
		handleDisplaySeekButtons(false);
	}, [isMobileView]);

	const playerRef = useRef<ReactPlayer | null>(null);
	const previousProgress = useRef<number>(0);

	//Handle Hover function over the container element
	const handleHoverVideoElement = useCallback(() => {
		if (!isPlayerPlaying && !isMobileView) {
			handleDisplaySeekButtons(true);
		} else {
			handleDisplaySeekButtons(false);
		}
	}, [lessonData.type, isPlayerPlaying, isMobileView]);

	const updateLocalStorageDurationData = useCallback(
		(duration: number, lessonDataDetails: LessonType, isCompleted: boolean) => {
			const localStorageDurationData = JSON.parse(localStorage.getItem(PRE_RECORDED_COURSE_KEY));
			const individualData =
				localStorageDurationData[courseId][lessonDataDetails.sectionId][lessonDataDetails.id];
			if (individualData.completedDuration < duration) {
				individualData.completedDuration = duration;
				individualData.completed = isCompleted;
			}
			localStorage.setItem(PRE_RECORDED_COURSE_KEY, JSON.stringify(localStorageDurationData));
		},
		[courseId],
	);

	const handleDisplaySeekButtons = useCallback(
		(displayValue: boolean) => {
			if (isMobileView) {
				setShowNextAndPreviousButtons(false);
			} else {
				setShowNextAndPreviousButtons(displayValue);
			}
		},
		[isMobileView],
	);

	const upsertProgressApi = useCallback(
		(duration: number, isLessonCompleted: boolean, lessonDataDetails: LessonType) =>
			upsertCourse({
				variables: {
					upsertCourseProgressInput: {
						courseId,
						lessonId: lessonDataDetails.id,
						sectionId: lessonDataDetails.sectionId,
						duration,
						isLessonCompleted,
					},
				},
				onError: (err: ApolloError) => {
					toast.error(err.message);
				},
				onCompleted: () => {
					updateLocalStorageDurationData(duration, lessonDataDetails, isLessonCompleted);
				},
			}),
		[lessonData, courseId],
	);

	//Debounced API to prevent multiple api calls to backend for updating time
	const debouncedApi = useRef(
		throttle((progress: OnProgressProps, lessonDataDetails: LessonType) => {
			previousProgress.current = progress.playedSeconds;
			upsertProgressApi(
				progress.playedSeconds,
				progress.playedSeconds > 0.8 * playerRef.current.getDuration(),
				lessonDataDetails,
			);
		}, DEBOUNCED_API_TIME),
	);

	const handleProgress = useCallback((progress: OnProgressProps, lessonDataDetails: LessonType) => {
		debouncedApi.current(progress, lessonDataDetails);
	}, []);

	useEffect(() => {
		return () => {
			debouncedApi.current.cancel();
		};
	}, []);

	const throttledHandleSeek = (seek: number) => {
		if (!playerRef.current) return;

		const prevSeek = previousProgress.current;
		const videoDuration = playerRef.current.getDuration() || 0;
		const maxAllowedSeek = (videoDuration * PREVENT_SEEKING_PERCENTAGE) / 100;

		// Allow free backward seeking
		if (seek < prevSeek) {
			isProgrammaticSeek.current = true;
			playerRef.current.seekTo(seek);
			return;
		}

		// Restrict forward seeking
		if (seek - prevSeek > maxAllowedSeek) {
			console.log(`Seeking more than ${maxAllowedSeek.toFixed(0)} seconds is not allowed`);
			toast(`You cannot seek more than ${maxAllowedSeek.toFixed(0)} seconds!`);
			isProgrammaticSeek.current = true;
			playerRef.current.seekTo(prevSeek); // Reset to previous position
			return;
		}

		// Update progress for valid forward seeks
		if (seek > prevSeek) {
			previousProgress.current = seek;
		}
	};

	const handleSeekWrapper = (seek: number) => {
		if (isProgrammaticSeek.current) {
			isProgrammaticSeek.current = false; // Reset the flag
			return;
		}
		throttledHandleSeek(seek);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			if (playerRef.current && playerRef.current.getDuration() > 0) {
				const localStorageDurationData = JSON.parse(localStorage.getItem(PRE_RECORDED_COURSE_KEY));
				playerRef.current.seekTo(
					localStorageDurationData[courseId][lessonData.sectionId][lessonData.id].completedDuration,
					'seconds',
				);
				clearInterval(interval);
			}
		}, 500);
		return () => clearInterval(interval);
	}, [lessonData]);

	return (
		<div
			className="player-wrapper w-100 h-100"
			onMouseEnter={handleHoverVideoElement}
			onMouseLeave={() => handleDisplaySeekButtons(false)}>
			<>
				{!isFirstLessonOfFirstSection && (
					<div
						className={`${
							showNextAndPreviousButtons ? 'opacity-100' : 'opacity-0'
						} position-absolute white z-3 previousLessonButton d-flex justify-content-around align-items-center commonButtonClass flex-row bg-white`}
						onClick={(e) => {
							e.stopPropagation();
							if (!showNextAndPreviousButtons) {
								return;
							}
							handlePreviousClick();
						}}>
						<i className="fa fa-solid fa-angle-left primaryColor" />
					</div>
				)}
				{!isLastLessonOfLastSection && (
					<div
						className={`${
							showNextAndPreviousButtons ? 'opacity-100' : 'opacity-0'
						} position-absolute white z-3 nextLessonButton d-flex justify-content-around align-items-center commonButtonClass flex-row bg-white`}
						onClick={(e) => {
							e.stopPropagation();
							if (!showNextAndPreviousButtons) {
								return;
							}
							handleNextClick();
						}}>
						<i className="fa fa-solid fa-angle-right primaryColor" />
					</div>
				)}
			</>

			<iframe
				className="courseContainer"
				src={lessonData?.contentUrl}
				width="100%"
				height="auto"
				allow="autoplay"
				allowFullScreen
				aria-controls=""
				style={{
					border: 'none',
					minHeight: isTabletView ? '56.25vw' : '670px',
				}}
				onLoad={() => {
					handleDisplaySeekButtons(false);
					setIsPlayerPlaying(true);
				}}
				onMouseEnter={() => handleDisplaySeekButtons(true)}
				onContextMenu={(e) => e.preventDefault()}
			/>
		</div>
	);
};

export default RecordedCourses;
