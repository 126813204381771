import moment from 'moment';
import { useEffect, useState } from 'react';

enum TimeUnit {
	DAYS = 'Days',
	HOURS = 'Hours',
	MINUTES = 'Minutes',
	SECONDS = 'Seconds',
}

const IndividualTimer = ({ time, unit }: { time: number; unit: TimeUnit }) => {
	return (
		<div className="col-xxl-3 col-6 g-2">
			<div className="bg-secondaryColor radius d-flex flex-column align-items-center py-2">
				<span className="primaryColor">{time}</span>
				<span>{unit}</span>
			</div>
		</div>
	);
};

const WebinarTimer = ({
	startDateTime,
	onTimerEnd,
}: {
	startDateTime: string;
	onTimerEnd: () => void;
}) => {
	const [remainingTime, setRemainingTime] = useState<number>(
		moment(startDateTime).diff(moment(), 'seconds'),
	);

	// Recalculate remaining time every second
	useEffect(() => {
		const interval = setInterval(() => {
			const diff = moment(startDateTime).diff(moment(), 'seconds');
			setRemainingTime(diff);

			// Update parent's state when the timer reaches 0
			if (diff <= 0) {
				onTimerEnd();
				clearInterval(interval); // Stop the interval
			}
		}, 1000);

		// Cleanup the interval when the component is unmounted or updated
		return () => clearInterval(interval);
	}, [startDateTime]);

	const duration = moment.duration(remainingTime, 'seconds');
	const days = Math.floor(duration.asDays());
	const hours = duration.hours();
	const minutes = duration.minutes();
	const secs = duration.seconds();
	return (
		<>
			<IndividualTimer time={days} unit={TimeUnit.DAYS} />
			<IndividualTimer time={hours} unit={TimeUnit.HOURS} />
			<IndividualTimer time={minutes} unit={TimeUnit.MINUTES} />
			<IndividualTimer time={secs} unit={TimeUnit.SECONDS} />
		</>
	);
};

export default WebinarTimer;
