import React from 'react';
import './PaymentInformation.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { BASE_PAGE_URL, COURSE_URL, MY_COURSE_URL, ZOOM_MEETING } from 'pages/routing/routePages';
import Button from './Button';
import { ButtonBorderType, ButtonType } from './types';
import { useUserContext } from 'context/UserContext';
import moment from 'moment';

interface PaymentStatus {
	image: string;
	alt: string;
	title: string;
	description: React.ReactNode;
	hideButton?: boolean;
	webinarCourse?: {
		courseId: string;
		webinarStartTime: string;
		webinarEndTime: string;
	};
}

const PaymentInformation: React.FC<PaymentStatus> = ({
	image,
	alt,
	title,
	description,
	hideButton = false,
	webinarCourse,
}) => {
	const { user } = useUserContext();
	return (
		<section className="payment-detail-wrapper">
			<div className="container">
				<div className="row justify-content-center">
					<div className="payment-layout mt-5">
						<div className="card px-4">
							<div className="layout-success text-center">
								<img
									src={toAbsoluteUrl('/media/logo/hoGrowthBlack.svg')}
									alt="Logo"
									className="pb-4"
								/>
								<div>
									<img srcSet={image} alt={alt} className="img-fluid mb-4" />
								</div>
								<h2 className="payment-status lora">{title}</h2>
								<p className="payment-desc g_mid">{description}</p>
								<div className="d-flex gap-md-0 justify-content-evenly mb-4 flex-wrap gap-3">
									{webinarCourse?.webinarStartTime &&
									moment(webinarCourse.webinarStartTime).diff(moment(), 'seconds') < 1800 &&
									moment(webinarCourse.webinarEndTime).isAfter(moment()) ? (
										<div className="w-100">
											<Button
												buttonType={ButtonType.PRIMARY_BUTTON}
												buttonBorderType={ButtonBorderType.BLACK_BORDER}
												classes="g_mid fs-16 w-100"
												buttonText="Join Now"
												handleClick={() =>
													(window.location.href = `${ZOOM_MEETING}?courseId=${webinarCourse.courseId}&isWebinar=true`)
												}
											/>
										</div>
									) : (
										<>
											<Button
												buttonText="Back to Home Page"
												buttonType={ButtonType.GREY_BUTTON}
												classes="g_mid w-auto fs-16"
												handleClick={() => (window.location.href = BASE_PAGE_URL)}
												buttonBorderType={ButtonBorderType.GREY_BORDER}
											/>

											{!hideButton && (
												<Button
													buttonText={user ? 'Go to My Courses' : 'Go To All Courses'}
													buttonType={ButtonType.PRIMARY_BUTTON}
													classes="g_mid w-auto fs-16"
													handleClick={() =>
														(window.location.href = user ? MY_COURSE_URL : COURSE_URL)
													}
													buttonBorderType={ButtonBorderType.BLACK_BORDER}
												/>
											)}
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PaymentInformation;
