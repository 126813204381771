import React from 'react';
import './privacypolicy.css';
import TopTitleSection from '../CommonComponents/TopTitleSection';
import { Accordion } from 'react-bootstrap';

const PNP = () => {
	const accordionItems = [
		{
			title: 'Applicability',
			content: `<p class="section-paragraph">This Policy applies to all Users of the Platform. </p> <p class="section-paragraph">HoGrowth owns and/or manages several platforms (other than the Platform) that offer arrange of services including services related to technology solutions in the space of education, either by itself or through its affiliates. Accordingly, this Policy only applies to the collection of your information through the Platform. </p> <p class="section-paragraph">HoGrowth has taken reasonable precautions as per applicable laws and implemented industry standards to treat Personal Information as confidential and to protect it fromunauthorized access, improper use or disclosure, modification and unlawful destruction or accidental loss of the Personal Information.
      </p>`,
		},
		{
			title: 'Access',
			content:
				'You may be allowed to access and view the Platform as a guest and without creating anaccount on the Platform or providing any Personal Information; HoGrowth does notvalidate or takes no responsibility towards information, if any, provided by you as a guest,except as otherwise required under any law, regulation, or an order of competentauthority. HoGrowth may keep records of telephone calls or emails received from ormade by you for making enquiries, feedback, or other purposes for the purpose ofrendering Services effectively and efficiently.',
		},
		{
			title: 'Controllers',
			content:
				'HoGrowth is the controller of Personal Information that it collects and processes in connection with the use of the Platform and the provision of the Services on the Platform. The kind of Personal Information we collect in connection with such use is detailed below.',
		},
		{
			title: 'Personal Information',
			content: `<p class="section-paragraph">“Personal Information” shall mean the information which identifies a User i.e., first and last name, identification number, email address, age, gender, location, photograph and/or phone number provided at the time of registration or any time thereafter on the Platform. “Sensitive Personal Information” shall include (i) passwords and financial data (except the truncated last four digits of credit/debit card),  (ii) health data,  (iii) official identifier (such as biometric data, aadhar number, social security number, driver’s license, passport, etc.,),  (iv) information about sexual life, sexual identifier, race, ethnicity, political or religious belief or affiliation,  (v) account details and passwords, or  (vi) other data/information categorized as ‘sensitive personal data’ or ‘special categories of data’ under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, General Data Protection Regulation (GDPR) (“Data Protection Laws”) and in context of this Policy or other equivalent / similar legislation. </p> <p class="section-paragraph">Usage of the term ‘Personal Information’ shall include ‘Sensitive Personal Information’ as may be applicable to the context of its usage.</p> <p class="section-paragraph">We request you to not provide HoGrowth with any Personal Information unless specifically requested by us. In the event you share with HoGrowth any Personal Information without us having specifically requested for the same, then we bear no liability in respect of such Personal Information provided by you.
      </p > `,
		},
		{
			title: 'Information We Collect',
			content: `       
        <p class="section-paragraph">We only collect information about you if we have a reason to do so — for example, toprovide our Services on the Platform, to communicate with you, or to make our Services better. <br/>We collect this information from the following sources:</p>
        <ul class="section-list">
          <li class="section-list-item"><strong>a. Basic account information: </strong> In order to access certain features of the Platform, you will need to register with us. We ask for basic information which may include your name, an email address, state of residence country, and password, along with a username and phone/mobile number. </li>
          <li class="section-list-item"><strong>b. Know Your Customer ('KYC') information: </strong> If you are an Content Provider registered with us, then, we also collect the KYC information, which may include information pertaining to your PAN number, aadhaar number, driver’s license, passport, your entity details such as name, MOU/AOA, certificate of incorporation, list of directors/partners etc., along with the relevant documents. We collect this information and documents from you to complete our verification process including pursuant to any other arrangement /agreement executed with you.</li>
          <li class="section-list-item"><strong>c. Public profile information: </strong> If you are an Content Provider, we may also collect certain additional information from you to enable creation of your public profile, if any, on the Platform to help Learners and other Users know you better. We do not share your KYC information, documents or any such Personal Information on these public profiles. Your public profile will only contain Personal Information that are required for Learners or Users to know you (as applicable) viz., your username, a brief description of any content you have made available on the Platform, your educational qualifications, your past associations, your photo, and such similar information that may enable a Learner or other User to know you better. The information in your public profile will be based on the information you provide to us or as updated by you on such applicable sections. </li>
          <li class="section-list-item"><strong>d. Information when you communicate with us: </strong> When you write to us with a question or to ask for help, we will keep that correspondence, and the email address, for future reference; this may also include any phone/ mobile numbers if you have provided us the same as part of your communication either in writing (emails included), over a phone call or otherwise. When you browse pages on our Platform, we will track that for statistical purposes which may be to improve the Platform and the Services. You may also provide us your Personal Information or other information when you respond to surveys, enter any form of contests, tests, events, competitions, webinars, etc., hosted by HoGrowth, either on the Platform or otherwise, or when you otherwise communicate with us via form, e-form, email, phone, or otherwise, we store a copy of our communications (including any call recordings or emails, if any, as permitted by applicable law). </li>
          <li class="section-list-item"><strong>e. Information from cookies & other technologies: </strong> We may collect information about you through the use of cookies and other similar technologies. A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. </li>
          <li class="section-list-item"><strong>f. Usage information: </strong> We collect information about your usage of our Platform. We also collect information about what happens when you use our Platform (e.g., page views, support document searches, features enabled for your account, interactions with other parts of our Services) along with information about your Supported Device (e.g., screen size, name of cellular network, and mobile device manufacturer). We use this information to provide our Platform to you, get insights on how people use our Platform so that we can make our Platform better, and understand and make predictions about User retention.</li>
        </ul>`,
		},
		{
			title: 'Basis for Collection and Processing of Your Personal Information',
			content: `<li><strong>Basis for Collection:</strong></li><p class='ms-3 mt-1'>We collect and process your personal information based on the following legal parameters, depending on the nature of the personal information and the purposes for which it is processed:</p>
      <ul class="section-list">
      <li class="section-list-item"><strong>a. Consent: </strong>We rely on your consent to process your Personal Information in certain situations. If we require your consent to collect and process certain Personal Information, as per the requirements under the applicable Data Protection Laws, your consent is sought at the time of collection of your Personal Information and such processing will be performed where consent is secured.</li>
      <li class="section-list-item"><strong>b. Compliance with a legal obligation: </strong> Your Personal Information may be processed by us, to the extent that such processing is necessary to comply with a legal obligation. We do not use Personal Information for making any automated decisions affecting or creating profiles other than what is described in this Policy.</li>
      </ul>`,
		},

		{
			title: 'How we Use Your Information',
			content: `We use/process your information in the following manner: <br/>
      <ul class=" mt-4">
          <li class="section-list-item"><strong>To provide Services on our Platform : </strong> We use your information as collected by us to allow you to access the Platform and the Services offered there in, including without limitation to set-up and maintain your account, provide customer service, fulfil purchases through the Platform, verify User information and to resolve any glitches with our Platform. The legal basis for this processing is consent or, where applicable, our legitimate interests in the proper administration of our Platform, and/or the performance of a contract between you and us.  </li>
          <li class="section-list-item"><strong>To improve our Platform and maintain safety : </strong> We use your information to improve and customize the Platform and Services offered by us, including providing automatic updates to newer versions of our Platform and creating new features based on the Platform usage analysis. </li>
          <li class="section-list-item"><strong>To market our Platform and communicate with You : </strong>We will use your information to develop a more targeted marketing of our Platform, to communicate with you about our offers, new products, services or even receive your feedback on the Platform. The legal basis for this processing is consent or, where applicable, our legitimate interests in the proper administration of our Platform, and/or the performance of a contract between you and us. </li>
          <li class="section-list-item"><strong>To establish, exercise, or defend legal claims :  </strong> We may process any Personal Information identified in this Policy when necessary for establishing, exercising, or defending legal claims, whether in court, administrative, or other proceedings. The legal basis for this processing is our legitimate interest in the protection and assertion of our legal rights, your legal rights, and the legal rights of others. </li>
          <li class="section-list-item"><strong>To manage risk and obtain professional advice : </strong>  We may process any of the Personal Information identified in this Policy to manage risk or obtain professional advice. The legal basis for this processing is our legitimate interest in the proper protection of our business and Platform. </li>
          <li class="section-list-item"><strong>Consent : </strong>We may otherwise use your information with your consent or at your direction.</li>
          <li class="section-list-item"><strong>To Better Understand Our Users : </strong>We may use information we gather to determine which areas of the Services are most frequently visited to understand how to enhance the Services</li>
        </ul>`,
		},
		{
			title: 'Your Choices',
			content: `
         <ul class="">
          <li class="section-list-item"><strong>Limit the information You provide : </strong> You always have an option to choose the information you provide to us, including the option to update or delete your information. However, please note that lack of certain information may not allow you the access to the Platform or any of its features, in part or in full.</li>
          <li class="section-list-item"><strong>Limit the communications You receive from us: </strong> Further, you will also have the option to choose what kind of communication you would like to receive from us and whether you would like to receive such communication at all or not. However, there may be certain communications that are required for legal or security purposes, including changes to various legal agreements, that you may not be able to limit.</li>
          <li class="section-list-item"><strong>Reject Cookies and other similar technologies: </strong> You may reject or remove cookies from your web browser; you will always have the option to change the default settings on your web browser if the same is set to ‘accept cookies’. However, please note that some Services offered on the Platform may not function or be available to you, when the cookies are rejected, removed, or disabled.</li>          
        </ul>`,
		},
		{
			title: 'Your Rights',
			content: `
         <ul class="">
          <li class="section-list-item"><strong>Right to Confirmation and Access : </strong> You have the right to get confirmation and access to your Personal Information that is with us along with other supporting information. </li>
          <li class="section-list-item"><strong>Right to Correction : </strong> You have the right to ask us to rectify your Personal Information that is with us that you think is inaccurate. You also have the right to ask us to update your Personal Information that you think is incomplete or out-of-date.</li>
          <li class="section-list-item"><strong>Right to be Forgotten : </strong> You have the right to restrict or prevent the continuing disclosure of your Personal Information under certain circumstances. </li>          
          <li class="section-list-item"><strong>Right to Erasure : </strong> If you wish to withdraw/remove your Personal Information from our Platform, you have the right to request erasure of your Personal Information from our Platform. However, please note that such erasure will remove all your Personal Information from our Platform (except as specifically stated in this Policy) and may result in deletion of your account on the Platform permanently, and the same will not be retrievable.</li>                
          </ul>`,
		},
		{
			title: 'Modification to Privacy Policy',
			content:
				'Our Policy may change from time to time. We may, at our discretion (unless required by applicable laws to mandatory do so), email periodic reminders of our notices and conditions, unless you have instructed us not to, but we encourage you to check our Platform frequently to see the recent changes. Unless stated otherwise, our current Policy applies to all information that we have about you and your account. We stand behind the promises we make, however, and will not materially change our policies and practices making them less protective of customer information collected in the past without your consent.',
		},
		{
			title: 'Contact for User Support/Queries',
			content:
				'For queries relating to Services offered by HoGrowth, please write to us at <a class="primaryColor g_mid text-decoration-none cursor-pointer" href="mailto:HoGrowth@gmail.com">HoGrowth@gmail.com</a>',
		},
		{
			title: 'Contact Us',
			content:
				'If you have concerns or queries regarding the Platform Terms, you may write to us by <a class="primaryColor g_mid text-decoration-none cursor-pointer" href="mailto:hogrowth@gmail.com">hogrowth@gmail.com</a> or by post to: <strong>Jainam Fincap Private Limited, F-7, 3rd Floor, VIP Plaza, VIP Road, Near Shyam Baba Mandir, Vesu, Surat, Gujarat 395007. </strong>',
		},
	];

	return (
		<section className="privacy-policy-wrapper bg-color19">
			<div className="container-fluid info-layout">
				<div className="row mt-5">
					<TopTitleSection
						title="Privacy Policy"
						description="Explore the courses that our learners love the most. Tried, tested, and highly recommended for your success."
					/>
				</div>
			</div>
			<div className="col-lg-10 layout-set my-3">
				<div className="terms-container p-4 text-black">
					<p className="g_reg color16">
						In addition to our Terms and conditions Service, HoGrowth respects your privacy and is
						committed to protecting it. This Privacy Policy (the “Policy”) explains the types of
						information collected by HoGrowth when you use the Platform (as defined in Terms and
						conditions) that references this Policy, how we collect, use, share and store such
						information collected and also explains the rationale for collection of such
						information, the privacy rights and choices you have regarding your information
						submitted to us when you use the Services.
					</p>
					<p className="g_reg color16">
						For ease of reference, use of the terms “HoGrowth”, “we”, “us, and/or “our” refer to
						Jainam Fincap Private Limited – a company incorporated in India and all of its
						affiliates which have license to host the Platform and offer Services. Similarly, use of
						the terms “you”, “yours” and/or “User(s)” refer to all users of the Platform and
						includes all Learners and Content Providers (as more particularly defined under our
						Terms and conditions).
					</p>

					<Accordion className="my-5" flush>
						{accordionItems.map((item, index) => (
							<Accordion.Item eventKey={index.toString()} key={index} className="border-0">
								<Accordion.Header
									className="custom-accordion-header g_mid fs-3"
									style={{ borderBottom: '1px solid #f1f1f1' }}>
									{item.title}
								</Accordion.Header>
								<Accordion.Body className="bg-backgroundGlobal px-0">
									<div dangerouslySetInnerHTML={{ __html: item.content }} />
								</Accordion.Body>
							</Accordion.Item>
						))}
					</Accordion>
				</div>
			</div>
		</section>
	);
};

export default PNP;
