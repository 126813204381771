import React, { useMemo } from 'react';
import {
	Course,
	CourseEnrollmentStatusEnum,
	LessonStatusEnum,
	LessonType,
	LessonTypeEnum,
} from 'components/_v2/Course/type';
import DocumentType from './LessonTypesComponents/DocumentType';
import LessonButtons from './LessonTypesComponents/LessonButtons';
import QuizCompleted from './LessonTypesComponents/QuizCompleted';
import QuizCompletedButtons from './LessonTypesComponents/Quiz/QuizCompletedButtons';
import {
	calculateCorrectMarksFromQuestionAnswers,
	calculateTotalMarksForQuiz,
} from './LessonTypesComponents/Quiz/helper';
import { useQuizContext } from 'context/QuizContext';
import { LessonChange } from './ViewCourseData';
import { CERTIFICATE_LESSON } from 'types/globalTypes';
import './CourseLessonData.css';
import useCourses from 'hooks/useCourses';
import { useNavigate } from 'react-router-dom';
import { BASE_PAGE_URL, MY_COURSE_URL } from 'pages/routing/routePages';
import {
	QuestionAnswerInputType,
	QuizReattemptDetails,
	QuizResultStatusEnum,
} from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';
import useQuiz from 'hooks/useQuiz';
import moment from 'moment';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { useCourseContext } from 'context/CourseContext';
import RecordedCourses from 'components/_v2/ViewCourseData/LessonTypesComponents/RecordedCourses';

const CourseLessonData = ({
	lessonData,
	courseId,
	handleLessonChange,
	isFirstLessonOfFirstSection,
	isLastLessonOfLastSection,
	hasCertificate = false,
	quizReattemptList,
	setQuizReattemptList,
	course,
}: {
	lessonData: LessonType;
	courseId: string;
	handleLessonChange: (shiftType: LessonChange) => void;
	isFirstLessonOfFirstSection: boolean;
	isLastLessonOfLastSection: boolean;
	hasCertificate: boolean;
	quizReattemptList: QuizReattemptDetails[];
	setQuizReattemptList: React.Dispatch<React.SetStateAction<QuizReattemptDetails[]>>;
	course: Course;
}) => {
	const {
		quizResult,
		resetQuizResultState,
		resetQuizState,
		setQuizResult,
		loadingGetQuizReattempt,
		loadingGetQuizResult,
		setLessonId,
		setEndTime,
		setRemainingTime,
	} = useQuizContext();
	const { handleQuizSubmit, handleQuizReattempt } = useQuiz();
	const { setRetakeQuiz, setAllQuestionAnswers } = useQuizContext();
	const { userCourseData } = useCourseContext();
	const isSmallScreen = useIsSmallScreen(576);
	const navigate = useNavigate();
	const { downloadCertificateForCourse, loadingStudentCourses } = useCourses();
	const showCertificate = lessonData?.id === CERTIFICATE_LESSON;

	const quizReattemptDetails = quizReattemptList.find(
		(listItem) => listItem.lessonId === lessonData?.id && listItem.quizId === lessonData?.quizId,
	);

	const isCourseStateCompleted = useMemo(() => {
		const courseEnrollmentObject = [
			...userCourseData.enrolledCourses,
			...userCourseData.completedCourses,
			...userCourseData.inProgressCourses,
		].find((courseObject) => courseObject.courseId === course?.id);

		return (
			courseEnrollmentObject?.status === CourseEnrollmentStatusEnum.COMPLETED &&
			courseEnrollmentObject.allow_certificate_download
		);
	}, [userCourseData, course?.id]);

	const handleReattemptQuiz = async () => {
		const questionAnswers: QuestionAnswerInputType[] = lessonData.quiz.questions.map((item) => ({
			mark: String(item.mark),
			questionId: item.id,
			answer: [],
			correctAnswer: item.answers[0],
		}));
		await handleQuizSubmit({
			lessonId: lessonData.id,
			isSubmit: false,
			courseId,
			questionAnswer: questionAnswers,
			quizId: lessonData.quizId,
			sectionId: lessonData.sectionId,
			is_start: true,
			callbackFunction: async (data) => {
				resetQuizState();
				resetQuizResultState();
				setAllQuestionAnswers(questionAnswers);
				setQuizResult({
					quizStartTime: moment().toISOString(),
					quizEndTime: data.submitQuiz.endTime,
					isSubmit: false,
					questionAnswer: [],
					quizSubmitTime: data.submitQuiz.quizSubmitTime,
				});
				setLessonId(lessonData.id);
				setEndTime(data.submitQuiz.endTime);
				setRetakeQuiz(true);
				setRemainingTime(moment(data.submitQuiz.endTime).diff(moment(), 'second'));
				await handleQuizReattempt(courseId, setQuizReattemptList);
			},
		});
	};

	const totalMarksAttained = useMemo(
		() => calculateCorrectMarksFromQuestionAnswers(quizResult.questionAnswer),
		[quizResult],
	);

	const totalQuizMarks = useMemo(
		() => calculateTotalMarksForQuiz(quizResult.questionAnswer),
		[quizResult],
	);

	const handleDownloadCertificate = (setLoading: (loading: boolean) => void, courseId: string) => {
		setLoading(true);
		downloadCertificateForCourse({
			courseId,
			onCompletedCallback: () => setLoading(false),
			onErrorCallback: () => setLoading(false),
		});
	};

	const handleNextClick = (setLoading: (loading: boolean) => void) => {
		if (!hasCertificate && showCertificate) {
			navigate(BASE_PAGE_URL);
		} else {
			showCertificate
				? handleDownloadCertificate(setLoading, courseId)
				: handleLessonChange(LessonChange.NEXT);
		}
	};

	const handlePreviousClick = () =>
		showCertificate ? navigate(MY_COURSE_URL) : handleLessonChange(LessonChange.PREVIOUS);

	if (!lessonData) {
		return null;
	}

	return (
		<div className={`${isSmallScreen ? 'borderSmallDevices py-3' : ''}`}>
			<div
				className={`w-100 d-flex justify-content-center align-items-center flex-column gap-3 ${
					isSmallScreen ? 'border-0' : ''
				} ${lessonData.type === LessonTypeEnum.DOCS ? 'bg-white' : ''} ${
					lessonData.type !== LessonTypeEnum.VIDEO ? 'courseContainer' : ''
				} ${
					lessonData.type === LessonTypeEnum.MEETING &&
					lessonData.status === LessonStatusEnum.COMPLETED
						? 'flex-column-reverse'
						: ''
				}`}>
				{quizResult.isSubmit === true ? (
					<QuizCompleted
						totalQuizMarks={totalQuizMarks}
						totalMarksAttained={totalMarksAttained}
						handleLessonChange={handleLessonChange}
						isLastLessonOfLastSection={isLastLessonOfLastSection}
						allowReattempt={quizReattemptDetails?.allowReAttempt}
						isCourseStateCompleted={isCourseStateCompleted && quizReattemptList.length === 0}
						reattemptStatus={quizReattemptDetails?.status}
						handleReattemptQuiz={handleReattemptQuiz}
						eligiblePercentageForCertificate={course.eligiblePercentageForCertificate}
					/>
				) : lessonData.type === LessonTypeEnum.VIDEO ? (
					<RecordedCourses
						lessonData={lessonData}
						handleNextClick={() => handleNextClick(() => null)}
						handlePreviousClick={handlePreviousClick}
						isFirstLessonOfFirstSection={isFirstLessonOfFirstSection}
						isLastLessonOfLastSection={!showCertificate && isLastLessonOfLastSection}
						courseId={courseId}
					/>
				) : (
					<DocumentType
						lessonData={lessonData}
						courseTypeSlug={course.courseType.slug}
						courseId={courseId}
						hasCertificate={hasCertificate}
						showCertificate={showCertificate}
						loadingContent={
							loadingStudentCourses || loadingGetQuizReattempt || loadingGetQuizResult
						}
					/>
				)}
			</div>
			<div className={`d-flex justify-content-end w-100 ${isSmallScreen ? '' : 'lectureButtons'}`}>
				{quizResult.isSubmit &&
				lessonData.type === LessonTypeEnum.QUIZ &&
				!quizReattemptDetails?.allowReAttempt &&
				![QuizResultStatusEnum.FAILED, QuizResultStatusEnum.PENDING].includes(
					quizReattemptDetails?.status,
				) ? (
					<QuizCompletedButtons
						courseId={courseId}
						lessonData={lessonData}
						totalQuizMarks={totalQuizMarks}
						totalMarksAttained={totalMarksAttained}
					/>
				) : lessonData.type === LessonTypeEnum.VIDEO && !isSmallScreen ? null : (
					<LessonButtons
						nextButtonText={
							!hasCertificate && showCertificate
								? 'Back to Home'
								: showCertificate
								? 'Download Certificate'
								: 'Next Lecture'
						}
						previousButtonText={showCertificate ? 'Go Back to My Courses' : 'Previous Lecture'}
						lessonName={lessonData?.name}
						isFirstLessonOfFirstSection={isFirstLessonOfFirstSection}
						isLastLessonOfLastSection={!showCertificate && isLastLessonOfLastSection}
						onNextClick={(setLoading) => handleNextClick(setLoading)}
						allQuizCompleted={quizReattemptList.length === 0 && isCourseStateCompleted}
						isDownloadCertificateButton={showCertificate && hasCertificate}
						course={course}
						onPreviousClick={handlePreviousClick}
					/>
				)}
			</div>
		</div>
	);
};

export default CourseLessonData;
