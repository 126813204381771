import { Modal, Image } from 'react-bootstrap';
import React, { useState } from 'react';
import Stars from 'components/_v2/CommonComponents/SubmitStarRatings/Stars';
import Button from 'components/_v2/CommonComponents/Button';
import { ButtonBorderType, ButtonType } from 'components/_v2/CommonComponents/types';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';

interface Props {
	courseRatings: number;
	courseName: string;
	courseDescription: string;
	onSubmit: (ratings: number, description: string) => void;
	onHide: () => void;
}

const SubmitEditStarRatingsModal = ({
	courseRatings,
	courseName,
	onSubmit,
	onHide,
	courseDescription,
}: Props) => {
	const [starRatings, setStarRatings] = useState<number>(courseRatings);
	const [description, setDescription] = useState<string>(courseDescription);

	return (
		<Modal centered onHide={onHide} show>
			<Modal.Header closeButton>
				<span className="g_mid fs-20 color17">Leave Your Rating</span>
			</Modal.Header>
			<Modal.Body className="pt-0">
				<hr className="mx-2 mt-0" />
				<div className="d-flex align-items-center ms-1 gap-2">
					<Image src={toAbsoluteUrl('/media/MyCourses/courseRatingModal.svg')} alt="Course Image" />
					<span className="g_mid fs-16 color17">{courseName}</span>
				</div>
				<hr className="mx-2" />
				<div className="d-flex flex-column gap-3 px-2">
					<div className="d-flex g_mid fs-16 flex-column flex-sm-row align-items-baseline align-items-sm-center gap-sm-3 gap-0">
						<span>
							Course Ratings<span className="text-danger">*</span>
						</span>
						<div>
							{Array.from(Array(5)).map((_, index) => (
								<Stars
									key={index}
									index={index}
									onClickHandler={() => setStarRatings(index + 1)}
									onMouseEnter={() => null}
									onMouseLeave={() => null}
									ratings={starRatings}
								/>
							))}
						</div>
					</div>
					<div className="d-flex flex-column gap-1">
						<textarea
							className="w-100 g_reg fs-16 disable-resize border-color21 rounded-2 h-auto p-3"
							rows={6}
							maxLength={500}
							onBlur={(e) => setDescription(e.target.value)}
							placeholder={`Leave a review \u2026`}>
							{description}
						</textarea>
						<span className="color21 fs-12">Max Characters 500</span>
					</div>
					<div className="d-flex justify-content-center">
						<Button
							buttonText="Submit Rating"
							classes="g_mid"
							buttonType={ButtonType.PRIMARY_BUTTON}
							handleClick={() => onSubmit(starRatings, description)}
							buttonBorderType={ButtonBorderType.BLACK_BORDER}
							disabled={starRatings === 0}
							type="submit"
							px="20px"
							py="10px"
						/>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default SubmitEditStarRatingsModal;
