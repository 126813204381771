import { createRoot } from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import { AppRoutes } from './pages/routing/AppRoutes';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/sass/style.scss';
import './assets/sass/style.react.scss';
import './assets/sass/fonts.css';
import 'react-responsive-modal/styles.css';
import './fonts/IBMPlexSerif-Regular.ttf';
import './fonts/Switzer-Regular.ttf';
import 'react-tabs/style/react-tabs.css';
import 'react-tooltip/dist/react-tooltip.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './pages/auth/core/Auth';
import { CourseProvider } from 'context/CourseContext';
import { UserProvider } from 'context/UserContext';
import { NotificationProvider } from 'context/NotificationContext';
import ChatStickyUi from 'components/ChatStickyUi/ChatStickyUi';
import { UserAddressProvider } from 'context/UserAddressContext';

// import "react-responsive-modal/styles.css";
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Web3tek mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Web3tek interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	'pdfjs-dist/build/pdf.worker.min.mjs',
	import.meta.url,
).toString();

const container = document.getElementById('root');

if (container) {
	createRoot(container).render(
		<AuthProvider>
			<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_ID}>
				<UserProvider>
					<CourseProvider>
						<NotificationProvider>
							<UserAddressProvider>
								<AppRoutes />
								<ChatStickyUi />
							</UserAddressProvider>
						</NotificationProvider>
					</CourseProvider>
				</UserProvider>
			</GoogleOAuthProvider>
		</AuthProvider>,
	);
}
