import CommonSlider from 'components/_v2/CommonComponents/CommonSlider';
import { SliderComponentType } from 'components/_v2/CommonComponents/types';
import React from 'react';
import { useCourseContext } from 'context/CourseContext';
import { Course } from 'components/_v2/Course/type';
import { useUserContext } from 'context/UserContext';
import _ from 'lodash';

const RelatedCourses = ({
	fullWidth,
	course,
	removePadding = false,
	removeStartingPadding,
	showLesserCourses = false,
}: {
	fullWidth?: boolean;
	course: Course;
	removePadding?: boolean;
	removeStartingPadding?: boolean;
	showLesserCourses?: boolean;
}) => {
	const { courses, userCourses } = useCourseContext();
	const { user } = useUserContext();

	const combinedCourses = _.unionBy(courses, userCourses, 'id');

	const relatedCoursesForCurrentCourse: Course[] = combinedCourses.reduce(
		(accumulator, currentValue) => {
			const doesCourseExistInRelated = course?.relatedCoursesUId.find(
				(relatedCourseId) => relatedCourseId === currentValue.id,
			);
			if (doesCourseExistInRelated) {
				accumulator.push(currentValue);
			}
			return accumulator;
		},
		[],
	);

	return (
		<section className={`${removePadding ? 'px-0' : 'px-3'} pb-5`}>
			<div className="container">
				<div
					className={`row ${
						fullWidth ? 'px-md-5 px-1' : removeStartingPadding ? 'px-0' : 'px-lg-5 px-sm-3 px-2'
					}`}>
					<div
						className={`${fullWidth && removeStartingPadding ? 'col-lg-12' : 'col-lg-7 col-12'}`}>
						<div className="row">
							<div className={`${fullWidth && removeStartingPadding ? '' : 'offset-lg-1'} p-lg-0`}>
								<h2 className="testimonials-heading ibm_semi">Related Courses</h2>
								{relatedCoursesForCurrentCourse.length > 0 ? (
									<CommonSlider
										showBackdropShadow={false}
										slidesToShow={2}
										sliderComponentType={SliderComponentType.COURSES}
										showViewAllButton={false}
										courses={relatedCoursesForCurrentCourse}
										hasTopRightButtons
										user={user}
										userCourses={userCourses}
										fullWidth={fullWidth}
										removeStartingPadding={removeStartingPadding}
										showLesserCourses={showLesserCourses}
									/>
								) : (
									<span className="g_reg fs-20">No Related Courses Found</span>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default RelatedCourses;
