import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import './ChangePassword.css';
import { toast } from 'react-hot-toast';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { useUserContext } from 'context/UserContext';
import Button from '../CommonComponents/Button';
import { ButtonBorderType, ButtonType } from '../CommonComponents/types';
import CommonPasswordInput from 'components/_v2/CommonComponents/CommonPasswordInput';

// GraphQL Mutations
const SEND_EMAIL_OTP = gql`
	mutation SendEmailOtp {
		sendEmailOtp
	}
`;

const NEW_CHANGE_PASSWORD = gql`
	mutation NewChangePassword($otp: String!, $newPassword: String!, $confirmNewPassword: String!) {
		newChangePassword(
			newChangePasswordInput: {
				otp: $otp
				newPassword: $newPassword
				confirmNewPassword: $confirmNewPassword
			}
		)
	}
`;

const OtpForm = ({
	otp,
	setOtp,
	canResend,
	seconds,
	handleResendOTP,
	handleVerificationClose,
	handleVerifyOtp,
	changingPassword,
}) => (
	<form
		onSubmit={(e) => {
			e.preventDefault();
		}}>
		<div className="fv-row mb-10 px-4">
			<label className="label-style g_reg">
				OTP <span className="text-danger">*</span>
			</label>
			<input
				className="form-control input-inner-style g_reg"
				name="otp"
				placeholder="Enter OTP"
				type="password"
				value={otp}
				onChange={(e) => setOtp(e.target.value)}
			/>
			<div className="veri-desc mt-2 mb-4 text-start">
				{canResend ? (
					<button className="btn resend-btn" onClick={handleResendOTP}>
						Resend OTP
					</button>
				) : (
					<>
						Please wait&nbsp;
						<span className="veri-desc-strong">{seconds}s</span>
						&nbsp;before requesting another code
					</>
				)}
			</div>
		</div>
		<div className="row px-4 pb-4">
			<div className="col-12 col-md-6">
				<Button
					buttonText="Cancel"
					classes="g_mid w-100"
					buttonType={ButtonType.GREY_BUTTON}
					handleClick={handleVerificationClose}
					buttonBorderType={ButtonBorderType.GREY_BORDER}
					type="reset"
				/>
			</div>
			<div className="col-12 col-md-6">
				<Button
					buttonType={ButtonType.PRIMARY_BUTTON}
					buttonText={changingPassword ? 'Changing Password...' : 'Continue'}
					classes="g_mid w-100"
					buttonBorderType={ButtonBorderType.NO_BORDER}
					handleClick={() => {
						handleVerifyOtp();
					}}
					type="submit"
				/>
			</div>
		</div>
	</form>
);

const ChangePassword = () => {
	const [modalOpen, setModalOpen] = useState(false);
	const [otp, setOtp] = useState('');
	const [canResend, setCanResend] = useState(false);
	const [seconds, setSeconds] = useState(89);
	const { user } = useUserContext();
	const ChangePasswordEmail = user?.email;

	const [sendEmailOtp, { loading: sendingOtp }] = useMutation(SEND_EMAIL_OTP, {
		onCompleted: () => {
			toast.success('OTP sent successfully!');
			setModalOpen(true);
		},
		onError: (error) => {
			console.error('Error sending OTP:', error);
			toast.error('Error sending OTP');
		},
	});

	const [newChangePassword, { loading: changingPassword }] = useMutation(NEW_CHANGE_PASSWORD);

	const validationSchema = Yup.object().shape({
		newPassword: Yup.string()
			.min(8, 'Minimum of 8 characters')
			.matches(/(?=.*\d)/, 'At least 1 number')
			.matches(/(?=.*[a-z])(?=.*[A-Z])/, 'At least 1 uppercase and 1 lowercase letter')
			.matches(/(?=.*[!@#$%^&*])/, 'At least 1 special character')
			.required('Please enter new password '),
		confirmNewPassword: Yup.string()
			.oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
			.required('Please enter confirm password'),
	});

	const handleVerifyClick = () => {
		setSeconds(89);
		sendEmailOtp();
	};

	const handleVerificationClose = () => {
		setModalOpen(false);
	};

	const handleVerifyOtp = (values, resetForm) => {
		newChangePassword({
			variables: {
				otp,
				newPassword: values.newPassword,
				confirmNewPassword: values.confirmNewPassword,
			},
			onCompleted: () => {
				if (resetForm) {
					resetForm();
				}
				toast.success('Password changed successfully!');
				setOtp('');
				handleVerificationClose();
			},
			onError: (error) => {
				console.error('Error changing password:', error);
				if (error.message.includes('otp not matched')) {
					toast.error('Invalid OTP!');
				} else {
					toast.error(error.message);
				}
			},
		});
	};

	useEffect(() => {
		if (seconds > 0) {
			setCanResend(false);
			const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
			return () => clearTimeout(timer);
		} else {
			setCanResend(true);
		}
	}, [seconds]);

	const handleResendOTP = () => {
		setSeconds(89);
		setCanResend(false);
		sendEmailOtp();
	};

	return (
		<div className="row mt-5">
			<Formik
				initialValues={{ newPassword: '', confirmNewPassword: '' }}
				validationSchema={validationSchema}
				onSubmit={handleVerifyClick}>
				{({ errors, handleSubmit, values: formValues, resetForm }) => (
					<>
						<div className="col-lg-12">
							<div className="row">
								<div className="col-lg-4">
									<h2 className="change-password-title lora">Change Password</h2>
									<Form>
										<div className="col-md-11 my-4">
											<CommonPasswordInput
												label="New Password"
												name="newPassword"
												disabled={sendingOtp}
												placeholder="Enter new Password"
												handleSubmit={handleSubmit}
											/>
										</div>
										<div className="col-md-11">
											<CommonPasswordInput
												label="Confirm New Password"
												name="confirmNewPassword"
												disabled={sendingOtp}
												placeholder="Enter Confirm Password"
												handleSubmit={handleSubmit}
											/>
										</div>
										<div className="mb-lg-0 mt-4 mb-4">
											<Button
												buttonText={sendingOtp ? 'Sending OTP...' : 'Send OTP'}
												buttonType={ButtonType.PRIMARY_BUTTON}
												classes="g_mid fs-16"
												buttonBorderType={ButtonBorderType.BLACK_BORDER}
												disabled={sendingOtp || !!errors.newPassword || !!errors.confirmNewPassword}
												px="26px"
												py="8px"
											/>
										</div>
									</Form>
								</div>
								<div className="col-lg-8">
									<div className="rules-layout">
										<h4 className="rules-title g_semi">Password Rules</h4>
										<p className="rules-label g_reg mb-4">
											To create a new password, you have to meet all of the following rules:
										</p>
										<ul className="rules-label g_reg">
											<li>Minimum of 8 characters</li>
											<li>At least 1 special character</li>
											<li>At least 1 number</li>
											<li>At least 1 capital letter</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						{modalOpen && (
							<div className="modal-backdrop">
								<div
									className="modal fade show"
									id="exampleModalToggle"
									aria-hidden="true"
									aria-labelledby="exampleModalToggleLabel"
									tabIndex={-1}
									style={{ display: 'block' }}>
									<div className="modal-dialog modal-dialog-centered mx-auto">
										<div className="modal-content container">
											<div className="modal-header border-0">
												<div className="mx-auto text-center">
													<img src={toAbsoluteUrl('/media/logo/veri.png')} alt="Verification" />
													<h2 className="veri-title lora">Verification</h2>
													<p className="veri-label veri-otp-layout g_reg">
														Please enter the 6-digit code sent to <br />
														<span className="veri-label-strong">{ChangePasswordEmail}</span>
													</p>
												</div>
											</div>
											<OtpForm
												otp={otp}
												setOtp={setOtp}
												canResend={canResend}
												seconds={seconds}
												handleResendOTP={handleResendOTP}
												handleVerificationClose={handleVerificationClose}
												handleVerifyOtp={() => handleVerifyOtp(formValues, resetForm)}
												changingPassword={changingPassword}
											/>
										</div>
									</div>
								</div>
							</div>
						)}
					</>
				)}
			</Formik>
		</div>
	);
};

export default ChangePassword;
